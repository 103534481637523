import React, {
  createContext,
  useContext,
  FC,
  useState,
  Dispatch,
  SetStateAction,
} from 'react';

import { WorkplaceFormData } from '../../components/commons/WorkplaceForm/workplaceFormSchema';
import { BuyerFragment } from '../../generated';

export type CreateOrJoinBusinessDataType = {
  selectedGooglePlaceDetails?: google.maps.places.PlaceResult;
  setSelectedGooglePlaceDetails: Dispatch<
    SetStateAction<google.maps.places.PlaceResult | undefined>
  >;
  selectedBuyerFromSearch?: BuyerFragment;
  setSelectedBuyerFromSearch: Dispatch<
    SetStateAction<BuyerFragment | undefined>
  >;
  formValues?: WorkplaceFormData;
  setFormValues: Dispatch<SetStateAction<WorkplaceFormData | undefined>>;
};

export const CreateOrJoinBusinessContext = createContext<CreateOrJoinBusinessDataType | null>(
  null
);

export const CreateOrJoinBusinessContextProvider: FC = ({ children }) => {
  const [selectedGooglePlaceDetails, setSelectedGooglePlaceDetails] = useState<
    google.maps.places.PlaceResult
  >();
  const [selectedBuyerFromSearch, setSelectedBuyerFromSearch] = useState<
    BuyerFragment
  >();
  const [formValues, setFormValues] = useState<WorkplaceFormData>();
  return (
    <CreateOrJoinBusinessContext.Provider
      value={{
        selectedGooglePlaceDetails,
        setSelectedGooglePlaceDetails,
        selectedBuyerFromSearch,
        setSelectedBuyerFromSearch,
        formValues,
        setFormValues,
      }}
    >
      {children}
    </CreateOrJoinBusinessContext.Provider>
  );
};

export const useCreateOrJoinBusinessContext = (): CreateOrJoinBusinessDataType => {
  const context = useContext(CreateOrJoinBusinessContext);

  if (context === null) {
    throw new Error(
      'useCreateOrJoinBusinessContext must be used within a Provider'
    );
  }

  return context;
};
