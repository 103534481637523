/* eslint-disable import/no-unresolved */
/* eslint-disable global-require */

import { REACT_APP_ENV_PREFIX } from '../../config';

import { EnvironmentKey } from './types';

export const isProduction = REACT_APP_ENV_PREFIX === 'choprd';

export const environments = isProduction
  ? require('prodConfig').config
  : require('devConfig').config;

export const getEnvConfig = (envKey: EnvironmentKey) => {
  return environments[envKey];
};
