import gql from 'graphql-tag';

export const BuyerEdgesFragment = gql`
  fragment BuyerEdgesFragment on UserBuyerConnection {
    edges {
      node {
        ...BuyerFragment
      }
    }
  }

  fragment BuyerFragment on Buyer {
    id
    name
    fullAddress
    profileImage {
      smallSquare
    }
    googlePlaceId
    googlePlaceAddressId
    longitude
    latitude
  }
`;
