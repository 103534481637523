import {
  WebLogger,
  IWebLogger,
  ConsoleLoggerProvider,
  DataDogLoggerProvider,
} from '@chocoapp/web-logger';

const isProduction = process.env.NODE_ENV === 'production';

const developmentLoggerProviders: IWebLogger[] = [
  new ConsoleLoggerProvider(),
  new DataDogLoggerProvider(
    window.DD_LOGS.createLogger('choco-web-catalog-dev')
  ),
];

const productionLoggerProviders: IWebLogger[] = [
  new DataDogLoggerProvider(window.DD_LOGS.createLogger('choco-web-catalog')),
];

export const AppLogger = new WebLogger({
  providers: isProduction
    ? productionLoggerProviders
    : developmentLoggerProviders,
});
