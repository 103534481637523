import { useGetUserIntercomCredentialsLazyQuery } from '../generated';

export function useIntercomCredentialsQuery() {
  const [getCredentials, { data }] = useGetUserIntercomCredentialsLazyQuery({
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    errorPolicy: 'all',
  });

  const userIntercomCredentialsResponse = data?.getUserIntercomCredentials;

  return {
    userIntercomCredentialsResponse,
    getCredentials,
  };
}
