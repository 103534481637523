import {
  CognitoUser,
  CognitoIdToken,
  CognitoUserSession,
} from 'amazon-cognito-identity-js';

import { AppLogger } from '../application/logger';

export default function createGetCurrentIdTokenHandler({
  cognitoUser,
}: {
  cognitoUser: CognitoUser | null;
}) {
  return () =>
    new Promise<CognitoIdToken & { jwtToken?: string }>((resolve, reject) => {
      // as we do in "getUserIdFromCognitoSync", if we don't have cognitoUser we just return
      if (!cognitoUser) {
        // we don't need to log exception here
        reject(Error('Session not found'));
        return;
      }

      cognitoUser.getSession(
        (err: Error, session: CognitoUserSession | null) => {
          if (err) {
            AppLogger.error('createGetCurrentIdTokenHandler', { err });
            reject(err);
          }

          if (session && !session.isValid()) {
            const refreshToken = session.getRefreshToken();
            cognitoUser.refreshSession(
              refreshToken,
              (refreshErr: Error, newSession: CognitoUserSession) => {
                if (refreshErr) {
                  AppLogger.error(
                    'createGetCurrentIdTokenHandler-refreshSession',
                    {
                      refreshErr,
                    }
                  );

                  reject(refreshErr);
                }

                resolve(newSession.getIdToken());
              }
            );
          }

          resolve(cognitoUser!.getSignInUserSession()!.getIdToken());
        }
      );
    });
}
