import jwtDecode from 'jwt-decode';

import { AppLogger } from '../application/logger';

export const decodeJwt = (token: string | string[]) => {
  try {
    return jwtDecode(token as string);
  } catch (error) {
    AppLogger.error('decodeJwtError', { error });
    return 'invalid token';
  }
};
