import queryString from 'query-string';

import { ObjectWithType } from '../util';

import resolveNamedRoute from './resolveNameModule';

export type NamedPathProps = {
  to: string;
  params?: ObjectWithType<any>;
  search?: ObjectWithType<any>;
  state?: ObjectWithType<any>;
};

export default function namedPathResolver(props: NamedPathProps) {
  const { to, params = {}, search } = props;

  const { path } = resolveNamedRoute({ to });

  const pathWithParams = Object.entries(params).reduce(
    (accumulator, [key, value]) => accumulator.replace(`:${key}`, value),
    path!
  );

  return search
    ? `${pathWithParams}?${queryString.stringify(search)}`
    : pathWithParams;
}
