import { Text, Flex, ChocoIcon, theme } from '@chocoapp/chocolate-ui';
import React from 'react';
import { useTranslation } from 'react-i18next';

export const NoPredictions = () => {
  const { t } = useTranslation('invitePage');

  return (
    <Flex
      data-testid="NoPredictions"
      sx={{
        justifyContent: 'center',
        alignItems: 'center',
        p: 'xl',
        gap: 'm',
        color: theme.colors.neutralTextWeak,
      }}
    >
      <ChocoIcon name="search" />

      <Text
        variant="BodyStrong"
        sx={{
          textAlign: 'center',
        }}
      >
        {t('noPredictionsFound')}
      </Text>
    </Flex>
  );
};
