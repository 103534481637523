import { CognitoUser, CognitoUserPool } from 'amazon-cognito-identity-js';

import { InitiateAuthType } from './AuthContext';

export const onRetryOTP = ({
  initiateAuth,
  currentPhoneNumber,
  reject,
  error,
  userPool,
}: {
  initiateAuth: InitiateAuthType;
  currentPhoneNumber?: string;
  reject: (err: Error) => void;
  error: Error;
  userPool: CognitoUserPool;
}) => {
  initiateAuth(currentPhoneNumber!, userPool);
  reject(error);
};

export const createSubmitOneTimePasswordHandler = ({
  cognitoUser,
  currentPhoneNumber,
  initiateAuth,
}: {
  cognitoUser: CognitoUser | null;
  currentPhoneNumber?: string;
  initiateAuth: InitiateAuthType;
}) => {
  return (oneTimePassword: string, userPool: CognitoUserPool) =>
    new Promise<void>((resolve, reject) => {
      cognitoUser!.sendCustomChallengeAnswer(oneTimePassword, {
        onSuccess: () => {
          resolve();
        },
        onFailure: (error: Error) =>
          onRetryOTP({
            initiateAuth,
            currentPhoneNumber,
            reject,
            error,
            userPool,
          }),
      });
    });
};
