import { Flex, useMediaQuery } from '@chocoapp/chocolate-ui';
import { useFlags } from '@chocoapp/launchdarkly-ui';
import { usePermissions } from '@chocoapp/toolbelt-auth';
import {
  identifyUser as identifyUserSegment,
  setSessionId as setSegmentSessionId,
  getSessionId as getSegmentSessionId,
  setExtraFields as setSegmentExtraFields,
  removeExtraFields as removeSegmentExtraFields,
  groupSegment,
  getExtraFields,
  setGroupId,
  getGroupId,
} from '@chocoapp/toolbelt-utils/lib/segment';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import React, { FC, useLayoutEffect, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { useAuthContext } from '../auth';
import PermissionsProvider from '../auth/PermissionsProvider';
import { ProfileBar } from '../components/commons';
import { GlobalNavigation } from '../components/commons/GlobalNavigation';
import GlobalNavigationV2 from '../components/commons/GlobalNavigationV2/GlobalNavigationV2';
import { OverrideState } from '../components/commons/OverrideState';
import { getIsUserTrial } from '../components/commons/Paywall/useTrackPremiumScreen';
import { PaywallBanner } from '../components/commons/PaywallBanner/PaywallBanner';
import { REACT_APP_ENV_PREFIX } from '../config';
import { LAUNCH_DARKLY } from '../constants/AppConstants';
import { useOnNotificationCreateSubscription } from '../data/useOnNotificationCreateSubscription';
import { UserFragment } from '../generated';
import { useBeforeUnload, useChatId } from '../hooks';
import { useLoggedInUser } from '../hooks/useLoggedInUser';
import { launchDarklyUserSubject } from '../portal/subjects';
import { LoggedInUserProvider } from '../store/LoggedInUserProvider';
import registerActivityDetector, {
  createDetector,
  removeSession,
  clearDetectorInstance,
} from '../util/activityDetector';
import { generateLDUser } from '../util/launchDarkly';
import { getEmailFromIntegrations } from '../util/supplier';

function useMainPageAnalytics(loggedInUser: UserFragment) {
  useLayoutEffect(() => {
    if (loggedInUser.id) {
      const { id } = loggedInUser;
      const name = loggedInUser.name ?? '';
      const email = loggedInUser.email ?? '';
      identifyUserSegment(id, { name, email });
    }
  }, [loggedInUser]);

  useLayoutEffect(() => {
    createDetector();
    registerActivityDetector();
    setSegmentSessionId(getSegmentSessionId());
  }, []);

  useEffect(() => {
    const referenceId = loggedInUser.linkedSupplier?.referenceId;

    if (!getExtraFields().referrer) {
      setSegmentExtraFields({ referrer: null });
    }

    setSegmentExtraFields({
      reference_id: referenceId,
      is_test: loggedInUser.isTest,
      is_supplier: loggedInUser.supplier,
      user_id: loggedInUser.id,
      support_login: loggedInUser.chocoUser,
      create_context: loggedInUser.createdContext,
      is_puppy: loggedInUser.isPuppy,
      platform: 'Web',
      ...(loggedInUser.linkedSupplier
        ? {
            access_package: loggedInUser.linkedSupplier?.accessPackage,
            supplier_entity_id: loggedInUser.linkedSupplier.id,
          }
        : {}),
    });

    if (loggedInUser.linkedSupplier) {
      setGroupId(loggedInUser.linkedSupplier.id);

      groupSegment({
        groupId: getGroupId(),
        traits: {
          name: loggedInUser.linkedSupplier.name,
          email: getEmailFromIntegrations(
            loggedInUser.linkedSupplier?.integration || []
          ),
          refId: referenceId,
          type: 'supplier',
        },
      });
    }
  }, [loggedInUser]);

  useBeforeUnload(() => {
    removeSession();
    clearDetectorInstance();
    removeSegmentExtraFields();
  });
}

export function useFeatureFlagUserRegister(loggedInUser?: UserFragment) {
  const ldClient = useLDClient();

  useLayoutEffect(() => {
    if (!ldClient || !loggedInUser?.id) return;

    ldClient.identify(generateLDUser(loggedInUser));
    sessionStorage.setItem(
      LAUNCH_DARKLY,
      JSON.stringify(generateLDUser(loggedInUser))
    );
    launchDarklyUserSubject.next(generateLDUser(loggedInUser));
  }, [ldClient, loggedInUser]);
}

type MainPageBodyProps = {
  actualLoggedInUser: UserFragment;
};

const MainPageBody: FC<MainPageBodyProps> = ({
  actualLoggedInUser,
  children,
}) => {
  const { webNavigation, paywallOct31Milestone } = useFlags();
  const { showGlobalNavigation } = useAuthContext();
  const isMedium = useMediaQuery();
  const { isSupplier } = usePermissions();
  const { pathname } = useLocation();

  const showNewNavbar =
    isSupplier ||
    // show new navbar if flag is on && buyer has only one location
    (webNavigation && actualLoggedInUser.buyers?.edges.length === 1);

  const isTrial = getIsUserTrial(actualLoggedInUser);
  const showPaywallBanner = isSupplier && paywallOct31Milestone && isTrial;

  const showProfileBar =
    !showNewNavbar &&
    !pathname.startsWith('/team') &&
    !pathname.startsWith('/inventory');

  return (
    <Flex
      sx={{
        bg: 'neutralBg',
        width: '100%',
        height: '100vh',
        flexDirection: 'column',
      }}
    >
      {showPaywallBanner && <PaywallBanner />}

      {showProfileBar && <ProfileBar actualLoggedInUser={actualLoggedInUser} />}

      <Flex sx={{ minHeight: 0, flex: 1 }}>
        {showGlobalNavigation && isMedium && !showNewNavbar && (
          <GlobalNavigation />
        )}

        {showNewNavbar && (
          <GlobalNavigationV2 actualLoggedInUser={actualLoggedInUser} />
        )}

        {children}
      </Flex>
      {REACT_APP_ENV_PREFIX !== 'choprd' && <OverrideState />}
    </Flex>
  );
};

const MainPage: FC = ({ children }) => {
  const chatId = useChatId();
  const { userPool, getCurrentUserId } = useAuthContext();
  const userId = getCurrentUserId(userPool);
  const { loggedInUser, loading } = useLoggedInUser();

  useMainPageAnalytics(loggedInUser);
  // Temp fix to make sure flags are loaded when
  // loggedInUser is fetched on login
  // Solving the following bug https://choco.atlassian.net/browse/INV-303
  useFeatureFlagUserRegister(loggedInUser);
  useOnNotificationCreateSubscription(userId, chatId);

  return (
    <PermissionsProvider>
      <LoggedInUserProvider user={loggedInUser} isLoading={loading}>
        <MainPageBody actualLoggedInUser={loggedInUser}>
          {children}
        </MainPageBody>
      </LoggedInUserProvider>
    </PermissionsProvider>
  );
};

export default MainPage;
