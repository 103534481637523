import {
  UserUpdateInput,
  useUpdateProfileMutation,
  UpdateProfileMutation,
  UserFragment,
  UserDocument,
  UserQueryVariables,
} from '../generated';

export type UpdateProfileInput = Pick<
  UserUpdateInput,
  'id' | 'name' | 'email' | 'supplier'
>;

const isUserUpdateSuccess = (
  userUpdate: UpdateProfileMutation['userUpdate']
): userUpdate is UserFragment => {
  return userUpdate?.__typename === 'User';
};

export const useUpdateProfileDataMutation = () => {
  const [updateProfileMutation, { loading }] = useUpdateProfileMutation();

  const updateProfile = async (user: UserUpdateInput) => {
    const { data } = await updateProfileMutation({
      variables: {
        user,
      },
      update: (store, { data: updateData }) => {
        if (updateData && isUserUpdateSuccess(updateData?.userUpdate)) {
          const variables: UserQueryVariables = {
            userId: user.id,
          };
          store.writeQuery({
            query: UserDocument,
            variables,
            data: updateData.userUpdate,
          });
        }
      },
    });

    if (isUserUpdateSuccess(data?.userUpdate)) {
      return {
        success: true,
      };
    }

    return {
      success: false,
    };
  };

  return { updateProfile, loading };
};
