import { ThemeUIStyleObject } from '@chocoapp/chocolate-ui';

export const wrapperStyles: ThemeUIStyleObject = {
  p: '14px',
  gap: '8px',
  height: '64px',
  alignItems: 'center',
  cursor: 'pointer',
  color: 'baseTextWeak',

  ':hover': {
    color: 'baseTextWeakActive',
  },
};
