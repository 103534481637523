import { CognitoUser, CognitoUserSession } from 'amazon-cognito-identity-js';

import useInterval from '../hooks/useInterval';

type HistoryPushType = ({
  to,
  state,
}: {
  to: string;
  state?:
    | {
        [key: string]: any;
      }
    | undefined;
}) => void;

const REFRESH_TIME = 5 * 60000; // 5 minutes

export default function useRefreshTokenPolling({
  cognitoUser,
}: {
  cognitoUser: CognitoUser | null;
  historyPush: HistoryPushType;
}) {
  useInterval(() => {
    if (!cognitoUser) return;

    cognitoUser.getSession(
      (sessionError: Error, session: CognitoUserSession | null) => {
        if (sessionError || !session) {
          return;
        }

        const refreshToken = session.getRefreshToken();
        cognitoUser.refreshSession(refreshToken, () => {});
      }
    );
  }, REFRESH_TIME);
}
