import { ChocoInput, ThemeUIStyleObject } from '@chocoapp/chocolate-ui';
import { isNil } from '@chocoapp/toolbelt-utils';
import React, { useCallback, useEffect, useRef } from 'react';

import { getChangesFromKeyDownEvent } from '../../auth/helpers/getChangesFromKeyDownEvent';
import { replaceOtpDigitsAtIndex } from '../../auth/helpers/replaceOtpDigitsAtIndex';
import { isNumeric } from '../../util/isNumeric';

/**
 * OTP input should contain only one digit, but if user types a 2nd one
 * figure out whether we keep the 1st or 2nd one based on the cursor position
 */
const getNewlyTypedDigit = (event: React.ChangeEvent<HTMLInputElement>) => {
  const inputValue = event.currentTarget.value;
  const cursorPosition = event.currentTarget.selectionStart;

  return cursorPosition === 2 ? inputValue[1] : inputValue[0];
};

const inputStyles: ThemeUIStyleObject = {
  width: '50px',
  fontSize: '20px',
  textAlign: 'center',
  caretColor: 'transparent',
  '&:not(:placeholder-shown):focus': {
    caretColor: 'transparent',
  },
};

type SingleOTPInputProps = {
  value: string;
  index: number;
  hasError: boolean;
  disabled: boolean;
  hasFocus: boolean;
  changeFocusIndex: (
    indexOffset: number,
    method: 'absolute' | 'relative'
  ) => void;
  otp: string[];
  setOtp: React.Dispatch<React.SetStateAction<string[]>>;
};

export const SingleOTPInput = ({
  otp,
  setOtp,
  value,
  index,
  hasError,
  disabled,
  hasFocus,
  changeFocusIndex,
}: SingleOTPInputProps) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const replaceCharAtIndex = useCallback(
    (newValue: string) => {
      if (!isNumeric(newValue) && newValue !== '') return;

      const newOtp = replaceOtpDigitsAtIndex(otp, newValue, index);
      setOtp(newOtp);

      const direction = newValue === '' ? -1 : 1;
      changeFocusIndex((newValue.length || 1) * direction, 'relative');
    },
    [changeFocusIndex, index, otp, setOtp]
  );

  const onChangeHandler = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      replaceCharAtIndex(getNewlyTypedDigit(event));
    },
    [replaceCharAtIndex]
  );

  const onKeyDownHandler = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      const changes = getChangesFromKeyDownEvent(event, otp[index]);

      if (!isNil(changes.value)) replaceCharAtIndex(changes.value);
      if (changes.focusIndex) changeFocusIndex(changes.focusIndex, 'relative');
    },
    [changeFocusIndex, index, otp, replaceCharAtIndex]
  );

  const onPasteHandler = useCallback(
    (event: React.ClipboardEvent<HTMLInputElement>) => {
      const pastedText = event.clipboardData.getData('text/plain');

      if (pastedText.length === 1) return; // only handle paste events of 2 numbers (or more)

      replaceCharAtIndex(pastedText);
    },
    [replaceCharAtIndex]
  );

  useEffect(() => {
    if (!inputRef.current) return;

    const isBeingReset = hasError && index === 0 && disabled === false;
    const isBeingFocused = hasFocus && !disabled;

    if (isBeingReset || isBeingFocused) inputRef.current.focus();
  }, [disabled, hasError, hasFocus, index, value]);

  return (
    <ChocoInput
      ref={inputRef}
      data-testid={`oneTimePasswordInput${index}`}
      type="text"
      inputMode="numeric"
      name="otp"
      value={value}
      onChange={onChangeHandler}
      placeholder=""
      onKeyDown={onKeyDownHandler}
      onPaste={onPasteHandler}
      onFocus={() => {
        changeFocusIndex(index, 'absolute');
      }}
      autoComplete="off"
      autoFocus={index === 0}
      disabled={disabled}
      hasError={hasError}
      sx={inputStyles}
    />
  );
};
