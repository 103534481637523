import COUNTRIES from '../../components/commons/Login/countries';
import {
  CountryCode,
  isValidCountryCode,
} from '../../components/commons/Login/CountryCode';

export const getDialingCodeFromCountryCode = (
  countryCode: string,
  fallBackCountryCode: CountryCode = 'DE'
) => {
  return isValidCountryCode(countryCode)
    ? COUNTRIES[countryCode].dialingCode
    : COUNTRIES[fallBackCountryCode].dialingCode;
};

export const getCountryCodeFromDialingCode = (
  dialingCode: string,
  fallBackCountryCode: CountryCode = 'DE'
) => {
  const foundValue = Object.values(COUNTRIES).find(
    info => info.dialingCode === dialingCode
  );

  return (foundValue ?? COUNTRIES[fallBackCountryCode]).code;
};
