import { ThemeType } from '@chocoapp/chocolate-ui';

type FontConfigurationType = {
  fontFamily?: string[];
  fontURL?: string;
};

export type SubdomainCustomization = {
  name: string;
  theme: {
    customColors: Partial<ThemeType['colors']>;
    customFonts?: {
      primary: FontConfigurationType;
      secondary?: FontConfigurationType;
    };
  };
};

export type SubdomainMapping = Record<string, SubdomainCustomization>;

export const subdomainMappings: SubdomainMapping = {
  nycwholesale: {
    name: 'NYC Wholesale',
    theme: {
      customColors: {
        chocoBlue: '#10253D',
        chocoMidBlue: '#00142A',
        chocoDarkBlue: '#000B17',
        chocoLightBlue: '#929ACA',
        chocoVeryLightBlue: '#C9CDE5',
        cream: '#F6F5F3',
        creamDarker: '#E4E2DD',
      },
      customFonts: {
        primary: {
          fontFamily: ['Bauer Bodoni Std'],
          fontURL:
            'https://assets.choco.com/nycwholesale/fonts/primary/font.css',
        },
        secondary: {
          fontFamily: ['Inter'],
          fontURL:
            'https://assets.choco.com/nycwholesale/fonts/secondary/font.css',
        },
      },
    },
  },
  totalfoodsws: {
    name: 'Total Foods WS',
    theme: {
      customColors: {
        chocoBlue: '#5B081F',
        chocoMidBlue: '#2E0410',
        chocoDarkBlue: '#170208',
        chocoLightBlue: '#C09FA8',
        chocoVeryLightBlue: '#E0CFD4',

        // positive
        honeyDew: '#44D6B4',
        honeyDewText: '#04A074',
        mint: '#BFEED8',
        cucumber: '#006854',

        // warning
        squash: '#E0A500',
        coconut: '#FFF0C6',
        banana: '#FFDB72',
        orange: '#FFCA38',

        // destructive
        plum: '#FF7F11',
        plumText: '#E66309',
        gojiBerry: '#CC4700',
        lychee: '#FFBF88',
      },
      customFonts: {
        primary: {
          fontFamily: ['Bauer Bodoni Std'],
          fontURL:
            'https://assets.choco.com/totalfoodsws/fonts/primary/font.css',
        },
        secondary: {
          fontFamily: ['Inter'],
          fontURL:
            'https://assets.choco.com/totalfoodsws/fonts/secondary/font.css',
        },
      },
    },
  },
  fsmfoods: {
    name: 'FSM Foods',
    theme: {
      customColors: {
        chocoBlue: '#86A89F',
        chocoMidBlue: '#677872',
        chocoDarkBlue: '#3C4742',
        chocoLightBlue: '#CBD8D5',
        chocoVeryLightBlue: '#EEF2F1',
      },
      customFonts: {
        primary: {
          fontFamily: ['Bauer Bodoni Std'],
          fontURL: 'https://assets.choco.com/fsmfoods/fonts/primary/font.css',
        },
        secondary: {
          fontFamily: ['Inter'],
          fontURL: 'https://assets.choco.com/fsmfoods/fonts/secondary/font.css',
        },
      },
    },
  },
  kaleelbrothers: {
    name: 'Kaleel Brothers',
    theme: {
      customColors: {
        chocoBlue: '#D42323',
        chocoMidBlue: '#761E1E',
        chocoDarkBlue: '#471B1B',
        chocoLightBlue: '#EA9191',
        chocoVeryLightBlue: '#F5C8C8',

        // positive
        honeyDew: '#44D6B4',
        honeyDewText: '#04A074',
        mint: '#BFEED8',
        cucumber: '#006854',

        // warning
        squash: '#E0A500',
        coconut: '#FFF0C6',
        banana: '#FFDB72',
        orange: '#FFCA38',

        // destructive
        plum: '#FF7F11',
        plumText: '#E66309',
        gojiBerry: '#CC4700',
        lychee: '#FFBF88',
      },
      customFonts: {
        primary: {
          fontFamily: ['Bauer Bodoni Std'],
          fontURL:
            'https://assets.choco.com/kaleelbrothers/fonts/primary/font.css',
        },
        secondary: {
          fontFamily: ['Inter'],
          fontURL:
            'https://assets.choco.com/kaleelbrothers/fonts/secondary/font.css',
        },
      },
    },
  },
  clarkdistributing: {
    name: 'Clark Distributors',
    theme: {
      customColors: {
        chocoBlue: '#307F17',
        chocoMidBlue: '#1E500F',
        chocoDarkBlue: '#0C2006',
        chocoLightBlue: '#98BF8B',
        chocoVeryLightBlue: '#D4E5C6',
      },
      customFonts: {
        primary: {
          fontFamily: ['Bauer Bodoni Std'],
          fontURL:
            'https://assets.choco.com/clarkdistributing/fonts/primary/font.css',
        },
        secondary: {
          fontFamily: ['Inter'],
          fontURL:
            'https://assets.choco.com/clarkdistributing/fonts/secondary/font.css',
        },
      },
    },
  },
  domenico: {
    name: 'Domenico',
    theme: {
      customColors: {
        chocoBlue: '#9900CC',
        chocoMidBlue: '#571D6C',
        chocoDarkBlue: '#2E1D34',
        chocoLightBlue: '#DCAAED',
        chocoVeryLightBlue: '#EED5F6',
      },
      customFonts: {
        primary: {
          fontFamily: ['Helvetica'],
          fontURL: 'https://assets.choco.com/domenico/fonts/primary/font.css',
        },
        secondary: {
          fontFamily: ['Inter'],
          fontURL: 'https://assets.choco.com/domenico/fonts/secondary/font.css',
        },
      },
    },
  },
  betterbrandfoods: {
    name: 'Better Brand Foods',
    theme: {
      customColors: {
        chocoBlue: '#F7CB3D',
        chocoMidBlue: '#603813',
        chocoDarkBlue: '#3F1F0A',
        chocoLightBlue: '#F7EA99',
        chocoVeryLightBlue: '#FBF4CD',

        banana: '#FAB86A',
      },
      customFonts: {
        primary: {
          fontFamily: ['Helvetica'],
          fontURL:
            'https://assets.choco.com/betterbrandfoods/fonts/primary/font.css',
        },
        secondary: {
          fontFamily: ['Inter'],
          fontURL:
            'https://assets.choco.com/betterbrandfoods/fonts/secondary/font.css',
        },
      },
    },
  },
  wohrlesfoods: {
    name: "Wohrle's Foods",
    theme: {
      customColors: {
        chocoBlue: '#212020',
        chocoMidBlue: '#464444',
        chocoDarkBlue: '#8A8686',
        chocoLightBlue: '#DEDEDE',
        chocoVeryLightBlue: '#F6F5F5',
      },
      customFonts: {
        primary: {
          fontFamily: ['Helvetica'],
          fontURL:
            'https://assets.choco.com/wohrlesfoods/fonts/primary/font.css',
        },
        secondary: {
          fontFamily: ['Inter'],
          fontURL:
            'https://assets.choco.com/wohrlesfoods/fonts/secondary/font.css',
        },
      },
    },
  },
  bobadirect: {
    name: 'Boba Direct',
    theme: {
      customColors: {
        chocoBlue: '#6BAD0D',
        chocoMidBlue: '#3F6212',
        chocoDarkBlue: '#1A2E05',
        chocoLightBlue: '#E6E99F',
        chocoVeryLightBlue: '#F0F3CE',
      },
      customFonts: {
        primary: {
          fontFamily: ['Helvetica'],
          fontURL: 'https://assets.choco.com/bobadirect/fonts/primary/font.css',
        },
        secondary: {
          fontFamily: ['Inter'],
          fontURL:
            'https://assets.choco.com/bobadirect/fonts/secondary/font.css',
        },
      },
    },
  },
};
