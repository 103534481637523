import {
  getLocalStorageItem,
  setLocalStorageItem,
} from '@chocoapp/toolbelt-utils';
import { LOCAL_STORAGE_KEYS } from '@chocoapp/toolbelt-utils/lib/localStorage/keys';
import React, {
  createContext,
  useContext,
  FC,
  useState,
  useCallback,
} from 'react';

type CollapsedContextType = {
  isCollapsed: boolean;
  toggleCollapsed: () => void;
};

export const CollapsedContext = createContext<CollapsedContextType | null>(
  null
);

export const CollapsedProvider: FC = ({ children }) => {
  const [isCollapsed, setIsCollapsed] = useState(
    getLocalStorageItem(LOCAL_STORAGE_KEYS.IS_COLLAPSED_LS_KEY) ?? false
  );

  const toggleCollapsed = useCallback(() => {
    const newValue = !isCollapsed;
    setLocalStorageItem(LOCAL_STORAGE_KEYS.IS_COLLAPSED_LS_KEY, newValue);
    setIsCollapsed(newValue);
  }, [isCollapsed]);

  return (
    <CollapsedContext.Provider value={{ isCollapsed, toggleCollapsed }}>
      {children}
    </CollapsedContext.Provider>
  );
};

export const useCollapsedContext = (): CollapsedContextType => {
  const context = useContext(CollapsedContext);

  if (context === null) {
    throw new Error('useCollapsedContext must be used within a Provider');
  }

  return context;
};
