import {
  Box,
  Button,
  ChocoDropdownFormField,
  ChocoInputFormField,
  Text,
} from '@chocoapp/chocolate-ui';
import React from 'react';
import { useController } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useGoPremiumForm } from './useGoPremiumForm';

// Hardcoded for now.
const roles = [
  'ceoOwner',
  'headOfSales',
  'salesRep',
  'customerService',
  'other',
];

type RequestPremiumProps = {
  onSuccessRedirect?: () => void;
};

export const RequestPremium = ({ onSuccessRedirect }: RequestPremiumProps) => {
  const { t } = useTranslation('getInTouch');

  const {
    loading,
    formUtils: {
      handleSubmit,
      register,
      formState: { errors },
      control,
    },
    onSubmit,
  } = useGoPremiumForm(onSuccessRedirect);

  const {
    // extracting ref to avoid "unreachable ref" error during tests
    // TODO remove when it will be fixed in dropdown in chocolate-ui
    field: { ref, ...fieldProps },
  } = useController({
    name: 'role',
    control,
  });

  return (
    <Box
      as="form"
      data-testid="RequestPremiumForm"
      onSubmit={handleSubmit(onSubmit)}
      sx={{ color: 'baseTextStrong', mt: 'xl' }}
    >
      <Text variant="H4">{t('requestPremiumScreen.title')}</Text>
      <Text sx={{ mt: 'xl' }} variant="TitleSmall">
        {t('requestPremiumScreen.subtitle')}
      </Text>
      <ChocoDropdownFormField
        labelText={t('requestPremiumScreen.roleLabel')}
        id="role"
        items={roles.map((key) => ({
          key,
          content: t(`requestPremiumScreen.${key}`),
        }))}
        sx={{ mt: 'xxl', textAlign: 'left' }}
        placeholder={t('requestPremiumScreen.rolePlaceholder')}
        data-testid="Role"
        errorMessage={errors.role?.message}
        {...fieldProps}
      />
      <ChocoInputFormField
        isRequired
        ref={register}
        id="email"
        name="email"
        placeholder={t('requestPremiumScreen.emailPlaceholder')}
        data-testid="EmailAddress"
        sx={{ mt: 'xl', textAlign: 'left' }}
        labelText={t('requestPremiumScreen.emailLabel')}
        errorMessage={errors.email?.message}
      />
      <ChocoInputFormField
        isRequired
        ref={register}
        id="phoneNumber"
        name="phoneNumber"
        placeholder={t('requestPremiumScreen.numberPlaceholder')}
        data-testid="PhoneNumber"
        sx={{ mt: 'xl', textAlign: 'left' }}
        labelText={t('requestPremiumScreen.numberLabel')}
        errorMessage={errors.phoneNumber?.message}
      />
      <Button
        isLoading={loading}
        type="submit"
        sx={{ width: '100%', mt: 'xxxxl' }}
      >
        {t('requestPremiumScreen.buttonCTA')}
      </Button>
    </Box>
  );
};
