import { useEffect } from 'react';

import { urlToRedirectToLite } from '../util/url';

const WebLitePage = () => {
  useEffect(() => {
    const url = urlToRedirectToLite();
    window.location.assign(url);
  });

  return null;
};

export default WebLitePage;
