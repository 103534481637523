import { TFunction } from 'i18next';
import * as yup from 'yup';

export type WorkplaceFormData = {
  name: string;
  address: string;
};

export const getValidationSchema = (t: TFunction) =>
  yup.object().shape({
    name: yup.string().required(t('createOrJoin.workplace.businessName.error')),
    address: yup
      .string()
      .required(t('createOrJoin.workplace.deliveryAddress.error')),
  });
