import { ApolloProvider as DefaultProvider } from '@apollo/client';
import React, { ReactNode, useEffect, useMemo } from 'react';

import { getEnvConfig } from '../auth/environments/utils';
import { useEnvironmentSelectorProvider } from '../auth/EnvironmentSelector/EnvironmentSelectorProvider';
import { appSyncClientSubject } from '../portal/subjects';
import { startSingleSpaApplication } from '../portal/utils/startSingleSpaApplication';

import { createNewApolloClientClearAllCacheAndRefetchEverything } from './createApolloClientInstance';

type ApolloProviderProps = {
  children: ReactNode;
};

const ApolloProvider = ({ children }: ApolloProviderProps) => {
  const { environment } = useEnvironmentSelectorProvider();

  const apiUrl: string = getEnvConfig(environment).ApiURL;

  /**
   * !! IMPORTANT !!
   * Always be EXTREMELY careful to not reinitialize the ApolloClient unnecessarily.
   * Depending on what you put in the dependencies array, it might reinitialize
   * more often than it should, completely busting all cache and leading to unintended
   * side-effects. Remember that any parent component rerender also rerenders this one.
   * Also be mindful of non-scalar values!!
   */
  const apolloClient = useMemo(
    () => {
      return createNewApolloClientClearAllCacheAndRefetchEverything(apiUrl);
    },
    [apiUrl] /* <--- IMPORTANT: be VERY mindful with this dependencies array  */
  );

  useEffect(() => {
    startSingleSpaApplication(apolloClient);
    appSyncClientSubject.next(apolloClient);
  }, [apolloClient]);

  return <DefaultProvider client={apolloClient}>{children}</DefaultProvider>;
};

export { ApolloProvider };
