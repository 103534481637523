import { Text, TextProps, ThemeUIStyleObject } from '@chocoapp/chocolate-ui';
import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { useCollapsedContext } from './useCollapsedContext';

const betaTagStyles: ThemeUIStyleObject = {
  bg: 'baseBg',
  color: 'baseTextWeak',
  px: '2px',
  borderRadius: 'regular',
};

const BetaTag = ({ sx }: Pick<TextProps, 'sx'>): JSX.Element => {
  const { t } = useTranslation('globalNavigation');
  return (
    <Text as="span" variant="Footnote" sx={{ ...betaTagStyles, ...sx }}>
      {t('newBadge')}
    </Text>
  );
};

const absolutePositionedTag: ThemeUIStyleObject = {
  position: 'absolute',
  top: 'calc(100% + 4px)',
  left: '-4px',
};

const WithBetaTagOnCollapse = ({
  children,
  isBeta,
}: {
  children: ReactNode;
  isBeta: boolean;
}): JSX.Element => {
  const { isCollapsed } = useCollapsedContext();

  return (
    <>
      {children}
      {isCollapsed && isBeta && <BetaTag sx={absolutePositionedTag} />}
    </>
  );
};

export { BetaTag, WithBetaTagOnCollapse };
