import { formatPhoneNumber } from '@chocoapp/toolbelt-utils';
import {
  AuthenticationDetails,
  CognitoUser,
  CognitoUserPool,
} from 'amazon-cognito-identity-js';

import createCognitoUser from './createCognitoUser';
import { hanldeSignup } from './handleSignup';

/* This comes from AWS Cognito, we have no control over properties */
type CognitoCustomChallengeResponse = {
  /* misleading name, but this is actually an UUID */
  USERNAME: string;

  /* a boolean, but as string */
  emailAuth: 'true' | 'false';

  /* masked email like v***a@choco.com, present only when emailAuth === "true" */
  userEmail?: string;
};

export const AUTH_CODE_PHONE_LENGTH = 4;
export const AUTH_CODE_EMAIL_LENGTH = 6;

export type TwoFactorMethod = 'email' | 'phone';

export type AuthMethod = 'signUp';

export type CustomAuthHandlerResponse = {
  user: CognitoUser;
  twoFactorMethod: TwoFactorMethod;
  authDigits: number;
  codeSentTo: string;
  method?: AuthMethod;
  userId?: string;
};

// Todo: double check these exception strings
const SIGNUP_TRIGGERING_ERRORS = [
  'UserNotFoundException',
  'UserNotExistException',
  'UserNotConfirmedException',
];

export const createInitiateCustomAuthHandler = (
  phoneNumber: string,
  userPool: CognitoUserPool
) => {
  return new Promise<CustomAuthHandlerResponse>((resolve, reject) => {
    if (Boolean(phoneNumber) === false || Boolean(userPool) === false) {
      reject();
    }

    const newCognitoUser = createCognitoUser(phoneNumber, userPool);

    const authenticationDetails = new AuthenticationDetails({
      Username: phoneNumber,
    });

    newCognitoUser.setAuthenticationFlowType('CUSTOM_AUTH');

    newCognitoUser.initiateAuth(authenticationDetails, {
      onSuccess: () => {
        reject(Error('CUSTOM_AUTH should call customChallenge'));
      },
      onFailure: (err: Error) => {
        if (SIGNUP_TRIGGERING_ERRORS.includes(err.name)) {
          hanldeSignup(phoneNumber, userPool, newCognitoUser, resolve, reject);
        } else {
          reject(err);
        }
      },
      customChallenge: (
        customChallengeResponse: CognitoCustomChallengeResponse
      ) => {
        const isEmailLogin = customChallengeResponse.emailAuth === 'true';

        resolve({
          user: newCognitoUser,
          authDigits: isEmailLogin
            ? AUTH_CODE_EMAIL_LENGTH
            : AUTH_CODE_PHONE_LENGTH,
          twoFactorMethod: isEmailLogin ? 'email' : 'phone',
          codeSentTo:
            isEmailLogin && customChallengeResponse.userEmail
              ? customChallengeResponse.userEmail
              : formatPhoneNumber(phoneNumber),
        });
      },
    });
  });
};
