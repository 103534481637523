import { PlaceDetails } from '@chocoapp/google-places';

import { BuyerCreateInput } from '../../../generated';

export const extractAddressDetails = (
  placeDetails: PlaceDetails
): Pick<
  BuyerCreateInput,
  | 'city'
  | 'country'
  | 'name'
  | 'fullAddress'
  | 'googlePlaceId'
  | 'latitude'
  | 'longitude'
  | 'utcOffset'
> => {
  const addressComponents = placeDetails.address_components;
  let city = '';
  let country = '';

  if (addressComponents)
    addressComponents.forEach(addressComponent => {
      if (addressComponent.types.includes('country')) {
        country = addressComponent.long_name;
      } else if (addressComponent.types.includes('locality')) {
        city = addressComponent.long_name;
      }
    });

  return {
    city,
    country,
    name: placeDetails?.name ?? '',
    fullAddress: placeDetails?.formatted_address ?? '',
    googlePlaceId: placeDetails?.place_id,
    latitude: placeDetails?.geometry?.location.lat(),
    longitude: placeDetails?.geometry?.location.lng(),
    utcOffset: placeDetails.utc_offset_minutes,
  };
};
