import { NavigationItemType as NavigationItemTypeV2 } from '../components/commons/GlobalNavigationV2/navigation';

// Only for vendors and therefore the filter is
// only used in the GlobalNavigationV2 component
const LoxHiddenItems = ['globalNavigationBroadcast'];

export const loxFilter =
  (loxEnabled: boolean) => (item: NavigationItemTypeV2) => {
    if (!loxEnabled) {
      return true;
    }

    return !LoxHiddenItems.includes(item.testId);
  };
