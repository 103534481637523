import queryString from 'query-string';

import { NotificationMessageFragment } from '../generated';

export const BUYER_QUERY_STRING_KEY = 'buyer';

export const isAssociatedWithMultipleLocations = () => {
  const userPayload = window.choco.auth.getLoggedInUserPayloadFromCognito();
  const { buyerIds } = userPayload || {};
  const buyerIdsArray = buyerIds ? JSON.parse(buyerIds) : [];
  return buyerIdsArray.length > 1;
};

export const OTHER_BUYER_ID = 'other';

export const getBuyerId = (
  buyer?: NotificationMessageFragment['chat']['buyer']
): string => {
  return !buyer ? OTHER_BUYER_ID : buyer.id;
};

export const addBuyerFieldToQueryString = ({
  buyer,
  query,
  metaLobbyEnabled,
}: {
  buyer: NotificationMessageFragment['chat']['buyer'];
  query: string;
  metaLobbyEnabled?: boolean;
}): string => {
  if (!isAssociatedWithMultipleLocations() || !metaLobbyEnabled) return query;

  const buyerId = getBuyerId(buyer);

  const queryStringData = {
    ...queryString.parse(query),
    [BUYER_QUERY_STRING_KEY]: buyerId,
  };

  const newQueryString = queryString.stringify(queryStringData);
  return `?${newQueryString}`;
};
