import { Ellipsis } from '@chocoapp/chocolate-ui';
import { formatPhoneNumber } from '@chocoapp/toolbelt-utils';
import React, { FC } from 'react';

import HiddenWhenCollapsed from '../../HiddenWhenCollapsed';

type UserInfoProps = {
  name: string | null | undefined;
  phone: string;
};

const UserInfo: FC<UserInfoProps> = ({ name, phone }) => {
  return (
    <HiddenWhenCollapsed>
      <Ellipsis variant="Subhead">{name}</Ellipsis>
      <Ellipsis variant="Caption">{formatPhoneNumber(phone)}</Ellipsis>
    </HiddenWhenCollapsed>
  );
};

export default UserInfo;
