import { initChocoSegment } from '@chocoapp/toolbelt-utils';

import { AppLogger } from '../../application/logger';
import { GlobalAuthContextType } from '../../auth';
import {
  UnreadMessagesDataType,
  UnreadMessagesActionsType,
} from '../../components/commons/UnreadMessages/UnreadMessagesContext';
import { APP_BROWSER_HISTORY } from '../../constants/AppConstants';

export const chocoGlobals = {
  auth: {
    appSyncClient: undefined,
  },
  segment: { sessionId: null, extraFields: {} },
  launchDarkly: {},
  browserHistory: APP_BROWSER_HISTORY,
};

window.choco = chocoGlobals as any;

try {
  initChocoSegment();
} catch (error) {
  AppLogger.error('Error initializing segment', { error });
}

export function exportChocoAuthGlobals(authContext: GlobalAuthContextType) {
  window.choco.auth = {
    ...window.choco.auth,
    ...authContext,
  };
}

export function exportChocoUnreadMessagesUtils(
  unreadMessages: Record<string, UnreadMessagesDataType | undefined>,
  unreadMessagesUtils: UnreadMessagesActionsType
) {
  window.choco.unreadMessagesInfos = {
    unreadMessages,
    ...unreadMessagesUtils,
  };
}

export function exportChocoAppsyncGlobals(appSyncClient: any) {
  window.choco.auth = {
    ...window.choco.auth,
    appSyncClient,
  };
}
