
      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "Node": [
      "BlockedEmail",
      "Broadcast",
      "Chat",
      "Buyer",
      "User",
      "Supplier",
      "PaymentAccount",
      "InviteTransmission",
      "TransmissionEvent",
      "Product",
      "ProductCategory",
      "VendorItemUnit",
      "OrderGuideV2ItemUnit",
      "OrderGuideV2Category",
      "VendorItemUnitPriceSet",
      "VendorItem",
      "VendorItemVariantGroup",
      "VendorItemCategory",
      "Message",
      "Order",
      "Invoice",
      "OmnichannelOrder",
      "OmnichannelOrderAttachment",
      "OrderChange",
      "OrderRestaurant",
      "OrderGuideV2",
      "SubCatalog",
      "BuyerStockCount",
      "BuyerStockInventoryProduct",
      "CatalogImportNode",
      "CategoryProducts",
      "ChatRef",
      "CustomCatalog",
      "OrderGuideCategory",
      "OrderGuideProductItem",
      "SuggestedProduct",
      "ConnectedAccount",
      "IntegrationAdapterConnectionBaseResult",
      "IntegrationAdapterConnectionAdminResult",
      "Group",
      "GroupUser",
      "IntegrationAdapterBaseResult",
      "IntegrationAdapterAdminResult",
      "UserRequestToJoin",
      "InvoiceListItem",
      "LobbyBuyer",
      "TaxonomyLabel",
      "SuppliersPaymentAccount",
      "Notification",
      "OrderResult"
    ],
    "Connection": [
      "BlockedEmailConnection",
      "BuyerUserConnection",
      "UserBuyerConnection",
      "UserChatConnection",
      "UserUserConnection",
      "SupplierUserConnection",
      "BuyerChatConnection",
      "ChatMessageConnection",
      "ChatUserConnection",
      "CatalogProductsConnection",
      "BuyerStockCountConnection",
      "BuyerStockInventoryProductConnection",
      "CatalogImportsResult",
      "CustomCatalogChatsResult",
      "CustomCatalogsForVendorResult",
      "SuggestedProductConnection",
      "ChatsConnection",
      "ConnectedAccountConnection",
      "IntegrationAdapterConnectionBaseResultConnection",
      "IntegrationAdapterConnectionAdminResultConnection",
      "GroupUserConnection",
      "IntegrationAdapterBaseResultConnection",
      "IntegrationAdapterAdminResultConnection",
      "RequestsToJoinConnection",
      "InvoicesListSuccess",
      "SubCatalogConnection",
      "UserLobbyBuyerConnection",
      "OrderGuideV2CategoriesResult",
      "OrderGuideV2ItemUnitsResult",
      "OrdersToReviewConnection",
      "SearchBuyerConnection",
      "CustomerChatsConnection",
      "GroupConnection",
      "SearchOrderConnection",
      "OrderDeskChatsConnection",
      "SearchProductsConnection",
      "SearchTaxonomyLabelsConnection",
      "SearchSupplierConnection",
      "UserSearchConnection",
      "VendorItemCategoriesForChatSuccess",
      "VendorItemCategoriesForVendorSuccess",
      "VendorItemsForVendorResult",
      "VendorItemsForChatSuccess",
      "ProductConnection"
    ],
    "Edge": [
      "BlockedEmailEdge",
      "BuyerUserEdge",
      "UserBuyerEdge",
      "UserChatEdge",
      "UserUserEdge",
      "SupplierUserEdge",
      "BuyerChatEdge",
      "ChatMessageEdge",
      "ChatUserEdge",
      "CatalogProductsEdge",
      "BuyerStockCountEdge",
      "BuyerStockInventoryProductEdge",
      "CatalogImportsResultEdge",
      "CustomCatalogChatsResultEdge",
      "CustomCatalogsForVendorResultEdge",
      "SuggestedProductEdge",
      "ChatsEdge",
      "ConnectedAccountEdge",
      "IntegrationAdapterConnectionBaseResultEdge",
      "IntegrationAdapterConnectionAdminResultEdge",
      "GroupUserEdge",
      "IntegrationAdapterBaseResultEdge",
      "IntegrationAdapterAdminResultEdge",
      "UserRequestToJoinEdge",
      "InvoiceListItemEdge",
      "SubCatalogEdge",
      "UserLobbyBuyerEdge",
      "OrderGuideV2CategoriesResultEdge",
      "OrderGuideV2ItemUnitsResultEdge",
      "OrdersToReviewEdge",
      "BuyerEdge",
      "CustomerChatsEdge",
      "GroupEdge",
      "SearchOrderEdge",
      "OrderDeskChatsEdge",
      "SearchProductsEdge",
      "SearchTaxonomyLabelsEdge",
      "SupplierSearchEdge",
      "UserSearchEdge",
      "VendorItemCategoriesForChatResultEdge",
      "VendorItemCategoriesForVendorResultEdge",
      "VendorItemsForVendorResultEdge",
      "VendorItemsForChatResultEdge",
      "ProductEdge"
    ],
    "MinimumOrderValueResponse": [
      "MinimumOrderValue",
      "MinimumOrderValueFailure",
      "AuthorizationFailure",
      "InputValidationFailure"
    ],
    "AppsyncResponse": [
      "AuthorizationFailure",
      "InputValidationFailure",
      "PaymentAccountsList",
      "NotFoundError",
      "PaymentResponse",
      "PaymentAccountNotFound",
      "InvoiceHasNoPayment",
      "OrderHasNoInvoice",
      "OrderHasOnlyAttachment",
      "OrderChangeError",
      "CreatedOrCompletedByNotFound",
      "CatalogNotFound",
      "BuyerStockInventoryNotFound",
      "CatalogExportStatusResult",
      "CatalogImportStatusResult",
      "CatalogImportTemplateResult",
      "CatalogImportsResult",
      "ChatNotFoundError",
      "ValidationError",
      "ChatSubCatalogProductsError",
      "CustomCatalogChatsResult",
      "CustomCatalogsForVendorResult",
      "BuyerReportGenerationError",
      "BuyerReportNotFoundError",
      "SuggestedProductSuccessResponse",
      "SupplierNotFound",
      "SubCatalogNotFoundError",
      "GroupResponseSuccess",
      "GetGroupEntitiesSuccessResponse",
      "GetGroupUsersSuccessResponse",
      "InviteeNotFound",
      "MarketingMessageSuggestionsSuccess",
      "ReconciliationRequestPlanBySupplierIdNotFound",
      "GetSmsMessageByMessageSidSuccess",
      "GetSmsMessagesFromPhoneNumberSuccess",
      "NoUnconnectedBuyers",
      "UserIntercomCredentialsFailure",
      "GetWhatsAppMessageByMessageSidSuccess",
      "OrderNotFound",
      "InvoicesListSuccess",
      "ListSubCatalogProductsError",
      "LookupPhoneNumbersError",
      "InvalidCursor",
      "OrderGuideV2CategoriesResult",
      "OrderGuideV2ItemUnitsResult",
      "PublicToken",
      "Redirect",
      "SearchGroupsSuccessResponse",
      "SearchProductsError",
      "SearchTaxonomyLabelsError",
      "SendSampleSmsMessageSuccess",
      "SendSampleSmsMessageFailure",
      "SupplierDeliverySettingsNotFound",
      "UserChatsError",
      "VendorItemCategoriesForChatSuccess",
      "VendorItemCategoriesForVendorSuccess",
      "VendorItemUnitsResult",
      "VendorItemsForVendorResult",
      "VendorItemsSuccess",
      "VendorItemsCountForChatSuccess",
      "VendorItemsForChatSuccess",
      "OrderCreatedError",
      "ExternalAccountDetailsMissed",
      "ExternalAccountNotFound",
      "SupplierHasActivePaymentAccountAlready",
      "ProductNotFoundError",
      "AddEntitiesToGroupResponseSuccess",
      "ChatHasNoSupplierEntity",
      "OrderAlreadyHasInvoice",
      "PaymentMethodNotAvailableForUser",
      "SupplierPaymentAccountAlreadyExists",
      "PaymentAccountAlreadyExists",
      "AddProductsToOrderGuidesResult",
      "AddSalesRepError",
      "BuyerAddSupplierError",
      "SupplierPaymentsDisabled",
      "AddUsersToGroupResponseSuccess",
      "AssignOrderToUserForReviewResponseError",
      "BatchOrderConfirmSuccess",
      "BatchOrderConfirmError",
      "OrderDeliveryItemsMismatched",
      "OrderDeliveryCheckAlreadyCompleted",
      "CartVersionConstraintError",
      "CartNotFoundError",
      "CatalogExportStartResult",
      "CatalogImportStartResult",
      "ClaimOnboardingChecklistRewardError",
      "DeliveryCheckReconciliationRequestNotFound",
      "DeliveryCheckReconciliationRequestAlreadyConfirmed",
      "OrderDeliveryCheckReconciliationRequestIdNotMatched",
      "ConnectAccountError",
      "ChocoEntityNotFound",
      "UnsupportedIntegrationAdapterType",
      "IntegrationAdapterNotFound",
      "OrderDeliveryCheckExists",
      "OrderDeliveryCheckImageNotFound",
      "CreateProfileError",
      "UserHasReferralCode",
      "ReferralCodeGenerationError",
      "CreateSubCatalogError",
      "DuplicateProductError",
      "DeleteBuyerReportScheduleSuccess",
      "BuyerReportScheduleNotFound",
      "DeleteBuyerReportScheduleError",
      "DeleteBuyerStockInventoryProductsSuccess",
      "DeleteSubCatalogSuccess",
      "DeleteSubCatalogError",
      "DisconnectAccountError",
      "IntegrationAdapterConnectionNotFound",
      "GetClientDebuggableArtifactUploadUrlError",
      "GetOrderGuideUploadUrlError",
      "BalanceCheckoutToken",
      "SupplierPaymentAccountMissing",
      "InvoiceAlreadyPaid",
      "InvoicePaymentInProgress",
      "InvoiceTotalTooLow",
      "IntegrationAdapterScopeInsufficient",
      "IntegrationAdapterExists",
      "AttemptToChangeAdapterAuthType",
      "AttemptToReduceIntegrationAdapterScope",
      "BuyerNotFound",
      "InvitorNotFound",
      "ExistingInvitedUser",
      "PuppyValidationFailure",
      "InviteUserToBuyerError",
      "KycResponse",
      "KycBuyerNotFound",
      "KycEntryAlreadyExists",
      "OrderEditedError",
      "OrderGuideCreatedSuccess",
      "OrderGuideCreatedError",
      "OrderGuideRequestError",
      "OrderGuideV2CategoriesUpdateResult",
      "OrderGuideV2ItemUnitsAddResult",
      "OrderGuideV2ItemUnitsRemoveResult",
      "OrderGuideV2ItemUnitsUpdateResult",
      "PostProcessing",
      "PreProcessing",
      "NoFurtherActionNeeded",
      "PaymentAccountIsInactive",
      "UnexpectedInvoiceState",
      "SupplierDoesNotAcceptOnlinePayments",
      "EntityCurrencyMismatch",
      "ReferUserChatExists",
      "ReferUserReferralCodeNotFound",
      "EmailFromBuyerReportScheduleRemoved",
      "EmailInBuyerReportScheduleNotFound",
      "RemoveEntitiesFromGroupResponseSuccess",
      "InvoiceRemoved",
      "InvoiceCannotBeRemoved",
      "InvoiceAttachmentRemoved",
      "InvoiceCannotBeUpdated",
      "PaymentAccountRemoved",
      "RemoveProductsFromOrderGuidesResult",
      "RemoveUsersFromGroupSuccess",
      "RequestToJoinBuyerError",
      "SelectProductForOrderGuideSuccess",
      "SelectProductsForOrderGuideSuccess",
      "SendSyntheticSmsMessageSuccess",
      "SendSyntheticWhatsAppMessageSuccess",
      "CartSubmissionError",
      "CreateMessageError",
      "CartSubmittedEmptyError",
      "CartInvalidProductsError",
      "CartAllProductsUnavailableError",
      "ForbiddenError",
      "ExistingOrderError",
      "ProductLeadTimeError",
      "MinimumOrderableAmountError",
      "OrderDeliveryCheckNotFound",
      "SupplierAddRequestError",
      "SupplierGetInTouchRequestSuccess",
      "SupplierGetInTouchRequestError",
      "InvalidInvitedPhoneNumber",
      "SupplierInviteError",
      "SupplierUserRemoveResponse",
      "UnassignUserFromOrderToReviewResponseError",
      "UndoMultiLocationOrderGuideTransactionResult",
      "UpdateBuyerUserChatsError",
      "NegativeAmountError",
      "InvoicedProducts",
      "UpdateProductVariantsInOrderGuideSuccess",
      "UpdateJoinRequestError",
      "UpdateSubCatalogError",
      "SupplierUpdateAccessPackageSuccess",
      "SupplierUpdateAccessPackageError",
      "UploadSubCatalogProductsError",
      "VendorItemCustomCreateResult",
      "VendorItemCustomUpdateResult",
      "VendorItemUnitBuyerPropertiesUpdateResult",
      "VendorItemUnitBuyerPropertiesUpdateBatchResult",
      "VendorItemUnitUpdateResult",
      "VendorItemUnitsImagesUploadResult",
      "BuyerChatsNotFound",
      "BuyerChatsProductListEmpty"
    ],
    "AppsyncError": [
      "AuthorizationFailure",
      "InputValidationFailure",
      "NotFoundError",
      "PaymentAccountNotFound",
      "InvoiceHasNoPayment",
      "OrderHasNoInvoice",
      "OrderChangeError",
      "CreatedOrCompletedByNotFound",
      "CatalogNotFound",
      "BuyerStockInventoryNotFound",
      "ChatNotFoundError",
      "ValidationError",
      "ChatSubCatalogProductsError",
      "BuyerReportGenerationError",
      "BuyerReportNotFoundError",
      "GetSuggestedProductsErrorResponse",
      "SupplierNotFound",
      "SubCatalogNotFoundError",
      "GroupResponseError",
      "GetGroupEntitiesErrorResponse",
      "GetGroupUsersErrorResponse",
      "InviteeNotFound",
      "MarketingMessageSuggestionsError",
      "MarketingMessageSuggestionsErrorNoSuggestions",
      "MarketingMessageSuggestionsErrorThrottling",
      "ReconciliationRequestPlanBySupplierIdNotFound",
      "NoUnconnectedBuyers",
      "UserIntercomCredentialsFailure",
      "OrderNotFound",
      "ListSubCatalogProductsError",
      "LookupPhoneNumbersError",
      "InvalidCursor",
      "SearchGroupsErrorResponse",
      "SearchProductsError",
      "SearchTaxonomyLabelsError",
      "SupplierDeliverySettingsNotFound",
      "UserChatsError",
      "OrderCreatedError",
      "ExternalAccountDetailsMissed",
      "ExternalAccountNotFound",
      "SupplierHasActivePaymentAccountAlready",
      "ProductNotFoundError",
      "AddEntitiesToGroupResponseError",
      "ChatHasNoSupplierEntity",
      "OrderAlreadyHasInvoice",
      "PaymentMethodNotAvailableForUser",
      "SupplierPaymentAccountAlreadyExists",
      "PaymentAccountAlreadyExists",
      "AddSalesRepError",
      "BuyerAddSupplierError",
      "SupplierPaymentsDisabled",
      "AddUsersToGroupResponseError",
      "AssignOrderToUserForReviewResponseError",
      "BatchOrderConfirmError",
      "OrderDeliveryItemsMismatched",
      "OrderDeliveryCheckAlreadyCompleted",
      "CartVersionConstraintError",
      "CartNotFoundError",
      "ClaimOnboardingChecklistRewardError",
      "DeliveryCheckReconciliationRequestNotFound",
      "DeliveryCheckReconciliationRequestAlreadyConfirmed",
      "OrderDeliveryCheckReconciliationRequestIdNotMatched",
      "ConnectAccountError",
      "ChocoEntityNotFound",
      "UnsupportedIntegrationAdapterType",
      "IntegrationAdapterNotFound",
      "OrderDeliveryCheckExists",
      "OrderDeliveryCheckImageNotFound",
      "CreateProfileError",
      "UserHasReferralCode",
      "ReferralCodeGenerationError",
      "CreateSubCatalogError",
      "DuplicateProductError",
      "BuyerReportScheduleNotFound",
      "DeleteBuyerReportScheduleError",
      "DeleteSubCatalogError",
      "DisconnectAccountError",
      "IntegrationAdapterConnectionNotFound",
      "GetClientDebuggableArtifactUploadUrlError",
      "GetOrderGuideUploadUrlError",
      "SupplierPaymentAccountMissing",
      "InvoiceAlreadyPaid",
      "InvoicePaymentInProgress",
      "InvoiceTotalTooLow",
      "IntegrationAdapterScopeInsufficient",
      "IntegrationAdapterExists",
      "AttemptToChangeAdapterAuthType",
      "AttemptToReduceIntegrationAdapterScope",
      "BuyerNotFound",
      "InvitorNotFound",
      "ExistingInvitedUser",
      "PuppyValidationFailure",
      "InviteUserToBuyerError",
      "KycBuyerNotFound",
      "KycEntryAlreadyExists",
      "OrderEditedError",
      "OrderGuideCreatedError",
      "OrderGuideRequestError",
      "PaymentAccountIsInactive",
      "UnexpectedInvoiceState",
      "SupplierDoesNotAcceptOnlinePayments",
      "EntityCurrencyMismatch",
      "ReferUserChatExists",
      "ReferUserReferralCodeNotFound",
      "EmailFromBuyerReportScheduleRemoved",
      "EmailInBuyerReportScheduleNotFound",
      "RemoveEntitiesFromGroupResponseError",
      "InvoiceCannotBeRemoved",
      "InvoiceCannotBeUpdated",
      "RemoveUsersFromGroupError",
      "RequestToJoinBuyerError",
      "CartSubmissionError",
      "CreateMessageError",
      "CartSubmittedEmptyError",
      "CartInvalidProductsError",
      "CartAllProductsUnavailableError",
      "ForbiddenError",
      "ExistingOrderError",
      "ProductLeadTimeError",
      "MinimumOrderableAmountError",
      "OrderDeliveryCheckNotFound",
      "SupplierAddRequestError",
      "SupplierGetInTouchRequestError",
      "InvalidInvitedPhoneNumber",
      "SupplierInviteError",
      "UnassignUserFromOrderToReviewResponseError",
      "UpdateBuyerUserChatsError",
      "NegativeAmountError",
      "UpdateJoinRequestError",
      "UpdateSubCatalogError",
      "SupplierUpdateAccessPackageError",
      "UploadSubCatalogProductsError",
      "BuyerChatsNotFound",
      "BuyerChatsProductListEmpty"
    ],
    "CutoffSetting": [
      "NoCutoffSetting",
      "WithCutoffSetting"
    ],
    "DeliverySettingException": [
      "SingleDayDeliveryExceptionWithCutoff",
      "SingleDayDeliveryExceptionWithoutCutoff",
      "NoDeliveryException"
    ],
    "IntegrationOption": [
      "IntegrationSmsOption",
      "IntegrationEmailOption",
      "IntegrationFaxOption",
      "IntegrationWhatsAppOption"
    ],
    "OrderExportConfigFormat": [
      "OrderExportConfigCsvFormat",
      "OrderExportConfigEdiFormat",
      "OrderExportConfigLobsterFormat"
    ],
    "OrderExportUploadOption": [
      "OrderExportChocoSftpUpload",
      "OrderExportExternalSftpUpload",
      "OrderExportLobsterUpload"
    ],
    "ListPaymentAccountsResponse": [
      "PaymentAccountsList",
      "AuthorizationFailure",
      "InputValidationFailure"
    ],
    "PaymentAccountDetails": [
      "BankAccountDetails",
      "CreditCardDetails",
      "BalanceVendorBankDetails",
      "BalanceBuyerBankDetails",
      "WalletDetails",
      "PayoutDetails"
    ],
    "GetReferralResponse": [
      "Referral",
      "NotFoundError"
    ],
    "DerivedPriceForOrderGuideResponse": [
      "AuthorizationFailure",
      "DerivedPriceForOrderGuide",
      "InputValidationFailure"
    ],
    "IsSelectedInOrderGuideResponse": [
      "IsSelectedInOrderGuide",
      "InputValidationFailure",
      "AuthorizationFailure"
    ],
    "RefEdge": [
      "OrderGuideV2CategoryRef",
      "VendorItemRef",
      "VendorItemCategoryRef",
      "OrderGuideV2Ref"
    ],
    "OrderInvoice": [
      "Invoice",
      "OrderHasNoInvoice",
      "OrderHasOnlyAttachment",
      "InputValidationFailure",
      "AuthorizationFailure"
    ],
    "CurrentPaymentResponse": [
      "PaymentResponse",
      "InvoiceHasNoPayment",
      "AuthorizationFailure",
      "InputValidationFailure"
    ],
    "PaymentAccountResponse": [
      "SecuredPaymentAccount",
      "PaymentAccountNotFound",
      "AuthorizationFailure"
    ],
    "OrderChangeResponse": [
      "OrderChange",
      "NoOrderChange",
      "OrderChangeReverted",
      "OrderChangeError"
    ],
    "GetOrderDeliveryCheckResponse": [
      "OrderDeliveryCheckDetails",
      "InputValidationFailure",
      "CreatedOrCompletedByNotFound",
      "AuthorizationFailure"
    ],
    "Attachment": [
      "Event",
      "Media",
      "BroadcastAttachment"
    ],
    "ChatEventTypes": [
      "RequestedChatCreated",
      "RequestedChatActivated",
      "UserAddedToChat",
      "ChatNameUpdated",
      "UserRemovedFromChat",
      "ChatCreated",
      "CartOpened",
      "CartUpdated",
      "CartSubmitted",
      "CartCanceled",
      "OrderEdited",
      "OrderGuideRequested",
      "OrderGuideCreated",
      "ReconciliationRequestConfirmed"
    ],
    "EventTarget": [
      "EventUser",
      "EventChat",
      "EventOrder",
      "EventDeliveryCheckReconciliationRequest"
    ],
    "BrowseCatalogResponse": [
      "CatalogProductsConnection",
      "AuthorizationFailure"
    ],
    "BrowseCatalogForChatResponse": [
      "CatalogProductsConnection",
      "CatalogNotFound",
      "AuthorizationFailure"
    ],
    "BuyerStockCountResponse": [
      "BuyerStockCountSuccess",
      "BuyerStockCountFailure",
      "InputValidationFailure",
      "AuthorizationFailure"
    ],
    "BuyerStockCountsResponse": [
      "BuyerStockCountsSuccess",
      "BuyerStockCountsFailure",
      "InputValidationFailure",
      "AuthorizationFailure"
    ],
    "BuyerStockInventoryResponse": [
      "BuyerStockInventory",
      "InputValidationFailure",
      "AuthorizationFailure",
      "BuyerStockInventoryNotFound",
      "BuyerStockInventoryFailure"
    ],
    "BuyerStockInventoryExportUrlResponse": [
      "BuyerStockInventoryExportUrlSuccess",
      "InputValidationFailure",
      "AuthorizationFailure",
      "BuyerStockInventoryNotFound",
      "BuyerStockInventoryExportUrlFailure"
    ],
    "CartResponse": [
      "Cart",
      "AuthorizationFailure"
    ],
    "CatalogExportStatusResponse": [
      "CatalogExportStatusResult",
      "InputValidationFailure",
      "AuthorizationFailure"
    ],
    "CatalogExportJob": [
      "JobQueued",
      "JobInProgress",
      "JobFailed",
      "CatalogExportJobSucceeded"
    ],
    "CatalogImportStatusResponse": [
      "CatalogImportStatusResult",
      "InputValidationFailure",
      "AuthorizationFailure"
    ],
    "CatalogImportJob": [
      "JobWaitingForInput",
      "JobInProgress",
      "JobFailed",
      "CatalogImportJobSucceeded"
    ],
    "CatalogImportTemplateResponse": [
      "CatalogImportTemplateResult",
      "AuthorizationFailure"
    ],
    "CatalogImportsResponse": [
      "CatalogImportsResult",
      "InputValidationFailure",
      "AuthorizationFailure"
    ],
    "Page": [
      "CatalogImportsResult",
      "CustomCatalogChatsResult",
      "CustomCatalogsForVendorResult",
      "InvoicesListSuccess",
      "OrderGuideV2CategoriesResult",
      "OrderGuideV2ItemUnitsResult",
      "VendorItemCategoriesForChatSuccess",
      "VendorItemCategoriesForVendorSuccess",
      "VendorItemsForVendorResult",
      "VendorItemsForChatSuccess"
    ],
    "ChatHasSupplierEmailSupportResponse": [
      "ChatHasSupplierEmailSupportResult",
      "ChatNotFoundError",
      "InputValidationFailure",
      "AuthorizationFailure"
    ],
    "ChatSubCatalogProductsResponse": [
      "ChatNotFoundError",
      "ValidationError",
      "ChatSubCatalogProductsError",
      "ProductSearchResult"
    ],
    "CreditCheckResponse": [
      "CreditCheckApproved",
      "CreditCheckDeclined",
      "CreditCheckFailure",
      "CreditCheckClientFailure",
      "InputValidationFailure",
      "AuthorizationFailure"
    ],
    "CustomCatalogChatsResponse": [
      "CustomCatalogChatsResult",
      "InputValidationFailure",
      "AuthorizationFailure"
    ],
    "CustomCatalogsResponse": [
      "CustomCatalogsResult",
      "InputValidationFailure",
      "AuthorizationFailure"
    ],
    "CustomCatalogsForVendorResponse": [
      "CustomCatalogsForVendorResult",
      "InputValidationFailure",
      "AuthorizationFailure"
    ],
    "FetchBuyerReportDownloadUrlResponse": [
      "BuyerReport",
      "BuyerReportGenerationError",
      "BuyerReportNotFoundError",
      "InputValidationFailure",
      "AuthorizationFailure"
    ],
    "FetchCatalogCategoriesForChatResponse": [
      "CatalogCategoriesResponse",
      "CatalogNotFound"
    ],
    "OrderGuideResponse": [
      "OrderGuide",
      "OrderGuideFailure",
      "AuthorizationFailure",
      "InputValidationFailure"
    ],
    "GetAllBuyerReportSchedulesResponse": [
      "BuyerReportSchedules",
      "InputValidationFailure",
      "AuthorizationFailure"
    ],
    "AppliedChatDeliverySettingResponse": [
      "AppliedChatDeliverySetting",
      "NotFoundError"
    ],
    "SuggestedProductResult": [
      "SuggestedProductSuccessResponse",
      "GetSuggestedProductsErrorResponse",
      "AuthorizationFailure",
      "InputValidationFailure"
    ],
    "GetBuyerContactInfosResponse": [
      "BuyerContactInfos",
      "NotFoundError",
      "AuthorizationFailure"
    ],
    "GetCatalogUploadUrlResponse": [
      "SupplierNotFound",
      "GetCatalogUploadUrlResult"
    ],
    "GetChatCountByCCEmailResponse": [
      "GetChatCountByCCEmailReport",
      "AuthorizationFailure"
    ],
    "GetChatSuppliersByLocationsResponse": [
      "ChatSuppliersByLocations",
      "InputValidationFailure",
      "AuthorizationFailure"
    ],
    "GetChatsByChatIdsResponse": [
      "ChatsConnection",
      "InputValidationFailure",
      "AuthorizationFailure"
    ],
    "GetChatsForSubCatalogResponse": [
      "GetChatsForSubCatalogResult",
      "SubCatalogNotFoundError",
      "AuthorizationFailure"
    ],
    "GetConnectedAccountsResponse": [
      "ConnectedAccountConnection",
      "InputValidationFailure",
      "AuthorizationFailure"
    ],
    "GetConnectedIntegrationAdaptersResponse": [
      "IntegrationAdapterConnectionBaseResultConnection",
      "IntegrationAdapterConnectionAdminResultConnection",
      "InputValidationFailure",
      "AuthorizationFailure"
    ],
    "SupplierLabelsResponse": [
      "SupplierLabelsList",
      "InputValidationFailure",
      "AuthorizationFailure"
    ],
    "CustomerOrdersInsightsResponse": [
      "CustomerOrderInsightsResponseSuccess",
      "InputValidationFailure",
      "CustomerOrderInsightsResponseFailure",
      "AuthorizationFailure"
    ],
    "CustomerOrdersInsightsResponseV2": [
      "CustomerOrderInsightsResponseSuccessV2",
      "InputValidationFailure",
      "CustomerOrderInsightsResponseFailure",
      "AuthorizationFailure"
    ],
    "GetEmailListsResponse": [
      "EmailLists",
      "AuthorizationFailure"
    ],
    "GetFeatureFlagsResponse": [
      "FeatureFlags",
      "AuthorizationFailure"
    ],
    "GroupResponse": [
      "GroupResponseSuccess",
      "GroupResponseError",
      "AuthorizationFailure"
    ],
    "GetGroupEntitiesResponse": [
      "GetGroupEntitiesSuccessResponse",
      "GetGroupEntitiesErrorResponse",
      "AuthorizationFailure"
    ],
    "GroupEntityNode": [
      "GroupEntityBuyer",
      "GroupEntitySupplier"
    ],
    "GroupEntity": [
      "GroupEntityBuyer",
      "GroupEntitySupplier"
    ],
    "GetGroupUsersResponse": [
      "GetGroupUsersSuccessResponse",
      "GetGroupUsersErrorResponse",
      "AuthorizationFailure"
    ],
    "GetIntegrationAdaptersResponse": [
      "IntegrationAdapterBaseResultConnection",
      "IntegrationAdapterAdminResultConnection",
      "InputValidationFailure",
      "AuthorizationFailure"
    ],
    "IntegrationAdapterAuthBase": [
      "IntegrationAdapterAuthorizationCodeAuthBase",
      "IntegrationAdapterClientCredentialsAuthBase",
      "IntegrationAdapterAPIKeyAuthBase"
    ],
    "IntegrationAdapterAuth": [
      "IntegrationAdapterAuthorizationCodeAuth",
      "IntegrationAdapterClientCredentialsAuth",
      "IntegrationAdapterAPIKeyAuth"
    ],
    "IntegrationAdapterWebhookAuth": [
      "IntegrationAdapterWebhookBasicAuth",
      "IntegrationAdapterWebhookSignatureAuth"
    ],
    "GetInviteTransmissionStatusResponse": [
      "InviteTransmissionStatus",
      "AuthorizationFailure",
      "InputValidationFailure"
    ],
    "GetInviteeBuyersResponse": [
      "InviteeNotFound",
      "InviteeBuyers"
    ],
    "LocationsBySupplierResponse": [
      "LocationsBySupplierSuccess",
      "InputValidationFailure",
      "AuthorizationFailure"
    ],
    "MarketingMessageSuggestionsResult": [
      "MarketingMessageSuggestionsSuccess",
      "MarketingMessageSuggestionsError",
      "MarketingMessageSuggestionsErrorNoSuggestions",
      "MarketingMessageSuggestionsErrorThrottling",
      "InputValidationFailure"
    ],
    "GetOmniChannelOrderResponse": [
      "OmnichannelOrder",
      "NotFoundError",
      "AuthorizationFailure"
    ],
    "GetOnboardingChecklistResponse": [
      "OnboardingChecklist",
      "AuthorizationFailure"
    ],
    "OnboardingChecklistItemValue": [
      "OnboardingChecklistBooleanValue",
      "OnboardingChecklistNumericValue"
    ],
    "GetOrderChannelContactInfosResponse": [
      "OrderChannelContactInfos",
      "NotFoundError",
      "AuthorizationFailure"
    ],
    "GetProductsForChatResponse": [
      "GetProductsForChatResult",
      "ChatNotFoundError",
      "AuthorizationFailure",
      "SupplierNotFound"
    ],
    "GetQboCustomersAndOrderGuidesToImportResponse": [
      "QboCustomersAndOrderGuidesToImport",
      "InputValidationFailure",
      "AuthorizationFailure"
    ],
    "GetReconciliationRequestPlanBySupplierIdResponse": [
      "ReconciliationRequestPlan",
      "InputValidationFailure",
      "ReconciliationRequestPlanBySupplierIdNotFound"
    ],
    "GetRequestsToJoinResponse": [
      "RequestsToJoinConnection",
      "InputValidationFailure",
      "AuthorizationFailure"
    ],
    "GetSmsMessageByMessageSidResponse": [
      "GetSmsMessageByMessageSidSuccess",
      "AuthorizationFailure"
    ],
    "GetSmsMessagesFromPhoneNumberResponse": [
      "GetSmsMessagesFromPhoneNumberSuccess",
      "AuthorizationFailure"
    ],
    "GetSubCatalogResponse": [
      "SubCatalog",
      "NotFoundError"
    ],
    "GetSubCatalogsForChatResponse": [
      "GetSubCatalogsForChatResult",
      "ChatNotFoundError",
      "AuthorizationFailure"
    ],
    "GetSupplierApplicationsResponse": [
      "SupplierApplications",
      "InputValidationFailure",
      "AuthorizationFailure"
    ],
    "CredentialsData": [
      "QbdCredentials"
    ],
    "GetSupplierProductsResponse": [
      "GetSupplierProductsResult",
      "AuthorizationFailure"
    ],
    "GetTransmissionBySesMessageIdResponse": [
      "Transmission",
      "NotFoundError",
      "AuthorizationFailure"
    ],
    "GetUnconnectedBuyerIdsResult": [
      "UnconnectedBuyers",
      "NoUnconnectedBuyers"
    ],
    "GetUploadedOrderResponse": [
      "GetUploadedOrderSuccess",
      "AuthorizationFailure",
      "InputValidationFailure"
    ],
    "UserIntercomCredentialsResponse": [
      "UserIntercomCredentialsSuccess",
      "UserIntercomCredentialsFailure"
    ],
    "GetWhatsAppMessageByMessageSidResponse": [
      "GetWhatsAppMessageByMessageSidSuccess",
      "AuthorizationFailure"
    ],
    "HasUserCompletedDeliveryCheckResponse": [
      "HasCompletedDeliveryCheckResult",
      "OrderNotFound",
      "InputValidationFailure",
      "AuthorizationFailure"
    ],
    "InventoryDownloadJobsResponse": [
      "InventoryDownloadJobsSuccess",
      "InventoryDownloadJobsFailure",
      "AuthorizationFailure",
      "InputValidationFailure"
    ],
    "InventoryDownloadJobReport": [
      "InventoryDownloadJobCompleted",
      "InventoryDownloadJobFailed"
    ],
    "InventorySuppliersResponse": [
      "InventorySuppliersSuccess",
      "InputValidationFailure",
      "AuthorizationFailure",
      "InventorySuppliersFailure"
    ],
    "InventoryUploadJobResponse": [
      "InventoryUploadJobSuccess",
      "InventoryUploadJobFailure",
      "AuthorizationFailure",
      "InputValidationFailure"
    ],
    "InventoryUploadTemplateResponse": [
      "InventoryUploadTemplateResponseSuccess",
      "InventoryUploadTemplateResponseFailure",
      "AuthorizationFailure",
      "InputValidationFailure"
    ],
    "InvoicesListResponse": [
      "InvoicesListSuccess",
      "InvoicesListFailure",
      "InputValidationFailure",
      "AuthorizationFailure"
    ],
    "ListSubCatalogProductsResponse": [
      "ProductSearchResult",
      "ListSubCatalogProductsError"
    ],
    "ListSubCatalogsResponse": [
      "SubCatalogConnection",
      "ValidationError"
    ],
    "LookupPhoneNumbersResponse": [
      "LookupPhoneNumbersSuccess",
      "LookupPhoneNumbersError"
    ],
    "PhoneNumberLookup": [
      "PhoneNumberLookupSuccess",
      "PhoneNumberLookupError"
    ],
    "MetaLobbyResponse": [
      "MetaLobbyResult",
      "InvalidCursor"
    ],
    "OrderGuideV2CategoriesResponse": [
      "OrderGuideV2CategoriesResult",
      "InputValidationFailure",
      "AuthorizationFailure"
    ],
    "OrderGuideV2ItemUnitsResponse": [
      "OrderGuideV2ItemUnitsResult",
      "InputValidationFailure",
      "AuthorizationFailure"
    ],
    "OrdersToReviewResponse": [
      "OrdersToReviewConnection",
      "InputValidationFailure"
    ],
    "PaymentOptionsResponse": [
      "PublicToken",
      "Redirect",
      "InputValidationFailure",
      "AuthorizationFailure"
    ],
    "SearchEntity": [
      "User",
      "Chat",
      "Message",
      "Order",
      "Supplier",
      "Product",
      "Broadcast",
      "Buyer"
    ],
    "SearchBuyerResponse": [
      "SearchBuyerConnection",
      "InputValidationFailure",
      "AuthorizationFailure"
    ],
    "SearchCustomerResponse": [
      "CustomerChatsConnection",
      "InputValidationFailure",
      "AuthorizationFailure"
    ],
    "SearchGroupsResponse": [
      "SearchGroupsSuccessResponse",
      "SearchGroupsErrorResponse",
      "AuthorizationFailure"
    ],
    "SearchOrderResponse": [
      "SearchOrderConnection",
      "InputValidationFailure",
      "AuthorizationFailure"
    ],
    "SearchOrderDeskCustomerResponse": [
      "OrderDeskChatsConnection",
      "InputValidationFailure",
      "AuthorizationFailure"
    ],
    "SearchProductsResponse": [
      "SearchProductsConnection",
      "SearchProductsError"
    ],
    "SearchTaxonomyLabelsResponse": [
      "SearchTaxonomyLabelsConnection",
      "SearchTaxonomyLabelsError"
    ],
    "SendSampleSmsMessageResponse": [
      "SendSampleSmsMessageSuccess",
      "SendSampleSmsMessageFailure",
      "InputValidationFailure",
      "AuthorizationFailure"
    ],
    "SettlementReportResponse": [
      "SettlementReportSuccess",
      "InputValidationFailure",
      "AuthorizationFailure"
    ],
    "StockCountSessionsResponse": [
      "AuthorizationFailure",
      "InputValidationFailure",
      "StockCountSessionsSuccess"
    ],
    "StockCountUsersResponse": [
      "StockCountUsersSuccess",
      "AuthorizationFailure"
    ],
    "StorageAreasResponse": [
      "StorageAreasSuccess",
      "InputValidationFailure",
      "AuthorizationFailure"
    ],
    "ItemVendor": [
      "ChocoVendor",
      "BuyerExternalVendor"
    ],
    "SupplierDeliveryDateSettingsResponse": [
      "SupplierDeliveryDateSettings",
      "SupplierNotFound",
      "SupplierDeliverySettingsNotFound"
    ],
    "SupplierSearchResponse": [
      "SearchSupplierConnection",
      "InputValidationFailure",
      "AuthorizationFailure"
    ],
    "UserChatsResponse": [
      "UserChatConnection",
      "UserChatsError"
    ],
    "UserSearchResponse": [
      "UserSearchConnection",
      "InputValidationFailure",
      "AuthorizationFailure"
    ],
    "ValidateProductsAvailabilityResponse": [
      "ValidateProductsAvailabilityResult",
      "ChatNotFoundError",
      "SupplierNotFound",
      "InputValidationFailure",
      "AuthorizationFailure"
    ],
    "VendorItemCategoriesForChatResponse": [
      "VendorItemCategoriesForChatSuccess",
      "InputValidationFailure",
      "AuthorizationFailure"
    ],
    "VendorItemCategoriesForVendorResponse": [
      "VendorItemCategoriesForVendorSuccess",
      "InputValidationFailure",
      "AuthorizationFailure"
    ],
    "VendorItemUnitsResponse": [
      "VendorItemUnitsResult",
      "InputValidationFailure",
      "AuthorizationFailure"
    ],
    "VendorItemsForVendorResponse": [
      "VendorItemsForVendorResult",
      "InputValidationFailure",
      "AuthorizationFailure"
    ],
    "VendorItemsResponse": [
      "VendorItemsSuccess",
      "InputValidationFailure",
      "AuthorizationFailure"
    ],
    "VendorItemsCountForChatResponse": [
      "VendorItemsCountForChatSuccess",
      "InputValidationFailure",
      "AuthorizationFailure"
    ],
    "VendorItemsForChatResponse": [
      "VendorItemsForChatSuccess",
      "InputValidationFailure",
      "AuthorizationFailure"
    ],
    "AcceptOrderResponse": [
      "Order",
      "OrderAlreadyAccepted",
      "OrderCreatedError",
      "AuthorizationFailure",
      "InputValidationFailure"
    ],
    "ActivateSuppliersPaymentAccountResponse": [
      "SuppliersPaymentAccount",
      "AuthorizationFailure",
      "InputValidationFailure",
      "PaymentAccountNotFound",
      "ExternalAccountDetailsMissed",
      "ExternalAccountNotFound",
      "SupplierHasActivePaymentAccountAlready"
    ],
    "AddBuyerStockInventoryProductResponse": [
      "AddBuyerStockInventoryProductFailure",
      "AuthorizationFailure",
      "BuyerStockInventoryProduct",
      "InputValidationFailure",
      "ProductNotFoundError",
      "SupplierNotFound"
    ],
    "AddEntitiesToGroupResponse": [
      "AddEntitiesToGroupResponseSuccess",
      "AddEntitiesToGroupResponseError",
      "AuthorizationFailure"
    ],
    "AddInvoiceResponse": [
      "Invoice",
      "InputValidationFailure",
      "AuthorizationFailure",
      "ChatHasNoSupplierEntity",
      "OrderAlreadyHasInvoice"
    ],
    "AddMarketingProductsToCartResponse": [
      "AddMarketingProductsToCartSuccess",
      "AuthorizationFailure",
      "InputValidationFailure"
    ],
    "AddMarketingProductsToCartTeamCartData": [
      "TeamCartExists",
      "TeamCartNotFound"
    ],
    "AddPaymentAccountResponse": [
      "PaymentAccount",
      "PaymentMethodNotAvailableForUser",
      "SupplierPaymentAccountAlreadyExists",
      "AuthorizationFailure",
      "InputValidationFailure",
      "PaymentAccountAlreadyExists"
    ],
    "AddProductsToOrderGuidesResponse": [
      "AddProductsToOrderGuidesResult",
      "InputValidationFailure",
      "AuthorizationFailure"
    ],
    "AddSalesRepRequestToJoinResponse": [
      "AddSalesRepSuccess",
      "AddSalesRepError",
      "InputValidationFailure",
      "AuthorizationFailure"
    ],
    "BuyerAddSupplierResponse": [
      "BuyerAddSupplier",
      "BuyerAddSupplierError",
      "InputValidationFailure",
      "AuthorizationFailure"
    ],
    "AddSuppliersPaymentAccountResponse": [
      "SuppliersPaymentAccount",
      "AuthorizationFailure",
      "InputValidationFailure",
      "SupplierNotFound",
      "SupplierPaymentsDisabled",
      "SupplierHasActivePaymentAccountAlready"
    ],
    "AddUsersToGroupResponse": [
      "AddUsersToGroupResponseSuccess",
      "AddUsersToGroupResponseError",
      "AuthorizationFailure"
    ],
    "AssignOrderToUserForReviewResponse": [
      "AssignOrderToUserForReviewResponseSuccess",
      "AssignOrderToUserForReviewResponseError"
    ],
    "BatchOrderConfirmResponse": [
      "BatchOrderConfirmSuccess",
      "BatchOrderConfirmError"
    ],
    "BulkDeclineOrdersResponse": [
      "OrdersBulkDeclined",
      "AuthorizationFailure",
      "InputValidationFailure"
    ],
    "BulkSendInviteTransmissionResponse": [
      "BulkInviteTransmissionSuccess",
      "AuthorizationFailure",
      "InputValidationFailure"
    ],
    "BulkOrderedProductDeliveryCheckSubmissionResponse": [
      "BulkOrderedProductDeliveryCheckSubmissionResult",
      "InputValidationFailure",
      "OrderNotFound",
      "OrderDeliveryItemsMismatched",
      "OrderDeliveryCheckAlreadyCompleted",
      "AuthorizationFailure"
    ],
    "BuyerDisableRolesResponse": [
      "Buyer",
      "NotFoundError"
    ],
    "CancelCartResponse": [
      "CancelCartResult",
      "CartVersionConstraintError",
      "CartNotFoundError",
      "InputValidationFailure",
      "AuthorizationFailure"
    ],
    "CatalogExportStartResponse": [
      "CatalogExportStartResult",
      "InputValidationFailure",
      "AuthorizationFailure"
    ],
    "CatalogImportStartResponse": [
      "CatalogImportStartResult",
      "InputValidationFailure",
      "AuthorizationFailure"
    ],
    "ClaimOnboardingChecklistRewardResponse": [
      "ClaimOnboardingChecklistRewardSuccess",
      "ClaimOnboardingChecklistRewardError",
      "AuthorizationFailure"
    ],
    "ConfirmDeliveryCheckReconciliationResponse": [
      "DeliveryCheckReconciliationRequest",
      "InputValidationFailure",
      "DeliveryCheckReconciliationRequestNotFound",
      "DeliveryCheckReconciliationRequestAlreadyConfirmed",
      "OrderDeliveryCheckReconciliationRequestIdNotMatched",
      "AuthorizationFailure"
    ],
    "ConnectAccountResponse": [
      "ConnectAccountUrl",
      "ConnectAccountError"
    ],
    "ConnectIntegrationAdapterResponse": [
      "IntegrationAdapterConnectionBaseResult",
      "IntegrationAdapterConnectionAdminResult",
      "ChocoEntityNotFound",
      "UnsupportedIntegrationAdapterType",
      "IntegrationAdapterNotFound",
      "InputValidationFailure",
      "AuthorizationFailure"
    ],
    "CreateBuyerReportScheduleResponse": [
      "BuyerReportSchedule",
      "InputValidationFailure",
      "AuthorizationFailure"
    ],
    "OrderDeliveryCheckCreationResponse": [
      "OrderDeliveryCheckDetails",
      "InputValidationFailure",
      "OrderDeliveryCheckExists",
      "OrderDeliveryCheckAlreadyCompleted",
      "OrderNotFound",
      "AuthorizationFailure"
    ],
    "OrderDeliveryCheckImageUploadUrlResponse": [
      "OrderDeliveryCheckImageUploadUrlResult",
      "InputValidationFailure",
      "OrderNotFound",
      "OrderDeliveryCheckImageNotFound",
      "OrderDeliveryCheckAlreadyCompleted",
      "AuthorizationFailure"
    ],
    "CreateEmailListResponse": [
      "EmailListCreated",
      "AuthorizationFailure",
      "InputValidationFailure"
    ],
    "CreateOrderUploadResponse": [
      "CreateOrderUploadSuccess",
      "CreateOrderUploadFailure",
      "AuthorizationFailure",
      "InputValidationFailure"
    ],
    "CreateProfileResponse": [
      "User",
      "BuyerExists",
      "CreateProfileError"
    ],
    "PushEndpointResponse": [
      "NotificationPreferences"
    ],
    "CreateReferralResponse": [
      "Referral",
      "UserHasReferralCode",
      "ReferralCodeGenerationError",
      "InputValidationFailure",
      "AuthorizationFailure"
    ],
    "CreateStorageItemResponse": [
      "CreateStorageItemSuccess",
      "CreateStorageItemFailure",
      "InputValidationFailure",
      "AuthorizationFailure"
    ],
    "createSubCatalogResponse": [
      "SubCatalog",
      "CreateSubCatalogError"
    ],
    "CreateSupplierContactResponse": [
      "SupplierContact",
      "AuthorizationFailure",
      "InputValidationFailure"
    ],
    "CreateUpdateSupplierProductResponse": [
      "Product",
      "InputValidationFailure",
      "ProductNotFoundError",
      "DuplicateProductError"
    ],
    "CreditCheckFollowUpCreateResponse": [
      "CreditCheckFollowUpCreateSuccess",
      "CreditCheckFollowUpCreateFailure",
      "InputValidationFailure",
      "AuthorizationFailure"
    ],
    "DeactivateSuppliersPaymentAccountResponse": [
      "SuppliersPaymentAccount",
      "AuthorizationFailure",
      "InputValidationFailure"
    ],
    "DeclineOrderResponse": [
      "OrderDeclined",
      "AuthorizationFailure",
      "InputValidationFailure"
    ],
    "DeleteBuyerReportScheduleResponse": [
      "DeleteBuyerReportScheduleSuccess",
      "BuyerReportScheduleNotFound",
      "DeleteBuyerReportScheduleError",
      "InputValidationFailure",
      "AuthorizationFailure"
    ],
    "DeleteBuyerStockInventoryProductsResponse": [
      "DeleteBuyerStockInventoryProductsSuccess",
      "DeleteBuyerStockInventoryProductsFailure",
      "InputValidationFailure",
      "AuthorizationFailure"
    ],
    "DeleteInventoryItemsResponse": [
      "DeleteInventoryItemsSuccess",
      "DeleteInventoryItemsFailure",
      "InputValidationFailure",
      "AuthorizationFailure"
    ],
    "deleteSubCatalogResponse": [
      "DeleteSubCatalogSuccess",
      "DeleteSubCatalogError"
    ],
    "DeletedSupplierNegotiatedPriceResponse": [
      "DeleteSupplierNegotiatedPriceResult",
      "AuthorizationFailure"
    ],
    "DisconnectAccountResponse": [
      "DisconnectAccountStatus",
      "DisconnectAccountError"
    ],
    "DisconnectIntegrationAdapterResponse": [
      "IntegrationAdapterConnectionBaseResult",
      "IntegrationAdapterConnectionAdminResult",
      "IntegrationAdapterConnectionNotFound",
      "InputValidationFailure",
      "AuthorizationFailure"
    ],
    "EditBuyerReportScheduleResponse": [
      "BuyerReportSchedule",
      "BuyerReportScheduleNotFound",
      "InputValidationFailure",
      "AuthorizationFailure"
    ],
    "GenerateBuyerReportResponse": [
      "BuyerReport",
      "InputValidationFailure",
      "AuthorizationFailure"
    ],
    "GetClientDebuggableArtifactUploadUrlResponse": [
      "GetClientDebuggableArtifactUploadUrl",
      "GetClientDebuggableArtifactUploadUrlError"
    ],
    "GetOrderGuideUploadUrlResponse": [
      "GetOrderGuideUploadUrl",
      "GetOrderGuideUploadUrlError"
    ],
    "InitInventoryDownloadResponse": [
      "InitInventoryDownloadSuccess",
      "InitInventoryDownloadFailure",
      "AuthorizationFailure"
    ],
    "InitInventoryUploadResponse": [
      "InitInventoryUploadSuccess",
      "InitInventoryUploadFailure",
      "AuthorizationFailure",
      "InputValidationFailure"
    ],
    "InitiateCheckoutResponse": [
      "BalanceCheckoutToken",
      "SupplierPaymentAccountMissing",
      "InvoiceAlreadyPaid",
      "AuthorizationFailure",
      "InputValidationFailure",
      "InvoicePaymentInProgress",
      "InvoiceTotalTooLow"
    ],
    "IntegrationAdapterConnectionAuthResponse": [
      "IntegrationAdapterConnectionAuthRedirect",
      "InputValidationFailure",
      "AuthorizationFailure"
    ],
    "IntegrationAdapterConnectionConsentResponse": [
      "IntegrationAdapterConnectionConsentChallenge",
      "IntegrationAdapterConnectionConsentRedirect",
      "InputValidationFailure",
      "AuthorizationFailure"
    ],
    "IntegrationAdapterConnectionCreateResponse": [
      "IntegrationAdapterConnectionAdminResult",
      "UnsupportedIntegrationAdapterType",
      "ChocoEntityNotFound",
      "IntegrationAdapterNotFound",
      "IntegrationAdapterScopeInsufficient",
      "InputValidationFailure",
      "AuthorizationFailure"
    ],
    "IntegrationAdapterConnectionUpdateResponse": [
      "IntegrationAdapterConnectionAdminResult",
      "IntegrationAdapterConnectionNotFound",
      "IntegrationAdapterScopeInsufficient",
      "InputValidationFailure",
      "AuthorizationFailure"
    ],
    "IntegrationAdapterCreateResponse": [
      "IntegrationAdapterAdminResult",
      "IntegrationAdapterExists",
      "InputValidationFailure",
      "AuthorizationFailure"
    ],
    "IntegrationAdapterUpdateResponse": [
      "IntegrationAdapterAdminResult",
      "IntegrationAdapterNotFound",
      "AttemptToChangeAdapterAuthType",
      "AttemptToReduceIntegrationAdapterScope",
      "InputValidationFailure",
      "AuthorizationFailure"
    ],
    "IntegrationWebhookRetryResponse": [
      "IntegrationWebhookRetryResults",
      "InputValidationFailure",
      "AuthorizationFailure"
    ],
    "IntegrationWebhookRetryResult": [
      "IntegrationWebhookRetrySuccess",
      "IntegrationWebhookRetryFailure"
    ],
    "InternalInviteUsersResponse": [
      "UsersList",
      "AuthorizationFailure",
      "ValidationError"
    ],
    "inviteNonChocoUserToBuyerResponse": [
      "BuyerUser",
      "BuyerNotFound",
      "InvitorNotFound",
      "ExistingInvitedUser",
      "PuppyValidationFailure"
    ],
    "InviteUserToBuyerResponse": [
      "BuyerUser",
      "InviteUserToBuyerError",
      "AuthorizationFailure",
      "InputValidationFailure"
    ],
    "KycCreateResponse": [
      "KycResponse",
      "KycBuyerNotFound",
      "KycEntryAlreadyExists",
      "InputValidationFailure",
      "AuthorizationFailure"
    ],
    "OrderCreatedResponse": [
      "Order",
      "OrderCreatedError",
      "AuthorizationFailure"
    ],
    "OrderEditedResponse": [
      "Order",
      "OrderEditedError",
      "AuthorizationFailure"
    ],
    "OrderGuideCreatedResponse": [
      "OrderGuideCreatedSuccess",
      "OrderGuideCreatedError"
    ],
    "OrderGuideRequestResponse": [
      "OrderGuideRequest",
      "OrderGuideRequestError"
    ],
    "OrderGuideV2CategoriesUpdateResponse": [
      "OrderGuideV2CategoriesUpdateResult",
      "InputValidationFailure",
      "AuthorizationFailure"
    ],
    "OrderGuideV2ItemUnitsAddResponse": [
      "OrderGuideV2ItemUnitsAddResult",
      "InputValidationFailure",
      "AuthorizationFailure"
    ],
    "OrderGuideV2ItemUnitsRemoveResponse": [
      "OrderGuideV2ItemUnitsRemoveResult",
      "InputValidationFailure",
      "AuthorizationFailure"
    ],
    "OrderGuideV2ItemUnitsUpdateResponse": [
      "OrderGuideV2ItemUnitsUpdateResult",
      "InputValidationFailure",
      "AuthorizationFailure"
    ],
    "PaymentAuthorizeResponse": [
      "PostProcessing",
      "PreProcessing",
      "NoFurtherActionNeeded",
      "InputValidationFailure",
      "AuthorizationFailure",
      "PaymentAccountNotFound",
      "PaymentAccountIsInactive",
      "UnexpectedInvoiceState",
      "SupplierDoesNotAcceptOnlinePayments",
      "EntityCurrencyMismatch"
    ],
    "ReferUserResponse": [
      "ReferUserResult",
      "ReferUserChatExists",
      "ReferUserReferralCodeNotFound",
      "InputValidationFailure",
      "AuthorizationFailure"
    ],
    "OmnichannelOrdersSubcriptionResponse": [
      "AuthorizationFailure",
      "OrderDeskNotification"
    ],
    "RejectOrderResponse": [
      "OrderRejected",
      "AuthorizationFailure",
      "InputValidationFailure"
    ],
    "RejectOrdersByIdResponse": [
      "OrdersRejected",
      "AuthorizationFailure",
      "InputValidationFailure"
    ],
    "RemoveEmailFromBuyerReportScheduleResponse": [
      "EmailFromBuyerReportScheduleRemoved",
      "InputValidationFailure",
      "AuthorizationFailure",
      "EmailInBuyerReportScheduleNotFound",
      "BuyerReportScheduleNotFound"
    ],
    "RemoveEntitiesFromGroupResponse": [
      "RemoveEntitiesFromGroupResponseSuccess",
      "RemoveEntitiesFromGroupResponseError",
      "AuthorizationFailure"
    ],
    "RemoveInvoiceResponse": [
      "InvoiceRemoved",
      "InputValidationFailure",
      "AuthorizationFailure",
      "InvoiceCannotBeRemoved"
    ],
    "RemoveInvoiceAttachmentResponse": [
      "InvoiceAttachmentRemoved",
      "InputValidationFailure",
      "AuthorizationFailure",
      "InvoiceCannotBeUpdated"
    ],
    "OrderDeskRemovedEmailResponse": [
      "OrderDeskEmailRemoved",
      "AuthorizationFailure",
      "InputValidationFailure"
    ],
    "RemovePaymentAccountResponse": [
      "PaymentAccountRemoved",
      "InputValidationFailure",
      "AuthorizationFailure"
    ],
    "RemoveProductsFromOrderGuidesResponse": [
      "RemoveProductsFromOrderGuidesResult",
      "InputValidationFailure",
      "AuthorizationFailure"
    ],
    "MinimumOrderValueUpdateResponse": [
      "MinimumOrderValueUpdate",
      "MinimumOrderValueFailure",
      "InputValidationFailure",
      "AuthorizationFailure"
    ],
    "RemoveUsersFromGroupResponse": [
      "RemoveUsersFromGroupSuccess",
      "RemoveUsersFromGroupError",
      "AuthorizationFailure"
    ],
    "RequestToJoinBuyerResponse": [
      "UserRequestToJoin",
      "RequestToJoinBuyerError",
      "AuthorizationFailure"
    ],
    "RetryOrderProcessingResponse": [
      "OrderProcessingRetrying",
      "NotFoundError",
      "AuthorizationFailure",
      "InputValidationFailure",
      "InternalServerError"
    ],
    "SelectProductForOrderGuideResponse": [
      "SelectProductForOrderGuideSuccess",
      "InputValidationFailure",
      "AuthorizationFailure"
    ],
    "SelectProductsForOrderGuideResponse": [
      "SelectProductsForOrderGuideSuccess",
      "AuthorizationFailure"
    ],
    "SendInviteTransmissionResponse": [
      "InviteTransmission",
      "AuthorizationFailure",
      "InputValidationFailure"
    ],
    "SendSyntheticSmsMessageResponse": [
      "SendSyntheticSmsMessageSuccess",
      "InputValidationFailure",
      "AuthorizationFailure"
    ],
    "SendSyntheticWhatsAppMessageResponse": [
      "SendSyntheticWhatsAppMessageSuccess",
      "InputValidationFailure",
      "AuthorizationFailure"
    ],
    "SetOrderAsTestResponse": [
      "TestOrderResponse",
      "InputValidationFailure"
    ],
    "SettlementReportCreateResponse": [
      "SettlementReportCreateSuccess",
      "InputValidationFailure",
      "AuthorizationFailure"
    ],
    "CartSubmissionResponse": [
      "CartSubmissionResult",
      "CartSubmissionError",
      "CreateMessageError",
      "CartNotFoundError",
      "CartSubmittedEmptyError",
      "CartVersionConstraintError",
      "CartInvalidProductsError",
      "CartAllProductsUnavailableError",
      "AuthorizationFailure",
      "ForbiddenError",
      "InputValidationFailure",
      "ExistingOrderError",
      "ProductLeadTimeError",
      "MinimumOrderableAmountError"
    ],
    "OrderDeliveryCheckSubmissionResponse": [
      "OrderDeliveryCheckDetails",
      "InputValidationFailure",
      "OrderDeliveryCheckAlreadyCompleted",
      "OrderNotFound",
      "OrderDeliveryItemsMismatched",
      "OrderDeliveryCheckNotFound",
      "AuthorizationFailure"
    ],
    "OrderedProductDeliveryCheckSubmissionResponse": [
      "OrderedProductDeliveryCheck",
      "InputValidationFailure",
      "OrderNotFound",
      "OrderDeliveryItemsMismatched",
      "OrderDeliveryCheckAlreadyCompleted",
      "AuthorizationFailure"
    ],
    "SubmitStockCountSessionResponse": [
      "AuthorizationFailure",
      "InputValidationFailure",
      "SubmitStockCountSessionFailure",
      "SubmitStockCountSessionSuccess"
    ],
    "SupplierAddRequestResponse": [
      "SupplierAddRequest",
      "SupplierAddRequestError"
    ],
    "SupplierGetInTouchRequestResponse": [
      "SupplierGetInTouchRequestSuccess",
      "SupplierGetInTouchRequestError"
    ],
    "SupplierInviteResponseEntity": [
      "User",
      "Chat",
      "InvalidInvitedPhoneNumber",
      "SupplierInviteError"
    ],
    "TriggerProductMatchingResponse": [
      "OmnichannelOrder",
      "AuthorizationFailure",
      "InputValidationFailure"
    ],
    "UnassignUserFromOrderToReviewResponse": [
      "UnassignUserFromOrderToReviewResponseSuccess",
      "UnassignUserFromOrderToReviewResponseError"
    ],
    "UndoMultiLocationOrderGuideTransactionResponse": [
      "UndoMultiLocationOrderGuideTransactionResult",
      "InputValidationFailure",
      "AuthorizationFailure"
    ],
    "UpdateBuyerInventoryProductResponse": [
      "BuyerInventoryProduct",
      "InputValidationFailure",
      "AuthorizationFailure"
    ],
    "UpdateBuyerStockInventoryProductResponse": [
      "AuthorizationFailure",
      "BuyerStockInventoryProduct",
      "InputValidationFailure",
      "ProductNotFoundError",
      "SupplierNotFound",
      "UpdateBuyerStockInventoryProductFailure"
    ],
    "UpdateBuyerUserChatsResponse": [
      "UpdateBuyerUserChatsSuccess",
      "UpdateBuyerUserChatsError",
      "AuthorizationFailure",
      "InputValidationFailure"
    ],
    "CartUpdateResponse": [
      "Cart",
      "CartNotFoundError",
      "NegativeAmountError",
      "AuthorizationFailure",
      "InputValidationFailure"
    ],
    "UpdateInventoryItemResponse": [
      "UpdateInventoryItemSuccess",
      "UpdateInventoryItemFailure",
      "InputValidationFailure",
      "AuthorizationFailure"
    ],
    "UpdateInvoiceResponse": [
      "Invoice",
      "InputValidationFailure",
      "AuthorizationFailure",
      "InvoiceCannotBeUpdated"
    ],
    "UpdateInvoicedProductResponse": [
      "InvoicedProducts",
      "InputValidationFailure",
      "AuthorizationFailure"
    ],
    "UpdatePendingUserResponse": [
      "User",
      "AuthorizationFailure"
    ],
    "UpdateProductVariantsInOrderGuideResponse": [
      "UpdateProductVariantsInOrderGuideSuccess",
      "InputValidationFailure",
      "AuthorizationFailure"
    ],
    "UpdateJoinRequestResponse": [
      "UpdateJoinRequestSuccess",
      "UpdateJoinRequestError",
      "InputValidationFailure",
      "AuthorizationFailure"
    ],
    "UpdateStorageItemResponse": [
      "UpdateStorageItemSuccess",
      "UpdateStorageItemFailure",
      "InputValidationFailure",
      "AuthorizationFailure"
    ],
    "updateSubCatalogResponse": [
      "SubCatalog",
      "UpdateSubCatalogError"
    ],
    "UpdateSubCatalogChatsResponse": [
      "UpdateSubCatalogChatsResult",
      "AuthorizationFailure"
    ],
    "EntityIdentity": [
      "IntegrationEntityIdentity",
      "InternalEntityIdentity"
    ],
    "SupplierUpdateAccessPackageResponse": [
      "SupplierUpdateAccessPackageSuccess",
      "SupplierUpdateAccessPackageError"
    ],
    "UpdateSupplierContactResponse": [
      "SupplierContact",
      "AuthorizationFailure",
      "InputValidationFailure"
    ],
    "UploadSubCatalogProductsResponse": [
      "UploadSubCatalogProductsResult",
      "UploadSubCatalogProductsError"
    ],
    "UpsertStockCountSessionItemsResponse": [
      "UpsertStockCountSessionItemsSuccess",
      "UpsertStockCountSessionItemsError",
      "InputValidationFailure",
      "AuthorizationFailure"
    ],
    "UserRequestToDeleteResponse": [
      "UserRequestToDeleteDetails",
      "AuthorizationFailure"
    ],
    "VendorItemCustomCreateResponse": [
      "VendorItemCustomCreateResult",
      "InputValidationFailure",
      "AuthorizationFailure"
    ],
    "VendorItemCustomUpdateResponse": [
      "VendorItemCustomUpdateResult",
      "InputValidationFailure",
      "AuthorizationFailure"
    ],
    "VendorItemUnitBuyerPropertiesUpdateResponse": [
      "VendorItemUnitBuyerPropertiesUpdateResult",
      "InputValidationFailure",
      "AuthorizationFailure"
    ],
    "VendorItemUnitBuyerPropertiesUpdateBatchResponse": [
      "VendorItemUnitBuyerPropertiesUpdateBatchResult",
      "InputValidationFailure",
      "AuthorizationFailure"
    ],
    "VendorItemUnitUpdateResponse": [
      "VendorItemUnitUpdateResult",
      "InputValidationFailure",
      "AuthorizationFailure"
    ],
    "VendorItemUnitsImagesUploadResponse": [
      "VendorItemUnitsImagesUploadResult",
      "InputValidationFailure",
      "AuthorizationFailure"
    ],
    "VendorItemUnitsUpdateResponse": [
      "VendorItemUnitsUpdateResult",
      "InputValidationFailure",
      "AuthorizationFailure"
    ],
    "VendorItemUnitUpdateFailureType": [
      "InputValidationFailure",
      "UnknownFailure",
      "AuthorizationFailure"
    ],
    "ActivateChatResponse": [
      "Chat"
    ],
    "BuyerChatsResponse": [
      "BuyerChatConnection",
      "InvalidCursor"
    ]
  }
};
      export default result;
    