import { Flex, ThemeUIStyleObject, Button, Text } from '@chocoapp/chocolate-ui';
import {
  getLocalStorageItem,
  removeLocalStorageItem,
  isNil,
} from '@chocoapp/toolbelt-utils';
import React from 'react';

import { OVERRIDE_STATE_KEY } from '../../../constants/AppConstants';
import { ImportMapType } from '../../../portal/utils/prefetchMFEs';

import { useRemoveQueryStringParam } from './utils';

const wapperStyles: ThemeUIStyleObject = {
  bg: 'warningBg',
  px: 'xs',
  py: 'xxs',
  alignItems: 'center',
  height: '42px',
};

const ALLOWED_ORIGINS = ['https://chocolify.spezi.app'];

export const OverrideState = () => {
  const { removeQueryStringParam } = useRemoveQueryStringParam();

  const overrideState: ImportMapType['imports'] = getLocalStorageItem(
    OVERRIDE_STATE_KEY
  );

  if (isNil(overrideState) || Object.keys(overrideState).length === 0)
    return null;

  // we just allow one for now
  // we want to see if it makes sense to test more than one at the time
  const overridedMFE = Object.keys(overrideState)[0];
  const overrideURL = overrideState[overridedMFE];
  const overrideStringArray = overrideURL.split('/');
  // the second to last part of the url is the name of the branch
  const overrideBranch = overrideStringArray[overrideStringArray.length - 2];

  const resetOverrideState = () => {
    removeLocalStorageItem(OVERRIDE_STATE_KEY);
    removeQueryStringParam(OVERRIDE_STATE_KEY);
    window.location.reload();
  };

  if (!ALLOWED_ORIGINS.includes(new URL(overrideURL).origin)) {
    resetOverrideState();
    return null;
  }

  return (
    <Flex data-testid="OverrideState" sx={wapperStyles}>
      <Text variant="Caption">
        {`Hey you!🌻 You're overriding "${overridedMFE}" - branch "${overrideBranch}"`}
      </Text>
      <Button
        size="small"
        variant="tertiary"
        data-testid="ResetButton"
        onClick={resetOverrideState}
      >
        Remove override
      </Button>
    </Flex>
  );
};
