import { AppLogger } from '../application/logger';

type RetryWithDelayOptions = {
  /** The number of milliseconds to wait before retrying */
  delay: number;
  /** The maximum number of retries before throwing an error */
  maxRetryCount?: number;
  /** Retry error message */
  retryExceededError?: string;
  /** Log retry errors to DataDog */
  logRetryErrorsToDD?: boolean;
  /** Error callback when all retry attempts failed and maxRetryCount was exceeded */
  finalErrorCallback?: (error: Error) => void;
  /** Success callback */
  successCallback?: () => void;
};

const finishRetrying = (
  { retryExceededError, finalErrorCallback }: RetryWithDelayOptions,
  error: unknown
) => {
  if (retryExceededError) {
    AppLogger.error(retryExceededError, { error });
  }

  if (finalErrorCallback) {
    finalErrorCallback(error as Error);
  }
};

/**
 * @description Retry a function with a delay between each attempt
 * @note Subject to linearly increasing delay, ie. `delay * retryCount`
 * @param {Function} asyncFunctionWithThrowable - The function to retry
 */
export function retryWithProgressiveDelay(
  asyncFunctionWithThrowable: (...args: any[]) => Promise<void>
) {
  return async function retryAttempt(
    options: RetryWithDelayOptions,
    retryCount = 0
  ): Promise<void> {
    const {
      delay,
      maxRetryCount = 3,
      logRetryErrorsToDD,
      successCallback,
    } = options;

    try {
      await asyncFunctionWithThrowable();
      if (successCallback) successCallback();
    } catch (error) {
      if (retryCount >= maxRetryCount) {
        finishRetrying(options, error);
        return;
      }

      if (logRetryErrorsToDD) {
        AppLogger.error('Retry attempt failed', { error });
      }

      setTimeout(
        () => {
          retryAttempt(options, retryCount + 1);
        },
        delay * (retryCount + 1)
      );
    }
  };
}
