import { Flex, Text } from '@chocoapp/chocolate-ui';
import React, { FC, ReactNode } from 'react';

type PageBodyProps = {
  title: string;
  subtitle: string;
  testid: string;
  icon: ReactNode;
};

const PageBody: FC<PageBodyProps> = ({ title, subtitle, testid, icon }) => (
  <Flex
    sx={{
      width: '100%',
      height: '100%',
      alignItems: 'center',
      justifyContent: 'center',
      bg: 'neutralBg',
    }}
  >
    <Flex
      data-testid={testid}
      sx={{
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
        maxWidth: '700px',
      }}
    >
      {icon}
      <Text variant="H4" mt="30px">
        {title}
      </Text>
      <Text variant="TitleMedium">{subtitle}</Text>
    </Flex>
  </Flex>
);

export default PageBody;
