export type UnreadMessagesDataType = {
  count: number;
  firstUnreadMessageId: string;
};
export type UnreadMessagesStateType = Record<
  string,
  UnreadMessagesDataType | undefined
>;

export type IncreaseActionType = (chatId: string, messageId: string) => void;
export type ResetActionType = (chatId: string) => void;
export type ClearAllActionType = () => void;

export type UnreadMessagesContextType = {
  increase: IncreaseActionType;
  reset: ResetActionType;
  resetCount: ResetActionType;
  clearAll: ClearAllActionType;
  state: UnreadMessagesStateType;
};

export type UnreadMessagesActionsType = Omit<
  UnreadMessagesContextType,
  'state'
>;

export enum UnreadMessagesActions {
  increase = 'increase',
  reset = 'reset',
  resetCount = 'resetCount',
  clearAll = 'clearAll',
}
