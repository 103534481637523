import { Flex, ThemeUIStyleObject, IconButton } from '@chocoapp/chocolate-ui';
import React, { FC } from 'react';

import { AppLogger } from '../../../application/logger';

const controlRootStyles: ThemeUIStyleObject = {
  gap: 's',
  paddingLeft: 'm',
  py: '2px',
  border: '1px solid',
  borderColor: 'neutralBorder',
  width: 'fit-content',
  alignItems: 'center',
  bg: 'neutralBg',
};

interface TextToClipboardProps {
  value: string;
  disabled?: boolean;
  onCopy?: () => void;
  'data-testid'?: string;
}

const TextToClipboard: FC<TextToClipboardProps> = ({
  children,
  value,
  disabled,
  onCopy,
  'data-testid': dataTestId,
}) => {
  const onCopyClick = async () => {
    try {
      await window.navigator.clipboard.writeText(value);

      if (onCopy) {
        onCopy();
      }
    } catch (error) {
      // TODO Add toast with error
      AppLogger.error('TextToClipboardError', { error });
    }
  };

  return (
    <Flex sx={controlRootStyles} data-testid={dataTestId}>
      {children}
      <IconButton
        name="copy"
        disabled={disabled}
        variant="tertiaryIcon"
        onClick={onCopyClick}
        data-testid={dataTestId ? `${dataTestId}-copy-button` : undefined}
      />
    </Flex>
  );
};

export default TextToClipboard;
