export enum PermissionTypesEnum {
  default = 'default',
  denied = 'denied',
  granted = 'granted',
}

export function requestNotificationPermission() {
  if ('Notification' in window) {
    const { permission, requestPermission } = (window as any).Notification;

    if (permission === PermissionTypesEnum.default) {
      requestPermission();
    }
  }
}

export type NativeNotificationType = {
  onClick?: (e?: Event) => void;
  title: string;
  body?: string;
  icon?: string;
};

export const spawnNotification = ({
  title,
  onClick,
  ...options
}: NativeNotificationType): void => {
  if (
    'Notification' in window &&
    Notification.permission === PermissionTypesEnum.granted
  ) {
    const notification = new Notification(title, options);
    notification.onclick = onClick || (() => window.focus());
  }
};
