import { pipe } from '@chocoapp/toolbelt-utils/lib/pipe';

import { AppLogger } from '../../application/logger';

import insertNewImportMap from './insertNewImportMap';

export type ImportMapType = {
  imports: {
    [key: string]: string;
  };
};

export const MAIN_TIME_TO_FETCH = 10000;
const MAX_RETRY_COUNT = 3;
export const IMPORT_MAP_ID = 'portal-import-map';

function getPortalImportMap(): ImportMapType {
  const portalImportMap: HTMLScriptElement = document.getElementById(
    IMPORT_MAP_ID
  ) as HTMLScriptElement;

  const imports = JSON.parse(
    portalImportMap?.textContent ?? '{ "imports": {} }'
  ) as ImportMapType;

  return imports;
}

export const removePreviousPortalImportMapNode = () => {
  const importMap = document.getElementById(IMPORT_MAP_ID);

  if (importMap) {
    importMap.remove();
  }
};

const reduceDepCache = (imports: ImportMapType['imports']) =>
  Object.values(imports).reduce(
    (previous, current) => ({
      ...previous,
      [current]: Object.values(imports).filter(
        (importUrl) => importUrl !== current
      ),
    }),
    {}
  );

export const withImports = ({ imports }: ImportMapType) => ({
  imports,
});

export const withDepCache = (importMap: ImportMapType) => ({
  ...importMap,
  depcache: reduceDepCache(importMap.imports),
});

function importWithRetryPolicy(retryCount = 1) {
  function retryWithDelay() {
    if (retryCount <= MAX_RETRY_COUNT) {
      setTimeout(
        importWithRetryPolicy(retryCount + 1),
        (MAIN_TIME_TO_FETCH / 10) * retryCount
      );
    } else {
      AppLogger.error('ImportMap: exhausted all retries', { retryCount });
    }
  }

  return async () => {
    try {
      const importMap = getPortalImportMap();
      const newPortalImportMap = pipe(withImports, withDepCache)(importMap);

      const { pathname } = window.location;

      const [firstMFE] = Object.keys(newPortalImportMap.imports).filter(
        (portal) => pathname.split('/')[1] !== portal.split('/')[1]
      );

      removePreviousPortalImportMapNode();
      insertNewImportMap(newPortalImportMap, IMPORT_MAP_ID, 'beforebegin');

      await SystemJS.prepareImport(true);
      // start import via depcache. firstMFE mustn't be already fetched
      // https://github.com/systemjs/systemjs/blob/master/docs/import-maps.md#depcache
      SystemJS.import(firstMFE);
    } catch (error) {
      AppLogger.error('ImportMap: failed to prefetch MFEs', { error });
      retryWithDelay();
    }
  };
}

// TODO use src/util/retryWithDelay.ts to simplify this function
export default function prefetchMFEs() {
  setTimeout(importWithRetryPolicy(), MAIN_TIME_TO_FETCH);
}
