import { Box } from '@chocoapp/chocolate-ui';
import React, { FC } from 'react';

import { UserFragment } from '../../../../generated';

import { EnvironmentNavItem } from './EnvironmentNavItem';
import GetHelp from './GetHelp';
import ThemeSwitch from './ThemeSwitch';
import { UserProfile } from './UserProfile';

type NavigationFooterProps = {
  actualLoggedInUser: UserFragment;
};

const NavigationFooter: FC<NavigationFooterProps> = ({
  actualLoggedInUser,
}) => {
  return (
    <Box>
      <EnvironmentNavItem />
      <ThemeSwitch />
      <GetHelp />
      <UserProfile actualLoggedInUser={actualLoggedInUser} />
    </Box>
  );
};

export default NavigationFooter;
