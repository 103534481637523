import { Flex, Text } from '@chocoapp/chocolate-ui';
import { formatPhoneNumber } from '@chocoapp/toolbelt-utils';
import React, { FC } from 'react';

type UserInfoProps = {
  name: string | null | undefined;
  phone: string;
};

const UserInfo: FC<UserInfoProps> = ({ name, phone }) => (
  <Flex
    sx={{
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'flex-end',
      color: 'baseTextStrong',
    }}
  >
    <Text variant="Subhead">{name}</Text>
    <Text variant="Caption">{formatPhoneNumber(phone)}</Text>
  </Flex>
);

export default UserInfo;
