import { useEffect, useState } from 'react';

import { requestNotificationPermission } from '../util/Notification';

export default function useRequestNotificationPermission(
  isAuthenticated: boolean
) {
  const [hasRequested, setHasRequested] = useState(false);
  useEffect(() => {
    if (isAuthenticated && !hasRequested) {
      setHasRequested(true);
      requestNotificationPermission();
    }
  }, [isAuthenticated, hasRequested]);
}
