import { Text, ThemeUIStyleObject } from '@chocoapp/chocolate-ui';
import FlyoutOptions, {
  FlyoutItem,
} from '@chocoapp/chocolate-ui/lib/components/commons/Flyout/FlyoutOptions';
import { TFunction } from 'i18next';
import React, { FC } from 'react';
import { Link } from 'react-router-dom';

const linkStyles: React.CSSProperties = {
  textDecoration: 'none',
};

const optionStyles: ThemeUIStyleObject = {
  color: 'neutralText',
};

const logOutOptionStyles: ThemeUIStyleObject = {
  ...optionStyles,
  color: 'dangerText',
};

type ProfileOptionsProps = {
  // passing t as a prop so it doesn't flash when the flyout opens the first time
  t: TFunction;
  closePopup: () => void;
};

const getFlyoutItems = ({
  t,
  closePopup,
}: ProfileOptionsProps): FlyoutItem[] => [
  {
    content: (
      <Link style={linkStyles} to="/settings/user-profile">
        <Text sx={optionStyles}>{t('userProfile.header')}</Text>
      </Link>
    ),
    onClick: closePopup,
  },
  {
    content: (
      <Link style={linkStyles} to="/logout">
        <Text sx={logOutOptionStyles}>{t('logout')}</Text>
      </Link>
    ),
    onClick: closePopup,
  },
];

const ProfileOptions: FC<ProfileOptionsProps> = ({ t, closePopup }) => {
  return <FlyoutOptions items={getFlyoutItems({ t, closePopup })} />;
};

export default ProfileOptions;
