import {
  Flex,
  ChocoIcon,
  Text,
  Box,
  ThemeUIStyleObject,
  IconName,
} from '@chocoapp/chocolate-ui';
import { useFlags } from '@chocoapp/launchdarkly-ui';
import { usePermissions } from '@chocoapp/toolbelt-auth';
import { trackSegmentAction, isNil } from '@chocoapp/toolbelt-utils';
import styled from '@emotion/styled';
import React, { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, useLocation, matchPath } from 'react-router-dom';

import { useAuthContext } from '../../../auth';
import { RoleI18nKeyPair } from '../GlobalNavigation/navigation';
import BubbleUnreadMessages from '../UnreadMessages/BubbleUnreadMessages';
import { useUnreadMessagesCount } from '../UnreadMessages/useUnreadMessagesCount';

import { BetaTag, WithBetaTagOnCollapse } from './BetaTag';
import HiddenWhenCollapsed from './HiddenWhenCollapsed';
import MenuTooltip from './MenuTooltip';
import { NavigationItemType } from './navigation';

const StyledNavigationItem = styled(NavLink)`
  text-decoration: none;
  color: ${({ theme }) => theme.colors.baseTextWeak};

  &:hover {
    color: ${({ theme }) => theme.colors.baseTextWeakActive};
  }
`;

const itemWrapper: ThemeUIStyleObject = {
  alignItems: 'center',
  gap: '14px',
  p: '20px',
};

const iconWrapper: ThemeUIStyleObject = {
  position: 'relative',
  height: '24px',
};

type NavigationItemTextProps = {
  defaultI18nKey: string;
  roleSpecificKey?: RoleI18nKeyPair;
};

const useGetNavigationItemText = ({
  defaultI18nKey,
  roleSpecificKey,
}: NavigationItemTextProps) => {
  const { t } = useTranslation('globalNavigation');
  const { enableWithRoles } = usePermissions();
  const i18nKey =
    !isNil(roleSpecificKey) && enableWithRoles([roleSpecificKey.role])
      ? roleSpecificKey.i18nKey
      : defaultI18nKey;

  return t(`navigation.${i18nKey}`);
};

const NavigationItem: FC<NavigationItemType> = ({
  to,
  route,
  iconName,
  i18nKey,
  roleSpecificKey,
  featureFlagKey,
  trackEventName,
  testId,
  isBeta,
}) => {
  const flags = useFlags();
  const navigationItemText = useGetNavigationItemText({
    defaultI18nKey: i18nKey,
    roleSpecificKey,
  });

  const { pathname } = useLocation();
  const { getLoggedInUserPayloadFromCognito } = useAuthContext();
  const onNavItemClick = useCallback(() => {
    if (!trackEventName) return;
    trackSegmentAction({
      event: trackEventName,
      data: {
        supplier_id: getLoggedInUserPayloadFromCognito().supplierId,
      },
    });
  }, [getLoggedInUserPayloadFromCognito, trackEventName]);

  const enabled = flags[featureFlagKey!] ?? true;
  const isActive = matchPath(pathname, route || to);
  const unreadMessages = useUnreadMessagesCount();

  return (
    <>
      {enabled && (
        <MenuTooltip tooltipText={navigationItemText}>
          <StyledNavigationItem
            to={to}
            data-testid={testId}
            onClick={onNavItemClick}
          >
            <Flex sx={itemWrapper}>
              <Box sx={iconWrapper}>
                <WithBetaTagOnCollapse isBeta={Boolean(isBeta)}>
                  <ChocoIcon
                    name={`${iconName}${isActive ? 'Filled' : ''}` as IconName}
                  />
                </WithBetaTagOnCollapse>
                {to === '/inbox' && (
                  <BubbleUnreadMessages count={unreadMessages} />
                )}
              </Box>
              <HiddenWhenCollapsed>
                <Flex sx={{ gap: 'xs', alignItems: 'center' }}>
                  <Text variant={isActive ? 'BodyStrong' : 'Body'}>
                    {navigationItemText}
                  </Text>
                  {isBeta && <BetaTag />}
                </Flex>
              </HiddenWhenCollapsed>
            </Flex>
          </StyledNavigationItem>
        </MenuTooltip>
      )}
    </>
  );
};

export default NavigationItem;
