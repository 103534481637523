import { ThemeUIStyleObject, Box, Ellipsis } from '@chocoapp/chocolate-ui';
import React, { FC } from 'react';
import { usePopperTooltip } from 'react-popper-tooltip';

import { COLLAPSED_WIDTH } from './GlobalNavigationV2';
import { useCollapsedContext } from './useCollapsedContext';

const tooltipStyles: ThemeUIStyleObject = {
  container: 'body',
  zIndex: 'tooltip',
  maxWidth: '180px',
  color: 'baseTextWeak',
  bg: 'baseBgStrong',
  borderRadius: '4px',
  py: '4px',
  px: '8px',
};

type MenuTooltipProps = {
  tooltipText: string;
};

const MenuTooltip: FC<MenuTooltipProps> = ({ tooltipText, children }) => {
  const { isCollapsed } = useCollapsedContext();

  const {
    getTooltipProps,
    setTooltipRef,
    setTriggerRef,
    visible,
  } = usePopperTooltip({
    placement: 'right',
    offset: [0, 4],
  });

  const tooltipProps = getTooltipProps();

  if (!isCollapsed) return <>{children}</>;

  return (
    <>
      <Box
        sx={{ width: COLLAPSED_WIDTH }}
        data-testid={`TooltipTrigger_${tooltipText}`}
        ref={setTriggerRef}
      >
        {children}
      </Box>
      {visible && (
        <Box
          data-testid={`TooltipContent_${tooltipText}`}
          ref={setTooltipRef}
          sx={tooltipStyles}
          {...tooltipProps}
        >
          <Ellipsis variant="Caption">{tooltipText}</Ellipsis>
        </Box>
      )}
    </>
  );
};

export default MenuTooltip;
