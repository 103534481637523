import { createBrowserHistory } from 'history';

export const APP_BROWSER_HISTORY = createBrowserHistory();
export const LS_PREMIUM_REQUESTED_KEY = 'premiumRequested';
export const LS_PREMIUM_REQUESTED_NUMBER_KEY = 'premiumRequestedNumber';

export const OVERRIDE_STATE_KEY = 'override-state';

export const SIGN_UP_PASSWORD = 'Cas9234j*0s';
export const RECAPTCHA_KEY = '6LfxtBwoAAAAAFyvkemzY5OGR2RRBx6CIjKfZz11';
export const GOOGLE_MAPS_API_KEY = 'AIzaSyBQXNBw0hfEjT8NkaP9_B9Mb9rSUxsSois';

export const UNAUTHORIZED_SUPPLIER_KEY = 'isUnauthorizedSupplier';
export const CHOCO_SUPPORT_LOGIN_PHONE_KEY = 'ChocoSupportLoginPhone';
export const LAST_VISITED_CHAT_KEY = 'lastVisitedChat';
export const LAUNCH_DARKLY = 'LDIdentity';
export const LAST_SIGNED_OUT_ID_TOKEN = 'ChocoLastSignedOutIdToken';
export const LS_PERSISTED_FILTER_PENDING = 'ChocoPersistedOrderFilters_pending';
export const LS_PERSISTED_FILTER_ACCEPTED =
  'ChocoPersistedOrderFilters_accepted';

export const LOGOUT_LS_EVENT_TRIGGER = 'ChocoLogoutLSEventTrigger';
