import { BuyerFragment } from '../../../generated';

export type GoogleMapsPosition = {
  lat: number;
  lng: number;
  title: string;
  id: string;
  fullAddress: string;
};

export const searchBuyersToGMPOsition = (
  buyers: BuyerFragment[]
): GoogleMapsPosition[] => {
  return buyers.map(({ longitude, latitude, id, name, fullAddress }) => ({
    lat: latitude!,
    lng: longitude!,
    title: name,
    fullAddress,
    id,
  }));
};
