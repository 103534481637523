import { Flex, ThemeUIStyleObject } from '@chocoapp/chocolate-ui';
import React from 'react';

import { isProduction } from '../../../../auth/environments/utils';
import { EnvironmentSelector } from '../../EnvironmentSelector';

const wrapperStyles: ThemeUIStyleObject = {
  justifyContent: 'center',
};

export const EnvironmentNavItem = () => {
  if (isProduction) return null;

  return (
    <Flex sx={wrapperStyles}>
      <EnvironmentSelector />
    </Flex>
  );
};
