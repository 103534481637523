import { Button, Flex, Text, ThemeUIStyleObject } from '@chocoapp/chocolate-ui';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { AsyncI18n } from '..';
import { useTrackPaywall } from '../Paywall/useTrackPremiumScreen';

import { PaywallModal } from './PaywallModal';

const bannerStyles: ThemeUIStyleObject = {
  p: 'l',
  bg: 'baseBg',
  width: '100%',
  columnGap: 'xl',
  justifyContent: 'center',
};

export const PaywallBanner = (): JSX.Element => {
  const { t } = useTranslation('getInTouch');

  const [modalIsOpen, setModalIsOpen] = useState(false);

  const trackEvent = useTrackPaywall({ name: 'Paywall Banner' });

  const handleTrackButton = () => {
    trackEvent({ event: 'Paywall Banner Clicked' });
    setModalIsOpen(true);
  };

  const handleCloseModal = () => {
    setModalIsOpen(false);
  };

  return (
    <AsyncI18n>
      <Flex sx={bannerStyles} data-testid="paywall-banner">
        <Text
          variant="BodyStrong"
          sx={{ color: 'baseTextWeak', alignSelf: 'center' }}
        >
          {t('paywallBanner.title')}
        </Text>
        <Button onClick={handleTrackButton} variant="secondary">
          {t('paywallBanner.buttonCTA')}
        </Button>
      </Flex>
      <PaywallModal isOpen={modalIsOpen} onCloseModal={handleCloseModal} />
    </AsyncI18n>
  );
};
