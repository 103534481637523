import { useEffect, useState } from 'react';

import { AppLogger } from '../../application/logger';
import { useOnNotificationCreateSubscription as originalUseOnNotificationCreateSubscription } from '../../generated';
import { onNotificationCreateSubject } from '../../portal/subjects';

import { useHandleMessageNotifications } from './notificationHooks';

export function useOnNotificationCreateSubscription(
  userId: string,
  currentChatId: string
) {
  const { showNotifications } = useHandleMessageNotifications(
    document,
    currentChatId
  );
  const [delaySub, setDelaySub] = useState(true);

  useEffect(() => {
    let delayTimeoutId: number | undefined = window.setTimeout(() => {
      setDelaySub(false);
      delayTimeoutId = undefined;
    }, 1000);

    return () => {
      if (delayTimeoutId) {
        window.clearTimeout(delayTimeoutId);
      }
    };
  }, []);

  const onNotificationCreateSubscription =
    originalUseOnNotificationCreateSubscription({
      skip: !userId || delaySub,
      variables: { userId },
      onData: (subscriptionData) => {
        try {
          const notification = subscriptionData.data.data?.onNotificationCreate;

          if (!notification) return;

          const { message, notificationDirectives } = notification;

          if (!message) return;

          onNotificationCreateSubject.next(message);

          if (message.user.id !== userId) showNotifications(message, notificationDirectives ?? undefined);
        } catch (error) {
          AppLogger.error('onNotificationCreateSubscriptionError', { error });
        }
      },
    });

  return { onNotificationCreateSubscription };
}
