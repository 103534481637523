import { CountryCode } from '../components/commons/Login/CountryCode';
import { REACT_APP_IPSTACK_API_KEY } from '../config';

type UserGeoLocationData = {
  countryCode: CountryCode;
  dialingCode: string;
};

export const fetchUserGeoLocationData = async (): Promise<UserGeoLocationData> => {
  try {
    const response = await fetch(
      `https://api.ipstack.com/check?access_key=${REACT_APP_IPSTACK_API_KEY}`
    );

    const {
      country_code: countryCode,
      location: { calling_code: dialingCode },
    } = await response.json();

    return {
      countryCode: countryCode as CountryCode,
      dialingCode: `+${dialingCode}`,
    };
  } catch {
    return {
      countryCode: 'DE',
      dialingCode: '+49',
    };
  }
};
