import { ThemeUIStyleObject, Flex } from '@chocoapp/chocolate-ui';
import { Global } from '@emotion/react';
import { FC } from 'react';

import { UserFragment } from '../../../generated';
import { AsyncI18n } from '../AsyncI18n';

import Collapse from './Collapse';
import NavigationFooter from './Footer/NavigationFooter';
import NavigationHeader from './Header/NavigationHeader';
import NavigationTabs from './NavigationTabs';
import { CollapsedProvider, useCollapsedContext } from './useCollapsedContext';

export const EXPANDED_WIDTH = '220px';
export const COLLAPSED_WIDTH = '64px';

const getGlobalNavigationStyles = (
  isCollapsed: boolean
): ThemeUIStyleObject => ({
  flexDirection: 'column',
  bg: 'baseBgStrong',
  width: isCollapsed ? COLLAPSED_WIDTH : EXPANDED_WIDTH,
  transition: `width 150ms ease-out`,
});

const bodyWrapper: ThemeUIStyleObject = {
  flex: 1,
  flexDirection: 'column',
  justifyContent: 'space-between',
  bg: 'baseBgStrong',
  border: '1px',
  borderColor: 'baseBorderMedium',
  borderStyle: 'solid none', // only top and bottom
};

type GlobalNavigationV2Props = {
  actualLoggedInUser: UserFragment;
};

const GlobalNavigationV2Content: FC<GlobalNavigationV2Props> = ({
  actualLoggedInUser,
}) => {
  const { isCollapsed } = useCollapsedContext();

  return (
    <AsyncI18n>
      <Flex
        data-testid="GlobalNavigationV2"
        sx={getGlobalNavigationStyles(isCollapsed)}
      >
        <Global
          // Adding this because some MFEs need to know about the current width of the sidebar
          // Exposing this as a css custom property is the easiest way to access it any where in the app
          styles={{
            ':root': {
              '--navigation-width': isCollapsed
                ? COLLAPSED_WIDTH
                : EXPANDED_WIDTH,
            },
          }}
        />
        <NavigationHeader user={actualLoggedInUser} />
        <Flex sx={bodyWrapper}>
          <NavigationTabs />
          <NavigationFooter actualLoggedInUser={actualLoggedInUser} />
        </Flex>
        <Collapse />
      </Flex>
    </AsyncI18n>
  );
};

const GlobalNavigationV2: FC<GlobalNavigationV2Props> = ({
  actualLoggedInUser,
}) => {
  return (
    <AsyncI18n>
      <CollapsedProvider>
        <GlobalNavigationV2Content actualLoggedInUser={actualLoggedInUser} />
      </CollapsedProvider>
    </AsyncI18n>
  );
};

export default GlobalNavigationV2;
