export const DE_AT_LUX_EMAIL = 'service@choco.com';
export const FR_BE_CH_EMAIL = 'aide@choco.com';
export const USA_EMAIL = 'supportusa@choco.com';
export const ES_PT_EMAIL = 'soporte@choco.com';
export const UK_EMAIL = 'supportuk@choco.com';
export const DEFAULT_EMAIL = 'contact@choco.com';

export const getSupportEmailByPhoneNumber = (phoneNumber: string): string => {
  switch (true) {
    case /(^\+49)|(^\+43)|(^\+352)/.test(phoneNumber): // DE, AT, LUX
      return DE_AT_LUX_EMAIL;
    case /(^\+32)|(^\+33)|(^\+41)/.test(phoneNumber): // FR, BE, CH
      return FR_BE_CH_EMAIL;
    case /(^\+1)/.test(phoneNumber): // US
      return USA_EMAIL;
    case /(^\+34)/.test(phoneNumber): // ES PT
      return ES_PT_EMAIL;
    case /(^\+44)/.test(phoneNumber): // UK
      return UK_EMAIL;
    default:
      return DEFAULT_EMAIL; // REST OF WORLD ;-)
  }
};
