import { useFlags } from '@chocoapp/launchdarkly-ui';
import { useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { useUnreadMessagesContext } from '../../components/commons/UnreadMessages';
import { NotificationDirectives, NotificationMessageFragment } from '../../generated';
import { spawnNotification } from '../../util/Notification';

import {
  getMessageNotification,
  getOrderNotification,
} from './notificationUtils';

export const useHandleMessageNotifications = (
  document: Document,
  currentChatId: string
) => {
  const { push: historyPush } = useHistory();
  const { t } = useTranslation('notifications', { useSuspense: false });
  const { increase, resetCount } = useUnreadMessagesContext();
  const { webMultiLocation: metaLobbyEnabled } = useFlags();

  useEffect(() => {
    const resetCountOnFocus = () => {
      resetCount(currentChatId);
    };

    window.addEventListener('focus', resetCountOnFocus);

    return () => {
      window.removeEventListener('focus', resetCountOnFocus);
    };
  }, [resetCount, currentChatId]);

  const showNotifications = useCallback(
    (message: NotificationMessageFragment, directives: NotificationDirectives | undefined) => {
      const messageChatId = message?.chat?.id;
      const messageId = message?.id;

      if (!directives?.suppressInboxCountUpdate) {
        increase(messageChatId, messageId);
      }

      if (document.hasFocus()) return;

      if (message.order) {
        spawnNotification(
          getOrderNotification({ message, t, historyPush, metaLobbyEnabled })
        );
        return;
      }

      spawnNotification(
        getMessageNotification({ message, t, historyPush, metaLobbyEnabled })
      );
    },
    [document, historyPush, increase, t, metaLobbyEnabled]
  );

  return { showNotifications };
};
