import {
  JoinRequestStatus,
  RequestToJoinBuyerMutation,
  UserRequestToJoin,
  useRequestToJoinBuyerMutation,
} from '../generated';

const isUserRequestToJoin = (
  requestToJoinBuyer: RequestToJoinBuyerMutation['requestToJoinBuyer']
): requestToJoinBuyer is UserRequestToJoin => {
  return requestToJoinBuyer.__typename === 'UserRequestToJoin';
};

const isAbleToAutoJoinBuyer = (data?: RequestToJoinBuyerMutation | null) => {
  return (
    data &&
    isUserRequestToJoin(data.requestToJoinBuyer) &&
    data.requestToJoinBuyer.status === JoinRequestStatus.AutoApproved
  );
};

export const useRequestToJoinBuyerDataMutation = () => {
  const [
    requestToJoinBuyer,
    { loading, error },
  ] = useRequestToJoinBuyerMutation();

  const handleRequestToJoinBuyer = async (buyerId: string) => {
    try {
      const res = await requestToJoinBuyer({ variables: { buyerId } });
      if (isAbleToAutoJoinBuyer(res.data)) {
        return { success: true };
      }
      return { success: false };
    } catch (err) {
      return { success: false };
    }
  };

  return {
    requestToJoinBuyer: handleRequestToJoinBuyer,
    loading,
    error,
  };
};
