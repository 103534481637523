import { ThemeUIStyleObject, Flex, Avatar, Text } from '@chocoapp/chocolate-ui';
import { isNil } from '@chocoapp/toolbelt-utils';
import React, { FC } from 'react';
import { Link } from 'react-router-dom';

import { LinkedSupplierFragment } from '../../../../generated';
import { getNormalizedAssetURL } from '../../../../whiteLabel/utils';
import HiddenWhenCollapsed from '../HiddenWhenCollapsed';
import MenuTooltip from '../MenuTooltip';
import { SkeletonProfile } from '../SkeletonProfile';
import { wrapperStyles } from '../styles';

const nameStyles: ThemeUIStyleObject = {
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  maxHeight: '40px',

  '@supports (-webkit-line-clamp:2)': {
    whiteSpace: 'initial',
    display: '-webkit-box',
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical',
  },
};

type SupplierProfileProps = {
  supplier: LinkedSupplierFragment | null | undefined;
};

const SupplierProfile: FC<SupplierProfileProps> = ({ supplier }) => {
  if (isNil(supplier)) return <SkeletonProfile />;

  const fallbackProfileURL = getNormalizedAssetURL(
    '/assortments/fallback_assortment_broadliner.webp'
  );
  const src = supplier.botUser?.profileImage?.smallSquare ?? fallbackProfileURL;

  return (
    <MenuTooltip tooltipText={supplier.name}>
      <Link
        data-testid="SupplierProfile"
        to="/settings/company-profile"
        style={{ textDecoration: 'none' }}
      >
        <Flex sx={wrapperStyles}>
          <Avatar
            size="s"
            src={src}
            id={supplier.id}
            name={supplier.name}
            data-testid="SupplierAvatar"
          />
          <HiddenWhenCollapsed>
            <Text sx={nameStyles} variant="Subhead">
              {supplier.name}
            </Text>
          </HiddenWhenCollapsed>
        </Flex>
      </Link>
    </MenuTooltip>
  );
};

export default SupplierProfile;
