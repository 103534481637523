import { ApolloClient, ApolloLink } from '@apollo/client';
import { InMemoryCache } from '@apollo/client/cache';
import { trackSegmentAction } from '@chocoapp/toolbelt-utils';
import { createAuthLink, AUTH_TYPE, AuthOptions } from 'aws-appsync-auth-link';
import { createSubscriptionHandshakeLink } from 'aws-appsync-subscription-link';

import { REACT_APP_AWS_APP_SYNC_REGION } from '../config';
import introspectionSchema from '../generated/introspection-result';

import { createWhiteLabelLink } from './createWhiteLabelLink';

const region = REACT_APP_AWS_APP_SYNC_REGION;

const auth: AuthOptions = {
  type: AUTH_TYPE.AMAZON_COGNITO_USER_POOLS,
  jwtToken: async () => {
    // record callstack before going async - this will capture the caller.
    const error = new Error();
    const idToken = await window.choco.auth.getJwtToken();

    if (!idToken) {
      trackSegmentAction({
        event: 'logoutOnTokenUnavailable',
        data: {
          stack: error.stack ?? 'stack unavailable',
        },
      });

      window.location.assign('/logout');
    }

    return idToken || '';
  },
};

const createNewApolloClientClearAllCacheAndRefetchEverything = (
  url: string
) => {
  const link = ApolloLink.from([
    createWhiteLabelLink(),
    createAuthLink({
      url,
      region,
      auth,
    }),
    createSubscriptionHandshakeLink({ url, region, auth }),
  ]);

  const apolloClient = new ApolloClient({
    link,
    cache: new InMemoryCache({
      possibleTypes: introspectionSchema.possibleTypes,
      typePolicies: {
        User: {
          fields: {
            profileImage: {
              merge(existing, incoming, { mergeObjects }) {
                return mergeObjects(existing, incoming);
              },
            },
          },
        },
        Buyer: {
          fields: {
            profileImage: {
              merge(existing, incoming, { mergeObjects }) {
                return mergeObjects(existing, incoming);
              },
            },
          },
        },
        Supplier: {
          fields: {
            profileImage: {
              merge(existing, incoming, { mergeObjects }) {
                return mergeObjects(existing, incoming);
              },
            },
          },
        },
        Message: {
          fields: {
            attachment: {
              merge(existing, incoming, { mergeObjects }) {
                return mergeObjects(existing, incoming);
              },
            },
          },
        },
        OmnichannelOrder: {
          fields: {
            parsedOrder: {
              merge(existing, incoming) {
                // Cache hack to prevent query from executing 2 times
                // parsedOrder from orderToReviewQuery has no PONumber and should
                // not override the parsedOrder from getOmnichannelQuery.
                // Otherwise it will cause that query to refetch.
                if (existing && !Object.keys(existing).includes('PONumber')) {
                  return incoming;
                }
                // Special case for product re-matching
                if (
                  existing &&
                  Object.keys(existing).includes('products') &&
                  Object.keys(existing).includes('PONumber') &&
                  incoming &&
                  Object.keys(incoming).includes('products') &&
                  Object.keys(incoming).includes('PONumber')
                ) {
                  return incoming;
                }

                if (!existing) {
                  return incoming;
                }

                return existing;
              },
            },
          },
        },
        OmnichannelProduct: {
          keyFields: ['parsedProductId', 'id'],
        },
      },
    }),
    connectToDevTools: true,
  });

  return apolloClient;
};

export { createNewApolloClientClearAllCacheAndRefetchEverything };
