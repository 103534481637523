import React, { FC } from 'react';
import { Route, RouteProps } from 'react-router-dom';

import { useAuthContext } from '../auth';
import { useNotificationRequestPermission } from '../hooks';

import { useHistoryPush } from '.';

const SkipRouteWhenAuthenticated: FC<RouteProps> = (props) => {
  const { isAuthenticated } = useAuthContext();
  const { namedHistoryPush } = useHistoryPush();

  useNotificationRequestPermission(isAuthenticated());

  if (isAuthenticated()) {
    namedHistoryPush({ to: 'main:inbox', replace: true });
  }

  return <Route {...props} />;
};

export { SkipRouteWhenAuthenticated };
