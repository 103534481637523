import { useEffect, useCallback } from 'react';

const useBeforeUnload = (value: (event: BeforeUnloadEvent) => any) => {
  const handleBeforeunload = useCallback(
    (event: BeforeUnloadEvent) => {
      value(event);
    },
    [value]
  );

  useEffect(() => {
    window.addEventListener('beforeunload', handleBeforeunload);
    return () => window.removeEventListener('beforeunload', handleBeforeunload);
  }, [handleBeforeunload]);
};

export default useBeforeUnload;
