import { Box } from '@chocoapp/chocolate-ui';
import React, { FC } from 'react';

import { useMFEContentLoaded } from '../../hooks';

type MFEContainerProps = {
  testId: string;
};

const MFEContainer: FC<MFEContainerProps> = ({ testId }) => {
  const { ref } = useMFEContentLoaded();

  return (
    <Box
      ref={ref}
      id="container"
      data-testid={testId}
      sx={{
        flex: 1,
        position: 'relative',
        overflow: 'hidden',
      }}
    />
  );
};

export default MFEContainer;
