import { Flex } from '@chocoapp/chocolate-ui';
import React, { FC } from 'react';

import { useMFEContentLoaded } from '../../hooks';

const InboxPage: FC = () => {
  const { ref } = useMFEContentLoaded();

  return (
    <Flex
      ref={ref}
      id="container"
      data-testid="inboxPage"
      sx={{
        flex: 1,
        position: 'relative',
        overflow: 'hidden',
      }}
    />
  );
};

export default InboxPage;
