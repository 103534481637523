import {
  Buyer,
  BuyerEdge,
  BuyerFragment,
  SearchBuyerConnection,
  SearchBuyerFilter,
  SearchBuyerQuery,
  useSearchBuyerLazyQuery,
} from '../generated';

const isSearchBuyer = (
  searchBuyer?: SearchBuyerQuery['searchBuyer']
): searchBuyer is SearchBuyerConnection => {
  return searchBuyer?.__typename === 'SearchBuyerConnection';
};

const getBuyerFromEdge = (edge: BuyerEdge): Buyer => {
  return edge.node;
};

const getBuyers = (data?: SearchBuyerQuery): BuyerFragment[] => {
  if (!data || !isSearchBuyer(data?.searchBuyer)) {
    return [];
  }

  return data.searchBuyer.edges.map(getBuyerFromEdge);
};

export const useSearchBuyerDataLazyQuery = (
  onCompleted: (buyers: BuyerFragment[]) => void
) => {
  const [searchBuyer, { loading, error }] = useSearchBuyerLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: data => {
      const buyers = getBuyers(data);
      onCompleted(buyers);
    },
  });

  const handleSearchBuyer = ({
    googlePlaceAddressId,
    googlePlaceEstablishment,
  }: SearchBuyerFilter) =>
    searchBuyer({
      variables: {
        query: {
          filter: {
            googlePlaceAddressId,
            googlePlaceEstablishment,
          },
        },
      },
    });

  return {
    searchBuyer: handleSearchBuyer,
    loading,
    error,
  };
};
