import { useUserQuery } from '../generated';

export default function useUserQueryAsync() {
  const { refetch } = useUserQuery({
    skip: true,
    fetchPolicy: 'network-only',
  });

  const refetchQuery = (userId: string) => {
    try {
      return refetch({ userId });
    } catch (error) {
      // Retry once after fail
      return refetch({ userId });
    }
  };

  return refetchQuery;
}
