import { ThemeUIStyleObject, useModal, Flex } from '@chocoapp/chocolate-ui';
import React, { useCallback } from 'react';

import { isProduction, getEnvConfig } from '../../../auth/environments/utils';
import { useEnvironmentSelectorProvider } from '../../../auth/EnvironmentSelector/EnvironmentSelectorProvider';

import { EnvironmentModal } from './EnvironmentModal';
import { emojiStyles } from './styles';

const selectStyles: ThemeUIStyleObject = {
  cursor: 'pointer',
  justifyContent: 'center',
};

type EnvironmentSelectorProps = {
  wrapperSx?: ThemeUIStyleObject;
};

export const EnvironmentSelector = ({
  wrapperSx,
}: EnvironmentSelectorProps) => {
  const { environment } = useEnvironmentSelectorProvider();

  const { isOpen, toggleModal } = useModal();

  const onCloseModal = useCallback(() => {
    toggleModal();
  }, [toggleModal]);

  const onClickSelect = () => {
    toggleModal();
  };

  if (isProduction) return null;

  return (
    <>
      <Flex
        onClick={onClickSelect}
        data-testid="EnvironmentSelector"
        sx={{ ...selectStyles, ...wrapperSx }}
      >
        <Flex sx={emojiStyles}>{getEnvConfig(environment).emoji}</Flex>
      </Flex>
      <EnvironmentModal isOpen={isOpen} onCloseModal={onCloseModal} />
    </>
  );
};
