import { trackSegmentAction } from '@chocoapp/toolbelt-utils';
import { useEffect } from 'react';

import getQueryString from './getQueryString';

import { getDecodedProductListJwtToken } from '.';

export default function useTappedOrderTransmissionLink(page: string) {
  const transmissionChannel = getQueryString('transmissionChannel');
  const { orderId } = getDecodedProductListJwtToken<{ orderId?: string }>();

  useEffect(() => {
    trackSegmentAction({
      event: 'tappedOrderTransmissionLink',
      data: {
        page,
        orderId,
        ...(transmissionChannel && { landedFrom: transmissionChannel }),
      },
    });
  }, [transmissionChannel, orderId, page]);
}
