import { NotificationMessageFragment } from '../generated';

import { addBuyerFieldToQueryString } from './metaLobby';
import { NativeNotificationType } from './Notification';
import { pipe } from './pipe';

import { ReactRouterHistoryPush } from '.';

type Translation = string;

export const createMessageNotification = (
  message: NotificationMessageFragment,
  translations: { title: Translation },
  historyPush: ReactRouterHistoryPush,
  metaLobbyEnabled?: boolean
): NativeNotificationType => {
  const { title } = translations;
  const {
    body,
    chat: { buyer },
  } = message;
  const chatId = message.chat.id;
  const search = addBuyerFieldToQueryString({
    buyer,
    query: window.location.search,
    metaLobbyEnabled,
  });

  return {
    title,
    body,
    icon: '/logo.png',
    onClick: () => {
      window.focus();
      historyPush({
        pathname: `/inbox/${chatId}`,
        state: { chatId },
        search,
      });
    },
  };
};

type OrderTranslationsType = {
  title: Translation;
  deliveryDate: Translation;
  orderProducts: Translation;
};

type FormatterInput = [
  string,
  {
    order: NotificationMessageFragment['order'];
    user: NotificationMessageFragment['user'];
    translations: OrderTranslationsType;
  }
];

const orderComment = ([final, data]: FormatterInput) => {
  const { order } = data;
  const condition = order?.body && !final;
  return [condition ? order?.body : final, data];
};
const deliveryDate = ([final, data]: FormatterInput) => {
  const { order, translations } = data;
  const condition = order?.deliveryDate && !final;
  return [condition ? translations.deliveryDate : final, data];
};
const orderProducts = ([final, data]: FormatterInput) => {
  const { order, translations } = data;
  const condition = order?.orderProducts && !final;
  return [condition ? translations.orderProducts : final, data];
};

export const createOrderNotification = (
  message: NotificationMessageFragment,
  translations: OrderTranslationsType,
  historyPush: ReactRouterHistoryPush,
  metaLobbyEnabled?: boolean
): NativeNotificationType => {
  const { title } = translations;
  const order = message.order ?? {};
  const user = message.user ?? {};
  const data = { order, user, translations };
  const chatId = message.chat.id;
  const search = addBuyerFieldToQueryString({
    buyer: message.chat.buyer,
    query: window.location.search,
    metaLobbyEnabled,
  });

  const [body] = pipe(orderComment, deliveryDate, orderProducts)(['', data]);

  return {
    title,
    body,
    icon: '/logo.png',
    onClick: () => {
      window.focus();
      historyPush({
        pathname: `/inbox/${chatId}`,
        state: { chatId },
        search,
      });
    },
  };
};
