import {
  ICognitoStorage,
  CognitoUser,
  CognitoUserPool,
} from 'amazon-cognito-identity-js';

import { AppLogger } from '../application/logger';

export type CognitoUserHiddenAttributes = {
  storage?: ICognitoStorage;
  poll?: CognitoUserPool;
  userDataKey?: string;
};

const TOKEN_FALLBACK = '';
/**
 * Workaround function to access cognito idToken synchronously.
 *
 * The official supported way is asynchronous so it is possible to check if the session is still valid.
 * For some use cases we don't really care if the token is expired or not, so it does not need to be async.
 *
 * @param cognitoUser CognitoUser | null
 */
export default function getUserIdFromCognitoSync(
  userPool: CognitoUserPool | null,
  cognitoUser: (CognitoUser & CognitoUserHiddenAttributes) | null
): string {
  try {
    if (!cognitoUser || !userPool) return TOKEN_FALLBACK;

    const idTokenKey = `CognitoIdentityServiceProvider.${userPool!.getClientId()}.${cognitoUser!.getUsername()}.idToken`;

    const jwtToken = cognitoUser!.storage!.getItem(idTokenKey);

    if (!jwtToken?.trim()) return TOKEN_FALLBACK;

    const [, payload] = jwtToken.split('.');

    return JSON.parse(window.atob(payload)).userId;
  } catch (error) {
    AppLogger.error('invalidgetUserIdFromCognitoSyncErrorToken', { error });
    return TOKEN_FALLBACK;
  }
}

export const getCurrentUserId = (userPool: CognitoUserPool) =>
  getUserIdFromCognitoSync(userPool, userPool.getCurrentUser());
