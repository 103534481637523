import { Box, Flex } from '@chocoapp/chocolate-ui';
import React, { ReactNode } from 'react';

import {
  AuthBackButton,
  AuthBackButtonProps,
} from '../components/commons/AuthBackButton';

type AuthLayoutBodyProps = {
  footer?: ReactNode | null;
  children: ReactNode;
  backButton?: AuthBackButtonProps | true;
};

export const AuthLayoutBody = ({
  children,
  footer,
  backButton,
}: AuthLayoutBodyProps) => {
  return (
    <Flex sx={{ flexDirection: 'column', flex: '1' }}>
      {backButton && (
        <AuthBackButton {...(backButton === true ? {} : backButton)} />
      )}

      <Box sx={{ flex: 'auto' }}>{children}</Box>

      {footer && <Flex sx={{ justifyContent: 'flex-end' }}>{footer}</Flex>}
    </Flex>
  );
};
