import { PermissionsProvider as ToolbeltPermissionsProvider } from '@chocoapp/toolbelt-auth';
import React, { FC, useEffect, useState, useCallback } from 'react';

import getIdToken from './getIdToken';

import { useAuthContext } from '.';

const PermissionsProvider: FC = ({ children }) => {
  const [token, setToken] = useState<string>('');
  const { userPool } = useAuthContext();

  const getCognitoToken = useCallback(async () => {
    const idToken = await getIdToken(userPool);
    setToken(idToken as string);
  }, [userPool]);

  useEffect(() => {
    getCognitoToken();
  }, [getCognitoToken]);

  return (
    <ToolbeltPermissionsProvider token={token}>
      {children}
    </ToolbeltPermissionsProvider>
  );
};

export default PermissionsProvider;
