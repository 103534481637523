import { PhoneNumberUtil } from 'google-libphonenumber';

import { CountryCode } from '../../components/commons/Login/CountryCode';

import {
  formatPartialPhoneNumber,
  getCountryCodeFromDialingCode,
  getCountryDialingCodeFromPhoneNumber,
  getDialingCodeFromCountryCode,
  hasCountryCodeInPhoneNumber,
} from '.';

type PhoneNumberInfo = {
  countryCode: CountryCode;
  phoneNumber: string; // formatted according to country of origin
  isValid: boolean;
};

const phoneUtil = PhoneNumberUtil.getInstance();

const validatePhoneNumber = (phoneNumber: string, countryCode: CountryCode) => {
  // if phoneNumber is empty, consider it valid: user didnt type anything yet
  if (!phoneNumber) return true;

  try {
    const number = phoneUtil.parse(phoneNumber, countryCode);
    return phoneUtil.isValidNumberForRegion(number, countryCode);
  } catch (error) {
    return false;
  }
};

export const parseIncompletePhoneNumber = (
  countryCode: CountryCode,
  partialNumber: string
): PhoneNumberInfo => {
  const normalizedNumber = partialNumber.trim();
  const selectedDialingCode = getDialingCodeFromCountryCode(countryCode);

  try {
    const actualDialingCode = getCountryDialingCodeFromPhoneNumber(
      partialNumber,
      countryCode
    );
    const guessedCountryCodeFromTypedNumber = getCountryCodeFromDialingCode(
      actualDialingCode
    );
    const hasTypedDialingCode = hasCountryCodeInPhoneNumber(partialNumber);

    /**
     * We only try to correct the country dropdown selection if the user manually
     * typed the country as part of the phonenumber and its a completely different
     * dialing code than the one selected in the dropdown. This prevent issues
     * when trying to input a Canadian number would reset the dropdown to US.
     * (Different countries can have the same dialing code, like +1, which is
     * used for US, Puerto Rico and Canada).
     */
    const normalizedCountryCode =
      hasTypedDialingCode && actualDialingCode !== selectedDialingCode
        ? guessedCountryCodeFromTypedNumber
        : countryCode;

    return {
      countryCode: normalizedCountryCode,
      phoneNumber: formatPartialPhoneNumber(countryCode, normalizedNumber),
      isValid: validatePhoneNumber(normalizedNumber, normalizedCountryCode),
    };
  } catch (e) {
    // if phoneNumber is empty, consider it valid: user didnt type anything yet
    const isValid = validatePhoneNumber(normalizedNumber, countryCode);

    return {
      countryCode,
      phoneNumber: formatPartialPhoneNumber(countryCode, normalizedNumber),
      isValid,
    };
  }
};
