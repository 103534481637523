import { GoogleMapsPosition } from './GoogleMapsPosition';

export const initMap = async (
  initialPosition: GoogleMapsPosition,
  ref: HTMLDivElement
): Promise<{
  map: google.maps.Map<HTMLElement>;
  marker: google.maps.Marker;
}> => {
  const position = { lat: initialPosition.lat, lng: initialPosition.lng };

  const { Map } = (await google.maps.importLibrary(
    'maps'
  )) as google.maps.MapsLibrary;

  const { Marker } = (await google.maps.importLibrary(
    'marker'
  )) as google.maps.MarkerLibrary;

  const map = new Map(ref, {
    zoom: 15,
    center: position,
    mapId: 'DEMO_MAP_ID',
    disableDefaultUI: true,
  });

  const marker = new Marker({
    map,
    position,
    title: initialPosition.title,
  });

  return { map, marker };
};
