import {
  Button,
  ChocoRadioGroup,
  ChocoInputFormField,
  Text,
  theme,
} from '@chocoapp/chocolate-ui';
import { trackSegmentPage } from '@chocoapp/toolbelt-utils';
import { yupResolver } from '@hookform/resolvers/yup';
import { TFunction } from 'i18next';
import { useCallback, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import * as yup from 'yup';

import { useAuthContext } from '../auth';
import { AuthLayoutBody } from '../auth/AuthLayoutBody';
import { isUserLinkedToBuyerOrSupplier } from '../auth/helpers/isUserLinkedToBuyerOrSupplier';
import useUserDataQuery from '../data/useUserDataQuery';
import { useHistoryPush } from '../router';
import {
  WHITE_LABEL_I18N_NAMESPACE,
  getWhiteLabelI18nNamespace,
} from '../whiteLabel/getWhiteLabelI18nNamespace';

import { CreateProfileFormData } from './useCreateProfileSubmit';
import { useHandleProfileSubmit } from './useHandleProfileSubmit';

const getValidationSchema = (t: TFunction, showWorkplacePicker: boolean) =>
  yup.object().shape({
    fullName: yup.string().required(t('createProfile.fullName.error')),
    email: yup
      .string()
      .required(t('createProfile.email.error'))
      .email(t('createProfile.email.notValid')),
    isSupplier: showWorkplacePicker
      ? yup.boolean().required(t('createProfile.workplace.error'))
      : yup.boolean().nullable(),
  });

export const CreateProfilePage = () => {
  const { t } = useTranslation(['auth', WHITE_LABEL_I18N_NAMESPACE]);

  const { namedHistoryPush } = useHistoryPush();

  const { getLoggedInUserIdFromCognito } = useAuthContext();
  const userId = getLoggedInUserIdFromCognito();

  const { user, loading: loadingUser } = useUserDataQuery(userId);

  const isPendingUser = Boolean(user.pending);
  const hasBuyerOrSupplierConnection = isUserLinkedToBuyerOrSupplier(user);

  const { handleSubmitProfile, loading } = useHandleProfileSubmit(
    isPendingUser,
    hasBuyerOrSupplierConnection,
    user.hasAcceptedTermsAndConditions
  );

  const showWorkplacePicker = !hasBuyerOrSupplierConnection;

  const { register, errors, handleSubmit, formState, control } =
    useForm<CreateProfileFormData>({
      resolver: yupResolver(getValidationSchema(t, showWorkplacePicker)),
      mode: 'all',
    });

  const logOut = useCallback(() => {
    namedHistoryPush({ to: 'auth:logout' });
  }, [namedHistoryPush]);

  useEffect(() => {
    if (user) {
      trackSegmentPage({
        name: 'create_profile',
        category: 'create_profile',
        props: {
          is_workspace_question_shown: showWorkplacePicker,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return (
    <AuthLayoutBody
      backButton={{ onClick: logOut }}
      footer={
        <Button
          disabled={!formState.isValid}
          size="large"
          form="createProfileForm"
          type="submit"
          isLoading={loading}
        >
          {t('continue')}
        </Button>
      }
    >
      <Text variant="H4" sx={{ mb: 'm' }}>
        {t('createProfile.title')}
      </Text>
      <Text sx={{ mb: 'm' }}>
        {t('createProfile.subtitle', {
          ns: getWhiteLabelI18nNamespace('auth'),
        })}
      </Text>
      <form id="createProfileForm" onSubmit={handleSubmit(handleSubmitProfile)}>
        <ChocoInputFormField
          ref={register}
          errorMessage={errors.fullName?.message}
          id="fullName"
          name="fullName"
          labelText={t('createProfile.fullName.label')}
          isRequired
          autoFocus
          sx={{ mb: 'xl' }}
        />
        <ChocoInputFormField
          ref={register}
          errorMessage={errors.email?.message}
          id="email"
          name="email"
          labelText={t('createProfile.email.label')}
          isRequired
          sx={{ mb: 'xl' }}
        />
        {showWorkplacePicker && !loadingUser && (
          <Controller
            control={control}
            name="isSupplier"
            data-testid="WorkplaceSelector"
            render={({ onChange, value, name, onBlur }) => (
              <ChocoRadioGroup
                errorMessage={errors.isSupplier?.message}
                isRequired={true}
                labelText={t('createProfile.workplace.label')}
                value={value}
                name={name}
                sx={{
                  '& div > label': {
                    backgroundColor: theme.colors.neutralBg,
                  },
                }}
                options={[
                  {
                    key: 'false',
                    value: 'false',
                    label: (
                      <Trans
                        t={t}
                        i18nKey="createProfile.workplace.buyer"
                        components={{
                          bold: <Text as="span" variant="BodyStrong" />,
                        }}
                      />
                    ),
                    onClick: () =>
                      onChange(false, {
                        shouldDirty: true,
                        shouldValidate: true,
                      }),
                    onBlur,
                  },
                  {
                    key: 'true',
                    value: 'true',
                    label: (
                      <Trans
                        t={t}
                        i18nKey="createProfile.workplace.supplier"
                        components={{
                          bold: <Text as="span" variant="BodyStrong" />,
                        }}
                      />
                    ),
                    onClick: () =>
                      onChange(true, {
                        shouldDirty: true,
                        shouldValidate: true,
                      }),
                    onBlur,
                  },
                ]}
              />
            )}
          />
        )}
      </form>
    </AuthLayoutBody>
  );
};
