import { useFlags } from '@chocoapp/launchdarkly-ui';
import {
  getLocalStorageItem,
  setLocalStorageItem,
} from '@chocoapp/toolbelt-utils';
import { trackSegmentAction } from '@chocoapp/toolbelt-utils/lib/segment';
import { CognitoUser, CognitoUserPool } from 'amazon-cognito-identity-js';
import React, {
  FC,
  useState,
  useEffect,
  useCallback,
  createContext,
  useContext,
} from 'react';

import {
  LAST_SIGNED_OUT_ID_TOKEN,
  LOGOUT_LS_EVENT_TRIGGER,
  UNAUTHORIZED_SUPPLIER_KEY,
} from '../constants/AppConstants';
import { exportChocoAuthGlobals } from '../portal/utils/window';
import { useHistoryPush } from '../router';

import { createConfirmSignUpHandler } from './createConfirmSignUpHandler';
import createGetCurrentIdTokenHandler from './createGetCurrentIdTokenHandler';
import { createInitiateCustomAuthHandler } from './createInitiateCustomAuthHandler';
import { createSubmitOneTimePasswordHandler } from './createSubmitOneTimePasswordHandler';
import { useEnvironmentSelectorProvider } from './EnvironmentSelector/EnvironmentSelectorProvider';
import getBotUserId from './getBotUserId';
import getUserIdFromCognitoSync from './getUserIdFromCognitoSync';
import { createLocalStorageBroadcastChannel } from './helpers/createLocalStorageBroadcastChannel';
import useRefreshTokenPolling from './useRefreshTokenPolling';

import { AuthContextType, getUserPool, InitiateAuthType } from '.';

export const logoutLSListener = createLocalStorageBroadcastChannel(
  LOGOUT_LS_EVENT_TRIGGER
);
logoutLSListener.receive(() => {
  window.location.reload();
});

export const Authenticator: FC = ({ children }) => {
  const { environment } = useEnvironmentSelectorProvider();
  const [showGlobalNavigation, setShowGlobalNavigation] = useState(true);
  const { historyPush } = useHistoryPush();
  const [userPool, setUserPool] = useState<CognitoUserPool>(
    getUserPool(environment)
  );
  const flags = useFlags();
  const [cognitoUser, setCognitoUser] = useState<CognitoUser | null>(
    userPool.getCurrentUser()
  );

  useEffect(() => {
    setUserPool(getUserPool(environment));
  }, [environment]);

  const getRefreshToken = useCallback(
    () => cognitoUser?.getSignInUserSession()?.getRefreshToken()?.getToken(),
    [cognitoUser]
  );

  const getLoggedInUserPayloadFromCognito = useCallback(
    () => cognitoUser?.getSignInUserSession()?.getIdToken()?.payload ?? {},
    [cognitoUser]
  );

  const [currentPhoneNumber, setCurrentPhoneNumber] = useState<
    string | undefined
  >(getLoggedInUserPayloadFromCognito().phone_number);

  // phone number needs to be updated when cognitoUser is updated
  useEffect(() => {
    const phone = getLoggedInUserPayloadFromCognito().phone_number;
    // don't overwrite phone number on authentication failure
    if (phone) {
      setCurrentPhoneNumber(phone);
    }
  }, [cognitoUser, setCurrentPhoneNumber, getLoggedInUserPayloadFromCognito]);

  const initiateAuth: InitiateAuthType = useCallback(
    async (phoneNumber: string, userPoolToUse: CognitoUserPool) => {
      const authResponse = await createInitiateCustomAuthHandler(
        phoneNumber,
        userPoolToUse
      );

      setCognitoUser(authResponse.user);
      setCurrentPhoneNumber(authResponse.user.getUsername());

      return authResponse;
    },
    []
  );

  const submitOneTimePassword = useCallback(
    (_oneTimePassword: string, _userPool: CognitoUserPool) => {
      const handler = createSubmitOneTimePasswordHandler({
        cognitoUser,
        currentPhoneNumber,
        initiateAuth,
      });
      return handler(_oneTimePassword, _userPool);
    },
    [cognitoUser, currentPhoneNumber, initiateAuth]
  );

  const confirmSignUp = useCallback(
    (_oneTimePassword: string, _userId: string) => {
      const handler = createConfirmSignUpHandler({
        cognitoUser,
        currentPhoneNumber,
      });
      return handler(_oneTimePassword, _userId);
    },
    [cognitoUser, currentPhoneNumber]
  );

  const isAuthenticated = useCallback(() => {
    const newCognitoUser = userPool.getCurrentUser();
    const session = cognitoUser?.getSignInUserSession();

    return (newCognitoUser !== null || session?.isValid()) ?? false;
  }, [cognitoUser, userPool]);

  const getCurrentUserId = useCallback(() => {
    return (
      getBotUserId(cognitoUser) ??
      getUserIdFromCognitoSync(userPool, cognitoUser)
    );
  }, [cognitoUser, userPool]);

  const getJwtToken = useCallback(async () => {
    // record callstack before going async - this will capture the caller.
    const { stack } = new Error();
    try {
      const getCurrentIdToken = createGetCurrentIdTokenHandler({ cognitoUser });
      const idToken = await getCurrentIdToken();

      return idToken.getJwtToken()!;
    } catch (error) {
      trackSegmentAction({
        event: 'errorOnTokenFetch',
        data: {
          stack: stack ?? 'stack unavailable',
        },
      });

      // fallback token needs to be empty string
      return '';
    }
  }, [cognitoUser]);

  // only use this method when you don't want to consume the bot user but
  // the actual logged in user
  const getLoggedInUserIdFromCognito = useCallback(
    () => getUserIdFromCognitoSync(userPool, cognitoUser),
    [cognitoUser, userPool]
  );

  const signOut = useCallback(
    (cb: () => Promise<void>) => {
      if (cognitoUser !== null) {
        const session = cognitoUser.getSignInUserSession();
        const idToken = session?.getIdToken().getJwtToken() ?? '';
        localStorage.setItem(LAST_SIGNED_OUT_ID_TOKEN, idToken);
        cognitoUser.signOut(async () => {
          if (cb) {
            // We provide this callback to keep resetting App data
            // separate from auth handling.
            await cb();
            logoutLSListener.post({
              event: 'logout',
              data: Date.now(),
            });
          }
          setCognitoUser(null);
        });
      }
    },
    [cognitoUser]
  );

  const isUnauthorizedSupplier = useCallback(
    () =>
      flags.paywallV0 &&
      getLocalStorageItem(UNAUTHORIZED_SUPPLIER_KEY) === 'true',
    [flags]
  );

  const saveIsUnauthorizedSupplier = useCallback(
    (isUnauthorized: boolean) =>
      setLocalStorageItem(UNAUTHORIZED_SUPPLIER_KEY, `${isUnauthorized}`),
    []
  );

  useRefreshTokenPolling({ cognitoUser, historyPush });

  const context: AuthContextType = {
    getRefreshToken,
    currentPhoneNumber,
    showGlobalNavigation,
    setShowGlobalNavigation,
    setCognitoUser,
    setUserPool,
    userPool,
    cognitoUser,
    setCurrentPhoneNumber,
    isAuthenticated,
    getCurrentUserId,
    getJwtToken,
    getLoggedInUserIdFromCognito,
    getLoggedInUserPayloadFromCognito,
    signOut,
    initiateAuth,
    submitOneTimePassword,
    confirmSignUp,
    isUnauthorizedSupplier,
    saveIsUnauthorizedSupplier,
  };

  exportChocoAuthGlobals({
    ...context,
    getCurrentUserId,
  });

  return (
    <AuthContext.Provider value={context}>{children}</AuthContext.Provider>
  );
};

export const AuthContext = createContext<AuthContextType | null>(null);

export const useAuthContext = (): AuthContextType => {
  const context = useContext(AuthContext);

  if (context === null) {
    throw new Error('useAuthContext must be used within a Provider');
  }

  return context;
};
