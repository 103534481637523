import { REACT_APP_IMPORT_MAP_STAGE } from '../config';
import { bootstrapWhiteLabel } from '../whiteLabel';

import { ImportMapStageType } from './types';
import bootstrap from './utils/bootstrap';
import './utils/window';

bootstrapWhiteLabel().then(() => {
  bootstrap(REACT_APP_IMPORT_MAP_STAGE as ImportMapStageType);
});
