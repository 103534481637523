import React from 'react';
import { useTranslation } from 'react-i18next';

import { AuthLayoutBody } from '../../auth/AuthLayoutBody';
import { AsyncI18n } from '../../components';
import { RequestPremium } from '../../components/commons/Paywall/RequestPremium';
import { useGoPremiumForm } from '../../components/commons/Paywall/useGoPremiumForm';
import { useTrackPaywall } from '../../components/commons/Paywall/useTrackPremiumScreen';
import { addPhoneNumberQuery } from '../../components/commons/Paywall/utils';
import { useRedirectToRequestReceived } from '../../hooks/useRedirectToRequestReceived';

const RequestPremiumScreen = (): JSX.Element => {
  const { t } = useTranslation('getInTouch');
  const { phoneNumber } = useGoPremiumForm();

  useTrackPaywall({
    name: 'Paywall Contact Information',
  });

  useRedirectToRequestReceived();

  return (
    <AsyncI18n>
      <AuthLayoutBody
        backButton={{
          href: `/get-in-touch${addPhoneNumberQuery(phoneNumber)}`,
          label: t('requestPremiumScreen.back'),
        }}
      >
        <RequestPremium />
      </AuthLayoutBody>
    </AsyncI18n>
  );
};

export default RequestPremiumScreen;
