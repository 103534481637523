import { Box } from '@chocoapp/chocolate-ui';
import { FeatureFlags } from '@chocoapp/launchdarkly-ui';
import React from 'react';
import { createPortal } from 'react-dom';

const FEATURE_FLAGS_UI_CONTAINER = document.getElementById('feature-flags-ui');
FEATURE_FLAGS_UI_CONTAINER?.style.setProperty('z-index', '4000');

/**
 * Renders the feature flags UI in a portal.
 * Why do we render this in a portal?
 * Because some elements like the modal disable interaction
 * with anything in the app root element when they are in focus.
 * We have to render this outside the app root element to ensure
 * that it can be accessed even when any UI component that makes
 * all others in the app element inactive is in focus.
 */
const FeatureFlagsUI = () => {
  return (
    FEATURE_FLAGS_UI_CONTAINER &&
    createPortal(
      <Box
        sx={{
          // we make the button border white because its the same color as the footer
          '&> div > div:first-child button': {
            border: '1px solid white',
          },
        }}
      >
        <FeatureFlags />
      </Box>,
      FEATURE_FLAGS_UI_CONTAINER
    )
  );
};

export default FeatureFlagsUI;
