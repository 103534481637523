import { FontCustomization } from '@chocoapp/chocolate-ui';
import { isNil } from '@chocoapp/toolbelt-utils';

import {
  SubdomainCustomization,
  SubdomainMapping,
  subdomainMappings,
} from './customMappings';

type CustomizationResult = [
  string | null,
  string | null,
  SubdomainCustomization['theme'] | null,
];

const getCustomizationsForSubdomain = (
  url: string,
  subdomainMapping: SubdomainMapping
): CustomizationResult => {
  if (!url || url.length === 0 || !subdomainMapping) {
    return [null, null, null];
  }
  const customizationElements = Object.entries(subdomainMapping);

  return customizationElements.reduce<CustomizationResult>(
    (acc, [subdomain, customization]) => {
      if (url.includes(subdomain)) {
        const { name, theme } = customization;
        return [subdomain, name, theme];
      }
      return acc;
    },
    [null, null, null]
  );
};

export const getWhiteLabelInfo = () => {
  const url = window.location.hostname;
  return getCustomizationsForSubdomain(url, subdomainMappings);
};

export const bootstrapWhiteLabel = async () => {
  const url = window.location.hostname;
  const [subdomain, name, theme] = getCustomizationsForSubdomain(
    url,
    subdomainMappings
  );

  const colors = theme?.customColors ?? {};
  const fonts = theme?.customFonts;

  if (!isNil(subdomain)) {
    const fontsCustomization: FontCustomization = {
      primary: [],
      secondary: [],
    };

    const originalTitle = document.title;
    document.title = name ?? originalTitle;

    if (!isNil(fonts) && !isNil(fonts?.primary)) {
      const primaryFontLink = document.createElement('link');
      primaryFontLink.rel = 'stylesheet';
      primaryFontLink.href = fonts.primary.fontURL ?? '';
      document.head.appendChild(primaryFontLink);
      if (!isNil(fonts.primary.fontFamily)) {
        fontsCustomization.primary?.push(...fonts.primary.fontFamily);
      }
    }

    if (!isNil(fonts) && !isNil(fonts?.secondary)) {
      const secondaryFontLink = document.createElement('link');
      secondaryFontLink.rel = 'stylesheet';
      secondaryFontLink.href = fonts.secondary?.fontURL ?? '';
      document.head.appendChild(secondaryFontLink);
      if (!isNil(fonts.secondary.fontFamily)) {
        fontsCustomization.secondary?.push(...fonts.secondary.fontFamily);
      }
    }

    window.choco = {
      ...(window.choco ?? {}),
      theme: {
        customColors: colors,
        customFontFamily: fontsCustomization,
      },
      assets: {
        prefix: subdomain,
      },
    };
  } else {
    window.choco = {
      ...(window.choco ?? {}),
      theme: {
        customColors: {},
        customFontFamily: {
          primary: [],
          secondary: ['Inter'],
        },
      },
      assets: {
        prefix: null,
      },
    };
  }
};
