import {
  trackSegmentAction,
  setExtraFields,
} from '@chocoapp/toolbelt-utils/lib/segment';
import React, { FC, useEffect } from 'react';

import { getDecodedProductListJwtToken } from '../hooks';
import getQueryString from '../hooks/getQueryString';
import useTappedOrderTransmissionLink from '../hooks/useTappedOrderTransmissionLink';

import { LoadingConfirmationOrderProvider } from './OrderEmailConfirmation/LoadingConfirmationOrder/LoadingConfirmationOrderProvider';
import ValidTokenPage from './ValidTokenPage';

const ProductsPage: FC = () => {
  const { chatId } = getDecodedProductListJwtToken<{ chatId?: string }>();
  const referrer = getQueryString('referrer');
  useTappedOrderTransmissionLink('editProducts');

  useEffect(() => {
    setExtraFields({ referrer });

    trackSegmentAction({
      event: 'editProductIDs-click',
      data: { chatId },
    });
  }, [referrer, chatId]);

  return (
    <LoadingConfirmationOrderProvider>
      <ValidTokenPage />
    </LoadingConfirmationOrderProvider>
  );
};

export default ProductsPage;
