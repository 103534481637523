import { Roles } from '@chocoapp/toolbelt-auth';
import { CognitoUser } from 'amazon-cognito-identity-js';

export default function getBotUserId(
  cognitoUser: CognitoUser | null
): string | null {
  const { 'cognito:groups': groups, botUserId } =
    cognitoUser?.getSignInUserSession()?.getIdToken().payload ?? {};

  if (groups?.includes(Roles.SUPPLIER_ADMINS) && botUserId) {
    return botUserId;
  }

  return null;
}
