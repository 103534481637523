import { Box, Text, ThemeUIStyleObject } from '@chocoapp/chocolate-ui';
import { TFunction } from 'i18next';
import React, { FC } from 'react';
import { LinkProps } from 'react-router-dom';

import { StyledLink } from './ProfileBarStyles';

type OptionLinkProps = {
  sx: ThemeUIStyleObject;
  translationKey: string;
  t: TFunction;
} & LinkProps;

const OptionLink: FC<OptionLinkProps> = ({
  sx,
  translationKey,
  t,
  ...props
}) => (
  <StyledLink {...props}>
    <Box sx={sx}>
      <Text>{t(translationKey)}</Text>
    </Box>
  </StyledLink>
);

export default OptionLink;
