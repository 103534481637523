import { CognitoUserPool } from 'amazon-cognito-identity-js';

import { EnvironmentKey } from './environments/types';
import { getEnvConfig } from './environments/utils';

export const getUserPool = (environment: EnvironmentKey) => {
  const envConfig = getEnvConfig(environment);

  return new CognitoUserPool(envConfig.userPoolV1);
};
