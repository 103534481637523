import { Box, Button, ChocoAsset, Text } from '@chocoapp/chocolate-ui';
import { trackSegmentAction, trackSegmentPage } from '@chocoapp/toolbelt-utils';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';

import { useAuthContext } from '../auth';
import { AuthLayoutBody } from '../auth/AuthLayoutBody';
import { isUserLinkedToBuyerOrSupplier } from '../auth/helpers/isUserLinkedToBuyerOrSupplier';
import { extractAddressDetails } from '../components/commons/GooglePlacesFormField/extractAddressDetails';
import { WorkplaceFormData } from '../components/commons/WorkplaceForm/workplaceFormSchema';
import { useCreateOrJoinBusinessContext } from '../data/providers/CreateOrJoinBusinessContextProvider';
import useUserDataQuery from '../data/useUserDataQuery';
import { useHistoryPush } from '../router';
import {
  WHITE_LABEL_I18N_NAMESPACE,
  getWhiteLabelI18nNamespace,
} from '../whiteLabel/getWhiteLabelI18nNamespace';
import { getAssetsPrefix } from '../whiteLabel/utils';

const getIntercomCustomAttributes = ({
  placeDetails,
  formValues,
}: {
  placeDetails?: google.maps.places.PlaceResult;
  formValues?: WorkplaceFormData;
}) => {
  if (placeDetails && formValues) {
    const addressDetails = extractAddressDetails(placeDetails);

    return {
      name: formValues?.name ?? '',
      city: addressDetails.city,
      country: addressDetails.country,
      fullAddress: addressDetails.fullAddress,
      latitude: addressDetails.latitude,
      longitude: addressDetails.longitude,
      utcOffset: addressDetails.utcOffset,
    };
  }
  return {};
};

export const AlmostTherePage = () => {
  const { t } = useTranslation(['auth', WHITE_LABEL_I18N_NAMESPACE]);
  const { getLoggedInUserIdFromCognito } = useAuthContext();
  const loggedInUserId = getLoggedInUserIdFromCognito();
  const { user } = useUserDataQuery(loggedInUserId);
  const { namedHistoryPush } = useHistoryPush();
  const hasBuyerOrSupplierConnection = isUserLinkedToBuyerOrSupplier(user);

  const { formValues, selectedGooglePlaceDetails: placeDetails } =
    useCreateOrJoinBusinessContext();

  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    if (!hasBuyerOrSupplierConnection) return;

    if (user.hasAcceptedTermsAndConditions) {
      namedHistoryPush({ to: 'main' });
    } else {
      namedHistoryPush({ to: 'auth:terms-and-conditions' });
    }
  }, [
    hasBuyerOrSupplierConnection,
    namedHistoryPush,
    user.hasAcceptedTermsAndConditions,
  ]);

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const showIntercom = query.get('showIntercom') === 'true';
    if (showIntercom) {
      const intercomCustomAttributes = getIntercomCustomAttributes({
        placeDetails,
        formValues,
      });
      window.Intercom('update', {
        alignment: 'left',
        ...intercomCustomAttributes,
      });
      window.Intercom('showMessages');
    }
    return () => {
      window.Intercom('hide');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const type = location.pathname.includes('create-business')
    ? 'create'
    : 'join';

  const handleOpenSupport = () => {
    if (!hasBuyerOrSupplierConnection) {
      trackSegmentAction({
        event: 'get_support_clicked',
        data: {
          source: 'create_profile',
          type,
        },
      });
      const buyerName = formValues?.name;
      const intercomCustomAttributes = getIntercomCustomAttributes({
        placeDetails,
        formValues,
      });

      const params = new URLSearchParams({ showIntercom: 'true' });
      history.replace({
        pathname: location.pathname,
        search: params.toString(),
      });

      window.Intercom('update', {
        ...intercomCustomAttributes,
        alignment: 'left',
      });
      window.Intercom('show');
      window.Intercom(
        'showNewMessage',
        t('almostThere.supportDefaultMessage', {
          buyerName,
          ns: getWhiteLabelI18nNamespace('auth'),
        })
      );
    }
  };

  useEffect(() => {
    trackSegmentPage({
      name: 'almost_there',
      category: 'almost_there',
      props: { source: 'create_profile', type },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AuthLayoutBody
      backButton
      footer={
        <Button
          data-testid="GetSupportButton"
          onClick={handleOpenSupport}
          size="large"
          type="submit"
        >
          {t('almostThere.getSupport')}
        </Button>
      }
    >
      <Box>
        <ChocoAsset
          onClick={handleOpenSupport}
          prefix={getAssetsPrefix()}
          path="/illustrations/questionHelp.svg"
          alt="questionHelp"
          sx={{ cursor: 'pointer' }}
        />
        <Text sx={{ mb: 'm' }} variant="H4">
          {t('almostThere.title')}
        </Text>
        <Text sx={{ mb: 'xl' }}>
          {t('almostThere.subTitle', {
            ns: getWhiteLabelI18nNamespace('auth'),
          })}
        </Text>
        <Text>{t('almostThere.description')}</Text>
      </Box>
    </AuthLayoutBody>
  );
};
