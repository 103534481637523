import React from 'react';
import { useTranslation } from 'react-i18next';

import { AuthLayoutBody } from '../../auth/AuthLayoutBody';
import { AsyncI18n } from '../../components';
import { GoPremium } from '../../components/commons/Paywall/GoPremium';
import { useRedirectToRequestReceived } from '../../hooks/useRedirectToRequestReceived';

const GoPremiumScreen = (): JSX.Element => {
  const { t } = useTranslation('getInTouch');

  useRedirectToRequestReceived();

  return (
    <AsyncI18n>
      <AuthLayoutBody
        backButton={{
          href: '/logout',
          label: t('goPremiumScreen.backToLogin'),
        }}
      >
        <GoPremium />
      </AuthLayoutBody>
    </AsyncI18n>
  );
};

export default GoPremiumScreen;
