import {
  ChocoAsset,
  Flex,
  Text,
  ThemeUIStyleObject,
} from '@chocoapp/chocolate-ui';
import React, { FC } from 'react';

import { getAssetsPrefix } from '../../../whiteLabel/utils';

export const UNREAD_MESSAGE_DOT_LIMIT = 100;

const bubbleStyles: ThemeUIStyleObject = {
  position: 'absolute',
  top: '-6px',
  left: '12px',
  alignItems: 'center',
  justifyContent: 'center',
  p: '2px',
  height: '18px',
  minWidth: '18px',
  border: '2px solid',
  borderColor: 'baseBorderStrong',
  borderRadius: '60px',
  backgroundColor: 'baseBg',
  color: 'baseTextWeak',
  flexShrink: 0,
  zIndex: 'tooltip',
};

const BubbleUnreadMessages: FC<{ count: number; sx?: ThemeUIStyleObject }> = ({
  count,
  sx,
}) => {
  if (!count) return null;
  return (
    <Flex
      data-testid={`chocoWebUnreadMessagesCount_${count}`}
      sx={{ ...bubbleStyles, ...sx }}
    >
      {count >= UNREAD_MESSAGE_DOT_LIMIT ? (
        <ChocoAsset
          data-testid="UnreadDot"
          prefix={getAssetsPrefix()}
          path="/illustrations/unreadDot.svg"
        />
      ) : (
        <Text variant="Caption" data-testid={`UnreadCount_${count}`}>
          {count}
        </Text>
      )}
    </Flex>
  );
};

export default BubbleUnreadMessages;
