import { useToasts } from '@chocoapp/chocolate-ui';
import { trackSegmentAction } from '@chocoapp/toolbelt-utils/lib/segment';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { useCreateProfile } from '../data/useCreateProfile';

// Todo(jp): move to better place = form definition!
export type CreateProfileFormData = {
  fullName: string;
  email: string;
  isSupplier?: boolean;
};

export const useCreateProfileSubmit = () => {
  const { createProfile, loading } = useCreateProfile();
  const { t } = useTranslation('auth');
  const { triggerErrorToast } = useToasts();
  const { state } = useLocation();

  const handleSubmitProfileCreate = useCallback(
    async (formData: CreateProfileFormData) => {
      trackSegmentAction({ event: 'createProfileV2-submit' });

      try {
        const { success } = await createProfile({
          variables: {
            user: {
              id: state?.userId ?? '',
              name: formData.fullName,
              email: formData.email,
              ...(formData.isSupplier !== undefined
                ? { supplier: formData.isSupplier }
                : {}),
            },
          },
        });

        if (!success) {
          triggerErrorToast({
            content: t('createProfile.failedToCreateProfile'),
          });
        }
      } catch (createProfileError) {
        triggerErrorToast({ content: t('phoneNumber.error') });
      }
    },
    [createProfile, state, t, triggerErrorToast]
  );

  return { handleSubmitProfileCreate, loading };
};
