import {
  Box,
  ThemeProvider,
  ChocoToastProvider,
  ThemeUIStyleObject,
  createCustomTheme,
} from '@chocoapp/chocolate-ui';
import { getSupportLoginPhone, isNil } from '@chocoapp/toolbelt-utils';
import { withLDProvider } from 'launchdarkly-react-client-sdk';
import React, { FC, Suspense, ReactNode, useEffect } from 'react';
import { I18nextProvider } from 'react-i18next';
import { Router } from 'react-router-dom';

// it needs to be imported before the others
// because in these files window.choco is being initialized
import './portal';

import { Authenticator, useAuthContext } from './auth';
import { EnvironmentSelectorProvider } from './auth/EnvironmentSelector';
import {
  useIsSupportLogin,
  SupportLoginProvider,
} from './auth/SupportLoginProvider/SupportLoginProvider';
import { isUnauthorizedAccessPackage } from './auth/useFetchUserInfo';
import { UnreadMessagesProvider } from './components';
import Intercom from './components/commons/Intercom/Intercom';
import FeatureFlagsUI from './components/FeatureFlagsUI';
import {
  REACT_APP_LAUNCH_DARKLY_CLIENT_SIDE_ID,
  REACT_APP_ENV_PREFIX,
} from './config';
import { APP_BROWSER_HISTORY } from './constants/AppConstants';
import { useUserDataQuery } from './data';
import { ApolloProvider } from './data/ApolloProvider';
import { useLoggedInUser } from './hooks/useLoggedInUser';
import { useFeatureFlagUserRegister } from './pages/MainPage';
import { Routes } from './router';
import routes from './routes';
import i18n from './setup/i18n';
import shouldEnableLaunchDarkly from './util/setupLDProvider';

const supportLoginStyles: ThemeUIStyleObject = {
  borderWidth: '3px',
  borderStyle: 'solid',
  borderColor: 'brandSecondary5',
  height: '100%',
  width: '100%',
  position: 'fixed',
  pointerEvents: 'none',
  zIndex: 'supportLogin',
};

export const AppContent: FC = () => {
  const { isSupportLogin, setIsSupportLogin } = useIsSupportLogin();
  const { getLoggedInUserIdFromCognito, saveIsUnauthorizedSupplier } =
    useAuthContext();
  const loggedInUserId = getLoggedInUserIdFromCognito();
  const { user } = useUserDataQuery(loggedInUserId);
  const { loggedInUser } = useLoggedInUser();

  useEffect(() => {
    setIsSupportLogin(Boolean(getSupportLoginPhone()));
  }, [setIsSupportLogin]);

  useEffect(() => {
    const isUnauthorized = isUnauthorizedAccessPackage(
      user?.linkedSupplier?.accessPackage
    );
    saveIsUnauthorizedSupplier(isUnauthorized);
  }, [user, saveIsUnauthorizedSupplier]);

  useFeatureFlagUserRegister(loggedInUser);

  return (
    <>
      {isSupportLogin && (
        <Box data-testid="SupportLoginWrapper" sx={supportLoginStyles} />
      )}
      <Routes routes={routes} />
    </>
  );
};

type AppProvidersProps = {
  children: ReactNode;
};

const AppProviders: FC<AppProvidersProps> = ({ children }) => {
  const customTheme = !isNil(window.choco.theme)
    ? createCustomTheme(window.choco.theme)
    : undefined;

  return (
    <I18nextProvider i18n={i18n}>
      <Router history={APP_BROWSER_HISTORY}>
        <ThemeProvider customTheme={customTheme}>
          <SupportLoginProvider>
            <EnvironmentSelectorProvider>
              <Authenticator>
                <UnreadMessagesProvider>
                  <ApolloProvider>
                    <ChocoToastProvider>
                      {children}
                      <Intercom />
                      <FeatureFlagsUI />
                    </ChocoToastProvider>
                  </ApolloProvider>
                </UnreadMessagesProvider>
              </Authenticator>
            </EnvironmentSelectorProvider>
          </SupportLoginProvider>
        </ThemeProvider>
      </Router>
    </I18nextProvider>
  );
};

const App: FC = () => (
  <Suspense fallback="">
    <AppProviders>
      <AppContent />
    </AppProviders>
  </Suspense>
);

export default withLDProvider({
  clientSideID: shouldEnableLaunchDarkly(REACT_APP_ENV_PREFIX)
    ? REACT_APP_LAUNCH_DARKLY_CLIENT_SIDE_ID
    : '',
  options: { disableSyncEventPost: true },
})(App);
