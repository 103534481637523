import { decodeJwt } from '../util';

import getQueryString from './getQueryString';

export default function getDecodedProductListJwtToken<T extends object>(): T {
  const token = getQueryString('token');

  if (!token) {
    return {} as T;
  }

  return decodeJwt(token) as T;
}
