import { trackSegmentAction, trackSegmentPage } from '@chocoapp/toolbelt-utils';

import { TwoFactorMethod } from './createInitiateCustomAuthHandler';

const authMethodEventMapping: Record<TwoFactorMethod, string> = {
  phone: 'oneTimePassword',
  email: 'oneTimePasswordEmail',
};

export const getTwoFactorEventName = (authMethod: TwoFactorMethod) => {
  return authMethod in authMethodEventMapping
    ? authMethodEventMapping[authMethod]
    : 'oneTimePassword';
};

/**
 * LOGIN PAGE
 */
export const trackViewLoginPage = () => {
  trackSegmentPage({ category: 'phoneNumberInput', name: 'phoneNumberInput' });
};

export const trackOpenedCountryCodeDropdown = () => {
  trackSegmentAction({ event: 'countryCodes-openModal' });
};

export const trackSelectedCountryCode = () => {
  trackSegmentAction({ event: 'countryCodes-selectCode' });
};

/**
 * ONE TIME PASSWORD PAGE
 */
export const trackViewOneTimePasswordPage = (authMethod: TwoFactorMethod) => {
  const eventName = getTwoFactorEventName(authMethod);

  trackSegmentPage({ category: eventName, name: eventName });
};

export const trackSubmitOneTimeTimePassword = (authMethod: TwoFactorMethod) => {
  const prefix = getTwoFactorEventName(authMethod);
  trackSegmentAction({ event: `${prefix}-submit` });
};

export const trackLoginSuccess = (authMethod: TwoFactorMethod) => {
  trackSegmentAction({
    event: 'auth-success',
    data: { from: getTwoFactorEventName(authMethod) },
  });
};

/* when the user types a wrong OneTimePassword code */
export const trackLoginFailure = (authMethod: TwoFactorMethod) => {
  trackSegmentAction({
    event: 'auth-fail',
    data: { from: getTwoFactorEventName(authMethod) },
  });
};
