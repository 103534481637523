import {
  Flex,
  ChocoIcon,
  ThemeUIStyleObject,
  Ellipsis,
  Text,
} from '@chocoapp/chocolate-ui';
import { useFlags } from '@chocoapp/launchdarkly-ui';
import { trackSegmentAction } from '@chocoapp/toolbelt-utils';
import { createSlider } from '@typeform/embed';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useAuthContext } from '../../../../auth';
import { getWhiteLabelInfo } from '../../../../whiteLabel';
import { isWhiteLabel } from '../../../../whiteLabel/utils';
import { AsyncI18n } from '../../AsyncI18n';
import BubbleUnreadMessages from '../../UnreadMessages/BubbleUnreadMessages';
import HiddenWhenCollapsed from '../HiddenWhenCollapsed';
import MenuTooltip from '../MenuTooltip';

const wrapperStyles: ThemeUIStyleObject = {
  alignItems: 'center',
  cursor: 'pointer',
  color: 'baseTextWeak',
  p: '12px 24px',
  gap: '12px',

  ':hover': {
    color: 'baseTextWeakActive',
  },
};

const legacyBubbleStyles: ThemeUIStyleObject = {
  background: 'white',
  border: '1px solid',
  borderColor: 'neutralBorderWeak',
  color: 'neutralTextWeak',
};

const legacyIconWrapper: ThemeUIStyleObject = {
  flexDirection: 'column',
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  height: '100%',
};

const legacyIcon: ThemeUIStyleObject = { color: 'neutralTextWeak' };

const iconContainer: ThemeUIStyleObject = {
  position: 'relative',
};

const legacyIconText: ThemeUIStyleObject = {
  color: 'neutralTextWeak',
  marginTop: '4px',
};

type Variant = 'legacy' | 'new';
type GetHelpProps = {
  variant?: Variant;
};

const Tooltip = ({
  children,
  variant,
}: {
  children: React.ReactNode;
  variant: Variant;
}) => {
  const { t } = useTranslation('globalNavigation');

  return variant === 'legacy' ? (
    <>{children}</>
  ) : (
    <MenuTooltip tooltipText={t('navigation.support')}>{children}</MenuTooltip>
  );
};

const GetHelp = ({ variant = 'new' }: GetHelpProps) => {
  const { webSupportChat } = useFlags();
  const [unreadMessages, setUnreadMessages] = useState(0);
  const [hovered, setHovered] = useState(false);
  const { t } = useTranslation('globalNavigation');

  const { userPool, getCurrentUserId, currentPhoneNumber } = useAuthContext();
  const [, name] = getWhiteLabelInfo();

  const triggerSegmentTracking = () => {
    trackSegmentAction({ event: 'Get Help Clicked' });

    if (!process.env.REACT_APP_TYPEFORM_ID || !isWhiteLabel()) return;
    const slider = createSlider(process.env.REACT_APP_TYPEFORM_ID, {
      hidden: {
        user_id: getCurrentUserId(userPool),
        phone_number: currentPhoneNumber ?? '',
        supplier_name: name ?? '',
        app_info: window.location.origin,
      },
    });
    slider.open();
  };

  // getHelp is the id of the intercom launcher
  const intercomID = isWhiteLabel() ? 'itercomDisabled' : 'getHelp';

  useEffect(() => {
    if (!webSupportChat || isWhiteLabel()) return;

    window.Intercom('onUnreadCountChange', (count: number) => {
      setUnreadMessages(count);
    });
  }, [webSupportChat]);

  if (!webSupportChat) return null;

  const Icon =
    variant === 'legacy' ? (
      <Flex sx={legacyIconWrapper}>
        <Flex sx={iconContainer}>
          <ChocoIcon
            name={hovered ? 'helpChatFilled' : 'helpChat'}
            size="s"
            sx={legacyIcon}
          />
          <BubbleUnreadMessages
            sx={legacyBubbleStyles}
            count={unreadMessages}
          />
        </Flex>
        <Text variant={'Caption'} sx={legacyIconText}>
          <Ellipsis variant="Footnote">{t('navigation.support')}</Ellipsis>
        </Text>
      </Flex>
    ) : (
      <>
        <Flex sx={iconContainer}>
          <ChocoIcon name="helpChat" size="s" />
          <BubbleUnreadMessages count={unreadMessages} />
        </Flex>
        <HiddenWhenCollapsed>
          <Ellipsis variant="Footnote">{t('navigation.support')}</Ellipsis>
        </HiddenWhenCollapsed>
      </>
    );

  return (
    <AsyncI18n>
      <Tooltip variant={variant}>
        <Flex
          data-testid="getHelp"
          id={intercomID}
          className={intercomID}
          sx={wrapperStyles}
          onClick={triggerSegmentTracking}
          onMouseEnter={() => setHovered(true)}
          onMouseLeave={() => setHovered(false)}
        >
          {Icon}
        </Flex>
      </Tooltip>
    </AsyncI18n>
  );
};

export default GetHelp;
