export const normalizeURL = (url: string | null): string => {
  if (!url && typeof url !== 'string') {
    return '';
  }

  return url.trim().toLowerCase();
};

export const isLocalhost = (hostname: string) =>
  Boolean(
    hostname === 'localhost' ||
      // 192.168.0.* is exposed by default by CRA and it's useful when testing from another device in the same LAN
      hostname.includes('192.168.0') ||
      // [::1] is the IPv6 localhost address.
      hostname === '[::1]' ||
      // 127.0.0.1/8 is considered localhost for IPv4.
      hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/)
  );

export const urlToRedirectToLite = () => {
  if (isLocalhost(window.location.hostname)) {
    const { search } = window.location;
    const liteLocalhostURL = `https://localhost:3002/order/confirm${search}`;
    return liteLocalhostURL;
  }

  return window.location.href.replace('web', 'supplier');
};
