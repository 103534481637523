import { start, addErrorHandler, AppError } from 'single-spa';

import { AppLogger } from '../../application/logger';
import { injectSomethingWentWrongPage } from '../../pages/SomethingWentWrongPage';

import { exportChocoAppsyncGlobals } from './window';

export const errorHandler = async (error: AppError) => {
  injectSomethingWentWrongPage();
  AppLogger.error('SingleSpaError', { error });
  SystemJS.delete(SystemJS.resolve(error.appOrParcelName));
};

addErrorHandler(errorHandler);

export async function startSingleSpaApplication(appSyncClient: any) {
  exportChocoAppsyncGlobals(appSyncClient);
  start();
}
