import {
  BuyerCreateInput,
  BuyerCreateMutation,
  BuyerFragment,
  useBuyerCreateMutation,
} from '../generated';

const isBuyer = (
  buyerCreate?: BuyerCreateMutation['buyerCreate']
): buyerCreate is BuyerFragment => {
  return buyerCreate?.__typename === 'Buyer';
};

const getBuyer = (data?: BuyerCreateMutation | null) => {
  if (data && isBuyer(data.buyerCreate)) {
    return data.buyerCreate;
  }
  return undefined;
};

export const useCreateBuyerCreateDataMutation = () => {
  const [createBuyer, { data, error, loading }] = useBuyerCreateMutation();

  const buyer: BuyerFragment | undefined = getBuyer(data);

  const handleCreateBuyer = async (input: BuyerCreateInput) => {
    await createBuyer({
      variables: {
        buyer: input,
      },
    });
  };

  return { createBuyer: handleCreateBuyer, loading, error, buyer };
};
