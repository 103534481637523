import {
  Box,
  Button,
  ChocoModal,
  ChocoModalContainer,
  ChocoModalContentArea,
  Text,
  ThemeUIStyleObject,
} from '@chocoapp/chocolate-ui';
import { trackSegmentAction, trackSegmentPage } from '@chocoapp/toolbelt-utils';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { GoogleMapsPosition } from './GoogleMapsPosition';
import { initMap } from './initGoogleMaps';

type GoogleMapModalProps = {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  locations: GoogleMapsPosition[];
  rejectAllLocations: () => void;
  confirmLocation: (id: string) => void;
  loading: boolean;
};

const ChocoModalContentStyles: ThemeUIStyleObject = {
  display: 'grid',
  gap: 'xxl',
};

const MapContainerStyles: ThemeUIStyleObject = { width: 840, height: 286 };

const PlaceInfoStyles: ThemeUIStyleObject = {
  alignSelf: 'center',
  textAlign: 'center',
};

const ButtonContainerStyles: ThemeUIStyleObject = { alignSelf: 'end' };

const TextStyles: ThemeUIStyleObject = { mb: 'xl' };

const GoogleMapModalContent = ({
  locations,
  confirmLocation,
  rejectAllLocations,
  loading,
}: Pick<
  GoogleMapModalProps,
  'locations' | 'confirmLocation' | 'rejectAllLocations' | 'loading'
>) => {
  const { t } = useTranslation('auth');
  const mapRef = useRef<HTMLDivElement>(null);
  const [map, setMap] = useState<google.maps.Map<HTMLElement>>();
  const [marker, setMarker] = useState<google.maps.Marker>();
  const { pathname } = useLocation();
  const [disabled, setDisabled] = useState(false);

  const [displayedLocationIndex, setDisplayedLocationIndex] = useState(0);

  const displayedLocation = locations[displayedLocationIndex];

  const initializeMap = useCallback(async () => {
    if (mapRef.current) {
      const googleMap = await initMap(locations[0], mapRef.current);
      setMap(googleMap.map);
      setMarker(googleMap.marker);
    }
  }, [locations]);

  useEffect(() => {
    initializeMap();
  }, [initializeMap]);

  const handleConfirmLocation = () => {
    trackSegmentAction({
      event: 'is_this_your_business_yes',
    });
    setDisabled(true);
    confirmLocation(displayedLocation.id);
  };

  const handleRejectLocation = useCallback(() => {
    trackSegmentAction({
      event: 'is_this_your_business_no',
    });
    if (displayedLocationIndex >= locations.length - 1) {
      setDisabled(true);
      rejectAllLocations();
      return;
    }

    const newIndex = displayedLocationIndex + 1;

    setDisplayedLocationIndex(newIndex);

    if (marker && map) {
      const { lat, lng } = locations[newIndex];
      map.setCenter({ lat, lng });
      marker.setPosition({ lat, lng });
    }
  }, [map, marker, locations, rejectAllLocations, displayedLocationIndex]);

  useEffect(() => {
    const type = pathname.includes('create-business') ? 'create' : 'join';
    trackSegmentPage({
      name: 'is_this_your_business',
      category: 'is_this_your_business',
      props: {
        source: 'create_profile',
        type,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ChocoModalContentArea sx={ChocoModalContentStyles}>
      <Box
        data-testid={marker ? 'MapLocations' : 'MapLoading'}
        ref={mapRef}
        sx={MapContainerStyles}
      />
      <Box sx={PlaceInfoStyles}>
        <Text>{displayedLocation.title}</Text>
        <Text>{displayedLocation.fullAddress}</Text>
      </Box>
      <Box sx={ButtonContainerStyles}>
        <Button
          data-testid="RejectLocation"
          variant="tertiary"
          onClick={handleRejectLocation}
          disabled={disabled}
        >
          {t('createOrJoin.maps.rejectLocation')}
        </Button>
        <Button
          data-testid="AcceptLocation"
          onClick={handleConfirmLocation}
          disabled={disabled}
          isLoading={loading}
        >
          {t('createOrJoin.maps.acceptLocation')}
        </Button>
      </Box>
    </ChocoModalContentArea>
  );
};

export const GoogleMapModal = ({
  isOpen,
  setIsOpen,
  ...props
}: GoogleMapModalProps) => {
  const { t } = useTranslation('auth');
  return (
    <ChocoModal isOpen={isOpen} size="small" onClose={() => setIsOpen(false)}>
      <ChocoModalContainer>
        <Text variant="H4" sx={TextStyles}>
          {t('createOrJoin.maps.title')}
        </Text>
        <Text sx={TextStyles}>{t('createOrJoin.maps.description')}</Text>
        <GoogleMapModalContent {...props} />
      </ChocoModalContainer>
    </ChocoModal>
  );
};
