import React, { FC, useState } from 'react';

import LoadingOrderConfirmation from './LoadingConfirmationOrder';

type LoadingConfirmationOrderContextType = {
  isLoading: boolean;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
} | null;

const LoadingConfirmationOrderContext = React.createContext<
  LoadingConfirmationOrderContextType
>(null);

const LoadingConfirmationOrderProvider: FC = ({ children }) => {
  const [isLoading, setIsLoading] = useState(true);

  return (
    <LoadingConfirmationOrderContext.Provider
      value={{ isLoading, setIsLoading }}
    >
      <LoadingOrderConfirmation />
      {children}
    </LoadingConfirmationOrderContext.Provider>
  );
};

const useLoadingConfirmationOrderContext = () => {
  const context = React.useContext(LoadingConfirmationOrderContext);
  if (context === null) {
    throw new Error(
      'useLoadingConfirmationOrderContext must be used within a LoadingConfirmationOrderProvider'
    );
  }
  return context;
};

export { LoadingConfirmationOrderProvider, useLoadingConfirmationOrderContext };
