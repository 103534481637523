import { Box, Button, ChocoInputFormField, Text } from '@chocoapp/chocolate-ui';
import { trackSegmentAction, trackSegmentPage } from '@chocoapp/toolbelt-utils';
import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { AuthLayoutBody } from '../../../auth/AuthLayoutBody';
import { useCreateOrJoinBusinessContext } from '../../../data/providers/CreateOrJoinBusinessContextProvider';
import { useSearchBuyerDataLazyQuery } from '../../../data/useSearchBuyerLazyQuery';
import { BuyerFragment } from '../../../generated';
import { BusinessAddressField } from '../GooglePlacesFormField/GooglePlacesFormField';

import { WorkplaceFormData, getValidationSchema } from './workplaceFormSchema';

type WorkplaceFormProps = {
  onSearchBuyerSuccess: (buyers: BuyerFragment[]) => void;
  loading?: boolean;
};

export const WorkplaceForm = ({
  onSearchBuyerSuccess,
  loading = false,
}: WorkplaceFormProps) => {
  const { t } = useTranslation('auth');
  const { pathname } = useLocation();

  const formMethods = useForm<WorkplaceFormData>({
    resolver: yupResolver(getValidationSchema(t)),
    mode: 'onChange',
  });

  const { setFormValues, selectedGooglePlaceDetails } =
    useCreateOrJoinBusinessContext();

  const { searchBuyer, loading: loadingSearchBuyer } =
    useSearchBuyerDataLazyQuery((buyers: BuyerFragment[]) => {
      setFormValues(formMethods.getValues());
      onSearchBuyerSuccess(buyers);
    });

  const searchBuyerFilter = useMemo(() => {
    if (!selectedGooglePlaceDetails) return undefined;
    return {
      googlePlaceAddressId: selectedGooglePlaceDetails.place_id!,
      googlePlaceEstablishment: {
        latitude: selectedGooglePlaceDetails.geometry?.location.lat() ?? 0,
        longitude: selectedGooglePlaceDetails.geometry?.location.lng() ?? 0,
        name: selectedGooglePlaceDetails.name,
      },
    };
  }, [selectedGooglePlaceDetails]);

  const { register, errors, handleSubmit, formState } = formMethods;

  const type = pathname.includes('create-business') ? 'create' : 'join';

  const handleSubmitProfile = () => {
    if (searchBuyerFilter) {
      trackSegmentAction({
        event: 'workplace_submitted',
        data: { source: 'create_profile', type },
      });
      searchBuyer(searchBuyerFilter);
    }
  };

  useEffect(() => {
    trackSegmentPage({
      name: 'workplace',
      category: 'workplace',
      props: { source: 'create_profile', type },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AuthLayoutBody
      backButton
      footer={
        <Button
          disabled={!formState.isValid}
          size="large"
          form="workplaceForm"
          type="submit"
          isLoading={loading || loadingSearchBuyer}
          data-testid="SubmitWorkplace"
        >
          {t('continue')}
        </Button>
      }
    >
      <Box
        sx={{
          display: 'grid',
          gridTemplateRows: 'max-content 1fr',
          gap: 'm',
          height: '100%',
        }}
      >
        <Text variant="H4">{t('createOrJoin.title')}</Text>
        <FormProvider {...formMethods}>
          <form id="workplaceForm" onSubmit={handleSubmit(handleSubmitProfile)}>
            <ChocoInputFormField
              ref={register}
              errorMessage={errors.name?.message}
              id="name"
              name="name"
              labelText={t('createOrJoin.workplace.businessName.label')}
              placeholder={t('createOrJoin.workplace.businessName.placeholder')}
              isRequired
              autoFocus
              sx={{ mb: 'xl' }}
              data-testid="BusinessNameField"
            />
            <BusinessAddressField />
          </form>
        </FormProvider>
      </Box>
    </AuthLayoutBody>
  );
};
