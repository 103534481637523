/* eslint-disable no-console */

export const LD_PROVIDER_LOCAL_STORAGE_KEY = 'ChocoEnableLDProvider';
const allowedEnvironments: string[] = ['chostg', 'choprd'];
const envIsEllegible = (environment: string) =>
  allowedEnvironments.includes(environment);
export const consoleWarningStyles = `
  background: white;
  border: 3px solid red;
  color: red;
  font-size: 30px;
  margin: 40px;
  padding: 20px;
`;
export const consoleWarningMessage = `%c 👋 Don't forget to turn off the feature flag provider! window.choco.launchDarkly.disableLaunchDarkly()`;

const getLocalStorageLaunchDarklyAvailability = (environment: string) => {
  const enabled = Boolean(localStorage.getItem(LD_PROVIDER_LOCAL_STORAGE_KEY));

  if (enabled && !envIsEllegible(environment)) {
    console.log(consoleWarningMessage, consoleWarningStyles);
  }

  return enabled;
};

export function enableLaunchDarkly() {
  localStorage.setItem(LD_PROVIDER_LOCAL_STORAGE_KEY, 'true');
  window.location.reload();
}

export function disableLaunchDarkly() {
  localStorage.removeItem(LD_PROVIDER_LOCAL_STORAGE_KEY);
  window.location.reload();
}

export default function shouldEnableLaunchDarkly(environment: string) {
  return (
    envIsEllegible(environment) ||
    getLocalStorageLaunchDarklyAvailability(environment)
  );
}

window.choco.launchDarkly = {
  enableLaunchDarkly,
  disableLaunchDarkly,
};
