// !! NOTE don't move or rename file without also changing the path in craco.config.js and jest.config.js
import {
  REACT_APP_AWS_APP_SYNC_URL,
  REACT_APP_AWS_COGNITO_USER_POOL_ID,
  REACT_APP_AWS_COGNITO_CLIENT_ID,
  REACT_APP_AWS_COGNITO_USER_POOL_ID_V2,
  REACT_APP_AWS_COGNITO_CLIENT_ID_V2,
  REACT_APP_COGNITO_PROXY_URL,
} from '../../config';

import { ProdEnvironmentType } from './types';

// eslint-disable-next-line import/prefer-default-export
export const config: ProdEnvironmentType = {
  choprd: {
    emoji: '🚔',
    ApiURL: REACT_APP_AWS_APP_SYNC_URL,
    userPoolV1: {
      UserPoolId: REACT_APP_AWS_COGNITO_USER_POOL_ID,
      ClientId: REACT_APP_AWS_COGNITO_CLIENT_ID,
    },
    userPoolV2: {
      UserPoolId: REACT_APP_AWS_COGNITO_USER_POOL_ID_V2,
      ClientId: REACT_APP_AWS_COGNITO_CLIENT_ID_V2,
      endpoint: REACT_APP_COGNITO_PROXY_URL,
    },
  },
};
