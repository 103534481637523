import * as ApolloHooks from '@apollo/client';
import * as DataDogRum from '@datadog/browser-rum';
import * as Styled from '@emotion/styled';
import * as SentryReact from '@sentry/react';
import * as SentryTracing from '@sentry/tracing';
import * as GoogleLibPhoneNumber from 'google-libphonenumber';
import * as Graphql from 'graphql';
import * as history from 'history';
import * as i18n from 'i18next';
import * as i18nXhrBackend from 'i18next-xhr-backend';
// eslint-disable-next-line no-restricted-imports
import * as LaunchDarkly from 'launchdarkly-react-client-sdk';
import * as LottieWeb from 'lottie-web';
import * as Luxon from 'luxon';
import * as Polished from 'polished';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import * as ReactI18next from 'react-i18next';

import { ObjectOrArray } from '../../util';

import { ImportMapType } from './prefetchMFEs';

window.SystemJS = window.System;

const key: string = 'commonDependencies';
const dependencies: ObjectOrArray<any> = {
  react: React,
  'react-dom': ReactDOM,
  '@emotion/styled': Styled,
  'launchdarkly-react-client-sdk': LaunchDarkly,
  'react-i18next': ReactI18next,
  i18next: i18n,
  'i18next-xhr-backend': i18nXhrBackend,
  '@apollo/react-hooks': ApolloHooks,
  graphql: Graphql,
  luxon: Luxon,
  '@sentry/react': SentryReact,
  '@sentry/tracing': SentryTracing,
  'google-libphonenumber': GoogleLibPhoneNumber,
  history,
  'lottie-web': LottieWeb,
  polished: Polished,
  '@datadog/browser-rum': DataDogRum,
};

const commonDependenciesImportMap = {
  dependencies: {},
  get imports() {
    return this.dependencies;
  },
  set imports(reference: ImportMapType['imports']) {
    this.dependencies = {
      ...this.dependencies,
      ...reference,
    };
  },
};

/**
 * registerDependency
 * @description used to add a new dynamic dependency to SystemJS' internal registry
 * @param {string} name
 * @param {any} importedPackage
 */
function registerDependency(name: string, importedPackage: any) {
  const internalName = `${key}:${name}`;

  window.SystemJS.set(internalName, importedPackage);
  commonDependenciesImportMap.imports = { [name]: internalName };
}

/**
 * bootstrapCommonDependencies
 * @description used to bootstrap all the common dependencies, it's used for the MFEs
 */
export default function bootstrapCommonDependencies() {
  Object.entries(dependencies).forEach(([name, importedPackage]) =>
    registerDependency(name, importedPackage)
  );

  return commonDependenciesImportMap.imports;
}
