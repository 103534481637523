interface BroadcastMessage<T> {
  event: string;
  data: T;
}

type BroadcastReceiveHandler<T> = (message: BroadcastMessage<T>) => void;

type Noop = () => void;

const localStorageIsAbsent = (): boolean => typeof localStorage === 'undefined';

type LocalStorageBroadcastChannel = {
  receive<T>(onReceive: BroadcastReceiveHandler<T>): (() => void) | undefined;
  post<T>(message: BroadcastMessage<T>): void;
};

export function createLocalStorageBroadcastChannel(
  name: string
): LocalStorageBroadcastChannel {
  return {
    /**
     * Receive notification from other tabs oor windows
     * @param onReceive the function to call when you receive information from other tabs
     * @returns {Function|undefined} a function to remove the attached storage event handler.
     * This could be called in a function like use effect to remove the handler when the component
     * un mounts to prevent memory leaks
     */
    receive<T>(onReceive: BroadcastReceiveHandler<T>): undefined | Noop {
      if (typeof window === 'undefined') return undefined;

      const handleStorageChange = (event: StorageEvent): void => {
        if (event.key !== name) return undefined;

        const message: BroadcastMessage<T> = JSON.parse(event.newValue!);

        if (!message.data) return undefined;

        onReceive(message);
        return undefined;
      };

      window.addEventListener('storage', handleStorageChange);

      return () => {
        window.removeEventListener('storage', handleStorageChange);
      };
    },

    /**
     * Notify other tabs or windows
     * @param message The message to broadcast to other tabs or windows
     */
    post<T>(message: BroadcastMessage<T>): void {
      if (localStorageIsAbsent()) return;

      localStorage.setItem(name, JSON.stringify(message));
    },
  };
}
