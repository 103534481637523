import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import namedPathResolver, { NamedPathProps } from './namedPathResolver';

type NamedHistoryPushProps = NamedPathProps & { replace?: boolean };
export type NamedHistoryPushType = (
  namedPathProps: NamedHistoryPushProps
) => void;
export default function useHistoryPush() {
  const history = useHistory();

  const historyPush = useCallback(
    ({
      to,
      state,
      replace,
    }: {
      to: string;
      state?: { [key: string]: any };
      replace?: boolean;
    }) => {
      if (replace) return history.replace(to, state || {});
      return history.push(to, state || {});
    },
    [history]
  );

  const namedHistoryPush: NamedHistoryPushType = useCallback(
    (props: NamedHistoryPushProps) => {
      if (props.replace)
        return history.replace(namedPathResolver(props), props.state);

      return history.push(namedPathResolver(props), props.state);
    },
    [history]
  );

  return {
    history,
    historyPush,
    namedHistoryPush,
  };
}
