import * as yup from 'yup';

import { parseIncompletePhoneNumber } from '../util/phoneNumbers';

export const loginFormValidationSchema = yup.object().shape({
  phoneNumber: yup.string().test('phoneNumber', (phoneNumber, context) => {
    const { countryCode } = context.parent;

    const result = parseIncompletePhoneNumber(countryCode, phoneNumber ?? '');

    return result.isValid;
  }),
});
