import {
  Text,
  ThemeUIStyleObject,
  Box,
  StyleIconName,
  ChocoStyleIcon,
} from '@chocoapp/chocolate-ui';
import { SpacingTypes } from '@chocoapp/chocolate-ui/lib/theme/types';
import React from 'react';

const premiumFeatureStyles: ThemeUIStyleObject = {
  display: 'grid',
  gridTemplateColumns: 'max-content 1fr',
  rowGap: 'xxs',
  columnGap: 'm',
  justifyContent: 'start',
  width: '325px',
  ml: 'auto',
  mr: 'auto',
};

const iconStyles: ThemeUIStyleObject = {
  gridRow: '1 / 3',
  alignSelf: 'center',
};

export type PremiumFeatureProps = {
  icon: StyleIconName;
  title: string;
  description: string;
  mt?: SpacingTypes;
};

const PremiumFeature = ({
  icon,
  title,
  description,
  mt = 'm',
}: PremiumFeatureProps): JSX.Element => (
  <Box sx={{ ...premiumFeatureStyles, mt }}>
    <ChocoStyleIcon name={icon} sx={iconStyles} />
    <Text sx={{ textAlign: 'start' }} variant="TitleSmall">
      {title}
    </Text>
    <Text sx={{ textAlign: 'start' }} variant="Footnote">
      {description}
    </Text>
  </Box>
);

export default PremiumFeature;
