import { useOrderConfirmMutation } from '../generated';

export const useOrderConfirmMutationForLoggedInUser = (
  transmissionChannel: string
) => {
  const [orderConfirmMutation, { error }] = useOrderConfirmMutation({
    onError: () => {},
    context: {
      headers: {
        source: transmissionChannel,
        'transmission-channel': transmissionChannel,
      },
    },
  });

  const hasErrors = error && !error.message.includes('Unauthorized');

  return {
    orderConfirmMutation,
    hasErrors,
  };
};
