import { ChocoUnitDropdownFormField, Flex, Text } from '@chocoapp/chocolate-ui';
import { ChocoDropdownItem } from '@chocoapp/chocolate-ui/lib/components/commons/ChocoForm/ChocoDropdown';
import { useFlags } from '@chocoapp/launchdarkly-ui';
import { createSlider } from '@typeform/embed';
import React, { useCallback, useMemo, useRef } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import '@typeform/embed/build/css/slider.css';

import { getHelpIntercomLauncherId } from '../components/commons/Intercom/Intercom';
import COUNTRIES from '../components/commons/Login/countries';
import { CountryCode } from '../components/commons/Login/CountryCode';
import { generatePlaceholderNumber } from '../components/commons/Login/utils';
import { useUserCountry } from '../hooks';
import { parseIncompletePhoneNumber } from '../util/phoneNumbers';
import { getWhiteLabelInfo } from '../whiteLabel';
import { isWhiteLabel } from '../whiteLabel/utils';

import { LoginFormData } from './LoginForm';
import {
  trackOpenedCountryCodeDropdown,
  trackSelectedCountryCode,
} from './trackingEvents';

type UpdatePhoneNumberFields = {
  validate: boolean;
  format?: boolean;
  skipCountry?: boolean;
};

const countriesOptions: ChocoDropdownItem<string>[] = Object.values(
  COUNTRIES
).map(({ name, dialingCode, code }) => ({
  key: code,
  content: `${name} (${dialingCode})`,
}));

export const LoginFormFields = () => {
  const { t } = useTranslation('auth');
  const { getHelpWebSignIn } = useFlags();
  const isCountryDropdownOpen = useRef(false); // safe cross-browser way to keep track of menu open/close

  const isWhiteLabelEnabled = isWhiteLabel();
  const [, name] = getWhiteLabelInfo();

  const {
    setValue,
    errors: formErrors,
    register,
    watch,
    clearErrors,
  } = useFormContext<LoginFormData>();

  const phoneNumber = watch('phoneNumber');
  const currentCountryCode = watch('countryCode');

  const setCountryCode = useCallback(
    (newCountryCode: CountryCode) => {
      setValue('countryCode', newCountryCode);
    },
    [setValue]
  );

  const { ignoreGeoIpRequestData } = useUserCountry(setCountryCode);

  const updatePhoneNumberFields = useCallback(
    (
      countryCode: CountryCode,
      rawPhoneNumber: string,
      options: UpdatePhoneNumberFields = {
        validate: false,
        format: false,
        skipCountry: false,
      }
    ) => {
      const parsedPhoneNumber = parseIncompletePhoneNumber(
        countryCode,
        rawPhoneNumber
      );

      const newPhoneNumber = options.format
        ? parsedPhoneNumber.phoneNumber
        : rawPhoneNumber;

      if (!options.skipCountry) {
        setValue('countryCode', parsedPhoneNumber.countryCode, {
          shouldValidate: false,
        });
      }

      setValue('phoneNumber', newPhoneNumber, { shouldValidate: false });

      if (!newPhoneNumber || parsedPhoneNumber.isValid) {
        clearErrors('phoneNumber');
      }
    },
    [setValue, clearErrors]
  );

  const handleTypeformLinkClick = () => {
    if (!process.env.REACT_APP_TYPEFORM_ID) return;
    const slider = createSlider(process.env.REACT_APP_TYPEFORM_ID, {
      hidden: {
        supplier_name: name ?? '',
        app_info: window.location.origin,
      },
    });
    slider.open();
  };

  const onClickCountryCodeDropdown = useCallback(() => {
    if (!isCountryDropdownOpen.current) {
      isCountryDropdownOpen.current = true;

      trackOpenedCountryCodeDropdown();
    } else {
      isCountryDropdownOpen.current = false;
    }
  }, []);

  const onDropdownBlur = useCallback(() => {
    isCountryDropdownOpen.current = false;
  }, []);

  const onSelectCountryCode = useCallback(
    (newCountryCode) => {
      // since user changed the dropdown manually, from now on ignore the geoIp request
      // this prevents race conditions when connection is slow
      ignoreGeoIpRequestData();

      setValue('countryCode', newCountryCode);
      updatePhoneNumberFields(newCountryCode, phoneNumber, {
        format: true,
        validate: true,
        skipCountry: true,
      });

      trackSelectedCountryCode();
    },
    [ignoreGeoIpRequestData, phoneNumber, setValue, updatePhoneNumberFields]
  );

  const onInputChangeHandler = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const inputValue = e.currentTarget.value;
      // phone format-as-you-type only works if the user is editing the end of the string
      const shouldFormat = e.currentTarget.selectionStart === inputValue.length;

      updatePhoneNumberFields(currentCountryCode, inputValue, {
        validate: false,
        format: shouldFormat,
      });
    },
    [updatePhoneNumberFields, currentCountryCode]
  );

  const onInputBlurHandler = useCallback(
    (e: React.FocusEvent<HTMLInputElement>) => {
      const inputValue = e.currentTarget.value;
      updatePhoneNumberFields(currentCountryCode, inputValue, {
        validate: true,
        format: true,
      });
    },
    [updatePhoneNumberFields, currentCountryCode]
  );

  const exampleNumber = useMemo(
    () => generatePlaceholderNumber(currentCountryCode),
    [currentCountryCode]
  );

  return (
    <>
      <ChocoUnitDropdownFormField
        labelText={t('phoneNumber.label')}
        isRequired
        id="phoneNumber"
        name="phoneNumber"
        ref={register}
        placeholder={exampleNumber}
        autoFocus
        value={phoneNumber}
        data-testid="login.form.phoneNumberInput"
        errorMessage={
          phoneNumber && formErrors.phoneNumber?.message
            ? t('loginV2.phoneNumber.invalidError')
            : undefined
        }
        isNativeDropdown
        onBlur={onInputBlurHandler}
        onChange={onInputChangeHandler}
        dropdownProps={{
          id: 'countryCode',
          value: currentCountryCode,
          onChange: onSelectCountryCode,
          items: countriesOptions,
          'data-testid': 'login.form.countrySelect',
          placeholder: 'generic placeholder',
          name: 'countryCode',
          selectRef: register,
          onClick: onClickCountryCodeDropdown,
          onBlur: onDropdownBlur,
        }}
      />

      {getHelpWebSignIn && (
        <Flex sx={{ gap: 'xxs', mt: 'xs' }}>
          <Text>{t('unableToLogin.header')}</Text>

          {!isWhiteLabelEnabled ? (
            <Text
              id={getHelpIntercomLauncherId}
              tabIndex={0}
              sx={{
                cursor: 'pointer',
                color: 'baseText',
                textDecoration: 'underline',
              }}
              data-testid="getHelpLoginPage"
            >
              {t('unableToLogin.getHelp')}
            </Text>
          ) : (
            <Text
              tabIndex={0}
              sx={{
                cursor: 'pointer',
                color: 'baseText',
                textDecoration: 'underline',
              }}
              data-testid="getHelpLoginPage"
              onClick={handleTypeformLinkClick}
            >
              {t('unableToLogin.getHelp')}
            </Text>
          )}
        </Flex>
      )}
    </>
  );
};
