import { UserFragment, UserQueryVariables, useUserQuery } from '../generated';

export default function useUserDataQuery(userId: string) {
  const variables: UserQueryVariables = {
    userId,
  };

  const { data, loading, error } = useUserQuery({
    variables,
    skip: !userId,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-first',
    errorPolicy: 'all',
  });

  const user = data?.user ?? ({} as UserFragment);

  return {
    user,
    loading,
    error,
  };
}
