import React from 'react';

import useTappedOrderTransmissionLink from '../hooks/useTappedOrderTransmissionLink';

import WebLitePage from './WebLitePage';

const OrderPageToChocoLite = () => {
  useTappedOrderTransmissionLink('order');

  return <WebLitePage />;
};

export default OrderPageToChocoLite;
