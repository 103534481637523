import { useToasts, Text, Link, Button } from '@chocoapp/chocolate-ui';
import { trackSegmentAction, trackSegmentPage } from '@chocoapp/toolbelt-utils';
import React, { useState, useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { useAuthContext } from '../auth';
import { AuthLayoutBody } from '../auth/AuthLayoutBody';
import { CountryCode } from '../components/commons/Login/CountryCode';
import { useUpdateProfileDataMutation } from '../data/useUpdateProfileDataMutation';
import useUserDataQuery from '../data/useUserDataQuery';
import { AccessPackage } from '../generated';
import { useUserCountry } from '../hooks';
import { useHistoryPush } from '../router';

enum Regions {
  R1 = 'R1',
  R2 = 'R2',
  R3 = 'R3',
  R4 = 'R4',
  R5 = 'R5',
}

const COUNTRY_CODES_BY_REGION: { [key in Regions]: CountryCode[] } = {
  R1: ['DE'],
  R2: ['FR', 'BE'],
  R3: [], // Default fallback
  R4: ['ES'],
  R5: ['GB'],
};

const getRegionByCountryCode = (countryCode: CountryCode): Regions => {
  switch (true) {
    case COUNTRY_CODES_BY_REGION[Regions.R1].includes(countryCode): // DE
      return Regions.R1;
    case COUNTRY_CODES_BY_REGION[Regions.R2].includes(countryCode): // FR BE
      return Regions.R2;
    case COUNTRY_CODES_BY_REGION[Regions.R4].includes(countryCode): // ES
      return Regions.R4;
    case COUNTRY_CODES_BY_REGION[Regions.R5].includes(countryCode): // GB
      return Regions.R5;
    default:
      return Regions.R3; // Fallback
  }
};

const BUYER_TAC_URLS: { [key in Regions]: string } = {
  R1: 'https://legal.choco.com/degastro#agb',
  R2: 'https://legal.choco.com/fr#conditionsgenerales',
  R3: 'https://legal.choco.com/us#tandcs',
  R4: 'https://legal.choco.com/es#terminosycondiciones',
  R5: 'https://legal.choco.com/uk#termsandconditions',
};
const BUYER_PP_URLS: { [key in Regions]: string } = {
  R1: 'https://legal.choco.com/degastro#datenschutzerklaerung',
  R2: 'https://legal.choco.com/fr#politiquedeconfidentialite',
  R3: 'https://legal.choco.com/us#usprivacypolicy',
  R4: 'https://legal.choco.com/es#politicadeprivacidad',
  R5: 'https://legal.choco.com/uk#ukprivacypolicy',
};
const SUPPLIER_FREE_TAC_URLS: { [key in Regions]: string } = {
  R1: 'https://legal.choco.com/delieferant#delieferantenagb',
  R2: 'https://legal.choco.com/frfournisseurs#frfournisseurscg',
  R3: 'https://legal.choco.com/usvendor#usvendorgtc',
  R4: 'https://legal.choco.com/esproveedores#esproveedortcg',
  R5: 'https://legal.choco.com/ukvendor#ukvendorgtc',
};
const SUPPLIER_FREE_PP_URLS: { [key in Regions]: string } = {
  R1: 'https://legal.choco.com/delieferant#datenschutzerklaerung',
  R2: 'https://legal.choco.com/frfournisseurs#politiquedeconfidentialite',
  R3: 'https://legal.choco.com/usvendor#usprivacypolicy',
  R4: 'https://legal.choco.com/esproveedores#politicadeprivacidad',
  R5: 'https://legal.choco.com/ukvendor#ukprivacypolicy',
};
const SUPPLIER_PREMIUM_TAC_URLS: { [key in Regions]: string } = {
  R1: 'https://legal.choco.com/de',
  R2: 'https://legal.choco.com/frpremium',
  R3: 'https://legal.choco.com/uspremium',
  R4: 'https://legal.choco.com/espremium',
  R5: 'https://legal.choco.com/ukpremium',
};
const SUPPLIER_PREMIUM_PP_URLS: { [key in Regions]: string } = {
  R1: 'https://legal.choco.com/delieferant#datenschutzerklaerung',
  R2: 'https://legal.choco.com/frfournisseurs#politiquedeconfidentialite',
  R3: 'https://legal.choco.com/usvendor#usprivacypolicy',
  R4: 'https://legal.choco.com/esproveedores#politicadeprivacidad',
  R5: 'https://legal.choco.com/ukvendor#ukprivacypolicy',
};

const getTacUrls = (
  isSupplier: boolean,
  isPremium: boolean,
  region: Regions = Regions.R3
): {
  tacUrl: string;
  ppUrl: string;
} => {
  if (isSupplier && isPremium) {
    return {
      tacUrl: SUPPLIER_PREMIUM_TAC_URLS[region],
      ppUrl: SUPPLIER_PREMIUM_PP_URLS[region],
    };
  }
  if (isSupplier && !isPremium) {
    return {
      tacUrl: SUPPLIER_FREE_TAC_URLS[region],
      ppUrl: SUPPLIER_FREE_PP_URLS[region],
    };
  }
  // is Buyer user
  return {
    tacUrl: BUYER_TAC_URLS[region],
    ppUrl: BUYER_PP_URLS[region],
  };
};

export const TermsAndConditionsPage = () => {
  const { t } = useTranslation('auth');
  const { triggerErrorToast } = useToasts();
  const { updateProfile, loading: updatingUser } =
    useUpdateProfileDataMutation();

  const [countryCode, setCountryCode] = useState<CountryCode>();
  const { isLoading: isLoadingCountryCode } = useUserCountry(setCountryCode);

  const { namedHistoryPush } = useHistoryPush();

  const { getLoggedInUserIdFromCognito } = useAuthContext();
  const userId = getLoggedInUserIdFromCognito();
  const { user, loading } = useUserDataQuery(userId);

  useEffect(() => {
    if (user.hasAcceptedTermsAndConditions === true) {
      namedHistoryPush({ to: 'main' });
    }
  }, [namedHistoryPush, user, user.hasAcceptedTermsAndConditions]);

  const isPremium =
    user.linkedSupplier?.accessPackage === AccessPackage.Premium ||
    user.linkedSupplier?.accessPackage === AccessPackage.Trial;

  const region = countryCode ? getRegionByCountryCode(countryCode) : Regions.R3;

  const { tacUrl, ppUrl } = getTacUrls(user.supplier, isPremium, region);

  const handleAccept = async () => {
    trackSegmentAction({ event: 'terms_and_conditions_submitted' });
    const { success } = await updateProfile({
      id: userId,
      hasAcceptedTermsAndConditions: true,
    });
    if (!success) {
      triggerErrorToast({
        content: t('createProfile.failedToCreateProfile'),
      });
    }
  };

  const loadingTac = loading || isLoadingCountryCode;

  useEffect(() => {
    trackSegmentPage({
      name: 'terms_and_conditions',
      category: 'terms_and_conditions',
    });
  }, []);

  return (
    <AuthLayoutBody
      footer={
        <Button
          size="large"
          data-testid="AcceptT&CButton"
          isLoading={updatingUser}
          disabled={loadingTac}
          onClick={handleAccept}
        >
          {t('termsAndConditions.accept')}
        </Button>
      }
    >
      <div data-testid="TCPage">
        <Text variant="H4" sx={{ mb: 'm' }}>
          {t('termsAndConditions.title')}
        </Text>
        {!loadingTac && (
          <Trans
            t={t}
            i18nKey="termsAndConditions.text"
            components={{
              tacLink: (
                <Link
                  onClick={() => trackSegmentAction({ event: 'terms_viewed' })}
                  href={tacUrl}
                  data-testid="TacLink"
                />
              ),
              ppLink: (
                <Link
                  onClick={() =>
                    trackSegmentAction({ event: 'privacy_policy_viewed' })
                  }
                  href={ppUrl}
                  data-testid="PpLink"
                />
              ),
            }}
          />
        )}
      </div>
    </AuthLayoutBody>
  );
};
