export const replaceOtpDigitsAtIndex = (
  currentDigits: string[],
  newChars: string,
  startIndex: number
) => {
  const isRemoving = newChars === '';
  const newCharsArray = isRemoving ? [''] : newChars.split('');

  const newOtp = [...currentDigits];
  newOtp.splice(startIndex, newCharsArray.length, ...newCharsArray);

  return newOtp;
};
