import {
  ToggleSwitch,
  Flex,
  Ellipsis,
  ThemeUIStyleObject,
  useColorMode,
} from '@chocoapp/chocolate-ui';
import { useFlags } from '@chocoapp/launchdarkly-ui';
import React, { FC, useCallback, memo, useMemo } from 'react';

import HiddenWhenCollapsed from '../HiddenWhenCollapsed';
import MenuTooltip from '../MenuTooltip';

const wrapperStyles: ThemeUIStyleObject = {
  alignItems: 'center',
  cursor: 'pointer',
  color: 'baseTextWeak',
  p: '12px 16px',
  gap: '12px',

  ':hover': {
    color: 'baseTextWeakActive',
  },
};

const ThemeSwitch: FC = () => {
  const { themeSwitch } = useFlags();
  const [theme, setTheme] = useColorMode();

  const isLightOn = theme === 'light';

  const toggleTheme = useCallback(() => {
    setTheme(isLightOn ? 'dark' : 'light');
  }, [isLightOn, setTheme]);

  const tooltipText = useMemo(
    () => `Activate ${isLightOn ? 'Dark' : 'Light'}`,
    [isLightOn]
  );

  return (
    <>
      {themeSwitch && (
        <MenuTooltip tooltipText={tooltipText}>
          <Flex sx={wrapperStyles}>
            <ToggleSwitch size="s" onChange={toggleTheme} checked={isLightOn} />
            <HiddenWhenCollapsed>
              <Ellipsis variant="Footnote">{tooltipText}</Ellipsis>
            </HiddenWhenCollapsed>
          </Flex>
        </MenuTooltip>
      )}
    </>
  );
};

export default memo(ThemeSwitch);
