import {
  ChocoModal,
  ChocoModalContainer,
  RadioOption,
  ChocoRadioGroupBase,
} from '@chocoapp/chocolate-ui';
import React, { useMemo } from 'react';

import { useAuthContext } from '../../../auth';
import {
  EnvironmentKey,
  EnvironmentProps,
} from '../../../auth/environments/types';
import { environments, getEnvConfig } from '../../../auth/environments/utils';
import { useEnvironmentSelectorProvider } from '../../../auth/EnvironmentSelector';
import { useHistoryPush } from '../../../router';

import { EnvironmentItem } from './EnvironmentItem';

const useGetEnvironmentOptions = (onCloseModal: () => void) => {
  const { cognitoUser } = useAuthContext();
  const { environment, setEnvironment } = useEnvironmentSelectorProvider();
  const { namedHistoryPush } = useHistoryPush();

  const environmentOptions: RadioOption[] = useMemo(() => {
    const onClickEnvironment = (value: EnvironmentKey) => {
      onCloseModal();
      setEnvironment(value);
      // Redirect to Logout flow only when a user is logged in
      // Otherwise simply change the env. (when on Login screen)
      cognitoUser?.getSession((_error: Error) => {
        namedHistoryPush({ to: 'auth:logout' });
      });
    };

    return Object.keys(environments).map((key) => {
      const envName = key as EnvironmentKey;
      const envItem: EnvironmentProps = getEnvConfig(envName);
      return {
        key,
        value: key,
        defaultChecked: environment === envName,
        label: <EnvironmentItem envName={envName} envItem={envItem} />,
        onClick: () => onClickEnvironment(envName),
      };
    });
  }, [
    environment,
    onCloseModal,
    setEnvironment,
    namedHistoryPush,
    cognitoUser,
  ]);

  return { environmentOptions };
};

type EnvironmentModalProps = {
  isOpen: boolean;
  onCloseModal: () => void;
};

export const EnvironmentModal = ({
  isOpen,
  onCloseModal,
}: EnvironmentModalProps) => {
  const { environmentOptions } = useGetEnvironmentOptions(onCloseModal);

  return (
    <ChocoModal
      size="small"
      isOpen={isOpen}
      onClose={onCloseModal}
      testId="EnvironmentModal"
    >
      <ChocoModalContainer width="320px">
        <ChocoRadioGroupBase name="environment" options={environmentOptions} />
      </ChocoModalContainer>
    </ChocoModal>
  );
};
