import { Box } from '@chocoapp/chocolate-ui';
import { useFlags } from '@chocoapp/launchdarkly-ui';
import { Permissions, usePermissions, Roles } from '@chocoapp/toolbelt-auth';
import { isNil } from '@chocoapp/toolbelt-utils';
import React, { FC } from 'react';

import { loxFilter } from '../../../lox/utils';
import { useLoggedInUserContext } from '../../../store/LoggedInUserProvider';

import { NavigationItemType, getNavigationItems } from './navigation';
import NavigationItem from './NavigationItem';

const useIsVisibleNavigationItem = () => {
  const { isBuyer, isSupplier, enableWithRoles } = usePermissions();
  const {
    user: { linkedSupplier },
    isLoading,
  } = useLoggedInUserContext();

  return ({ shouldHideForLegacyUsers }: NavigationItemType) => {
    if (shouldHideForLegacyUsers) {
      return (
        isLoading ||
        isBuyer ||
        (isSupplier &&
          !isNil(linkedSupplier) &&
          enableWithRoles([Roles.SUPPLIER_ADMINS, Roles.SUPPLIER_SALES_REPS]))
      );
    }
    return true;
  };
};

type NavigationItemPermissionProps = {
  children: React.ReactNode;
  displayWhen?: Roles[];
};

const NavigationItemPermission: FC<NavigationItemPermissionProps> = ({
  displayWhen,
  children,
}) => {
  return displayWhen?.length ? (
    <Permissions displayWhen={displayWhen}>{children}</Permissions>
  ) : (
    <>{children}</>
  );
};

const NavigationTabs: FC = () => {
  const flags = useFlags();
  const isVisibleNavigationItem = useIsVisibleNavigationItem();
  const navigationItems = getNavigationItems(flags);

  return (
    <Box>
      {navigationItems
        .filter(
          (navigationItem) => !flags[navigationItem.denyListFeatureFlagKey!]
        )
        .filter(isVisibleNavigationItem)
        .filter(loxFilter(flags.loxMvp))
        .map((navigationItem) => (
          <NavigationItemPermission
            key={`navigationItem_${navigationItem.testId}`}
            displayWhen={navigationItem.displayWhen}
          >
            <NavigationItem {...navigationItem} />
          </NavigationItemPermission>
        ))}
    </Box>
  );
};

export default NavigationTabs;
