import { NavigationItemType } from '../components/commons/GlobalNavigation/navigation';
import { NavigationItemType as NavigationItemTypeV2 } from '../components/commons/GlobalNavigationV2/navigation';

import { subdomainMappings } from './customMappings';

const WHITE_LABEL_URL = 'https://whitelabelassets.choco.com';
const CHOCO_ASSETS_URL = 'https://assets.choco.com';

export const isWhiteLabel = () => {
  const url = window.location?.hostname ?? '';
  const subdomainElements = Object.keys(subdomainMappings);
  return subdomainElements.some((subdomain) => url.includes(subdomain));
};

export const getAssetsPrefix = () => {
  return window.choco?.assets?.prefix || '';
};

export const getNormalizedAssetURL = (assetPath: string) => {
  const whiteLabelEnabled = isWhiteLabel();
  const prefix = window.choco?.assets?.prefix;
  const chocoDefaultPrefix = '/web/assets/';
  const normalizedChocoPath = `${chocoDefaultPrefix}/${assetPath}`.replace(
    /(\/{2,})/g,
    '/'
  );
  const normalizedWhiteLabelPath = `${
    prefix ?? ''
  }/assets/${assetPath}`.replace(/(\/{2,})/g, '/');

  const url = new URL(
    whiteLabelEnabled ? normalizedWhiteLabelPath : normalizedChocoPath,
    whiteLabelEnabled ? WHITE_LABEL_URL : CHOCO_ASSETS_URL
  ).href;
  return url;
};

const WhiteLabelHiddenItems = [
  'globalNavigationReporting',
  'globalNavigationInventoryV2',
];

export const whiteLabelFilter =
  (isSupplier: boolean, whiteLabelEnabled: boolean) =>
  (item: NavigationItemType | NavigationItemTypeV2) => {
    if (isSupplier) {
      return true;
    }

    if (!whiteLabelEnabled) {
      return true;
    }

    return !WhiteLabelHiddenItems.includes(item.testId);
  };
