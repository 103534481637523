import {
  trackSegmentAction,
  removeSessionId,
  setSessionId,
} from '@chocoapp/toolbelt-utils/lib/segment';
import createActivityDetector from 'activity-detector';

const TIME_TO_IDLE = 15 * 60000; // 15 mins

type ActivityDetectorType = {
  on: (eventType: string, callback: () => void) => void;
  init: () => void;
  stop: () => void;
};

let activityDetector: ActivityDetectorType | null = null;

export const createDetector = () => {
  activityDetector = createActivityDetector({
    timeToIdle: TIME_TO_IDLE,
    autoInit: false,
    ignoredEventsWhenIdle: [],
    inactivityEvents: [],
  });
  return activityDetector;
};

export const clearDetectorInstance = () => {
  if (activityDetector) {
    activityDetector.stop();
    activityDetector = null;
  }
};

export const removeSession = () => {
  trackSegmentAction({
    event: 'sessionEnd',
    data: { timeStamp: Date.now() },
  });

  removeSessionId();
};

export const registerSession = () => {
  const sessionId = Date.now();
  setSessionId(sessionId);

  trackSegmentAction({
    event: 'sessionStart',
    data: { timeStamp: Date.now() },
  });
};

export default function registerActivityDetector() {
  if (activityDetector) {
    activityDetector.on('idle', removeSession);
    activityDetector.on('active', registerSession);
    activityDetector.init();
  }
}
