import { useFlags } from '@chocoapp/launchdarkly-ui';
import { trackSegmentAction } from '@chocoapp/toolbelt-utils';
import queryString from 'query-string';
import { useCallback, useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import {
  LS_PREMIUM_REQUESTED_KEY,
  LS_PREMIUM_REQUESTED_NUMBER_KEY,
} from '../constants/AppConstants';
import { useHistoryPush } from '../router';

import { TwoFactorMethod } from './createInitiateCustomAuthHandler';
import {
  trackLoginSuccess,
  trackSubmitOneTimeTimePassword,
} from './trackingEvents';
import { useFetchUserInfo } from './useFetchUserInfo';
import { useRedirectAfterLogin } from './useRedirectAfterLogin';

import { logoutLSListener, useAuthContext } from '.';

const useOneTimePasswordFormDataAction = ({
  isConfirmSignUp,
  userId,
}: {
  isConfirmSignUp?: boolean;
  userId?: string;
}) => {
  const { submitOneTimePassword, confirmSignUp } = useAuthContext();
  const { userPool } = useAuthContext();

  return (oneTimePassword: string) =>
    isConfirmSignUp
      ? confirmSignUp(oneTimePassword, userId!)
      : submitOneTimePassword(oneTimePassword, userPool);
};

export const useOneTimePasswordFormDataSuccess = () => {
  const flags = useFlags();
  const { namedHistoryPush } = useHistoryPush();
  const { currentPhoneNumber: phoneNumber, saveIsUnauthorizedSupplier } =
    useAuthContext();
  const callFetchUserInfo = useFetchUserInfo();
  const redirectAfterLogin = useRedirectAfterLogin();

  return useCallback(
    async (authMethod: TwoFactorMethod) => {
      trackLoginSuccess(authMethod);

      const {
        id,
        pending,
        isUnauthorizedSupplier,
        isTrial,
        hasBuyerOrSupplierConnection,
        hasAcceptedTermsAndConditions,
      } = await callFetchUserInfo();

      saveIsUnauthorizedSupplier(Boolean(isUnauthorizedSupplier));

      if (!hasBuyerOrSupplierConnection || pending) {
        namedHistoryPush({
          to: 'auth:create-profile',
          state: { userId: id },
          replace: true,
        });
        return;
      }

      if (flags.paywallV0 && isUnauthorizedSupplier) {
        const lastNumberToRequestPremium = localStorage.getItem(
          LS_PREMIUM_REQUESTED_NUMBER_KEY
        );

        const hasAlreadyRequestedPremium =
          lastNumberToRequestPremium === phoneNumber;

        if (hasAlreadyRequestedPremium) {
          localStorage.setItem(LS_PREMIUM_REQUESTED_KEY, 'true');
        } else {
          localStorage.setItem(LS_PREMIUM_REQUESTED_KEY, 'false');
        }

        const to = hasAlreadyRequestedPremium
          ? 'auth:requestReceived'
          : 'auth:getInTouch';

        namedHistoryPush({
          to,
          search: {
            ...(phoneNumber && { phoneNumber }),
            ...(isTrial && { isTrial }),
          },
        });

        return;
      }

      redirectAfterLogin(hasAcceptedTermsAndConditions);
    },
    [
      callFetchUserInfo,
      flags.paywallV0,
      namedHistoryPush,
      phoneNumber,
      redirectAfterLogin,
      saveIsUnauthorizedSupplier,
    ]
  );
};

export const useCognitoUserIsRequiredEffect = () => {
  const { cognitoUser } = useAuthContext();
  const { namedHistoryPush } = useHistoryPush();
  const location = useLocation();

  useEffect(() => {
    if (!cognitoUser) {
      namedHistoryPush({
        to: 'auth:login',
        search: queryString.parse(location.search),
      });
    }
  }, [cognitoUser, location.search, namedHistoryPush]);
};

type UseSubmitOneTimePasswordProps = {
  onError: () => void;
  isConfirmSignUp?: boolean;
  userId?: string;
};
export const useSubmitOneTimePassword = ({
  onError,
  isConfirmSignUp,
  userId,
}: UseSubmitOneTimePasswordProps) => {
  useCognitoUserIsRequiredEffect();

  const [loading, setLoading] = useState(false);
  const submitOneTimePassword = useOneTimePasswordFormDataAction({
    isConfirmSignUp,
    userId,
  });

  const onSuccess = useOneTimePasswordFormDataSuccess();

  const submitOtp = useCallback(
    async (oneTimePassword: string, authMethod: TwoFactorMethod) => {
      try {
        trackSubmitOneTimeTimePassword(authMethod);

        setLoading(true);

        await submitOneTimePassword(oneTimePassword);
        trackSegmentAction({
          event: 'user_authenticated',
          data: {
            auth_type: oneTimePassword.length === 4 ? 'phone' : 'email',
            is_authenticated: true,
          },
        });
        await onSuccess(authMethod);
        logoutLSListener.post({
          event: 'login',
          data: Date.now(),
        });
      } catch (err) {
        trackSegmentAction({
          event: 'user_authenticated',
          data: {
            auth_type: oneTimePassword.length === 4 ? 'phone' : 'email',
            is_authenticated: false,
          },
        });
        onError();
        setLoading(false);
      }
    },
    [onError, onSuccess, submitOneTimePassword]
  );

  return { submitOtp, loading };
};
