import { formatPhoneNumber } from '@chocoapp/toolbelt-utils';
import {
  CognitoUser,
  CognitoUserAttribute,
  CognitoUserPool,
} from 'amazon-cognito-identity-js';
import { v4 } from 'uuid';

import { RECAPTCHA_KEY, SIGN_UP_PASSWORD } from '../constants/AppConstants';

import {
  AUTH_CODE_EMAIL_LENGTH,
  CustomAuthHandlerResponse,
} from './createInitiateCustomAuthHandler';

export const hanldeSignup = (
  phoneNumber: string,
  userPool: CognitoUserPool,
  newCognitoUser: CognitoUser,
  resolve: (
    value: CustomAuthHandlerResponse | PromiseLike<CustomAuthHandlerResponse>
  ) => void,
  reject: (reason?: any) => void
) => {
  try {
    window.grecaptcha.enterprise.ready(async () => {
      const token = await window.grecaptcha.enterprise.execute(RECAPTCHA_KEY, {
        action: 'signup',
      });

      const phoneNumberAttribute = new CognitoUserAttribute({
        Name: 'phone_number',
        Value: phoneNumber,
      });

      const userId = v4().toUpperCase();
      const user = JSON.stringify({ id: userId });

      const clientMetadata = {
        user,
        captchaToken: token,
        signUpVersion: '2.0.0',
        signUpClient: 'choco-app',
        clientType: 'Web',
      };

      const resendCode = () => {
        newCognitoUser.resendConfirmationCode((error) => {
          if (error) {
            return reject(error);
          }

          return resolve({
            user: newCognitoUser,
            authDigits: AUTH_CODE_EMAIL_LENGTH,
            twoFactorMethod: 'phone',
            codeSentTo: formatPhoneNumber(phoneNumber),
            method: 'signUp',
            userId,
          });
        }, clientMetadata);
      };

      userPool.signUp(
        phoneNumber,
        SIGN_UP_PASSWORD,
        [phoneNumberAttribute],
        [],
        (error) => {
          if (!error) {
            resolve({
              user: newCognitoUser,
              authDigits: AUTH_CODE_EMAIL_LENGTH,
              twoFactorMethod: 'phone',
              codeSentTo: formatPhoneNumber(phoneNumber),
              method: 'signUp',
              userId,
            });
            return;
          }

          if (error.name === 'UsernameExistsException') {
            resendCode();
            return;
          }

          reject(error);
        },
        clientMetadata
      );
    });
  } catch (error) {
    reject(error);
  }
};
