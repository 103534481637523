import { UserFragment } from '../generated';

import { pipe } from './pipe';

type LDUserReturnTypes = {
  key: string;
  name: string;
  email: string | undefined;
  custom: any;
};

export const anonymousKey = 'anonymous';
const platform = 'Web';

const withKey = (user: UserFragment) => (
  data: Partial<LDUserReturnTypes>
): Partial<LDUserReturnTypes> => ({
  ...data,
  key: user.id || anonymousKey,
});

const withDefaultAttributes = (user: UserFragment) => (
  data: Partial<LDUserReturnTypes>
): Partial<LDUserReturnTypes> => ({
  ...data,
  ...(data.key !== anonymousKey
    ? { name: user.name ?? '', email: user.email ?? '' }
    : {}),
});

const withCustomAttributes = (actualLoggedInUser: UserFragment) => (
  data: Partial<LDUserReturnTypes>
): Partial<LDUserReturnTypes> => ({
  ...data,
  custom: {
    platform,
    chocoUser: Boolean(actualLoggedInUser.chocoUser),
    isTest: Boolean(actualLoggedInUser.isTest),
    isPuppy: actualLoggedInUser.isPuppy,
    isSupplier: actualLoggedInUser.linkedSupplier?.id
      ? Boolean(actualLoggedInUser.linkedSupplier?.id)
      : undefined,
    supplierId: actualLoggedInUser.linkedSupplier?.id,
    supplierRefId: actualLoggedInUser.linkedSupplier?.referenceId,
    supplierAccessPackage: actualLoggedInUser.linkedSupplier?.accessPackage,
  },
});

export const generateLDUser = (
  actualLoggedInUser: UserFragment
): LDUserReturnTypes => {
  const generateUser = pipe(
    withKey(actualLoggedInUser),
    withDefaultAttributes(actualLoggedInUser),
    withCustomAttributes(actualLoggedInUser)
  );

  return generateUser({});
};
