import { useToasts } from '@chocoapp/chocolate-ui';
import { trackSegmentAction } from '@chocoapp/toolbelt-utils/lib/segment';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { useUpdateProfileDataMutation } from '../data/useUpdateProfileDataMutation';

import { CreateProfileFormData } from './useCreateProfileSubmit';

export const useProfileUpdateSubmit = () => {
  const { updateProfile, loading } = useUpdateProfileDataMutation();

  const { t } = useTranslation('auth');
  const { triggerErrorToast } = useToasts();
  const { state } = useLocation();

  const handleSubmitProfileUpdate = useCallback(
    async (formData: CreateProfileFormData) => {
      trackSegmentAction({ event: 'createProfileV2-submit' });

      try {
        const { success } = await updateProfile({
          id: state?.userId ?? '',
          name: formData.fullName,
          email: formData.email,
        });

        if (!success) {
          triggerErrorToast({
            content: t('createProfile.failedToCreateProfile'),
          });
        }
      } catch (createProfileError) {
        triggerErrorToast({ content: t('phoneNumber.error') });
      }
    },
    [state, t, triggerErrorToast, updateProfile]
  );
  return { handleSubmitProfileUpdate, loading };
};
