import { trackSegmentAction } from '@chocoapp/toolbelt-utils';
import { useTranslation } from 'react-i18next';

import useHistoryPush from '../router/useHistoryPush';
import {
  WHITE_LABEL_I18N_NAMESPACE,
  getWhiteLabelI18nNamespace,
} from '../whiteLabel/getWhiteLabelI18nNamespace';

import {
  CreateProfileFormData,
  useCreateProfileSubmit,
} from './useCreateProfileSubmit';
import { useProfileUpdateSubmit } from './useProfileUpdateSubmit';

export const useHandleProfileSubmit = (
  isPendingUser: boolean,
  hasBuyerOrSupplierConnection: boolean,
  hasAcceptedTermsAndConditions: boolean
) => {
  const { t } = useTranslation(['auth', WHITE_LABEL_I18N_NAMESPACE]);
  const { handleSubmitProfileCreate, loading: loadingProfileCreate } =
    useCreateProfileSubmit();
  const { handleSubmitProfileUpdate, loading: loadingProfileUpdate } =
    useProfileUpdateSubmit();

  const { namedHistoryPush } = useHistoryPush();

  const handleSubmitProfile = async (formData: CreateProfileFormData) => {
    trackSegmentAction({
      event: 'create_profile_submitted',
      data: {
        workspace_response: formData.isSupplier ? 'Supplier' : 'Restaurant',
      },
    });
    if (isPendingUser) {
      await handleSubmitProfileCreate(formData);
    } else {
      await handleSubmitProfileUpdate(formData);
    }

    if (!hasAcceptedTermsAndConditions && hasBuyerOrSupplierConnection) {
      namedHistoryPush({
        to: 'auth:terms-and-conditions',
      });
      return;
    }

    if (formData.isSupplier === true && !hasBuyerOrSupplierConnection) {
      window.Intercom('update', { alignment: 'left' });
      window.Intercom('show');
      window.Intercom(
        'showNewMessage',
        t('createProfile.supportDefaultMessage', {
          ns: getWhiteLabelI18nNamespace('auth'),
        })
      );
      return;
    }

    if (formData.isSupplier === false && !hasBuyerOrSupplierConnection) {
      namedHistoryPush({
        to: 'auth:create-or-join',
      });
      return;
    }

    namedHistoryPush({ to: 'main', replace: true });
  };

  return {
    handleSubmitProfile,
    loading: loadingProfileCreate || loadingProfileUpdate,
  };
};
