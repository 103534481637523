import { ThemeUIStyleObject } from '@chocoapp/chocolate-ui';
import styled from '@emotion/styled';
import { Link } from 'react-router-dom';

export const profileBarStyles: ThemeUIStyleObject = {
  p: 'xs',
  width: '100%',
  borderBottom: '1px solid',
  borderColor: 'neutralBorder',
  justifyContent: 'flex-end',
};

const hoverStyles: ThemeUIStyleObject = {
  bg: 'neutralBgMedium',
  cursor: 'pointer',
  border: '1px solid',
  borderColor: 'neutralBorder',
};

export const getProfileDetailsStyles = (
  isOpen: boolean
): ThemeUIStyleObject => ({
  p: 'xs',
  gap: 'xs',
  border: '1px solid',
  borderColor: 'transparent',
  ...(isOpen && hoverStyles),
  '&:hover': {
    ...hoverStyles,
  },
});

export const StyledLink = styled(Link)`
  text-decoration: none;
  color: inherit;
`;
