import { Text, Flex, Button } from '@chocoapp/chocolate-ui';
import { getSupportLoginPhone } from '@chocoapp/toolbelt-utils';
import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect, useCallback } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { CountryCode } from '../components/commons/Login/CountryCode';
import { getFullPhoneNumber } from '../components/commons/Login/utils';
import { getDialingCodeFromCountryCode } from '../util/phoneNumbers';

import { AuthLayoutBody } from './AuthLayoutBody';
import { LoginFormFields } from './LoginFormFields';
import { loginFormValidationSchema } from './loginFormValidationSchema';
import { trackViewLoginPage } from './trackingEvents';
import { useSubmitPhoneNumber } from './usePhoneNumberFormData';

export type LoginFormData = {
  countryCode: CountryCode;
  phoneNumber: string;
};

export const LoginForm = () => {
  const { isSubmitting, error, submitPhoneNumber } = useSubmitPhoneNumber();
  const supportLoginPhoneNumber = getSupportLoginPhone();
  const { t } = useTranslation('auth');

  const formMethods = useForm<LoginFormData>({
    resolver: yupResolver(loginFormValidationSchema),
    defaultValues: { phoneNumber: '', countryCode: 'DE' },
    mode: 'onSubmit',
  });
  const { handleSubmit, watch, formState } = formMethods;
  const currentPhoneNumber = watch('phoneNumber');

  const onSubmitHandler = useCallback(
    ({ countryCode, phoneNumber }: LoginFormData) => {
      const cleanPhoneNumber = getFullPhoneNumber(
        getDialingCodeFromCountryCode(countryCode),
        phoneNumber
      );

      return submitPhoneNumber(cleanPhoneNumber);
    },
    [submitPhoneNumber]
  );

  useEffect(() => {
    trackViewLoginPage();
  }, []);

  useEffect(() => {
    if (supportLoginPhoneNumber && !error && !isSubmitting) {
      submitPhoneNumber(supportLoginPhoneNumber);
    }
  }, [supportLoginPhoneNumber, error, isSubmitting, submitPhoneNumber]);

  return (
    <FormProvider {...formMethods}>
      <AuthLayoutBody
        footer={
          <Button
            size="large"
            form="form.login.phoneNumber"
            type="submit"
            data-testid="login.form.submitButton"
            isLoading={formState.isSubmitting}
            disabled={!currentPhoneNumber}
            sx={{ alignSelf: 'flex-end', flex: '0' }}
          >
            {t('continue')}
          </Button>
        }
      >
        <Flex
          as="form"
          sx={{ flexDirection: 'column', gap: 'm', height: '100%' }}
          onSubmit={handleSubmit(onSubmitHandler)}
          id="form.login.phoneNumber"
        >
          <Text variant="H4">{t('phoneNumber.title')}</Text>
          <Text>{t('phoneNumber.subtitle')}</Text>

          <LoginFormFields />
        </Flex>
      </AuthLayoutBody>
    </FormProvider>
  );
};
