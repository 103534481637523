import { useHistory, useLocation } from 'react-router-dom';

export const useRemoveQueryStringParam = () => {
  const location = useLocation();
  const history = useHistory();

  const removeQueryStringParam = (queryString: string) => {
    const queryParams = new URLSearchParams(location.search);

    if (queryParams.has(queryString)) {
      queryParams.delete(queryString);
      history.replace({
        search: queryParams.toString(),
      });
    }
  };

  return { removeQueryStringParam };
};
