import { ChocoAsset } from '@chocoapp/chocolate-ui';
import { trackSegmentPage } from '@chocoapp/toolbelt-utils/lib/segment';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { PageBody } from '../components';
import { getAssetsPrefix } from '../whiteLabel/utils';

const NotFoundPage = () => {
  const { t } = useTranslation('globalNavigation');

  useEffect(() => {
    trackSegmentPage({
      category: 'error',
      name: '404-show',
    });
  }, []);

  return (
    <PageBody
      title={t('notFound.title')}
      subtitle={t('notFound.subtitle')}
      testid="notFoundPage"
      icon={
        <ChocoAsset
          prefix={getAssetsPrefix()}
          path="/illustrations/coconut.svg"
        />
      }
    />
  );
};

export default NotFoundPage;
