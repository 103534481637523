import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';

import {
  DATA_DOG_APPLICATION_ID,
  DATA_DOG_CLIENT_TOKEN,
  DATA_DOG_ENV,
  DATA_DOG_SITE,
  REACT_APP_DATA_DOG_SERVICE_NAME,
  REACT_APP_ENV_PREFIX,
  REACT_APP_VERSION_COMMIT_SHA,
} from '../config';

datadogRum.init({
  applicationId: DATA_DOG_APPLICATION_ID,
  clientToken: DATA_DOG_CLIENT_TOKEN,
  site: DATA_DOG_SITE,
  service: REACT_APP_DATA_DOG_SERVICE_NAME,
  env: DATA_DOG_ENV,
  sessionSampleRate: 100,
  sessionReplaySampleRate: 10,
  trackUserInteractions: true,
  trackResources: true,
  trackLongTasks: true,
  defaultPrivacyLevel: 'mask',
  allowedTracingUrls: [
    /https:\/\/.*\.appsync-api\.eu-west-1\.amazonaws\.com\/graphql/,
  ],
});

datadogLogs.init({
  clientToken: DATA_DOG_CLIENT_TOKEN,
  site: DATA_DOG_SITE,
  service: REACT_APP_DATA_DOG_SERVICE_NAME,
  version: REACT_APP_VERSION_COMMIT_SHA,
  env: REACT_APP_ENV_PREFIX,
  forwardErrorsToLogs: false,
  sessionSampleRate: 100,
});
