import { useCallback } from 'react';

import { useUserQueryAsync } from '../data';
import { AccessPackage } from '../generated';

import { isUserLinkedToBuyerOrSupplier } from './helpers/isUserLinkedToBuyerOrSupplier';

import { useAuthContext } from '.';

export const isUnauthorizedAccessPackage = (
  accessPackage?: AccessPackage | null
) =>
  Boolean(
    accessPackage &&
      [AccessPackage.Free, AccessPackage.Verified].includes(accessPackage)
  );

export const useFetchUserInfo = (): (() => Promise<{
  id: string;
  isUnauthorizedSupplier: boolean;
  isTrial: boolean;
  pending: boolean;
  chocoUser: boolean;
  hasBuyerOrSupplierConnection: boolean;
  hasAcceptedTermsAndConditions: boolean;
}>) => {
  const callUserQuery = useUserQueryAsync();
  const { getLoggedInUserIdFromCognito } = useAuthContext();

  return useCallback(async () => {
    const userId = getLoggedInUserIdFromCognito();
    const { data } = await callUserQuery(userId);

    const hasBuyerOrSupplierConnection = isUserLinkedToBuyerOrSupplier(
      data.user
    );

    return {
      id: data?.user?.id ?? '',
      pending: data?.user?.pending ?? false,
      isUnauthorizedSupplier: isUnauthorizedAccessPackage(
        data?.user?.linkedSupplier?.accessPackage
      ),
      isTrial:
        data?.user?.linkedSupplier?.accessPackage === AccessPackage.Trial,
      chocoUser: data.user?.chocoUser ?? false,
      hasBuyerOrSupplierConnection,
      hasAcceptedTermsAndConditions:
        data.user?.hasAcceptedTermsAndConditions ?? false,
    };
  }, [callUserQuery, getLoggedInUserIdFromCognito]);
};
