import React, { FC } from 'react';
import { RouteProps, Route, useLocation, Redirect } from 'react-router-dom';

import { useAuthContext } from '../auth';
import { useNotificationRequestPermission } from '../hooks';

import { namedPathResolver } from '.';

const pathNamesForInboxRedirect = ['/', '/login', '/sessions/two-factor'];

const PrivateRoute: FC<RouteProps> = (props) => {
  const { isAuthenticated, isUnauthorizedSupplier } = useAuthContext();
  const location = useLocation();

  useNotificationRequestPermission(isAuthenticated());

  if (!isAuthenticated()) {
    const { pathname, search } = location;
    const next = `${pathname}${search ?? ''}`;

    return (
      <Redirect
        to={namedPathResolver({
          to: 'auth:login',
          search: { next },
        })}
      />
    );
  }

  // unauthorized suppliers (ie. free/verified) are redirected to the "paywall"
  if (isUnauthorizedSupplier()) {
    return <Redirect to={namedPathResolver({ to: 'auth:getInTouch' })} />;
  }

  if (pathNamesForInboxRedirect.includes(location?.pathname)) {
    return (
      <Redirect to={namedPathResolver({ to: 'main:inbox' })} push={false} />
    );
  }

  return <Route {...props} />;
};

export default PrivateRoute;
