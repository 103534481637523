import {
  IntegrationEmailOption,
  IntegrationType,
  SupplierIntegrationsFragment,
} from '../generated';

const isEmailIntegration = (
  integration: SupplierIntegrationsFragment
): integration is IntegrationEmailOption => {
  return (
    'emailAddress' in integration &&
    'type' in integration &&
    integration.type === IntegrationType.Email
  );
};

const getEmailFromIntegrations = (
  integrations: SupplierIntegrationsFragment[]
): string => {
  const foundEmailIntegration = integrations.find(isEmailIntegration);

  return foundEmailIntegration?.emailAddress ?? '';
};

export { isEmailIntegration, getEmailFromIntegrations };
