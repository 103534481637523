import { Flex, Box, Text, ThemeUIStyleObject } from '@chocoapp/chocolate-ui';
import Lottie from 'lottie-react';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import confirmingOrder from '../../../assets/lotties/confirmingOrder.json';
import { AsyncI18n } from '../../../components';

import { useLoadingConfirmationOrderContext } from './LoadingConfirmationOrderProvider';

const loadingOrderConfirmationStyles: ThemeUIStyleObject = {
  alignItems: 'center',
  backgroundColor: 'baseBgMedium',
  color: 'baseTextWeakActive',
  flexDirection: 'column',
  height: '100%',
  width: '100%',
  padding: '96px 16px 0',
  textAlign: 'center',
  zIndex: 1,
};

const LoadingOrderConfirmation: FC = () => {
  const { isLoading } = useLoadingConfirmationOrderContext();
  const { t } = useTranslation('emailFlow');

  return isLoading ? (
    <AsyncI18n>
      <Flex
        data-testid="LoadingConfirmationOrder"
        sx={loadingOrderConfirmationStyles}
      >
        <Text variant="H3">{t('redirecting_screen__loading')}</Text>
        <Box sx={{ height: ['200px', '300px', '400px', '500px'] }}>
          <Lottie
            loop
            autoplay
            animationData={confirmingOrder}
            style={{ width: '100%', height: '100%' }}
            rendererSettings={{
              preserveAspectRatio: 'xMidYMid slice',
            }}
          />
        </Box>

        <Box sx={{ maxWidth: '600px' }}>
          <Text variant="TitleMedium" sx={{ mr: '4px', display: 'inline' }}>
            {t('redirecting_screen_headline')}
          </Text>
          <Text variant="TitleMedium" sx={{ display: 'inline' }}>
            {t('redirecting_screen__curiosity_body_1')}
          </Text>
        </Box>
      </Flex>
    </AsyncI18n>
  ) : null;
};

export default LoadingOrderConfirmation;
