import { CognitoUserPool } from 'amazon-cognito-identity-js';

import { AppLogger } from '../application/logger';

import createGetCurrentIdTokenHandler from './createGetCurrentIdTokenHandler';

/**
 * Sometimes we need to access the idToken outside of the context of a React component without throwing errors.
 * Hence, this function has no correlation with the Authenticator provider whatsoever.
 */
export default async function getIdToken(userPool: CognitoUserPool) {
  const cognitoUser = userPool.getCurrentUser();

  try {
    const getCognitoIdToken = createGetCurrentIdTokenHandler({
      cognitoUser,
    });

    const { jwtToken } = await getCognitoIdToken();
    return jwtToken;
  } catch (error) {
    AppLogger.error('getIdTokenError', { error });
    // fallback token needs to be empty string
    return '';
  }
}
