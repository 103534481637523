import React, { FC, useEffect } from 'react';

import { useOrderConfirmMutationForLoggedInUser } from '../../data/confirmOrderForLoggedInUser';
import { getDecodedProductListJwtToken } from '../../hooks';
import getQueryString from '../../hooks/getQueryString';
import { useHistoryPush } from '../../router';
import ConfirmOrderErrorPage from '../ConfirmOrderErrorPage';

import { useLoadingConfirmationOrderContext } from './LoadingConfirmationOrder/LoadingConfirmationOrderProvider';

const ConfirmOrder: FC = () => {
  const { chatId, orderId } = getDecodedProductListJwtToken<{
    chatId?: string;
    orderId?: string;
  }>();
  const transmissionChannel = getQueryString('transmissionChannel');
  const { isLoading, setIsLoading } = useLoadingConfirmationOrderContext();
  const { historyPush } = useHistoryPush();

  const {
    orderConfirmMutation,
    hasErrors,
  } = useOrderConfirmMutationForLoggedInUser(transmissionChannel!);

  useEffect(() => {
    const confirmOrder = async () => {
      await orderConfirmMutation({
        variables: {
          // TODO(markus): handle orderId being undefined
          id: orderId!,
        },
      });
      setIsLoading(false);
    };

    confirmOrder();
  }, [orderConfirmMutation, orderId, setIsLoading]);

  if (hasErrors) return <ConfirmOrderErrorPage />;

  if (!isLoading) {
    // TODO(markus): handle chatId and orderId being undefined
    historyPush({ to: `/inbox/${chatId}/order/${orderId}` });
  }

  return null;
};

export default ConfirmOrder;
