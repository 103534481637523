import { isNumeric } from '../../util/isNumeric';

type KeyDownHandlerOutput = {
  value?: string;
  focusIndex?: number;
};

export const getChangesFromKeyDownEvent = (
  event: React.KeyboardEvent<HTMLInputElement>,
  existingValue: string
): KeyDownHandlerOutput => {
  if (event.key === 'Backspace' || event.key === 'Delete') {
    event.preventDefault();

    return { value: '' };
  }
  if (event.key === 'ArrowRight') {
    return { focusIndex: 1 };
  }
  if (event.key === 'ArrowLeft') {
    return { focusIndex: -1 };
  }
  if (event.key === ' ') {
    event.preventDefault();
  } else if (isNumeric(event.key) && existingValue === event.key) {
    // onChange event is NOT triggered if user types the same number that is already in the input
    // so here we manually trigger the focus to next element
    return { focusIndex: 1 };
  }

  return {};
};
