import { useFlags } from '@chocoapp/launchdarkly-ui';
import { FC, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { INTERCOM_APP_ID } from '../../../config';
import { UserFragment } from '../../../generated';
import { useIntercomCredentials } from '../../../hooks/useIntercomCredentials';
import { useLoggedInUser } from '../../../hooks/useLoggedInUser';

/**
 * @description The Intercom provider.
 * 'boot' is called only on initial app render, providing the details of the logged in user.
 *  We hash the user id on the backend and send this to Intercom on 'boot', enabling their 'Identity Verification' as additional security.
 *  @custom_launcher_selector custom launcher is an element id, or class name (for multiple elements), which will trigger the Intercom messenger.
 *  Add this id or classname to an element to allow it to open the Intercom messenger.
 */
export const getHelpIntercomLauncherId = 'getHelpLoginPage';

type CompanyInfo = {
  // eslint-disable-next-line camelcase
  company_id: string;
  name: string;
  plan?: string;
  // eslint-disable-next-line camelcase
  is_company_supplier?: boolean;
};

const getCompanyInfo = (user: UserFragment): CompanyInfo | undefined => {
  if (user.linkedSupplier) {
    return {
      company_id: user.linkedSupplier.id,
      name: user.linkedSupplier.name,
      plan: user.linkedSupplier.accessPackage,
      // Custom attributes need to be added in quotation marks.
      // prettier-ignore
      "is_company_supplier": Boolean(user.linkedSupplier)
    };
  }

  const buyerEdgesCount = user.buyers?.edges.length;
  const currentBuyer = user.buyers?.edges[0]?.node;

  // We would like to send information about the buyers company
  // Only if the user has one company. If not we will not send any information
  if (buyerEdgesCount === 1 && currentBuyer) {
    return {
      company_id: currentBuyer.id,
      name: currentBuyer.name,
    };
  }

  return undefined;
};
const Intercom: FC = () => {
  const location = useLocation();
  const { loggedInUser, loading } = useLoggedInUser();
  const { getHelpWebSignIn } = useFlags();
  const { credentials, getCredentials } = useIntercomCredentials();

  const baseIntercomConfig = {
    app_id: INTERCOM_APP_ID,
    session_duration: 5 * 60 * 1000,
    hide_default_launcher: true,
    language_override: window.navigator.language,
  };

  useEffect(() => {
    // A user will be treated as an anonymous user before they are logged in.
    if (location.pathname === '/login' && getHelpWebSignIn) {
      window.Intercom('boot', {
        ...baseIntercomConfig,
        custom_launcher_selector: `#${getHelpIntercomLauncherId}`,
      });
    }

    if (!loading && Object.keys(loggedInUser).length && credentials) {
      window.Intercom('boot', {
        ...baseIntercomConfig,
        email: loggedInUser.email,
        user_id: loggedInUser.id,
        user_hash: credentials,
        name: loggedInUser.name,
        phone: loggedInUser.phone,
        custom_launcher_selector: '.getHelp',
        company: getCompanyInfo(loggedInUser),
        // prettier-ignore
        "isSupplier": loggedInUser.supplier
      });
    }
    // we don't want retrigger useEffect when location.pathname changes so it is omitted
    // eslint-disable-next-line
  }, [loggedInUser, loading, credentials]);

  useEffect(() => {
    if (!loading && Object.keys(loggedInUser).length) {
      getCredentials();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, loggedInUser]);

  // 'update' is called on every page change to update Intercom with browsing activity and changes in user details.
  useEffect(() => {
    if (!loading && Object.keys(loggedInUser).length) {
      window.Intercom('update');
    }
  }, [loading, location, loggedInUser]);

  return null;
};

export default Intercom;
