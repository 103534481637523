import React, { FC, createContext, useContext } from 'react';

import { unreadMessagesSubject } from '../../../portal/subjects';
import { exportChocoUnreadMessagesUtils } from '../../../portal/utils/window';

import { UnreadMessagesContextType } from './UnreadMessagesContext';
import UnreadMessagesFavicon from './UnreadMessagesFavicon';
import useUnreadMessagesReducer, {
  unreadMessagesReducer,
} from './useUnreadMessagesReducer';

type UnreadMessagesProviderProps = {
  children: React.ReactNode;
};

export const UnreadMessagesProvider: FC<UnreadMessagesProviderProps> = ({
  children,
}) => {
  const { state, actions } = useUnreadMessagesReducer(unreadMessagesReducer);

  exportChocoUnreadMessagesUtils(state, actions);
  unreadMessagesSubject.next(state);

  return (
    <>
      <UnreadMessagesFavicon unreadMessages={state} />
      <UnreadMessagesContext.Provider value={{ state, ...actions }}>
        {children}
      </UnreadMessagesContext.Provider>
    </>
  );
};

const UnreadMessagesContext = createContext<UnreadMessagesContextType | null>(
  null
);

export const useUnreadMessagesContext = (): UnreadMessagesContextType => {
  const context = useContext(UnreadMessagesContext);

  if (context === null) {
    throw new Error('useUnreadMessagesContext must be used within a Provider');
  }

  return context;
};
