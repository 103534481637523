import React, { useState, FC, createContext, useContext } from 'react';

import { SupportLoginContextType } from './SupportLoginContext';

export const SupportLoginProvider: FC = ({ children }) => {
  const [isSupportLogin, setIsSupportLogin] = useState(false);

  return (
    <SupportLoginContext.Provider
      value={{
        isSupportLogin,
        setIsSupportLogin,
      }}
    >
      {children}
    </SupportLoginContext.Provider>
  );
};

export const SupportLoginContext = createContext<SupportLoginContextType | null>(
  null
);

export const useIsSupportLogin = () => {
  const context = useContext(SupportLoginContext);

  if (context === null) {
    throw new Error('useSupportLogin must be used within a Provider');
  }

  return context;
};
