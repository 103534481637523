import { useToasts } from '@chocoapp/chocolate-ui';
import { useTranslation } from 'react-i18next';

export default function useOneTimePasswordFormDataError() {
  const { triggerErrorToast } = useToasts();
  const { t } = useTranslation('auth');

  return (translationKey: string) =>
    triggerErrorToast({ content: t(translationKey) });
}
