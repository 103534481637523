import { Flex, Avatar, Ellipsis } from '@chocoapp/chocolate-ui';
import { isNil } from '@chocoapp/toolbelt-utils';
import React, { FC } from 'react';

import { BuyerFragment } from '../../../../generated';
import { getNormalizedAssetURL } from '../../../../whiteLabel/utils';
import HiddenWhenCollapsed from '../HiddenWhenCollapsed';
import MenuTooltip from '../MenuTooltip';
import { SkeletonProfile } from '../SkeletonProfile';
import { wrapperStyles } from '../styles';

type BuyerLocationProps = {
  buyer: BuyerFragment | null | undefined;
};

// for now it's not going to be clickable
// in case of multi location, this will trigger the modal to select another location
const BuyerLocation: FC<BuyerLocationProps> = ({ buyer }) => {
  if (isNil(buyer)) return <SkeletonProfile />;

  const fallbackProfileURL = getNormalizedAssetURL(
    '/assortments/fallback_assortment_broadliner.webp'
  );
  const src = buyer.profileImage?.smallSquare ?? fallbackProfileURL;

  return (
    <MenuTooltip tooltipText={buyer.name}>
      <Flex data-testid="BuyerLocation" sx={wrapperStyles}>
        <Avatar
          size="s"
          src={src}
          id={buyer.id}
          name={buyer.name}
          data-testid="BuyerAvatar"
        />
        <HiddenWhenCollapsed>
          <Ellipsis variant="Subhead">{buyer.name}</Ellipsis>
          <Ellipsis title={buyer.fullAddress} variant="Caption">
            {buyer.fullAddress}
          </Ellipsis>
        </HiddenWhenCollapsed>
      </Flex>
    </MenuTooltip>
  );
};

export default BuyerLocation;
