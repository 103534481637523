import {
  IsUserInChatQueryVariables,
  useIsUserInChatQuery as useIsUserInChat,
} from '../generated';

export default function useIsUserInChatQuery({
  userId,
  chatId,
}: IsUserInChatQueryVariables) {
  const variables = {
    userId,
    chatId,
  };

  const { data, loading } = useIsUserInChat({
    variables,
    skip: !userId || !chatId,
  });

  const isUserInChat = data?.isUserInChat;

  return {
    isUserInChat,
    loading,
  };
}
