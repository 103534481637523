import { ChocoAsset, Flex } from '@chocoapp/chocolate-ui';
import React, { FC } from 'react';

import { getAssetsPrefix } from '../../../whiteLabel/utils';

const InviteHeader: FC = () => {
  return (
    <Flex
      sx={{
        width: '100%',
        justifyContent: 'space-between',
        display: ['block', 'none'],
        padding: 'xl',
      }}
    >
      <ChocoAsset
        width={157}
        height={89}
        prefix={getAssetsPrefix()}
        path="/brand/logo.svg"
      />
      <ChocoAsset
        width={74}
        height={78}
        prefix={getAssetsPrefix()}
        path="/illustrations/envelope.svg"
      />
    </Flex>
  );
};

export default InviteHeader;
