import {
  Flex,
  useButtonGroup,
  PopperOptions,
  Avatar,
} from '@chocoapp/chocolate-ui';
import { trackSegmentAction } from '@chocoapp/toolbelt-utils';
import React, { FC, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import mergeRefs from 'react-merge-refs';

import { AsyncI18n } from '..';
import { UserFragment } from '../../../generated';

import Options from './Options';
import { profileBarStyles, getProfileDetailsStyles } from './ProfileBarStyles';
import UserInfo from './UserInfo';

const popperOptions: PopperOptions = {
  placement: 'bottom-end',
  modifiers: [{ name: 'offset', options: { offset: [0, 4] } }],
};

type ProfileBarProps = {
  actualLoggedInUser: UserFragment;
};

const ProfileBar: FC<ProfileBarProps> = ({ actualLoggedInUser }) => {
  const { t } = useTranslation('settingsPage');

  const { userProfileImage, name, phone, id } = useMemo(
    () => ({
      id: actualLoggedInUser.id,
      userProfileImage: actualLoggedInUser?.profileImage?.smallSquare,
      phone: actualLoggedInUser.phone,
      name: actualLoggedInUser.name,
    }),
    [actualLoggedInUser]
  );

  const {
    targetRef,
    setReferenceElement,
    popperRef,
    setPopperElement,
    styles,
    attributes,
    ...toggleUtils
  } = useButtonGroup(popperOptions);

  const onClickProfileBar = useCallback(() => {
    toggleUtils.toggle();
    trackSegmentAction({
      event: 'globalUserProfile',
    });
  }, [toggleUtils]);

  return (
    <AsyncI18n>
      <Flex sx={profileBarStyles} data-testid="profile-bar">
        <Flex
          ref={mergeRefs([setReferenceElement, targetRef])}
          onClick={onClickProfileBar}
          sx={{ ...getProfileDetailsStyles(toggleUtils.state) }}
        >
          <UserInfo name={name} phone={phone} />
          <Avatar id={id} name={name} src={userProfileImage || undefined} />
        </Flex>

        {toggleUtils.state && (
          <Options
            ref={mergeRefs([setPopperElement, popperRef])}
            popperStyles={styles.popper}
            popperAttribute={attributes.popper}
            close={toggleUtils.off}
            t={t}
          />
        )}
      </Flex>
    </AsyncI18n>
  );
};

export default ProfileBar;
