import { IconName } from '@chocoapp/chocolate-ui';
import { Roles } from '@chocoapp/toolbelt-auth';

export type RoleI18nKeyPair = { role: Roles; i18nKey: string };

export type NavigationItemType = {
  route: string;
  iconName: IconName;
  testId: string;
  i18nKey: string;
  roleSpecificKey?: RoleI18nKeyPair;
  featureFlagKey?: string;
  displayWhen?: Roles[];
  denyListFeatureFlagKey?: string;
  trackEventName?: string;
  shouldHideForLegacyUsers?: boolean;
  isBeta?: boolean;
};

export const getTopNavigationItems = (
  flags: Record<string, string>
): NavigationItemType[] => [
  {
    route: '/inbox',
    iconName: 'chat',
    testId: 'globalNavigationInbox',
    i18nKey: 'inbox',
    roleSpecificKey: { role: Roles.BUYER, i18nKey: 'buyerInbox' },
  },
  {
    route: '/broadcast',
    iconName: 'megaphone',
    testId: 'globalNavigationBroadcast',
    i18nKey: 'broadcast',
    displayWhen: [Roles.SUPPLIER_ADMINS, Roles.SUPPLIER_SALES_REPS],
  },
  {
    route: `/orders/${flags.supplierWebReviewOrders ? 'pending' : 'accepted'}`,
    iconName: 'cart',
    testId: 'globalNavigationOrders',
    i18nKey: 'orders',
    shouldHideForLegacyUsers: true,
  },
  {
    route: '/team',
    iconName: 'customer',
    testId: 'globalNavigationTeam',
    i18nKey: 'team',
    featureFlagKey: 'webTeamManagement',
    displayWhen: [Roles.BUYER],
  },
  {
    route: '/contacts/customers',
    iconName: 'customer',
    testId: 'globalNavigationCustomers',
    i18nKey: 'customers',
    displayWhen: [Roles.SUPPLIER_ADMINS, Roles.SUPPLIER_SALES_REPS],
  },
  {
    route: '/catalog',
    iconName: 'products',
    testId: 'globalNavigationCatalog',
    i18nKey: 'catalog',
    displayWhen: [Roles.SUPPLIER_ADMINS, Roles.SUPPLIER_SALES_REPS],
  },
  {
    route: '/payments',
    iconName: 'payments',
    testId: 'globalNavigationPayments',
    i18nKey: 'payments',
    displayWhen: [Roles.SUPPLIER_ADMINS],
    featureFlagKey: 'supplierPaymentsTabWeb',
    isBeta: true,
  },
  {
    route: '/reporting',
    iconName: 'statistics',
    testId: 'globalNavigationReporting',
    i18nKey: 'reporting',
    displayWhen: [Roles.BUYER],
    featureFlagKey: 'buyerWebReporting',
    isBeta: false,
  },
  {
    route: '/inventory-v2',
    iconName: 'inventory',
    testId: 'globalNavigationInventoryV2',
    i18nKey: 'inventory',
    displayWhen: [Roles.BUYER],
    featureFlagKey: 'buyerInventoryManagementV2',
  },
];
