import { useAuthContext } from '../../../auth';
import getQueryString from '../../../hooks/getQueryString';

export const addPhoneNumberQuery = (phoneNumber: string | null): string => {
  if (phoneNumber) {
    return `?phoneNumber=${encodeURIComponent(phoneNumber)}`;
  }
  return '';
};

export const usePhoneNumber = (): {
  phoneNumber: string;
  cognitoPhoneNumber: string;
} => {
  const { cognitoUser } = useAuthContext();

  const cognitoPhoneNumber = cognitoUser?.getUsername() ?? '';

  const phoneNumber = getQueryString('phoneNumber') || cognitoPhoneNumber;

  return {
    phoneNumber,
    cognitoPhoneNumber,
  };
};
