import {
  Box,
  ChocoAsset,
  Link,
  Text,
  ThemeUIStyleObject,
  theme,
} from '@chocoapp/chocolate-ui';
import { trackSegmentAction, trackSegmentPage } from '@chocoapp/toolbelt-utils';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { AuthLayoutBody } from '../auth/AuthLayoutBody';
import { useHistoryPush } from '../router';
import {
  WHITE_LABEL_I18N_NAMESPACE,
  getWhiteLabelI18nNamespace,
} from '../whiteLabel/getWhiteLabelI18nNamespace';
import { getAssetsPrefix } from '../whiteLabel/utils';

const linkStyles: ThemeUIStyleObject = {
  width: '100%',
};

const cardStyles: ThemeUIStyleObject = {
  display: 'grid',
  gridTemplateColumns: 'max-content 1fr',
  gap: 'm',
  backgroundColor: theme.colors.neutralBg,
  mb: 'l',
  p: 'm',
  alignItems: 'center',
  color: 'initial',
};

const illustrationStyles: ThemeUIStyleObject = {
  p: 'l',
};

export const CreateOrJoinBusinessPage = () => {
  const { t } = useTranslation(['auth', WHITE_LABEL_I18N_NAMESPACE]);
  const { namedHistoryPush } = useHistoryPush();

  useEffect(() => {
    trackSegmentPage({
      name: 'create_or_join',
      category: 'create_or_join',
      props: {
        source: 'create_profile',
      },
    });
  }, []);

  const handleClickCreateBusiness = () => {
    trackSegmentAction({
      event: 'create_buyer_selected',
      data: {
        source: 'create_profile',
      },
    });
    namedHistoryPush({ to: 'auth:create-business' });
  };

  const handleClickJoinBusiness = () => {
    trackSegmentAction({
      event: 'join_buyer_selected',
      data: {
        source: 'create_profile',
      },
    });
    namedHistoryPush({ to: 'auth:join-business' });
  };

  return (
    <AuthLayoutBody backButton>
      <Text variant="H4" sx={{ mb: 'm' }}>
        {t('createOrJoin.title')}
      </Text>
      <Text sx={{ mb: 'm' }}>
        {t('createOrJoin.description', {
          ns: getWhiteLabelI18nNamespace('auth'),
        })}
      </Text>
      <Link sx={linkStyles} href="/create-business">
        <Box
          data-testid="CreateBusinessButton"
          sx={cardStyles}
          onClick={handleClickCreateBusiness}
        >
          <ChocoAsset
            alt="chefsHat"
            sx={illustrationStyles}
            prefix={getAssetsPrefix()}
            path="/illustrations/chefsHat.svg"
          />
          <Box>
            <Text variant="BodyStrong">
              {t('createOrJoin.create.title', {
                ns: getWhiteLabelI18nNamespace('auth'),
              })}
            </Text>
            <Text sx={{ color: theme.colors.neutralTextWeak }}>
              {t('createOrJoin.create.description')}
            </Text>
          </Box>
        </Box>
      </Link>
      <Link sx={linkStyles} href="/join-business">
        <Box
          data-testid="JoinBusinessButton"
          sx={cardStyles}
          onClick={handleClickJoinBusiness}
        >
          <ChocoAsset
            alt="knifeStroke"
            prefix={getAssetsPrefix()}
            path="/illustrations/knife.svg"
          />
          <Box>
            <Text variant="BodyStrong">{t('createOrJoin.join.title')}</Text>
            <Text sx={{ color: theme.colors.neutralTextWeak }}>
              {t('createOrJoin.join.description', {
                ns: getWhiteLabelI18nNamespace('auth'),
              })}
            </Text>
          </Box>
        </Box>
      </Link>
    </AuthLayoutBody>
  );
};
