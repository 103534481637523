import { AuthenticationDetails, CognitoUser } from 'amazon-cognito-identity-js';

import { SIGN_UP_PASSWORD } from '../constants/AppConstants';

export const createConfirmSignUpHandler = ({
  cognitoUser,
  currentPhoneNumber,
}: {
  cognitoUser: CognitoUser | null;
  currentPhoneNumber?: string;
}) => {
  return (oneTimePassword: string, userId: string) =>
    new Promise<void>((resolve, reject) => {
      cognitoUser!.confirmRegistration(
        oneTimePassword,
        false,
        confirmationError => {
          if (confirmationError) {
            reject(confirmationError);
          } else {
            const authDetails = new AuthenticationDetails({
              Username: currentPhoneNumber!,
              Password: SIGN_UP_PASSWORD,
            });

            cognitoUser?.setAuthenticationFlowType('USER_PASSWORD_AUTH');

            cognitoUser!.authenticateUser(authDetails, {
              onFailure(authenticationError) {
                reject(authenticationError);
              },
              onSuccess() {
                resolve();
              },
            });
          }
        },
        {
          signUpVersion: '2.0.0',
          signUpClient: 'choco-app',
          user: JSON.stringify({ id: userId }),
        }
      );
    });
};
