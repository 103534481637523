import { Flex, Avatar, Flyout } from '@chocoapp/chocolate-ui';
import { trackSegmentAction } from '@chocoapp/toolbelt-utils';
import React, { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { UserFragment } from '../../../../../generated';
import { AsyncI18n } from '../../../AsyncI18n';
import MenuTooltip from '../../MenuTooltip';
import { SkeletonProfile } from '../../SkeletonProfile';
import { wrapperStyles } from '../../styles';

import ProfileOptions from './ProfileOptions';
import UserInfo from './UserInfo';

type UserProfileProps = {
  actualLoggedInUser: UserFragment;
};

const UserProfile: FC<UserProfileProps> = ({ actualLoggedInUser }) => {
  const { t } = useTranslation('settingsPage');

  const onClickLoggedInUser = useCallback(() => {
    trackSegmentAction({
      event: 'globalUserProfile',
    });
  }, []);

  if (!Object.keys(actualLoggedInUser).length) return <SkeletonProfile />;

  return (
    <AsyncI18n>
      <MenuTooltip tooltipText={actualLoggedInUser.name!}>
        <Flyout
          data-testid="UserProfile"
          placement="right-end"
          contentOffset={{ x: 0, y: 0 }}
          onClickTrigger={onClickLoggedInUser}
          triggerElement={
            <Flex data-testid="UserProfile" sx={wrapperStyles}>
              <Avatar
                id={actualLoggedInUser.id}
                size="s"
                name={actualLoggedInUser.name}
                src={actualLoggedInUser?.profileImage?.smallSquare}
                data-testid="UserAvatar"
              />
              <UserInfo
                name={actualLoggedInUser.name}
                phone={actualLoggedInUser.phone}
              />
            </Flex>
          }
        >
          {({ closePopup }) => <ProfileOptions t={t} closePopup={closePopup} />}
        </Flyout>
      </MenuTooltip>
    </AsyncI18n>
  );
};

export default UserProfile;
