import { trackSegmentAction, trackSegmentPage } from '@chocoapp/toolbelt-utils';
import { useEffect } from 'react';

import { useAuthContext } from '../../../auth';
import { useUserDataQuery } from '../../../data';
import { AccessPackage, UserFragment } from '../../../generated';

type TackPaywallProps = {
  name: string;
  props?: Record<string, string>;
};

type TrackPremiumEventProps = {
  event: string;
  data?: Record<string, unknown>;
};

export const getIsUserTrial = (user: UserFragment): boolean => {
  return user.linkedSupplier?.accessPackage === AccessPackage.Trial;
};

export const useTrackPaywall = ({
  name,
  props,
}: TackPaywallProps): (({ event, data }: TrackPremiumEventProps) => void) => {
  const { getLoggedInUserIdFromCognito } = useAuthContext();
  const loggedInUserId = getLoggedInUserIdFromCognito();
  const { user, loading } = useUserDataQuery(loggedInUserId);

  const isTrial = getIsUserTrial(user);
  // we return this function here to prevent having to
  // extract the logic for "isTrial" in all places
  const trackEvent = ({ event, data }: TrackPremiumEventProps): void => {
    trackSegmentAction({
      event,
      data: {
        ...data,
        is_trial: isTrial,
      },
    });
  };

  useEffect(() => {
    if (!loading && name) {
      trackSegmentPage({
        category: 'Paywall',
        name,
        props: {
          is_trial: isTrial,
          ...props,
        },
      });
    }
  }, [loading, name, isTrial, props]);

  return trackEvent;
};
