import { setSupportLoginPhone } from '@chocoapp/toolbelt-utils';
import { FC } from 'react';
import { useLocation } from 'react-router-dom';

import { useHistoryPush } from '../router';

const SupportLoginPage: FC = () => {
  const { namedHistoryPush } = useHistoryPush();
  const location = useLocation();

  if (location?.pathname === `/support-login`) {
    const params = new URLSearchParams(location.search);
    const phoneNumber = params.get('phoneNumber')?.trim() ?? '';

    setSupportLoginPhone(`+${phoneNumber}`);
    namedHistoryPush({ to: 'auth:logout' });
  }

  return null;
};

export default SupportLoginPage;
