import { ApolloLink } from '@apollo/client';

import { isWhiteLabel } from '../whiteLabel/utils';

export const createWhiteLabelLink = () => {
  const whiteLabelLink = new ApolloLink((operation, forward) => {
    const shouldNotAddHeaders =
      !isWhiteLabel() || !window.choco?.assets?.prefix;

    if (shouldNotAddHeaders) {
      return forward(operation);
    }

    operation.setContext(({ headers = {} }) => ({
      headers: {
        ...headers,
        'x-choco-app-bundle-id': window.choco?.assets?.prefix ?? '',
      },
    }));

    return forward(operation);
  });

  return whiteLabelLink;
};
