import gql from 'graphql-tag';

export default gql`
  fragment NotificationFragment on Notification {
    __typename
    id
    userId
    notificationDirectives {
      suppressInboxCountUpdate
    }
    message {
      ...NotificationMessageFragment
    }
  }

  fragment NotificationMessageFragment on Message {
    id
    body
    user {
      id
    }
    order {
      id
      body
      deliveryDate
      orderProducts {
        amount
        product {
          id
        }
      }
    }
    chat {
      id
      buyer {
        id
      }
    }
  }
`;
