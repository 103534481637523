import { AsYouTypeFormatter } from 'google-libphonenumber';

import { CountryCode } from '../../components/commons/Login/CountryCode';

import { getDialingCodeFromCountryCode } from './getCountryCodeFromDialingCode';

export const removePrefix = (value: string, prefix: string) => {
  return value.startsWith(prefix) ? value.slice(prefix.length) : value;
};

export const hasCountryCodeInPhoneNumber = (phoneNumber: string) =>
  phoneNumber.trim().startsWith('+');

export const prepareNumberForGooglePhoneLib = (
  countryCode: CountryCode,
  phoneNumber: string
) => {
  const normalizedNumber = phoneNumber.trim();

  const hasUserTypedCountryCode = hasCountryCodeInPhoneNumber(phoneNumber);
  const countryDialingCode = getDialingCodeFromCountryCode(countryCode);
  const hasLeadingZeroes = normalizedNumber.startsWith('0');

  // to correctly format a phone number, googlephonelib requires it to either
  // contain a leading zero (0), or the country dialing code (+XX) in the front.
  // in case both are missing, we temporarily append the country code, but it
  // will be removed later
  if (!hasUserTypedCountryCode && !hasLeadingZeroes) {
    return `${countryDialingCode}${normalizedNumber}`;
  }

  return normalizedNumber;
};

export const formatPartialPhoneNumber = (
  countryCode: CountryCode,
  rawPhoneNumber: string
) => {
  const countryDialingCode = getDialingCodeFromCountryCode(countryCode);
  const hasUserTypedCountryCode = hasCountryCodeInPhoneNumber(rawPhoneNumber);
  const normalizedNumber = prepareNumberForGooglePhoneLib(
    countryCode,
    rawPhoneNumber
  );

  // remove any non numbers, split string into an array of individual digits, ignoring spaces
  const digits = normalizedNumber
    .replace(/(?!^\+)[\D+]/g, '')
    .split('')
    .filter(digit => digit.trim());

  let formattedNumber = '';

  const formatter = new AsYouTypeFormatter(countryCode);
  digits.forEach(digit => {
    formattedNumber = formatter.inputDigit(digit);
  });

  // only keep the country code in the phone number in case the user
  // explicitly typed that into the input
  return hasUserTypedCountryCode
    ? formattedNumber.trim()
    : removePrefix(formattedNumber, countryDialingCode).trim();
};
