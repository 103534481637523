import {
  setLocalStorageItem,
  getLocalStorageItem,
} from '@chocoapp/toolbelt-utils';
import React, {
  createContext,
  useContext,
  ReactNode,
  useMemo,
  useState,
  useCallback,
} from 'react';

import { EnvironmentKey } from '../environments/types';
import { isProduction } from '../environments/utils';

export const LS_ENV = 'ChocoSelectedEnvironment';

type ActionsType = {
  environment: EnvironmentKey;
  setEnvironment: (newEnvironment: EnvironmentKey) => void;
};

const EnvironmentSelectorActions = createContext<ActionsType | null>(null);

export const EnvironmentSelectorProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const lsEnvironment: EnvironmentKey | null = getLocalStorageItem(
    LS_ENV
  ) as EnvironmentKey;

  const [environment, setLocalEnvironment] = useState<EnvironmentKey>(
    isProduction ? 'choprd' : lsEnvironment || 'chostg'
  );

  const setEnvironment = useCallback((newEnvironment: EnvironmentKey) => {
    setLocalStorageItem(LS_ENV, newEnvironment);
    setLocalEnvironment(newEnvironment);
  }, []);

  const providerValue: ActionsType = useMemo(
    () => ({
      environment,
      setEnvironment,
    }),
    [environment, setEnvironment]
  );

  return (
    <EnvironmentSelectorActions.Provider value={providerValue}>
      {children}
    </EnvironmentSelectorActions.Provider>
  );
};

export const useEnvironmentSelectorProvider = (): ActionsType => {
  const actions = useContext(EnvironmentSelectorActions);
  if (actions === null) {
    throw new Error(
      'useEnvironmentSelectorProvider must be used within a Provider'
    );
  }

  return actions;
};
