import {
  Box,
  Button,
  ChocoIcon,
  ChocoModal,
  ChocoModalActionBar,
  ChocoModalContainer,
  Text,
} from '@chocoapp/chocolate-ui';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { LS_PREMIUM_REQUESTED_KEY } from '../../../constants/AppConstants';
import { GoPremium } from '../Paywall/GoPremium';
import { RequestPremium } from '../Paywall/RequestPremium';
import { RequestReceived } from '../Paywall/RequestReceived';

type PaywallModalProps = {
  isOpen: boolean;
  onCloseModal: () => void;
};

type PaywallScreens = 'goPremium' | 'requestPremium' | 'requestReceived';

export const PaywallModal = ({ isOpen, onCloseModal }: PaywallModalProps) => {
  const { t } = useTranslation('getInTouch');

  const premiumAlreadyRequested =
    localStorage.getItem(LS_PREMIUM_REQUESTED_KEY) === 'true';

  const [currentScreen, setCurrentScreen] = useState<PaywallScreens>(
    premiumAlreadyRequested ? 'requestReceived' : 'goPremium'
  );

  const handleBackClick = () => {
    setCurrentScreen('goPremium');
  };

  const handleLetsTalkRedirect = () => {
    setCurrentScreen('requestPremium');
  };

  const handleSuccessRedirect = () => {
    setCurrentScreen('requestReceived');
  };

  return (
    <ChocoModal isOpen={isOpen} size="small" onClose={onCloseModal}>
      <ChocoModalContainer width="540px">
        <ChocoModalActionBar closeButtonTestId={`paywall-modal-close-button`} />
        {currentScreen === 'requestPremium' && (
          <Button
            sx={{
              p: 0,
              pt: 'xxs',
              position: 'absolute',
              '&> div': {
                justifyContent: 'flex-start',
              },
            }}
            variant="tertiary"
            onClick={handleBackClick}
          >
            <ChocoIcon name="arrowLeft" />
            <Text variant="Subhead">{t('requestPremiumScreen.back')}</Text>
          </Button>
        )}
        <Box sx={{ textAlign: 'center' }}>
          {currentScreen === 'goPremium' && (
            <GoPremium onLetsTalkRedirect={handleLetsTalkRedirect} />
          )}
          {currentScreen === 'requestPremium' && (
            <RequestPremium onSuccessRedirect={handleSuccessRedirect} />
          )}
          {currentScreen === 'requestReceived' && <RequestReceived />}
        </Box>
      </ChocoModalContainer>
    </ChocoModal>
  );
};
