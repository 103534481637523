import { useAuthContext } from '../auth';
import { useUserDataQuery } from '../data';

export const useLoggedInUser = () => {
  const { getLoggedInUserIdFromCognito } = useAuthContext();
  const loggedInUserId = getLoggedInUserIdFromCognito();
  const { user: loggedInUser, loading } = useUserDataQuery(loggedInUserId);

  return {
    loggedInUser,
    loading,
  };
};
