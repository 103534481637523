import React, { FC, useContext } from 'react';

import { UserFragment } from '../generated';

type LoggedInUserContextType = {
  user: UserFragment;
  isLoading: boolean;
};
const LoggedInUserContext = React.createContext<LoggedInUserContextType | null>(
  null
);

type LoggedInUserProviderProps = {
  user: UserFragment;
  isLoading: boolean;
};

export const LoggedInUserProvider: FC<LoggedInUserProviderProps> = ({
  user,
  isLoading,
  children,
}) => {
  return (
    <LoggedInUserContext.Provider value={{ user, isLoading }}>
      {children}
    </LoggedInUserContext.Provider>
  );
};

export const useLoggedInUserContext = (): LoggedInUserContextType => {
  const context = useContext(LoggedInUserContext);
  if (context === null) {
    throw new Error('useLoggedInUserContext must be used within a Provider');
  }

  return context;
};
