export const REACT_APP_AWS_COGNITO_REGION =
  process.env.REACT_APP_AWS_COGNITO_REGION!;
export const REACT_APP_AWS_COGNITO_USER_POOL_ID =
  process.env.REACT_APP_AWS_COGNITO_USER_POOL_ID!;
export const REACT_APP_AWS_COGNITO_CLIENT_ID =
  process.env.REACT_APP_AWS_COGNITO_CLIENT_ID!;
export const REACT_APP_AWS_COGNITO_USER_POOL_ID_V2 =
  process.env.REACT_APP_AWS_COGNITO_USER_POOL_ID_V2!;
export const REACT_APP_AWS_COGNITO_CLIENT_ID_V2 =
  process.env.REACT_APP_AWS_COGNITO_CLIENT_ID_V2!;
export const REACT_APP_COGNITO_PROXY_URL = `https://${process.env.REACT_APP_COGNITO_PROXY_URL}`;
export const REACT_APP_AWS_APP_SYNC_URL =
  process.env.REACT_APP_AWS_APP_SYNC_URL!;
export const REACT_APP_AWS_APP_SYNC_REGION =
  process.env.REACT_APP_AWS_APP_SYNC_REGION!;
export const REACT_APP_IMAGE_URL_PREFIX =
  process.env.REACT_APP_IMAGE_URL_PREFIX!;
export const REACT_APP_LOGO_SVG = process.env.REACT_APP_LOGO_SVG!;
export const REACT_APP_LOGO_VIEW_BOX = process.env.REACT_APP_LOGO_VIEW_BOX!;
export const REACT_APP_OPEN_ID_JWT_TOKEN =
  process.env.REACT_APP_OPEN_ID_JWT_TOKEN!;
export const REACT_APP_GTM_CONTAINER_ID =
  process.env.REACT_APP_GTM_CONTAINER_ID!;
export const REACT_APP_LAUNCH_DARKLY_CLIENT_SIDE_ID =
  process.env.REACT_APP_LAUNCH_DARKLY_CLIENT_SIDE_ID!;
export const REACT_APP_IMPORT_MAP_STAGE =
  process.env.REACT_APP_IMPORT_MAP_STAGE!;
export const REACT_APP_NODE_ENV = process.env.REACT_APP_NODE_ENV!;
export const REACT_APP_ENV_PREFIX = process.env.REACT_APP_ENV_PREFIX!;
export const REACT_APP_VERSION_COMMIT_SHA =
  process.env.REACT_APP_VERSION_COMMIT_SHA!;
export const REACT_APP_IMPORT_MAP_OVERRIDES_ENDPOINT =
  'https://cdn.jsdelivr.net/npm/import-map-overrides@2.4.2/dist/import-map-overrides.js';
export const REACT_APP_IPSTACK_API_KEY = process.env.REACT_APP_IPSTACK_API_KEY!;
export const COMMIT_SHA = process.env.REACT_APP_COMMIT_SHA!;
export const REACT_APP_SIGNUP_URL = process.env.REACT_APP_SIGNUP_URL!;
export const INVITE_LINK_BASE_URL = process.env.REACT_APP_INVITE_LINK_BASE_URL;
export const INTERCOM_APP_ID = process.env.REACT_APP_INTERCOM_APP_ID;
export const DATA_DOG_APPLICATION_ID =
  process.env.REACT_APP_DATA_DOG_APPLICATION_ID!;
export const DATA_DOG_CLIENT_TOKEN =
  process.env.REACT_APP_DATA_DOG_CLIENT_TOKEN!;
export const DATA_DOG_ENV = process.env.REACT_APP_DATA_DOG_ENV!;
export const DATA_DOG_SITE = 'datadoghq.eu';
export const REACT_APP_DATA_DOG_SERVICE_NAME = 'choco-web';
