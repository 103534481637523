import {
  i18nextFactoryOptions,
  i18nextFactory,
} from '@chocoapp/toolbelt-utils/lib/i18nextConfig';

import { REACT_APP_VERSION_COMMIT_SHA } from '../config';

export const i18nConfig: i18nextFactoryOptions = {
  version: REACT_APP_VERSION_COMMIT_SHA,
  supportedLocales: [
    'en',
    'en-GB',
    'cs',
    'de',
    'es',
    'fr',
    'nl',
    'pl',
    'pt', // LEGACY: no longer officially supported, but still used
    'ru', // LEGACY: no longer officially supported, but still used
  ],
  aliases: {
    'en-US': 'en',
    'pt-BR': 'pt',
    'nl-BE': 'nl',
  },
};

export default i18nextFactory(i18nConfig);
