import React, { FC } from 'react';
import Favicon from 'react-favicon';

import { getNormalizedAssetURL, isWhiteLabel } from '../../../whiteLabel/utils';

import {
  UnreadMessagesStateType,
  UnreadMessagesDataType,
} from './UnreadMessagesContext';

const FAVICON = 'favicon';

const totalCountReducer = (acc: number, prev: number) => acc + prev;

export const createFavIconUrl = (
  unreadMessages: UnreadMessagesStateType
): string => {
  if (isWhiteLabel()) {
    return getNormalizedAssetURL('/brand/favicon.png');
  }

  const unreadValues: number[] = Object.values(unreadMessages || {}).map(
    (item: UnreadMessagesDataType | undefined) => item?.count || 0
  );
  const unreadTotal = unreadValues.reduce(totalCountReducer, 0);
  const faviconWithMsgCount = `${FAVICON}_${
    unreadTotal > 9 ? '9plus' : unreadTotal
  }`;

  const favicon = unreadTotal ? faviconWithMsgCount : FAVICON;
  return `/${favicon}.png`;
};

const UnreadMessagesFavicon: FC<{
  unreadMessages: UnreadMessagesStateType;
}> = ({ unreadMessages }) => {
  const favIconUrl = createFavIconUrl(unreadMessages);
  return <Favicon url={favIconUrl} />;
};

export default UnreadMessagesFavicon;
