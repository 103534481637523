import { Box } from '@chocoapp/chocolate-ui';
import { trackSegmentAction } from '@chocoapp/toolbelt-utils';
import { TFunction } from 'i18next';
import React, { useCallback } from 'react';

import OptionLink from './OptionLink';
import {
  optionStyles,
  lastOptionStyles,
  ButtonGroupStyles,
} from './OptionsStyles';

type OptionsProps = {
  popperStyles: React.CSSProperties;
  popperAttribute:
    | {
        [key: string]: string;
      }
    | undefined;
  close: () => void;
  t: TFunction;
};

const Options = React.forwardRef<HTMLDivElement, OptionsProps>(
  ({ popperStyles, popperAttribute, close, t }, ref) => {
    const trackLogout = useCallback(() => {
      close();
      trackSegmentAction({ event: 'logoutButton-logout' });
    }, [close]);

    return (
      <Box
        ref={ref}
        style={popperStyles}
        {...popperAttribute}
        sx={ButtonGroupStyles}
      >
        <OptionLink
          to="/settings/user-profile"
          sx={optionStyles}
          translationKey="userProfile.header"
          t={t}
          onClick={close}
        />
        <OptionLink
          to="/logout"
          sx={lastOptionStyles}
          translationKey="logout"
          t={t}
          onClick={trackLogout}
        />
      </Box>
    );
  }
);

export default Options;
