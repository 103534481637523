import React, { Suspense, FC, ReactNode } from 'react';

type AsyncTranslation = {
  children: ReactNode;
  fallback?: ReactNode;
};

const AsyncTranslation: FC<AsyncTranslation> = ({
  children,
  fallback = '',
}) => <Suspense fallback={fallback}>{children}</Suspense>;

export default AsyncTranslation;
