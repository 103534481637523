import React, { FC, useEffect } from 'react';

import ConfirmOrderErrorPage from '../ConfirmOrderErrorPage';
import WebLitePage from '../WebLitePage';

import { useLoadingConfirmationOrderContext } from './LoadingConfirmationOrder/LoadingConfirmationOrderProvider';
import useConfirmOrderForChocoLite from './useConfirmOrderForChocoLite';

const ConfirmOrderInLite: FC = () => {
  const { isLoading, setIsLoading } = useLoadingConfirmationOrderContext();
  const { confirmOrderForChocoLite, hasErrors } = useConfirmOrderForChocoLite();

  useEffect(() => {
    const confirmOrder = async () => {
      await confirmOrderForChocoLite();
    };

    confirmOrder();
  }, [confirmOrderForChocoLite, setIsLoading]);

  if (hasErrors) return <ConfirmOrderErrorPage />;

  if (isLoading) return null;

  return <WebLitePage />;
};

export default ConfirmOrderInLite;
