import { Text, Flex } from '@chocoapp/chocolate-ui';
import { isNil, removeSupportLoginPhone } from '@chocoapp/toolbelt-utils';
import React, { useCallback, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { SingleOTPInput } from '../components/commons/SingleOTPInput';
import { useBeforeUnload } from '../hooks';
import { ensureValueWithinRange } from '../util/ensureWithinRange';

import { AuthLayoutBody } from './AuthLayoutBody';
import {
  AUTH_CODE_PHONE_LENGTH,
  AuthMethod,
  TwoFactorMethod,
} from './createInitiateCustomAuthHandler';
import {
  trackLoginFailure,
  trackViewOneTimePasswordPage,
} from './trackingEvents';
import { useSubmitOneTimePassword } from './useSubmitOneTimePassword';

export type OneTimePasswordPageState = {
  digits: number;
  twoFactorMethod: TwoFactorMethod;
  codeSentTo: string;
  method?: AuthMethod;
  userId?: string;
};

const OneTimePasswordForm = ({
  isConfirmSignUp,
}: {
  isConfirmSignUp?: boolean;
}) => {
  const { t } = useTranslation('auth');
  const { state } = useLocation<
    Partial<OneTimePasswordPageState> | undefined
  >();
  const [focusIndex, setFocusIndex] = useState(0);

  const codeSentTo = state?.codeSentTo ?? '';
  const digits = state?.digits ?? AUTH_CODE_PHONE_LENGTH;
  const twoFactorMethod = state?.twoFactorMethod ?? 'phone';

  const [otp, setOtp] = useState<string[]>(new Array(digits).fill(''));
  const [hasError, setHasError] = useState(false);

  const onErrorHandler = useCallback(() => {
    trackLoginFailure(twoFactorMethod);

    setOtp(new Array(digits).fill(''));
    setHasError(true);
    setFocusIndex(0);
  }, [digits, twoFactorMethod]);

  const { submitOtp, loading } = useSubmitOneTimePassword({
    onError: onErrorHandler,
    isConfirmSignUp,
    userId: state?.userId,
  });

  const updateOtp = useCallback(
    (newOtp: string[] | ((currentValue: string[]) => string[])) => {
      const ensureLength = typeof newOtp === 'function' ? newOtp(otp) : newOtp;

      const finalOtp = ensureLength.slice(0, digits);

      setOtp(finalOtp);
      setHasError(false);

      const currentCode = finalOtp.join('').trim();
      if (currentCode.length === digits)
        submitOtp(currentCode, twoFactorMethod);
    },
    [twoFactorMethod, digits, otp, submitOtp]
  );

  const onSetFocusIndex = useCallback(
    (newIndex: number | undefined, method: 'absolute' | 'relative') => {
      if (isNil(newIndex)) return;

      setFocusIndex(currentIndex => {
        const newAttemptedIndex =
          method === 'absolute' ? newIndex : currentIndex + newIndex;

        return ensureValueWithinRange(newAttemptedIndex, 0, digits - 1);
      });
    },
    [digits]
  );

  useEffect(() => {
    trackViewOneTimePasswordPage(twoFactorMethod);
  }, [twoFactorMethod]);

  useBeforeUnload(() => {
    removeSupportLoginPhone();
  });

  return (
    <AuthLayoutBody backButton>
      <Flex sx={{ flexDirection: 'column', gap: 'm' }}>
        <Text variant="H4">{t('loginV2.oneTimePassword.title')}</Text>
        <Text>
          <Trans
            t={t}
            i18nKey="loginV2.oneTimePassword.subtitle"
            values={{ phoneNumber: codeSentTo }}
          >
            Enter the code sent to
            <Text as="span" variant="BodyStrong">
              {codeSentTo}
            </Text>
          </Trans>
        </Text>

        <Flex sx={{ flexDirection: 'column', gap: 'm', minHeight: '240px' }}>
          <Flex sx={{ gap: 's' }}>
            {otp.map((_, index) => (
              <SingleOTPInput
                key={index}
                value={otp[index] ?? ''}
                index={index}
                hasFocus={index === focusIndex}
                changeFocusIndex={onSetFocusIndex}
                hasError={hasError}
                disabled={loading}
                otp={otp}
                setOtp={updateOtp}
              />
            ))}
          </Flex>

          {hasError && (
            <Text sx={{ color: 'dangerText' }}>
              {t('loginV2.oneTimePassword.incorrectCode')}
            </Text>
          )}

          {loading && (
            <Text sx={{ color: 'baseText' }}>
              {t('oneTimePassword.loading')}
            </Text>
          )}
        </Flex>
      </Flex>
    </AuthLayoutBody>
  );
};

export { OneTimePasswordForm };
