import { Box } from '@chocoapp/chocolate-ui';
import { PlacePrediction } from '@chocoapp/google-places';
import React, { FC } from 'react';

import { NoPredictions } from './NoPredictions';
import { Prediction } from './Prediction';

type PredictionsProps = {
  predictions: PlacePrediction[];
  selectPrediction: (prediction: PlacePrediction, index: number) => void;
  predictionsListRef: React.MutableRefObject<HTMLDivElement | null>;
};

export const Predictions: FC<PredictionsProps> = ({
  predictions,
  selectPrediction,
  predictionsListRef,
}) => (
  <Box
    sx={{ height: '100%', width: '100%', overflow: 'auto' }}
    ref={predictionsListRef}
  >
    {predictions.length ? (
      predictions.map((prediction, index) => (
        <Prediction
          key={prediction.place_id}
          selectPrediction={(p: google.maps.places.AutocompletePrediction) =>
            selectPrediction(p, index)
          }
          prediction={prediction}
          index={index}
        />
      ))
    ) : (
      <NoPredictions />
    )}
  </Box>
);
