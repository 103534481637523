import { Box, ThemeUIStyleObject } from '@chocoapp/chocolate-ui';
import { keyframes } from '@emotion/react';
import React, { FC } from 'react';

import { useCollapsedContext } from './useCollapsedContext';

const animation = keyframes`
    0% {
      opacity: 0;
      visibility: hidden;
    }
    100% {
      opacity: 1;
      visibility: visible;
    }`;

const expandedStyles: ThemeUIStyleObject = {
  animation: `${animation} 200ms ease-in`,
};

const HiddenWhenCollapsed: FC = ({ children }) => {
  const { isCollapsed } = useCollapsedContext();

  return isCollapsed ? null : (
    <Box data-testid="Element_expanded" sx={expandedStyles}>
      {children}
    </Box>
  );
};

export default HiddenWhenCollapsed;
