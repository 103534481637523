import { Box, Flex, Link, Text, useToasts } from '@chocoapp/chocolate-ui';
import { trackSegmentAction } from '@chocoapp/toolbelt-utils';
import { QRCodeCanvas } from 'qrcode.react';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { getNormalizedAssetURL } from '../../../whiteLabel/utils';

import TextToClipboard from './TextToClipboard';

type InviteBodyProps = {
  referralLink: string;
  referralCode: string;
};

const InviteBody: FC<InviteBodyProps> = ({ referralLink, referralCode }) => {
  const { t } = useTranslation('inviteFallbackPage');
  const { triggerSuccessToast } = useToasts();

  const ChocoLogo = getNormalizedAssetURL('/brand/logo-circle.svg');

  const referralLinkWithoutProtocol = referralLink?.replace(/^https?:\/\//, '');

  const onCopyReferralLink = () => {
    trackSegmentAction({
      event: 'Web Fallback Link Copied',
      data: {
        referral_code: referralCode,
      },
    });

    triggerSuccessToast({
      content: t('linkCopied'),
    });
  };

  return (
    <Flex
      sx={{
        textAlign: 'center',
        padding: 'xl',
        rowGap: 'm',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <Text variant="H4">{t('yourInvitedToOrderOnChoco')}</Text>
      <Text>{t('youNedAndroidOrIOS')}</Text>
      <Text>{t('scanQrCodeBelow')}</Text>
      <Flex
        sx={{
          justifyContent: 'center',
          border: '4px solid',
          borderColor: 'baseBorderStrong',
          padding: 'l',
        }}
      >
        <QRCodeCanvas
          value={referralLink}
          size={180}
          fgColor="#18003F"
          level="H"
          imageSettings={{
            src: ChocoLogo,
            height: 56,
            width: 56,
            excavate: true,
          }}
          data-testid="qrCodeModal-qrCode"
        />
      </Flex>
      <Text>{t('orOpenLinkMobileDevice')}</Text>
      <TextToClipboard
        value={referralLink}
        data-testid="inviteTextToClipboard"
        onCopy={onCopyReferralLink}
      >
        {referralLinkWithoutProtocol}
      </TextToClipboard>
      <Box sx={{ mt: 's' }}>
        <Link
          href="https://www.choco.com"
          data-testid="learnMoreLink"
          variant="Body"
          sx={{
            textDecoration: 'underline',
          }}
        >
          {t('learnMoreAboutChoco')}
        </Link>
      </Box>
    </Flex>
  );
};

export default InviteBody;
