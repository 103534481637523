import { useCallback, useState } from 'react';

import { confirmOrderMutation } from '../../data/confirmOrderMutation';
import { getDecodedProductListJwtToken } from '../../hooks';
import getQueryString from '../../hooks/getQueryString';
import { retryWithProgressiveDelay } from '../../util/retryWithDelay';

import { useLoadingConfirmationOrderContext } from './LoadingConfirmationOrder/LoadingConfirmationOrderProvider';

const getConfirmOrderParams = (): Parameters<
  typeof confirmOrderMutation
>[0] => {
  const { orderId } = getDecodedProductListJwtToken<{ orderId?: string }>();
  const token = getQueryString('token');
  const transmissionChannel = getQueryString('transmissionChannel');

  return {
    // TODO(markus): handle orderId being undefined
    orderId: orderId!,
    token,
    transmissionChannel,
    source: transmissionChannel,
  };
};

const confirmOrderAttempt = async (): Promise<void> => {
  const confirmOrderParams = getConfirmOrderParams();
  await confirmOrderMutation(confirmOrderParams);
};

const useConfirmOrderForChocoLite = () => {
  const { setIsLoading } = useLoadingConfirmationOrderContext();
  const [hasErrors, setHasErrors] = useState<boolean>(false);

  const confirmOrderForChocoLite = useCallback(async () => {
    const confirmOrderWithRetry = retryWithProgressiveDelay(
      confirmOrderAttempt
    );

    confirmOrderWithRetry({
      retryExceededError: `OrderConfirm: exhausted all retries; URL: ${window.location.href}`,
      delay: 250,
      maxRetryCount: 2,
      finalErrorCallback: () => {
        setHasErrors(true);
        setIsLoading(false);
      },
      successCallback: () => {
        setIsLoading(false);
      },
    });
  }, [setIsLoading]);

  return { confirmOrderForChocoLite, hasErrors };
};

export default useConfirmOrderForChocoLite;
