import {
  Box,
  ChocoInputFormField,
  ChocoSearch,
  ThemeUIStyleObject,
  theme,
  useClickOutside,
} from '@chocoapp/chocolate-ui';
import {
  useGooglePlaces,
  PlacePrediction,
  maybeAddGooglePlacesScriptTag,
} from '@chocoapp/google-places';
import { trackSegmentAction, trackSegmentPage } from '@chocoapp/toolbelt-utils';
import debounce from 'lodash.debounce';
import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { GOOGLE_MAPS_API_KEY } from '../../../constants/AppConstants';
import { useCreateOrJoinBusinessContext } from '../../../data/providers/CreateOrJoinBusinessContextProvider';
import { WorkplaceFormData } from '../WorkplaceForm/workplaceFormSchema';

import { Predictions } from './Predictions';

const dropdownStyles: ThemeUIStyleObject = {
  background: theme.colors.neutralBg,
  boxShadow: theme.shadows.dropdown,
};

const GooglePlacesList = ({
  setOpen,
}: {
  setOpen: Dispatch<SetStateAction<boolean>>;
}) => {
  const { t } = useTranslation('auth');
  const { pathname } = useLocation();
  const { setValue, getValues } = useFormContext<WorkplaceFormData>();
  const [searchTerm, setSearchTerm] = useState(getValues('address') || '');
  const [hasAlreadyFetchedOnceCheck, setHasAlreadyFetchedOnceCheck] =
    useState(false);

  const { setSelectedGooglePlaceDetails } = useCreateOrJoinBusinessContext();

  const {
    predictions,
    fetchPlacePredictions,
    predictionsListRef,
    fetchPlaceDetails,
  } = useGooglePlaces({
    onPlaceDetailsRequestSuccess: (placeDetails) => {
      setValue('address', placeDetails.formatted_address, {
        shouldDirty: true,
        shouldValidate: true,
      });
      setSelectedGooglePlaceDetails(placeDetails);
    },
  });

  const type = pathname.includes('create-business') ? 'create' : 'join';

  useEffect(() => {
    if (hasAlreadyFetchedOnceCheck === true && predictions.length === 0) {
      trackSegmentAction({
        event: 'empty_search',
        data: {
          search_term: searchTerm,
          source: 'create_profile',
          type,
        },
      });
    }
  }, [predictions, searchTerm, type, hasAlreadyFetchedOnceCheck]);

  const handleFetchPlacePredictions = useCallback(
    async (value: string) => {
      trackSegmentAction({
        event: 'search_submitted',
        data: {
          search_term: value,
          source: 'create_profile',
          type,
        },
      });
      fetchPlacePredictions({ searchTerm: value, placeTypes: ['address'] });
      setHasAlreadyFetchedOnceCheck(true);
    },
    [type, fetchPlacePredictions]
  );

  const debouncedHandleFetchPlacePredictions = debounce((value: string) => {
    handleFetchPlacePredictions(value);
  }, 400);

  const handleSearch = (value: string) => {
    setSearchTerm(value);
    debouncedHandleFetchPlacePredictions(value);
  };

  const handleSelectPridiction = async (
    prediction: PlacePrediction,
    index: number
  ) => {
    trackSegmentAction({
      event: 'address_selected',
      data: {
        search_term: searchTerm,
        source: 'create_profile',
        type,
        rank: index,
      },
    });
    await fetchPlaceDetails({
      placeId: prediction.place_id,
      fieldsToFetch: [
        'name',
        'formatted_address',
        'geometry',
        'place_id',
        'address_components',
      ],
    });
    setOpen(false);
  };

  useEffect(() => {
    if (searchTerm) {
      handleFetchPlacePredictions(searchTerm);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    trackSegmentPage({
      name: 'delivery_address',
      category: 'delivery_address',
      props: {
        source: 'create_profile',
        type,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box sx={dropdownStyles} data-testid="GooglePredictionsList">
      <ChocoSearch
        data-testid="GooglePlaceSearchField"
        autoFocus={true}
        handleSearch={handleSearch}
        placeholder={t('createOrJoin.workplace.deliveryAddress.search')}
        searchTerm={searchTerm}
      />
      <Predictions
        predictionsListRef={predictionsListRef}
        predictions={predictions}
        selectPrediction={handleSelectPridiction}
      />
    </Box>
  );
};

export const BusinessAddressField = () => {
  const { t } = useTranslation('auth');
  const { pathname } = useLocation();
  const boxRef = useRef<HTMLDivElement>(null);
  const [open, setOpen] = useState(false);
  const { errors, register } = useFormContext<WorkplaceFormData>();

  useClickOutside([boxRef], () => {
    setOpen(false);
  });

  useMemo(() => {
    maybeAddGooglePlacesScriptTag({ apiKey: GOOGLE_MAPS_API_KEY });
  }, []);

  const handleOnFocus = () => {
    const type = pathname.includes('create-business') ? 'create' : 'join';
    trackSegmentAction({
      event: 'delivery_address_selected',
      data: {
        source: 'create_profile',
        type,
      },
    });
    setOpen(true);
  };

  return (
    <Box ref={boxRef} sx={{ position: 'relative' }}>
      <ChocoInputFormField
        ref={register}
        id="address"
        name="address"
        isRequired={true}
        labelText={t('createOrJoin.workplace.deliveryAddress.label')}
        errorMessage={errors.address?.message}
        placeholder={t('createOrJoin.workplace.deliveryAddress.placeholder')}
        onFocus={handleOnFocus}
        readOnly={true}
        data-testid="DeliveryAddressField"
      />
      {open && <GooglePlacesList setOpen={setOpen} />}
    </Box>
  );
};
