import { isDateExpired } from '@chocoapp/toolbelt-utils';
import React from 'react';

import { useAuthContext } from '../../auth';
import { getDecodedProductListJwtToken } from '../../hooks';
import { useIsUserInChat } from '../../hooks/useIsUserInChat';
import useTappedOrderTransmissionLink from '../../hooks/useTappedOrderTransmissionLink';
import WebLitePage from '../WebLitePage';

import ConfirmOrder from './ConfirmOrder';
import ConfirmOrderInLite from './ConfirmOrderInLite';
import { useLoadingConfirmationOrderContext } from './LoadingConfirmationOrder/LoadingConfirmationOrderProvider';

const OrderEmailConfirmation = () => {
  const { setIsLoading } = useLoadingConfirmationOrderContext();
  const { isAuthenticated } = useAuthContext();
  const { exp } = getDecodedProductListJwtToken<{
    exp?: number;
  }>();

  useTappedOrderTransmissionLink('confirmOrder');

  const { isUserInChat, loading } = useIsUserInChat();

  if (loading) return null;

  if (isAuthenticated() && isUserInChat) {
    return <ConfirmOrder />;
  }

  // TODO(markus): handle exp being undefined
  const expiredToken = isDateExpired(exp!);

  if (expiredToken) {
    setIsLoading(false);
    return <WebLitePage />;
  }

  return <ConfirmOrderInLite />;
};

export default OrderEmailConfirmation;
