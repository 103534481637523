import { useIntercomCredentialsQuery } from '../data/useIntercomCredentialsQuery';

/**
 * @returns credentials undefined on unsuccessful response
 */
export const useIntercomCredentials = () => {
  const {
    userIntercomCredentialsResponse: response,
    getCredentials,
  } = useIntercomCredentialsQuery();

  return {
    credentials:
      response?.__typename === 'UserIntercomCredentialsSuccess'
        ? response.webHash
        : undefined,
    getCredentials,
  };
};
