import React from 'react';

import { LoadingConfirmationOrderProvider } from './OrderEmailConfirmation/LoadingConfirmationOrder/LoadingConfirmationOrderProvider';
import OrderEmailConfirmation from './OrderEmailConfirmation/OrderEmailConfirmation';

const OrderConfirmationPage = () => {
  return (
    <LoadingConfirmationOrderProvider>
      <OrderEmailConfirmation />
    </LoadingConfirmationOrderProvider>
  );
};

export default OrderConfirmationPage;
