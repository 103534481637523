import { useFlags } from '@chocoapp/launchdarkly-ui';
import { trackSegmentAction } from '@chocoapp/toolbelt-utils';
import React, { useState } from 'react';

import { useAuthContext } from '../auth';
import { GoogleMapModal } from '../components/commons/GoogleMapModal/GoogleMapModal';
import {
  GoogleMapsPosition,
  searchBuyersToGMPOsition,
} from '../components/commons/GoogleMapModal/GoogleMapsPosition';
import { extractAddressDetails } from '../components/commons/GooglePlacesFormField/extractAddressDetails';
import { WorkplaceForm } from '../components/commons/WorkplaceForm/WorkplaceForm';
import { useUserDataQuery } from '../data';
import { useCreateOrJoinBusinessContext } from '../data/providers/CreateOrJoinBusinessContextProvider';
import { useCreateBuyerCreateDataMutation } from '../data/useBuyerCreateMutation';
import { useRequestToJoinBuyerDataMutation } from '../data/useRequestToJoinBuyerMutation';
import {
  AddressSource,
  BuyerCreateInput,
  BuyerCreatedContext,
  BuyerFragment,
} from '../generated';
import { useHistoryPush } from '../router';

export const CreateBusinessPage = () => {
  const { automateClaimBuyer } = useFlags();
  const [isOpen, setIsOpen] = useState(false);

  const [locations, setLocations] = useState<GoogleMapsPosition[]>([]);

  const { namedHistoryPush } = useHistoryPush();
  const { getLoggedInUserIdFromCognito } = useAuthContext();
  const loggedInUserId = getLoggedInUserIdFromCognito();
  const { user } = useUserDataQuery(loggedInUserId);

  const { createBuyer, loading } = useCreateBuyerCreateDataMutation();

  const {
    requestToJoinBuyer,
    loading: loadingRequestToJoin,
  } = useRequestToJoinBuyerDataMutation();

  const {
    formValues,
    selectedGooglePlaceDetails: placeDetails,
  } = useCreateOrJoinBusinessContext();

  const handleCreateBuyer = async () => {
    if (placeDetails) {
      const addressDetails = extractAddressDetails(placeDetails);

      const input: BuyerCreateInput = {
        displayName: formValues?.name ?? '',
        addressSource: AddressSource.Custom,
        isTest: false,
        createdContext: BuyerCreatedContext.OrganicSignUp,
        ...addressDetails,
      };

      await createBuyer(input);

      if (user.hasAcceptedTermsAndConditions) {
        namedHistoryPush({ to: 'main' });
      } else {
        namedHistoryPush({ to: 'auth:terms-and-conditions' });
      }
    }
  };

  const handleOnSearchBuyerSuccess = async (buyers: BuyerFragment[]) => {
    // Auto create Buyer when no existing buyer found
    if (buyers.length === 0) {
      await handleCreateBuyer();
      return;
    }

    // Open Modal for Maps click through
    const buyerLocations = searchBuyersToGMPOsition(buyers);
    setLocations(buyerLocations);
    setIsOpen(true);
  };

  const handleConfirmLocation = async (buyerId: string) => {
    if (automateClaimBuyer) {
      const { success } = await requestToJoinBuyer(buyerId);
      if (success) {
        trackSegmentAction({
          event: 'join_buyer_auto_approved',
          data: {
            source: 'create_profile',
          },
        });
        namedHistoryPush({ to: 'auth:terms-and-conditions' });
        return;
      }
    }
    namedHistoryPush({ to: 'auth:almost-there' });
  };

  const handleRejectAllLocations = () => {
    // Auto create Buyer when no existing buyer was matched
    handleCreateBuyer();
  };

  return (
    <>
      <WorkplaceForm
        onSearchBuyerSuccess={handleOnSearchBuyerSuccess}
        loading={loading}
      />
      <GoogleMapModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        rejectAllLocations={handleRejectAllLocations}
        confirmLocation={handleConfirmLocation}
        locations={locations}
        loading={loadingRequestToJoin}
      />
    </>
  );
};
