import {
  UnreadMessagesStateType,
  UnreadMessagesDataType,
} from './UnreadMessagesContext';
import { useUnreadMessagesContext } from './UnreadMessagesProvider';

const totalCountReducer = (acc: number, prev: number) => acc + prev;
const showUnreadMessage = (unreadMessages: UnreadMessagesStateType) => {
  const unreadValues: number[] = Object.values(unreadMessages || {}).map(
    (item: UnreadMessagesDataType | undefined) => item?.count || 0
  );

  return unreadValues.reduce(totalCountReducer, 0);
};

export const useUnreadMessagesCount = (): number => {
  const { state: unreadMessages } = useUnreadMessagesContext();
  return showUnreadMessage(unreadMessages);
};
