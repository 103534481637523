import { useEffect, useRef } from 'react';

type CallbackType = {
  current?: () => void;
};

function useInterval(callback: () => void, delay: number | undefined) {
  const savedCallback: CallbackType = useRef();

  useEffect(() => {
    savedCallback.current = callback;
  });

  useEffect(() => {
    const tick = () => savedCallback.current && savedCallback.current();
    const id = setInterval(tick, delay);
    return () => clearInterval(id);
  }, [delay]);
}

export default useInterval;
