import {
  Box,
  ChocoStyleIcon,
  Flex,
  Text,
  ThemeUIStyleObject,
} from '@chocoapp/chocolate-ui';
import { PlacePrediction } from '@chocoapp/google-places';
import React, { FC } from 'react';

const predictionStyles: ThemeUIStyleObject = {
  cursor: 'pointer',
  px: 'm',
  py: 'xs',
  width: '100%',

  ':focus, :hover': {
    bg: 'neutralBgMedium',
  },
};

type PredictionProps = {
  selectPrediction: (prediction: PlacePrediction) => void;
  prediction: PlacePrediction;
  index: number;
};

export const Prediction: FC<PredictionProps> = ({
  selectPrediction,
  prediction,
  index,
}) => (
  <Box sx={predictionStyles}>
    <Flex
      sx={{ alignItems: 'center', gap: 'm' }}
      data-testid="handlePlaceSelect"
      tabIndex={index}
      onClick={() => {
        selectPrediction(prediction);
      }}
    >
      <ChocoStyleIcon name="pin" />
      <Flex sx={{ flexDirection: 'column', width: '100%' }}>
        <Text>{prediction.structured_formatting.main_text}</Text>
        <Text sx={{ color: 'neutralTextStrongDisabled' }}>
          {prediction.structured_formatting.secondary_text}
        </Text>
      </Flex>
    </Flex>
  </Box>
);
