import { useReducer, useCallback } from 'react';

import { ObjectWithType } from '../../../util';

import {
  UnreadMessagesStateType,
  IncreaseActionType,
  ResetActionType,
  ClearAllActionType,
  UnreadMessagesActions,
} from './UnreadMessagesContext';

export const UNREAD_MESSAGES_KEY = 'chocoUnreadChatMessagesV1';

export const unreadMessagesReducer = (
  state: UnreadMessagesStateType,
  {
    type,
    payload,
  }: {
    type: UnreadMessagesActions;
    payload: ObjectWithType<any>;
  }
) => {
  switch (type) {
    case UnreadMessagesActions.increase: {
      const { chatId, messageId } = payload;
      const prevCount = state[chatId!]?.count || 0;
      const prevUnreadMessageId = state[chatId!]?.firstUnreadMessageId;

      const firstUnreadMessageId = !prevCount ? messageId : prevUnreadMessageId;
      const unreadInfo = {
        [chatId!]: {
          count: prevCount + 1,
          firstUnreadMessageId,
        },
      };

      const newState = { ...state, ...unreadInfo };

      localStorage.setItem(UNREAD_MESSAGES_KEY, JSON.stringify(newState));
      return newState;
    }

    case UnreadMessagesActions.resetCount: {
      const { chatId } = payload;
      if (!state[chatId!]) return state;

      const newChat = { ...state[chatId], count: 0 };
      const newState = { ...state, [chatId]: newChat };
      localStorage.setItem(UNREAD_MESSAGES_KEY, JSON.stringify(newState));
      return newState;
    }

    case UnreadMessagesActions.reset: {
      const { chatId } = payload;
      if (!state[chatId!]) return state;

      const { [chatId!]: omit, ...newState } = state;
      localStorage.setItem(UNREAD_MESSAGES_KEY, JSON.stringify(newState));
      return newState;
    }

    case UnreadMessagesActions.clearAll: {
      localStorage.removeItem(UNREAD_MESSAGES_KEY);
      return {};
    }

    default:
      return state;
  }
};

type UnreadMessagesReturn = {
  state: UnreadMessagesStateType;
  actions: Record<
    UnreadMessagesActions,
    any | IncreaseActionType | ResetActionType | ClearAllActionType
  >;
};

export type UnreadMessagesReducerType = (
  state: UnreadMessagesStateType,
  {
    type,
    payload: { chatId, messageId },
  }: {
    type: UnreadMessagesActions;
    payload: ObjectWithType<any>;
  }
) => UnreadMessagesStateType;

export default function useUnreadMessagesReducer(
  reducer: UnreadMessagesReducerType
): UnreadMessagesReturn {
  const initialState: UnreadMessagesStateType = JSON.parse(
    localStorage.getItem(UNREAD_MESSAGES_KEY) || '{}'
  );
  const [state, dispatch] = useReducer(reducer, initialState);

  const increase = useCallback(
    (chatId, messageId) =>
      dispatch({
        type: UnreadMessagesActions.increase,
        payload: { chatId, messageId },
      }),
    [dispatch]
  );

  const reset = useCallback(
    chatId =>
      dispatch({ type: UnreadMessagesActions.reset, payload: { chatId } }),
    [dispatch]
  );

  const resetCount = useCallback(
    chatId =>
      dispatch({ type: UnreadMessagesActions.resetCount, payload: { chatId } }),
    [dispatch]
  );

  const clearAll = useCallback(
    () => dispatch({ type: UnreadMessagesActions.clearAll, payload: {} }),
    [dispatch]
  );

  return { state, actions: { increase, reset, clearAll, resetCount } };
}
