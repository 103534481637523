import { useEffect, useRef, useState, useCallback } from 'react';

const useMFEContentLoaded = () => {
  const elementRef = useRef<HTMLDivElement>(null);
  const [contentLoaded, setContentLoaded] = useState(false);

  const callback = useCallback((mutationsList: any, observer: any) => {
    mutationsList.forEach((mutation: any) => {
      if (mutation.type === 'childList') {
        setContentLoaded(true);
        observer.disconnect();
      }
    });
  }, []);

  useEffect(() => {
    if (elementRef.current) {
      const observer = new MutationObserver(callback);
      observer.observe(elementRef.current, { childList: true });
    }
  }, [callback]);

  return { ref: elementRef, contentLoaded };
};

export default useMFEContentLoaded;
