import {
  Box,
  Flex,
  Text,
  ChocoIcon,
  IconName,
  ThemeUIStyleObject,
} from '@chocoapp/chocolate-ui';
import { useFlags } from '@chocoapp/launchdarkly-ui';
import { Permissions, Roles, usePermissions } from '@chocoapp/toolbelt-auth';
import { isNil, trackSegmentAction } from '@chocoapp/toolbelt-utils';
import { Global } from '@emotion/react';
import styled from '@emotion/styled';
import React, { FC, Fragment, ReactNode, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, matchPath, useLocation } from 'react-router-dom';

import { UnreadMessages, useUnreadMessagesContext } from '..';
import { useLoggedInUserContext } from '../../../store/LoggedInUserProvider';
import { isWhiteLabel, whiteLabelFilter } from '../../../whiteLabel/utils';
import { AsyncI18n } from '../AsyncI18n';
import { BetaTag } from '../GlobalNavigationV2/BetaTag';
import { EnvironmentNavItem } from '../GlobalNavigationV2/Footer/EnvironmentNavItem';
import GetHelp from '../GlobalNavigationV2/Footer/GetHelp';
import { CollapsedProvider } from '../GlobalNavigationV2/useCollapsedContext';
import {
  UnreadMessagesDataType,
  UnreadMessagesStateType,
} from '../UnreadMessages/UnreadMessagesContext';

import {
  RoleI18nKeyPair,
  getTopNavigationItems,
  NavigationItemType,
} from './navigation';

const StyledNavigationLink = styled(NavLink)`
  position: relative;
  text-decoration: none;
  display: block;
  align-items: center;
  flex-flow: column;
  height: 60px;
  border-radius: 6px;
  margin-top: 6px;
  margin-bottom: 6px;
  &.active,
  &:hover,
  &:focus {
    background: ${({ theme }) => theme.colors.neutralBgMedium};
  }
`;

type GlobalNavigationItemProps = {
  route: string;
  iconName: IconName;
  children: ReactNode;
  testId: string;
  enabled?: boolean;
  trackEventName?: string;
  isBeta?: boolean;
};

const totalCountReducer = (acc: number, prev: number) => acc + prev;
const showUnreadMessage = (unreadMessages: UnreadMessagesStateType) => {
  const unreadValues: number[] = Object.values(unreadMessages || {}).map(
    (item: UnreadMessagesDataType | undefined) => item?.count || 0
  );

  return unreadValues.reduce(totalCountReducer, 0);
};

const globalNavigationItemStyles: ThemeUIStyleObject = {
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  gap: 'xxs',
  padding: '2px',
  width: '100%',
  height: '100%',
  color: 'neutralTextWeak',
  textAlign: 'center',
};

const GlobalNavigationItem: FC<GlobalNavigationItemProps> = ({
  route,
  iconName,
  children,
  testId,
  enabled,
  trackEventName,
  isBeta,
}) => {
  const { state: unreadMessages } = useUnreadMessagesContext();
  const count = showUnreadMessage(unreadMessages);

  const { pathname } = useLocation();
  const isActive = matchPath(pathname, route);

  const onNavItemClick = useCallback(() => {
    if (!trackEventName) return;
    trackSegmentAction({
      event: trackEventName,
    });
  }, [trackEventName]);

  return (
    <Fragment>
      {enabled && (
        <StyledNavigationLink to={route} onClick={onNavItemClick}>
          <Flex as="span" sx={globalNavigationItemStyles}>
            <ChocoIcon
              name={`${iconName}${isActive ? 'Filled' : ''}` as IconName}
            />
            {isBeta ? (
              <BetaTag />
            ) : (
              <Text variant="Caption" data-testid={testId}>
                {children}
              </Text>
            )}
          </Flex>
          {route === '/inbox' && Boolean(count) && (
            <UnreadMessages count={count} />
          )}
        </StyledNavigationLink>
      )}
    </Fragment>
  );
};

type GlobalNavigationItemPermissionProps = {
  children: ReactNode;
  displayWhen?: Roles[];
};

const GlobalNavigationItemPermission: FC<
  GlobalNavigationItemPermissionProps
> = ({ displayWhen, children }) => {
  return displayWhen?.length ? (
    <Permissions displayWhen={displayWhen}>{children}</Permissions>
  ) : (
    <Fragment>{children}</Fragment>
  );
};

const useIsVisibleNavigationItem = () => {
  const { isBuyer, isSupplier, enableWithRoles } = usePermissions();
  const {
    user: { linkedSupplier },
    isLoading,
  } = useLoggedInUserContext();

  return ({ shouldHideForLegacyUsers }: NavigationItemType) => {
    if (shouldHideForLegacyUsers) {
      return (
        isLoading ||
        isBuyer ||
        (isSupplier &&
          !isNil(linkedSupplier) &&
          enableWithRoles([Roles.SUPPLIER_ADMINS, Roles.SUPPLIER_SALES_REPS]))
      );
    }
    return true;
  };
};

type NavigationItemTextProps = {
  defaultI18nKey: string;
  roleSpecificKey?: RoleI18nKeyPair;
};

const NavigationItemText: FC<NavigationItemTextProps> = ({
  defaultI18nKey,
  roleSpecificKey,
}) => {
  const { t } = useTranslation('globalNavigation');
  const { enableWithRoles } = usePermissions();
  const i18nKey =
    !isNil(roleSpecificKey) && enableWithRoles([roleSpecificKey.role])
      ? roleSpecificKey.i18nKey
      : defaultI18nKey;

  return <>{t(`navigation.${i18nKey}`)}</>;
};

const GlobalTopNavigation: FC = () => {
  const flags = useFlags();
  const isVisibleNavigationItem = useIsVisibleNavigationItem();
  const topNavigationItems = getTopNavigationItems(flags);
  const { isSupplier } = usePermissions();

  return (
    <Box sx={{ alignSelf: 'flex-start', width: '100%' }}>
      {topNavigationItems
        .filter(
          (navigationItem) => !flags[navigationItem.denyListFeatureFlagKey!]
        )
        .filter(isVisibleNavigationItem)
        .filter(whiteLabelFilter(isSupplier, isWhiteLabel()))
        .map((navigationItem) => (
          <GlobalNavigationItemPermission
            key={`navigationItem_${navigationItem.testId}`}
            displayWhen={navigationItem.displayWhen}
          >
            <GlobalNavigationItem
              route={navigationItem.route}
              iconName={navigationItem.iconName}
              testId={navigationItem.testId}
              enabled={flags[navigationItem.featureFlagKey!] ?? true}
              trackEventName={navigationItem.trackEventName}
              isBeta={navigationItem.isBeta}
            >
              <NavigationItemText
                defaultI18nKey={navigationItem.i18nKey}
                roleSpecificKey={navigationItem.roleSpecificKey}
              />
            </GlobalNavigationItem>
          </GlobalNavigationItemPermission>
        ))}
    </Box>
  );
};

const useRoute = () => {
  const { isSupplier } = usePermissions();

  if (isSupplier) {
    return '/settings';
  }

  return '/settings/user-profile';
};

const GlobalBottomNavigation: FC = () => {
  const { t } = useTranslation('globalNavigation');
  const route = useRoute();

  return (
    <Box sx={{ alignSelf: 'flex-end', width: '100%' }}>
      <EnvironmentNavItem />
      <GetHelp variant="legacy" />
      <GlobalNavigationItem
        route={route}
        iconName="cog"
        testId="globalNavigationSettings"
        enabled
        trackEventName="settings"
      >
        {t('navigation.settings')}
      </GlobalNavigationItem>
    </Box>
  );
};

const GlobalNavigationMenu: FC = () => {
  return (
    <Fragment>
      <GlobalTopNavigation />
      <GlobalBottomNavigation />
    </Fragment>
  );
};

const globalNavigationContainerStyles: ThemeUIStyleObject = {
  width: 'auto',
  padding: '8px',
  position: 'relative',
  borderRight: '1px solid',
  borderColor: 'neutralBorder',
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignItems: 'center',
  background: 'neutralBg',
  minWidth: '100px',
};

const GlobalNavigation: FC = () => {
  return (
    <Flex data-testid="globalNavigation" sx={globalNavigationContainerStyles}>
      <AsyncI18n>
        <CollapsedProvider>
          <GlobalNavigationMenu />
          {/*  Adding this because some micro front ends need to know about the current width of the sidebar
          Exposing this as a css custom property is the easiest way to access it any where in the app */}
          <Global
            styles={{
              ':root': {
                '--navigation-width': '100px',
              },
            }}
          />
        </CollapsedProvider>
      </AsyncI18n>
    </Flex>
  );
};

export default GlobalNavigation;
