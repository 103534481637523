import { formatDate } from '@chocoapp/toolbelt-utils';
import { TFunction } from 'i18next';
import { DateTime } from 'luxon';

import { NotificationMessageFragment } from '../../generated';
import { ReactRouterHistoryPush } from '../../util';
import { NativeNotificationType } from '../../util/Notification';
import {
  createMessageNotification,
  createOrderNotification,
} from '../../util/notifications';

type getNotificationCallback = (options: {
  message: NotificationMessageFragment;
  t: TFunction;
  historyPush: ReactRouterHistoryPush;
  metaLobbyEnabled?: boolean;
}) => NativeNotificationType;

export const getMessageNotification: getNotificationCallback = ({
  message,
  t,
  historyPush,
  metaLobbyEnabled,
}) => {
  const translations = { title: t('message.title', { message }) };

  return createMessageNotification(
    message,
    translations,
    historyPush,
    metaLobbyEnabled
  );
};

export const getOrderNotification: getNotificationCallback = ({
  message,
  t,
  historyPush,
  metaLobbyEnabled,
}) => {
  const time = message.order?.deliveryDate ?? null;
  const deliveryDate = time
    ? formatDate(time, 'seconds', DateTime.DATE_FULL, 'utc')
    : '';
  const orderProducts = message.order?.orderProducts ?? [];

  const totalAmountOfProducts = orderProducts.reduce(
    (accumulator, current) => accumulator + (current?.amount ?? 0),
    0
  );

  const translations = {
    title: t('order.title', { message }),
    deliveryDate: t('order.deliveryDate', { message, deliveryDate }),
    orderProducts: t('order.orderProducts', { totalAmountOfProducts }),
  };

  return createOrderNotification(
    message,
    translations,
    historyPush,
    metaLobbyEnabled
  );
};
