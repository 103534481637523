import { Flex, Text } from '@chocoapp/chocolate-ui';
import React from 'react';

import {
  EnvironmentKey,
  EnvironmentProps,
} from '../../../auth/environments/types';

import { emojiStyles } from './styles';

type EnvironmentItemProps = {
  envName: EnvironmentKey;
  envItem: EnvironmentProps;
};

export const EnvironmentItem = ({ envName, envItem }: EnvironmentItemProps) => (
  <Flex data-testid={envName} sx={{ alignItems: 'center', gap: 'xs' }}>
    <Flex sx={emojiStyles}>{envItem.emoji}</Flex>
    <Text variant="BodyStrong">{envName}</Text>
  </Flex>
);
