import { PhoneNumberUtil } from 'google-libphonenumber';

import { getDialingCodeFromCountryCode } from '.';

const phoneUtil = PhoneNumberUtil.getInstance();

/**
 * Try to get the country dialing code from a phoneNumber, even if it's still incomplete.
 */
export const getCountryDialingCodeFromPhoneNumber = (
  phoneNumber: string,
  fallbackCountryCode: string
) => {
  const fallBackDialingCode = getDialingCodeFromCountryCode(
    fallbackCountryCode
  );

  try {
    const number = phoneUtil.parse(phoneNumber);

    return number.getCountryCode()
      ? `+${number.getCountryCode()}`
      : fallBackDialingCode;
  } catch {
    return fallBackDialingCode;
  }
};
