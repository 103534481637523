import { trackSegmentPage } from '@chocoapp/toolbelt-utils';
import React, { useEffect } from 'react';

import { LoginForm } from '../auth/LoginForm';
import { AsyncI18n } from '../components';

const LoginPage = () => {
  useEffect(() => {
    trackSegmentPage({
      name: 'user_verification',
      category: 'user_verification',
    });
  }, []);
  return (
    <AsyncI18n>
      <LoginForm />
    </AsyncI18n>
  );
};

export default LoginPage;
