import { useToasts } from '@chocoapp/chocolate-ui';
import { trackSegmentAction } from '@chocoapp/toolbelt-utils';
import { yupResolver } from '@hookform/resolvers/yup';
import { PhoneNumberUtil } from 'google-libphonenumber';
import { useEffect } from 'react';
import { useForm, UseFormMethods } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import { AppLogger } from '../../../application/logger';
import { useAuthContext } from '../../../auth';
import {
  LS_PREMIUM_REQUESTED_KEY,
  LS_PREMIUM_REQUESTED_NUMBER_KEY,
} from '../../../constants/AppConstants';
import { useUserDataQuery } from '../../../data';
import { useSupplierGetInTouchMutation } from '../../../generated';
import { useHistoryPush } from '../../../router';

import { getIsUserTrial } from './useTrackPremiumScreen';
import { usePhoneNumber } from './utils';

const pnu = PhoneNumberUtil.getInstance();

const validatePhoneNumber = (number?: string): boolean => {
  if (!number) return false;

  const parsedNumber = pnu.parse(number);
  return pnu.isValidNumber(parsedNumber);
};

const useValidationSchema = () => {
  const { t } = useTranslation('getInTouch');

  const validationSchema = yup.object({
    email: yup
      .string()
      .email(t('requestPremiumScreen.emailInvalid'))
      .required(t('requestPremiumScreen.emailMandatory')),
    phoneNumber: yup
      .string()
      .required(t('requestPremiumScreen.numberMandatory'))
      .test(
        'phoneNumber',
        t('requestPremiumScreen.numberInvalid'),
        validatePhoneNumber
      ),
    role: yup.string().nullable(true),
  });

  return { validationSchema };
};

type GoPremiumFormData = {
  email: string;
  phoneNumber: string;
  role: string | null;
};

type UseGoPremiumFormReturnType = {
  formUtils: UseFormMethods<GoPremiumFormData>;
  loading: boolean;
  onSubmit: (data: GoPremiumFormData) => Promise<void>;
  phoneNumber: string;
};

export const useGoPremiumForm = (
  onSuccessRedirect?: () => void
): UseGoPremiumFormReturnType => {
  const { validationSchema } = useValidationSchema();
  const { getLoggedInUserIdFromCognito } = useAuthContext();

  const { phoneNumber, cognitoPhoneNumber } = usePhoneNumber();

  const loggedInUserId = getLoggedInUserIdFromCognito();
  const { user, loading: loadingUser } = useUserDataQuery(loggedInUserId);

  const { historyPush } = useHistoryPush();

  const { t } = useTranslation('getInTouch');
  const { triggerErrorToast } = useToasts();

  const [supplierGetInTouchMutation, { loading }] =
    useSupplierGetInTouchMutation();

  const formUtils = useForm<GoPremiumFormData>({
    resolver: yupResolver(validationSchema),
    reValidateMode: 'onChange',
    defaultValues: {
      email: user.email || '',
      phoneNumber,
      role: null,
    },
  });

  useEffect(() => {
    // we need to manually set the email in case the user navigates directly
    // to "/request-premium" and the user is not in the Graphql cache yet
    // useForm only sets initial value once!
    const emailValue = formUtils.getValues().email;
    if (!loadingUser && user.email && !emailValue) {
      formUtils.setValue('email', user.email);
    }
  }, [loadingUser, user, formUtils]);

  const onSubmit = async (data: GoPremiumFormData): Promise<void> => {
    const isTrial = getIsUserTrial(user);

    const formattedData: {
      email: string;
      phoneNumber: string;
      role?: string;
    } = {
      email: data.email,
      phoneNumber: data.phoneNumber,
      ...(data.role && { role: data.role }),
    };

    trackSegmentAction({
      event: 'Paywall Send Request Clicked',
      data: {
        supplier_role: formattedData.role,
        is_trial: isTrial,
      },
    });

    try {
      await supplierGetInTouchMutation({ variables: { input: formattedData } });

      localStorage.setItem(LS_PREMIUM_REQUESTED_NUMBER_KEY, cognitoPhoneNumber);
      localStorage.setItem(LS_PREMIUM_REQUESTED_KEY, 'true');

      // we track if the /request-received screen was accessed from /request-premium or other
      const query = new URLSearchParams({
        phoneNumber,
        fromContactInformation: 'true',
        role: String(formattedData.role),
      }).toString();

      if (onSuccessRedirect) {
        onSuccessRedirect();
      } else {
        historyPush({
          to: `/request-received?${query}`,
        });
      }
    } catch (e) {
      triggerErrorToast({
        content: t('requestPremiumScreen.failedRequest'),
      });
      AppLogger.error('SupplierGetInTouchMutationError', { error: e });
      trackSegmentAction({
        event: 'Paywall Request Fail',
        data: {
          supplier_role: formattedData.role,
          is_trial: isTrial,
        },
      });
    }
  };

  return {
    formUtils,
    loading,
    onSubmit,
    phoneNumber,
  };
};
