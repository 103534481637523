import { ThemeUIStyleObject } from '@chocoapp/chocolate-ui';

const hoverStyles: ThemeUIStyleObject = {
  '&:hover': {
    bg: 'neutralBgMedium',
    cursor: 'pointer',
  },
};

export const ButtonGroupStyles: ThemeUIStyleObject = {
  boxShadow: '0px 0px 25px rgba(0, 0, 0, 0.1)',
  bg: 'neutralBg',
  minWidth: '250px',
  zIndex: 'buttonGroup',
  border: '1px solid',
  borderColor: 'neutralBorder',
};

export const lastOptionStyles: ThemeUIStyleObject = {
  px: 'm',
  py: 's',
  color: 'dangerText',
  ...hoverStyles,
};

export const optionStyles: ThemeUIStyleObject = {
  ...lastOptionStyles,
  color: 'neutralText',
  borderBottom: '1px solid',
  borderColor: 'neutralBorder',
};
