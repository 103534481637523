import routes from '../routes';

export default function resolveNamedRoute({ to }: { to: string }) {
  const [firstKey, ...remainingKeys] = to.split(':');

  const route = remainingKeys.reduce((acc, key) => {
    return acc.routes![key];
  }, routes[firstKey]);

  if (!route) {
    throw Error(`Route not found: '${to}'`);
  }

  return route;
}
