import { useEffect } from 'react';

import { LS_PREMIUM_REQUESTED_KEY } from '../constants/AppConstants';
import { useHistoryPush } from '../router';

export const useRedirectToRequestReceived = () => {
  const { namedHistoryPush } = useHistoryPush();

  useEffect(() => {
    const premiumAlreadyRequested =
      localStorage.getItem(LS_PREMIUM_REQUESTED_KEY) === 'true';

    if (premiumAlreadyRequested) {
      namedHistoryPush({ to: 'auth:requestReceived' });
    }
  }, [namedHistoryPush]);
};
