import { SomethingWentWrongPage } from '@chocoapp/chocolate-ui';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

const SomethingWentWrong: FC = () => {
  const { t } = useTranslation('somethingWentWrongPage');

  return (
    <SomethingWentWrongPage
      title={t('title')}
      retryBtnText={t('retryBtnText')}
      subtitle={t('subtitle')}
      browser={t('browser')}
      operationalSystem={t('operationalSystem')}
    />
  );
};

export default SomethingWentWrong;
