import { CountryCode } from './CountryCode';

export type CountryType = {
  code: CountryCode;
  unicode: string;
  name: string;
  dialingCode: string;
};

const COUNTRIES: Record<CountryCode, CountryType> = {
  FR: {
    code: 'FR',
    unicode: '1f1eb-1f1f7',
    name: 'France',
    dialingCode: '+33',
  },
  DE: {
    code: 'DE',
    unicode: '1f1e9-1f1ea',
    name: 'Germany',
    dialingCode: '+49',
  },
  ES: {
    code: 'ES',
    unicode: '1f1ea-1f1f8',
    name: 'Spain',
    dialingCode: '+34',
  },
  US: {
    code: 'US',
    unicode: '1f1fa-1f1f8',
    name: 'United States',
    dialingCode: '+1',
  },
  GB: {
    code: 'GB',
    unicode: '1f1ec-1f1e7',
    name: 'United Kingdom',
    dialingCode: '+44',
  },
  AT: {
    code: 'AT',
    unicode: '1f1e6-1f1f9',
    name: 'Austria',
    dialingCode: '+43',
  },
  BE: {
    code: 'BE',
    unicode: '1f1e7-1f1ea',
    name: 'Belgium',
    dialingCode: '+32',
  },
  AF: {
    code: 'AF',
    unicode: '1f1e6-1f1eb',
    name: 'Afghanistan',
    dialingCode: '+93',
  },
  AL: {
    code: 'AL',
    unicode: '1f1e6-1f1f1',
    name: 'Albania',
    dialingCode: '+355',
  },
  DZ: {
    code: 'DZ',
    unicode: '1f1e9-1f1ff',
    name: 'Algeria',
    dialingCode: '+213',
  },
  AS: {
    code: 'AS',
    unicode: '1f1e6-1f1f8',
    name: 'American Samoa',
    dialingCode: '+1',
  },
  AD: {
    code: 'AD',
    unicode: '1f1e6-1f1e9',
    name: 'Andorra',
    dialingCode: '+376',
  },
  AO: {
    code: 'AO',
    unicode: '1f1e6-1f1f4',
    name: 'Angola',
    dialingCode: '+244',
  },
  AI: {
    code: 'AI',
    unicode: '1f1e6-1f1ee',
    name: 'Anguilla',
    dialingCode: '+1',
  },
  AG: {
    code: 'AG',
    unicode: '1f1e6-1f1ec',
    name: 'Antigua & Barbuda',
    dialingCode: '+1',
  },
  AR: {
    code: 'AR',
    unicode: '1f1e6-1f1f7',
    name: 'Argentina',
    dialingCode: '+54',
  },
  AM: {
    code: 'AM',
    unicode: '1f1e6-1f1f2',
    name: 'Armenia',
    dialingCode: '+374',
  },
  AW: {
    code: 'AW',
    unicode: '1f1e6-1f1fc',
    name: 'Aruba',
    dialingCode: '+297',
  },
  AU: {
    code: 'AU',
    unicode: '1f1e6-1f1fa',
    name: 'Australia',
    dialingCode: '+61',
  },

  AZ: {
    code: 'AZ',
    unicode: '1f1e6-1f1ff',
    name: 'Azerbaijan',
    dialingCode: '+994',
  },
  AX: {
    code: 'AX',
    unicode: '1f1e6-1f1fd',
    name: 'Åland Islands',
    dialingCode: '+358',
  },
  BS: {
    code: 'BS',
    unicode: '1f1e7-1f1f8',
    name: 'Bahamas',
    dialingCode: '+1',
  },
  BH: {
    code: 'BH',
    unicode: '1f1e7-1f1ed',
    name: 'Bahrain',
    dialingCode: '+973',
  },
  BD: {
    code: 'BD',
    unicode: '1f1e7-1f1e9',
    name: 'Bangladesh',
    dialingCode: '+880',
  },
  BB: {
    code: 'BB',
    unicode: '1f1e7-1f1e7',
    name: 'Barbados',
    dialingCode: '+1',
  },
  BY: {
    code: 'BY',
    unicode: '1f1e7-1f1fe',
    name: 'Belarus',
    dialingCode: '+375',
  },

  BZ: {
    code: 'BZ',
    unicode: '1f1e7-1f1ff',
    name: 'Belize',
    dialingCode: '+501',
  },
  BJ: {
    code: 'BJ',
    unicode: '1f1e7-1f1ef',
    name: 'Benin',
    dialingCode: '+229',
  },
  BM: {
    code: 'BM',
    unicode: '1f1e7-1f1f2',
    name: 'Bermuda',
    dialingCode: '+1',
  },
  BT: {
    code: 'BT',
    unicode: '1f1e7-1f1f9',
    name: 'Bhutan',
    dialingCode: '+975',
  },
  BO: {
    code: 'BO',
    unicode: '1f1e7-1f1f4',
    name: 'Bolivia',
    dialingCode: '+591',
  },
  BA: {
    code: 'BA',
    unicode: '1f1e7-1f1e6',
    name: 'Bosnia & Herzegovina',
    dialingCode: '+387',
  },
  BW: {
    code: 'BW',
    unicode: '1f1e7-1f1fc',
    name: 'Botswana',
    dialingCode: '+276',
  },
  BR: {
    code: 'BR',
    unicode: '1f1e7-1f1f7',
    name: 'Brazil',
    dialingCode: '+55',
  },
  IO: {
    code: 'IO',
    unicode: '1f1ee-1f1f4',
    name: 'British Indian Ocean Territory',
    dialingCode: '+246',
  },
  VG: {
    code: 'VG',
    unicode: '1f1fb-1f1ec',
    name: 'British Virgin Islands',
    dialingCode: '+1',
  },
  BN: {
    code: 'BN',
    unicode: '1f1e7-1f1f3',
    name: 'Brunei',
    dialingCode: '+673',
  },
  BG: {
    code: 'BG',
    unicode: '1f1e7-1f1ec',
    name: 'Bulgaria',
    dialingCode: '+359',
  },
  BF: {
    code: 'BF',
    unicode: '1f1e7-1f1eb',
    name: 'Burkina Faso',
    dialingCode: '+226',
  },
  BI: {
    code: 'BI',
    unicode: '1f1e7-1f1ee',
    name: 'Burundi',
    dialingCode: '+257',
  },
  KH: {
    code: 'KH',
    unicode: '1f1f0-1f1ed',
    name: 'Cambodia',
    dialingCode: '+855',
  },
  CM: {
    code: 'CM',
    unicode: '1f1e8-1f1f2',
    name: 'Cameroon',
    dialingCode: '+237',
  },
  CA: {
    code: 'CA',
    unicode: '1f1e8-1f1e6',
    name: 'Canada',
    dialingCode: '+1',
  },
  CV: {
    code: 'CV',
    unicode: '1f1e8-1f1fb',
    name: 'Cape Verde',
    dialingCode: '+238',
  },
  BQ: {
    code: 'BQ',
    unicode: '1f1e7-1f1f6',
    name: 'Caribbean Netherlands',
    dialingCode: '+599',
  },
  KY: {
    code: 'KY',
    unicode: '1f1f0-1f1fe',
    name: 'Cayman Islands',
    dialingCode: '+1',
  },
  CF: {
    code: 'CF',
    unicode: '1f1e8-1f1eb',
    name: 'Central African Republic',
    dialingCode: '+236',
  },
  TD: {
    code: 'TD',
    unicode: '1f1f9-1f1e9',
    name: 'Chad',
    dialingCode: '+235',
  },
  CL: {
    code: 'CL',
    unicode: '1f1e8-1f1f1',
    name: 'Chile',
    dialingCode: '+56',
  },
  CN: {
    code: 'CN',
    unicode: '1f1e8-1f1f3',
    name: 'China',
    dialingCode: '+86',
  },
  CX: {
    code: 'CX',
    unicode: '1f1e8-1f1fd',
    name: 'Christmas Island',
    dialingCode: '+61',
  },
  CC: {
    code: 'CC',
    unicode: '1f1e8-1f1e8',
    name: 'Cocos (Keeling) Islands',
    dialingCode: '+61',
  },
  CO: {
    code: 'CO',
    unicode: '1f1e8-1f1f4',
    name: 'Colombia',
    dialingCode: '+57',
  },
  KM: {
    code: 'KM',
    unicode: '1f1f0-1f1f2',
    name: 'Comoros',
    dialingCode: '+269',
  },
  CG: {
    code: 'CG',
    unicode: '1f1e8-1f1ec',
    name: 'Congo - Brazzaville',
    dialingCode: '+242',
  },
  CD: {
    code: 'CD',
    unicode: '1f1e8-1f1e9',
    name: 'Congo - Kinshasa',
    dialingCode: '+243',
  },
  CK: {
    code: 'CK',
    unicode: '1f1e8-1f1f0',
    name: 'Cook Islands',
    dialingCode: '+682',
  },
  CR: {
    code: 'CR',
    unicode: '1f1e8-1f1f7',
    name: 'Costa Rica',
    dialingCode: '+506',
  },
  HR: {
    code: 'HR',
    unicode: '1f1ed-1f1f7',
    name: 'Croatia',
    dialingCode: '+385',
  },
  CU: {
    code: 'CU',
    unicode: '1f1e8-1f1fa',
    name: 'Cuba',
    dialingCode: '+53',
  },
  CW: {
    code: 'CW',
    unicode: '1f1e8-1f1fc',
    name: 'Curaçao',
    dialingCode: '+599',
  },
  CY: {
    code: 'CY',
    unicode: '1f1e8-1f1fe',
    name: 'Cyprus',
    dialingCode: '+357',
  },
  CZ: {
    code: 'CZ',
    unicode: '1f1e8-1f1ff',
    name: 'Czechia',
    dialingCode: '+420',
  },
  CI: {
    code: 'CI',
    unicode: '1f1e8-1f1ee',
    name: 'Côte d’Ivoire',
    dialingCode: '+225',
  },
  DK: {
    code: 'DK',
    unicode: '1f1e9-1f1f0',
    name: 'Denmark',
    dialingCode: '+45',
  },
  DJ: {
    code: 'DJ',
    unicode: '1f1e9-1f1ef',
    name: 'Djibouti',
    dialingCode: '+253',
  },
  DM: {
    code: 'DM',
    unicode: '1f1e9-1f1f2',
    name: 'Dominica',
    dialingCode: '+1',
  },
  DO: {
    code: 'DO',
    unicode: '1f1e9-1f1f4',
    name: 'Dominican Republic',
    dialingCode: '+1',
  },
  EC: {
    code: 'EC',
    unicode: '1f1ea-1f1e8',
    name: 'Ecuador',
    dialingCode: '+593',
  },
  EG: {
    code: 'EG',
    unicode: '1f1ea-1f1ec',
    name: 'Egypt',
    dialingCode: '+20',
  },
  SV: {
    code: 'SV',
    unicode: '1f1f8-1f1fb',
    name: 'El Salvador',
    dialingCode: '+503',
  },
  GQ: {
    code: 'GQ',
    unicode: '1f1ec-1f1f6',
    name: 'Equatorial Guinea',
    dialingCode: '+240',
  },
  ER: {
    code: 'ER',
    unicode: '1f1ea-1f1f7',
    name: 'Eritrea',
    dialingCode: '+291',
  },
  EE: {
    code: 'EE',
    unicode: '1f1ea-1f1ea',
    name: 'Estonia',
    dialingCode: '+372',
  },
  ET: {
    code: 'ET',
    unicode: '1f1ea-1f1f9',
    name: 'Ethiopia',
    dialingCode: '+251',
  },
  FK: {
    code: 'FK',
    unicode: '1f1eb-1f1f0',
    name: 'Falkland Islands',
    dialingCode: '+500',
  },
  FO: {
    code: 'FO',
    unicode: '1f1eb-1f1f4',
    name: 'Faroe Islands',
    dialingCode: '+298',
  },
  FJ: {
    code: 'FJ',
    unicode: '1f1eb-1f1ef',
    name: 'Fiji',
    dialingCode: '+679',
  },
  FI: {
    code: 'FI',
    unicode: '1f1eb-1f1ee',
    name: 'Finland',
    dialingCode: '+358',
  },

  GF: {
    code: 'GF',
    unicode: '1f1ec-1f1eb',
    name: 'French Guiana',
    dialingCode: '+594',
  },
  PF: {
    code: 'PF',
    unicode: '1f1f5-1f1eb',
    name: 'French Polynesia',
    dialingCode: '+689',
  },
  GA: {
    code: 'GA',
    unicode: '1f1ec-1f1e6',
    name: 'Gabon',
    dialingCode: '+241',
  },
  GM: {
    code: 'GM',
    unicode: '1f1ec-1f1f2',
    name: 'Gambia',
    dialingCode: '+220',
  },
  GE: {
    code: 'GE',
    unicode: '1f1ec-1f1ea',
    name: 'Georgia',
    dialingCode: '+995',
  },

  GH: {
    code: 'GH',
    unicode: '1f1ec-1f1ed',
    name: 'Ghana',
    dialingCode: '+233',
  },
  GI: {
    code: 'GI',
    unicode: '1f1ec-1f1ee',
    name: 'Gibraltar',
    dialingCode: '+350',
  },
  GR: {
    code: 'GR',
    unicode: '1f1ec-1f1f7',
    name: 'Greece',
    dialingCode: '+30',
  },
  GL: {
    code: 'GL',
    unicode: '1f1ec-1f1f1',
    name: 'Greenland',
    dialingCode: '+299',
  },
  GD: {
    code: 'GD',
    unicode: '1f1ec-1f1e9',
    name: 'Grenada',
    dialingCode: '+1',
  },
  GP: {
    code: 'GP',
    unicode: '1f1ec-1f1f5',
    name: 'Guadeloupe',
    dialingCode: '+590',
  },
  GU: {
    code: 'GU',
    unicode: '1f1ec-1f1fa',
    name: 'Guam',
    dialingCode: '+1',
  },
  GT: {
    code: 'GT',
    unicode: '1f1ec-1f1f9',
    name: 'Guatemala',
    dialingCode: '+502',
  },
  GG: {
    code: 'GG',
    unicode: '1f1ec-1f1ec',
    name: 'Guernsey',
    dialingCode: '+44',
  },
  GN: {
    code: 'GN',
    unicode: '1f1ec-1f1f3',
    name: 'Guinea',
    dialingCode: '+224',
  },
  GW: {
    code: 'GW',
    unicode: '1f1ec-1f1fc',
    name: 'Guinea-Bissau',
    dialingCode: '+245',
  },
  GY: {
    code: 'GY',
    unicode: '1f1ec-1f1fe',
    name: 'Guyana',
    dialingCode: '+592',
  },
  HT: {
    code: 'HT',
    unicode: '1f1ed-1f1f9',
    name: 'Haiti',
    dialingCode: '+509',
  },
  HN: {
    code: 'HN',
    unicode: '1f1ed-1f1f3',
    name: 'Honduras',
    dialingCode: '+504',
  },
  HK: {
    code: 'HK',
    unicode: '1f1ed-1f1f0',
    name: 'Hong Kong SAR China',
    dialingCode: '+852',
  },
  HU: {
    code: 'HU',
    unicode: '1f1ed-1f1fa',
    name: 'Hungary',
    dialingCode: '+36',
  },
  IS: {
    code: 'IS',
    unicode: '1f1ee-1f1f8',
    name: 'Iceland',
    dialingCode: '+354',
  },
  IN: {
    code: 'IN',
    unicode: '1f1ee-1f1f3',
    name: 'India',
    dialingCode: '+91',
  },
  ID: {
    code: 'ID',
    unicode: '1f1ee-1f1e9',
    name: 'Indonesia',
    dialingCode: '+62',
  },
  IR: {
    code: 'IR',
    unicode: '1f1ee-1f1f7',
    name: 'Iran',
    dialingCode: '+98',
  },
  IQ: {
    code: 'IQ',
    unicode: '1f1ee-1f1f6',
    name: 'Iraq',
    dialingCode: '+964',
  },
  IE: {
    code: 'IE',
    unicode: '1f1ee-1f1ea',
    name: 'Ireland',
    dialingCode: '+353',
  },
  IM: {
    code: 'IM',
    unicode: '1f1ee-1f1f2',
    name: 'Isle of Man',
    dialingCode: '+44',
  },
  IL: {
    code: 'IL',
    unicode: '1f1ee-1f1f1',
    name: 'Israel',
    dialingCode: '+972',
  },
  IT: {
    code: 'IT',
    unicode: '1f1ee-1f1f9',
    name: 'Italy',
    dialingCode: '+39',
  },
  JM: {
    code: 'JM',
    unicode: '1f1ef-1f1f2',
    name: 'Jamaica',
    dialingCode: '+1',
  },
  JP: {
    code: 'JP',
    unicode: '1f1ef-1f1f5',
    name: 'Japan',
    dialingCode: '+81',
  },
  JE: {
    code: 'JE',
    unicode: '1f1ef-1f1ea',
    name: 'Jersey',
    dialingCode: '+44',
  },
  JO: {
    code: 'JO',
    unicode: '1f1ef-1f1f4',
    name: 'Jordan',
    dialingCode: '+962',
  },
  KZ: {
    code: 'KZ',
    unicode: '1f1f0-1f1ff',
    name: 'Kazakhstan',
    dialingCode: '+7',
  },
  KE: {
    code: 'KE',
    unicode: '1f1f0-1f1ea',
    name: 'Kenya',
    dialingCode: '+254',
  },
  KI: {
    code: 'KI',
    unicode: '1f1f0-1f1ee',
    name: 'Kiribati',
    dialingCode: '+686',
  },
  XK: {
    code: 'XK',
    unicode: '1f1fd-1f1f0',
    name: 'Kosovo',
    dialingCode: '+383',
  },
  KW: {
    code: 'KW',
    unicode: '1f1f0-1f1fc',
    name: 'Kuwait',
    dialingCode: '+965',
  },
  KG: {
    code: 'KG',
    unicode: '1f1f0-1f1ec',
    name: 'Kyrgyzstan',
    dialingCode: '+996',
  },
  LA: {
    code: 'LA',
    unicode: '1f1f1-1f1e6',
    name: 'Laos',
    dialingCode: '+856',
  },
  LV: {
    code: 'LV',
    unicode: '1f1f1-1f1fb',
    name: 'Latvia',
    dialingCode: '+371',
  },
  LB: {
    code: 'LB',
    unicode: '1f1f1-1f1e7',
    name: 'Lebanon',
    dialingCode: '+961',
  },
  LS: {
    code: 'LS',
    unicode: '1f1f1-1f1f8',
    name: 'Lesotho',
    dialingCode: '+266',
  },
  LR: {
    code: 'LR',
    unicode: '1f1f1-1f1f7',
    name: 'Liberia',
    dialingCode: '+231',
  },
  LY: {
    code: 'LY',
    unicode: '1f1f1-1f1fe',
    name: 'Libya',
    dialingCode: '+218',
  },
  LI: {
    code: 'LI',
    unicode: '1f1f1-1f1ee',
    name: 'Liechtenstein',
    dialingCode: '+423',
  },
  LT: {
    code: 'LT',
    unicode: '1f1f1-1f1f9',
    name: 'Lithuania',
    dialingCode: '+370',
  },
  LU: {
    code: 'LU',
    unicode: '1f1f1-1f1fa',
    name: 'Luxembourg',
    dialingCode: '+352',
  },
  MO: {
    code: 'MO',
    unicode: '1f1f2-1f1f4',
    name: 'Macau SAR China',
    dialingCode: '+853',
  },
  MK: {
    code: 'MK',
    unicode: '1f1f2-1f1f0',
    name: 'Macedonia',
    dialingCode: '+389',
  },
  MG: {
    code: 'MG',
    unicode: '1f1f2-1f1ec',
    name: 'Madagascar',
    dialingCode: '+261',
  },
  MW: {
    code: 'MW',
    unicode: '1f1f2-1f1fc',
    name: 'Malawi',
    dialingCode: '+265',
  },
  MY: {
    code: 'MY',
    unicode: '1f1f2-1f1fe',
    name: 'Malaysia',
    dialingCode: '+60',
  },
  MV: {
    code: 'MV',
    unicode: '1f1f2-1f1fb',
    name: 'Maldives',
    dialingCode: '+960',
  },
  ML: {
    code: 'ML',
    unicode: '1f1f2-1f1f1',
    name: 'Mali',
    dialingCode: '+223',
  },
  MT: {
    code: 'MT',
    unicode: '1f1f2-1f1f9',
    name: 'Malta',
    dialingCode: '+356',
  },
  MH: {
    code: 'MH',
    unicode: '1f1f2-1f1ed',
    name: 'Marshall Islands',
    dialingCode: '+692',
  },
  MQ: {
    code: 'MQ',
    unicode: '1f1f2-1f1f6',
    name: 'Martinique',
    dialingCode: '+596',
  },
  MR: {
    code: 'MR',
    unicode: '1f1f2-1f1f7',
    name: 'Mauritania',
    dialingCode: '+222',
  },
  MU: {
    code: 'MU',
    unicode: '1f1f2-1f1fa',
    name: 'Mauritius',
    dialingCode: '+230',
  },
  YT: {
    code: 'YT',
    unicode: '1f1fe-1f1f9',
    name: 'Mayotte',
    dialingCode: '+262',
  },
  MX: {
    code: 'MX',
    unicode: '1f1f2-1f1fd',
    name: 'Mexico',
    dialingCode: '+52',
  },
  FM: {
    code: 'FM',
    unicode: '1f1eb-1f1f2',
    name: 'Micronesia',
    dialingCode: '+691',
  },
  MD: {
    code: 'MD',
    unicode: '1f1f2-1f1e9',
    name: 'Moldova',
    dialingCode: '+373',
  },
  MC: {
    code: 'MC',
    unicode: '1f1f2-1f1e8',
    name: 'Monaco',
    dialingCode: '+377',
  },
  MN: {
    code: 'MN',
    unicode: '1f1f2-1f1f3',
    name: 'Mongolia',
    dialingCode: '+976',
  },
  ME: {
    code: 'ME',
    unicode: '1f1f2-1f1ea',
    name: 'Montenegro',
    dialingCode: '+382',
  },
  MS: {
    code: 'MS',
    unicode: '1f1f2-1f1f8',
    name: 'Montserrat',
    dialingCode: '+1',
  },
  MA: {
    code: 'MA',
    unicode: '1f1f2-1f1e6',
    name: 'Morocco',
    dialingCode: '+212',
  },
  MZ: {
    code: 'MZ',
    unicode: '1f1f2-1f1ff',
    name: 'Mozambique',
    dialingCode: '+258',
  },
  MM: {
    code: 'MM',
    unicode: '1f1f2-1f1f2',
    name: 'Myanmar (Burma)',
    dialingCode: '+95',
  },
  NA: {
    code: 'NA',
    unicode: '1f1f3-1f1e6',
    name: 'Namibia',
    dialingCode: '+264',
  },
  NR: {
    code: 'NR',
    unicode: '1f1f3-1f1f7',
    name: 'Nauru',
    dialingCode: '+674',
  },
  NP: {
    code: 'NP',
    unicode: '1f1f3-1f1f5',
    name: 'Nepal',
    dialingCode: '+977',
  },
  NL: {
    code: 'NL',
    unicode: '1f1f3-1f1f1',
    name: 'Netherlands',
    dialingCode: '+31',
  },
  NC: {
    code: 'NC',
    unicode: '1f1f3-1f1e8',
    name: 'New Caledonia',
    dialingCode: '+687',
  },
  NZ: {
    code: 'NZ',
    unicode: '1f1f3-1f1ff',
    name: 'New Zealand',
    dialingCode: '+64',
  },
  NI: {
    code: 'NI',
    unicode: '1f1f3-1f1ee',
    name: 'Nicaragua',
    dialingCode: '+505',
  },
  NE: {
    code: 'NE',
    unicode: '1f1f3-1f1ea',
    name: 'Niger',
    dialingCode: '+227',
  },
  NG: {
    code: 'NG',
    unicode: '1f1f3-1f1ec',
    name: 'Nigeria',
    dialingCode: '+234',
  },
  NU: {
    code: 'NU',
    unicode: '1f1f3-1f1fa',
    name: 'Niue',
    dialingCode: '+683',
  },
  NF: {
    code: 'NF',
    unicode: '1f1f3-1f1eb',
    name: 'Norfolk Island',
    dialingCode: '+672',
  },
  KP: {
    code: 'KP',
    unicode: '1f1f0-1f1f5',
    name: 'North Korea',
    dialingCode: '+850',
  },
  MP: {
    code: 'MP',
    unicode: '1f1f2-1f1f5',
    name: 'Northern Mariana Islands',
    dialingCode: '+1',
  },
  NO: {
    code: 'NO',
    unicode: '1f1f3-1f1f4',
    name: 'Norway',
    dialingCode: '+47',
  },
  OM: {
    code: 'OM',
    unicode: '1f1f4-1f1f2',
    name: 'Oman',
    dialingCode: '+968',
  },
  PK: {
    code: 'PK',
    unicode: '1f1f5-1f1f0',
    name: 'Pakistan',
    dialingCode: '+92',
  },
  PW: {
    code: 'PW',
    unicode: '1f1f5-1f1fc',
    name: 'Palau',
    dialingCode: '+680',
  },
  PS: {
    code: 'PS',
    unicode: '1f1f5-1f1f8',
    name: 'Palestinian Territories',
    dialingCode: '+970',
  },
  PA: {
    code: 'PA',
    unicode: '1f1f5-1f1e6',
    name: 'Panama',
    dialingCode: '+507',
  },
  PG: {
    code: 'PG',
    unicode: '1f1f5-1f1ec',
    name: 'Papua New Guinea',
    dialingCode: '+675',
  },
  PY: {
    code: 'PY',
    unicode: '1f1f5-1f1fe',
    name: 'Paraguay',
    dialingCode: '+595',
  },
  PE: {
    code: 'PE',
    unicode: '1f1f5-1f1ea',
    name: 'Peru',
    dialingCode: '+51',
  },
  PH: {
    code: 'PH',
    unicode: '1f1f5-1f1ed',
    name: 'Philippines',
    dialingCode: '+63',
  },
  PL: {
    code: 'PL',
    unicode: '1f1f5-1f1f1',
    name: 'Poland',
    dialingCode: '+48',
  },
  PT: {
    code: 'PT',
    unicode: '1f1f5-1f1f9',
    name: 'Portugal',
    dialingCode: '+351',
  },
  PR: {
    code: 'PR',
    unicode: '1f1f5-1f1f7',
    name: 'Puerto Rico',
    dialingCode: '+1',
  },
  QA: {
    code: 'QA',
    unicode: '1f1f6-1f1e6',
    name: 'Qatar',
    dialingCode: '+974',
  },
  RO: {
    code: 'RO',
    unicode: '1f1f7-1f1f4',
    name: 'Romania',
    dialingCode: '+40',
  },
  RU: {
    code: 'RU',
    unicode: '1f1f7-1f1fa',
    name: 'Russia',
    dialingCode: '+7',
  },
  RW: {
    code: 'RW',
    unicode: '1f1f7-1f1fc',
    name: 'Rwanda',
    dialingCode: '+250',
  },
  RE: {
    code: 'RE',
    unicode: '1f1f7-1f1ea',
    name: 'Réunion',
    dialingCode: '+262',
  },
  WS: {
    code: 'WS',
    unicode: '1f1fc-1f1f8',
    name: 'Samoa',
    dialingCode: '+685',
  },
  SM: {
    code: 'SM',
    unicode: '1f1f8-1f1f2',
    name: 'San Marino',
    dialingCode: '+378',
  },
  SA: {
    code: 'SA',
    unicode: '1f1f8-1f1e6',
    name: 'Saudi Arabia',
    dialingCode: '+966',
  },
  SN: {
    code: 'SN',
    unicode: '1f1f8-1f1f3',
    name: 'Senegal',
    dialingCode: '+221',
  },
  RS: {
    code: 'RS',
    unicode: '1f1f7-1f1f8',
    name: 'Serbia',
    dialingCode: '+381',
  },
  SC: {
    code: 'SC',
    unicode: '1f1f8-1f1e8',
    name: 'Seychelles',
    dialingCode: '+248',
  },
  SL: {
    code: 'SL',
    unicode: '1f1f8-1f1f1',
    name: 'Sierra Leone',
    dialingCode: '+232',
  },
  SG: {
    code: 'SG',
    unicode: '1f1f8-1f1ec',
    name: 'Singapore',
    dialingCode: '+65',
  },
  SX: {
    code: 'SX',
    unicode: '1f1f8-1f1fd',
    name: 'Sint Maarten',
    dialingCode: '+1',
  },
  SK: {
    code: 'SK',
    unicode: '1f1f8-1f1f0',
    name: 'Slovakia',
    dialingCode: '+421',
  },
  SI: {
    code: 'SI',
    unicode: '1f1f8-1f1ee',
    name: 'Slovenia',
    dialingCode: '+386',
  },
  SB: {
    code: 'SB',
    unicode: '1f1f8-1f1e7',
    name: 'Solomon Islands',
    dialingCode: '+677',
  },
  SO: {
    code: 'SO',
    unicode: '1f1f8-1f1f4',
    name: 'Somalia',
    dialingCode: '+252',
  },
  ZA: {
    code: 'ZA',
    unicode: '1f1ff-1f1e6',
    name: 'South Africa',
    dialingCode: '+27',
  },
  KR: {
    code: 'KR',
    unicode: '1f1f0-1f1f7',
    name: 'South Korea',
    dialingCode: '+82',
  },
  SS: {
    code: 'SS',
    unicode: '1f1f8-1f1f8',
    name: 'South Sudan',
    dialingCode: '+211',
  },

  LK: {
    code: 'LK',
    unicode: '1f1f1-1f1f0',
    name: 'Sri Lanka',
    dialingCode: '+94',
  },
  BL: {
    code: 'BL',
    unicode: '1f1e7-1f1f1',
    name: 'St. Barthélemy',
    dialingCode: '+590',
  },
  SH: {
    code: 'SH',
    unicode: '1f1f8-1f1ed',
    name: 'St. Helena',
    dialingCode: '+290',
  },
  KN: {
    code: 'KN',
    unicode: '1f1f0-1f1f3',
    name: 'St. Kitts & Nevis',
    dialingCode: '+1',
  },
  LC: {
    code: 'LC',
    unicode: '1f1f1-1f1e8',
    name: 'St. Lucia',
    dialingCode: '+1',
  },
  MF: {
    code: 'MF',
    unicode: '1f1f2-1f1eb',
    name: 'St. Martin',
    dialingCode: '+590',
  },
  PM: {
    code: 'PM',
    unicode: '1f1f5-1f1f2',
    name: 'St. Pierre & Miquelon',
    dialingCode: '+508',
  },
  VC: {
    code: 'VC',
    unicode: '1f1fb-1f1e8',
    name: 'St. Vincent & Grenadines',
    dialingCode: '+1',
  },
  SD: {
    code: 'SD',
    unicode: '1f1f8-1f1e9',
    name: 'Sudan',
    dialingCode: '+249',
  },
  SR: {
    code: 'SR',
    unicode: '1f1f8-1f1f7',
    name: 'Suriname',
    dialingCode: '+597',
  },
  SJ: {
    code: 'SJ',
    unicode: '1f1f8-1f1ef',
    name: 'Svalbard & Jan Mayen',
    dialingCode: '+47',
  },
  SZ: {
    code: 'SZ',
    unicode: '1f1f8-1f1ff',
    name: 'Swaziland',
    dialingCode: '+268',
  },
  SE: {
    code: 'SE',
    unicode: '1f1f8-1f1ea',
    name: 'Sweden',
    dialingCode: '+46',
  },
  CH: {
    code: 'CH',
    unicode: '1f1e8-1f1ed',
    name: 'Switzerland',
    dialingCode: '+41',
  },
  SY: {
    code: 'SY',
    unicode: '1f1f8-1f1fe',
    name: 'Syria',
    dialingCode: '+963',
  },
  ST: {
    code: 'ST',
    unicode: '1f1f8-1f1f9',
    name: 'São Tomé & Príncipe',
    dialingCode: '+239',
  },
  TW: {
    code: 'TW',
    unicode: '1f1f9-1f1fc',
    name: 'Taiwan',
    dialingCode: '+886',
  },
  TJ: {
    code: 'TJ',
    unicode: '1f1f9-1f1ef',
    name: 'Tajikistan',
    dialingCode: '+992',
  },
  TZ: {
    code: 'TZ',
    unicode: '1f1f9-1f1ff',
    name: 'Tanzania',
    dialingCode: '+255',
  },
  TH: {
    code: 'TH',
    unicode: '1f1f9-1f1ed',
    name: 'Thailand',
    dialingCode: '+66',
  },
  TL: {
    code: 'TL',
    unicode: '1f1f9-1f1f1',
    name: 'Timor-Leste',
    dialingCode: '+670',
  },
  TG: {
    code: 'TG',
    unicode: '1f1f9-1f1ec',
    name: 'Togo',
    dialingCode: '+228',
  },
  TK: {
    code: 'TK',
    unicode: '1f1f9-1f1f0',
    name: 'Tokelau',
    dialingCode: '+690',
  },
  TO: {
    code: 'TO',
    unicode: '1f1f9-1f1f4',
    name: 'Tonga',
    dialingCode: '+676',
  },
  TT: {
    code: 'TT',
    unicode: '1f1f9-1f1f9',
    name: 'Trinidad & Tobago',
    dialingCode: '+1',
  },
  TN: {
    code: 'TN',
    unicode: '1f1f9-1f1f3',
    name: 'Tunisia',
    dialingCode: '+216',
  },
  TR: {
    code: 'TR',
    unicode: '1f1f9-1f1f7',
    name: 'Turkey',
    dialingCode: '+90',
  },
  TM: {
    code: 'TM',
    unicode: '1f1f9-1f1f2',
    name: 'Turkmenistan',
    dialingCode: '+993',
  },
  TC: {
    code: 'TC',
    unicode: '1f1f9-1f1e8',
    name: 'Turks & Caicos Islands',
    dialingCode: '+1',
  },
  TV: {
    code: 'TV',
    unicode: '1f1f9-1f1fb',
    name: 'Tuvalu',
    dialingCode: '+688',
  },
  VI: {
    code: 'VI',
    unicode: '1f1fb-1f1ee',
    name: 'U.S. Virgin Islands',
    dialingCode: '+1',
  },
  UG: {
    code: 'UG',
    unicode: '1f1fa-1f1ec',
    name: 'Uganda',
    dialingCode: '+256',
  },
  UA: {
    code: 'UA',
    unicode: '1f1fa-1f1e6',
    name: 'Ukraine',
    dialingCode: '+380',
  },
  AE: {
    code: 'AE',
    unicode: '1f1e6-1f1ea',
    name: 'United Arab Emirates',
    dialingCode: '+971',
  },
  UY: {
    code: 'UY',
    unicode: '1f1fa-1f1fe',
    name: 'Uruguay',
    dialingCode: '+598',
  },
  UZ: {
    code: 'UZ',
    unicode: '1f1fa-1f1ff',
    name: 'Uzbekistan',
    dialingCode: '+998',
  },
  VU: {
    code: 'VU',
    unicode: '1f1fb-1f1fa',
    name: 'Vanuatu',
    dialingCode: '+678',
  },
  VA: {
    code: 'VA',
    unicode: '1f1fb-1f1e6',
    name: 'Vatican City',
    dialingCode: '+39',
  },
  VE: {
    code: 'VE',
    unicode: '1f1fb-1f1ea',
    name: 'Venezuela',
    dialingCode: '+58',
  },
  VN: {
    code: 'VN',
    unicode: '1f1fb-1f1f3',
    name: 'Vietnam',
    dialingCode: '+84',
  },
  WF: {
    code: 'WF',
    unicode: '1f1fc-1f1eb',
    name: 'Wallis & Futuna',
    dialingCode: '+681',
  },
  EH: {
    code: 'EH',
    unicode: '1f1ea-1f1ed',
    name: 'Western Sahara',
    dialingCode: '+212',
  },
  YE: {
    code: 'YE',
    unicode: '1f1fe-1f1ea',
    name: 'Yemen',
    dialingCode: '+967',
  },
  ZM: {
    code: 'ZM',
    unicode: '1f1ff-1f1f2',
    name: 'Zambia',
    dialingCode: '+260',
  },
  ZW: {
    code: 'ZW',
    unicode: '1f1ff-1f1fc',
    name: 'Zimbabwe',
    dialingCode: '+263',
  },
};

export default COUNTRIES;
