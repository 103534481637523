import { ThemeProvider, createCustomTheme } from '@chocoapp/chocolate-ui';
import { isNil } from '@chocoapp/toolbelt-utils';
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { I18nextProvider } from 'react-i18next';

import SomethingWentWrong from '../components/commons/SomethingWentWrong/SomethingWentWrong';
import i18n from '../setup/i18n';

const SomethingWentWrongPage = () => {
  const customTheme = !isNil(window.choco.theme)
    ? createCustomTheme(window.choco.theme)
    : undefined;

  return (
    <I18nextProvider i18n={i18n}>
      <ThemeProvider customTheme={customTheme}>
        <Suspense fallback="">
          <SomethingWentWrong />
        </Suspense>
      </ThemeProvider>
    </I18nextProvider>
  );
};

export const injectSomethingWentWrongPage = () => {
  ReactDOM.render(
    <SomethingWentWrongPage />,
    document.getElementById('container')
  );
};
