import { useFlags } from '@chocoapp/launchdarkly-ui';
import React, { useState } from 'react';

import { GoogleMapModal } from '../components/commons/GoogleMapModal/GoogleMapModal';
import {
  GoogleMapsPosition,
  searchBuyersToGMPOsition,
} from '../components/commons/GoogleMapModal/GoogleMapsPosition';
import { WorkplaceForm } from '../components/commons/WorkplaceForm/WorkplaceForm';
import { useRequestToJoinBuyerDataMutation } from '../data/useRequestToJoinBuyerMutation';
import { BuyerFragment } from '../generated';
import { useHistoryPush } from '../router';

export const JoinBusinessPage = () => {
  const { automateClaimBuyer } = useFlags();
  const [isOpen, setIsOpen] = useState(false);

  const [locations, setLocations] = useState<GoogleMapsPosition[]>([]);

  const { namedHistoryPush } = useHistoryPush();

  const { requestToJoinBuyer, loading } = useRequestToJoinBuyerDataMutation();

  const handleOnSearchBuyerSuccess = async (buyers: BuyerFragment[]) => {
    if (buyers.length === 0) {
      namedHistoryPush({ to: 'auth:almost-there' });
      return;
    }
    // Open Modal for Maps click through
    const buyerLocations = searchBuyersToGMPOsition(buyers);
    setLocations(buyerLocations);
    setIsOpen(true);
  };

  const handleConfirmLocation = async (buyerId: string) => {
    if (automateClaimBuyer) {
      const { success } = await requestToJoinBuyer(buyerId);
      if (success) {
        namedHistoryPush({ to: 'auth:terms-and-conditions' });
        return;
      }
    }
    namedHistoryPush({ to: 'auth:almost-there' });
  };

  const handleRejectAllLocations = async () => {
    namedHistoryPush({ to: 'auth:almost-there' });
  };

  return (
    <>
      <WorkplaceForm onSearchBuyerSuccess={handleOnSearchBuyerSuccess} />
      <GoogleMapModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        rejectAllLocations={handleRejectAllLocations}
        confirmLocation={handleConfirmLocation}
        locations={locations}
        loading={loading}
      />
    </>
  );
};
