import { MutationFunctionOptions } from '@apollo/client';

import {
  CreateProfileInput,
  CreateProfileMutation,
  Exact,
  useCreateProfileMutation,
} from '../generated';

/**
 * This might seem odd, but the "CreateProfileError" on the v1 of the CreateProfileMutation
 * can be safely ignored. As is the "BuyerExists" response, both should still be
 * treated as successes. The v2 of this mutation will fix this confusion, and shall
 * return an error only if something went actually wrong, so until we migrate to that
 * (theres a ticket for it), this is the actual way to handle this request.
 * @see https://choco.atlassian.net/browse/GROW-4900
 */
const successTypenames: CreateProfileMutation['createProfile']['__typename'][] = [
  'BuyerExists',
  'CreateProfileError',
  'User',
];

export const useCreateProfile = () => {
  const [callCreateProfileMutation, { loading }] = useCreateProfileMutation();

  const createProfile = async (
    options:
      | MutationFunctionOptions<
          CreateProfileMutation,
          Exact<{ user: CreateProfileInput }>
        >
      | undefined
  ) => {
    const { data } = await callCreateProfileMutation(options);

    const isSuccessResponse =
      data?.createProfile.__typename &&
      successTypenames.includes(data?.createProfile.__typename);

    return {
      success: isSuccessResponse,
    };
  };

  return {
    createProfile,
    loading,
  };
};
