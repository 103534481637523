import { Flex, Spinner } from '@chocoapp/chocolate-ui';
import React from 'react';

const ReLoginPage = () => {
  return (
    <Flex
      sx={{ justifyContent: 'center', alignItems: 'center', width: '100%' }}
    >
      <Spinner size={50} />
    </Flex>
  );
};

export default ReLoginPage;
