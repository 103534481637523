import gql from 'graphql-tag';

gql`
  query getUserIntercomCredentials {
    getUserIntercomCredentials {
      ... on UserIntercomCredentialsSuccess {
        webHash
      }
    }
  }
`;
