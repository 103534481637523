import { AppLogger } from '../../application/logger';
import { REACT_APP_IMPORT_MAP_OVERRIDES_ENDPOINT } from '../../config';
import importMapJson from '../../import-map.json';
import { isIE } from '../../util/isIE';
import { isWhiteLabel } from '../../whiteLabel/utils';
import { ImportMapStageType } from '../types';

import initCommonDependencies from './commonDependencies';
import insertNewImportMap from './insertNewImportMap';
import prefetchMFEs, { IMPORT_MAP_ID, ImportMapType } from './prefetchMFEs';

export const importRegistry = () => import('../registry');
const hasImportOverrides = () =>
  Object.keys(localStorage ?? {}).some((item) =>
    item.includes('import-map-override:')
  );

const injectImportMapOverrides = () => {
  SystemJS.import(REACT_APP_IMPORT_MAP_OVERRIDES_ENDPOINT);

  window.addEventListener('import-map-overrides:init', () => {
    importRegistry();
  });
};

const insertMainImportMap =
  (importMapStage: ImportMapStageType) => (data: ImportMapType) => {
    insertNewImportMap(data, IMPORT_MAP_ID, 'beforebegin');

    if (importMapStage !== 'production') {
      injectImportMapOverrides();
      return;
    }

    importRegistry();
  };

const eagerLazyLoadMFEs = () => {
  // We don't want to optmize the application if we are
  // using import-map-overrides for development.
  // That leads to inconsistent behavior with import maps
  // Therefore, this line avoids lazy-loading the MFEs in case
  // there's an override in the localStorage
  if (!hasImportOverrides() && !isIE) {
    prefetchMFEs();
  }
};

const injectLocalStaticImportMap = () => {
  insertNewImportMap(importMapJson.development, IMPORT_MAP_ID);
  importRegistry();
};

const overrideWhiteLabelDomain = (data: ImportMapType): ImportMapType => {
  if (isWhiteLabel()) {
    const newData = {
      imports: {
        ...Object.entries(data.imports).reduce<ImportMapType['imports']>(
          (acc, [key, value]) => {
            const localUrl = new URL(window.location.href);
            const valueUrl = new URL(value);
            valueUrl.hostname = localUrl.hostname;

            return {
              ...acc,
              [key]: valueUrl.href,
            };
          },
          {}
        ),
      },
    };

    return newData;
  }

  return data;
};

export default async function bootstrap(importMapStage: ImportMapStageType) {
  const commonDependenciesImportMap = initCommonDependencies();
  insertNewImportMap({ imports: commonDependenciesImportMap });

  if (importMapStage === 'development') {
    injectLocalStaticImportMap();
    return null;
  }

  try {
    return fetch('/import-map.json')
      .then((response) => response.json())
      .then(overrideWhiteLabelDomain)
      .then(insertMainImportMap(importMapStage))
      .finally(eagerLazyLoadMFEs);
  } catch (reason) {
    // TODO: redirect user to a 500 page (non-existent atm)
    return AppLogger.error('bootstrapError', { reason });
  }
}
