import { PhoneNumberUtil, PhoneNumberFormat } from 'google-libphonenumber';

import {
  getDialingCodeFromCountryCode,
  removePrefix,
} from '../../../util/phoneNumbers';

import { CountryCode } from './CountryCode';

export const getFullPhoneNumber = (
  dialingCode: string,
  phoneNumber: string
): string => {
  // keep only numbers and "+"
  let parsedNumber = phoneNumber.replace(/(?!^\+)[\D+]/g, '');

  // if dialing code is not Ivory Coast, then remove leading zeros
  if (dialingCode !== '+225') {
    parsedNumber = parsedNumber.replace(/^0+/, '');
  }

  // if number starts with +, ignore the country code param
  if (parsedNumber.charAt(0) === '+') {
    return parsedNumber;
  }
  return `${dialingCode}${parsedNumber}`;
};

export const generatePlaceholderNumber = (countryCode: CountryCode) => {
  if (countryCode) {
    const number = new PhoneNumberUtil();

    const exampleNumber = number.getExampleNumberForType(countryCode, 1);

    const formattedInternationalNumber = number.format(
      exampleNumber,
      PhoneNumberFormat.INTERNATIONAL
    );

    const countryDialingCode = getDialingCodeFromCountryCode(countryCode);
    const placeholder = removePrefix(
      formattedInternationalNumber,
      countryDialingCode
    );

    return placeholder.trim();
  }
  return '';
};

export const getLocalizedContactFormUrl = (language: string = 'en') => {
  // 'en-UK' -> 'en'
  const currentLanguage = language.split('-')[0];

  switch (true) {
    case currentLanguage === 'cs':
      // czech is a special case. choco.com uses 'cz' and browsers use 'cs'
      return `https://choco.com/cz/suppliers#contact`;
    case ['de', 'es', 'fr'].includes(currentLanguage):
      return `https://choco.com/${currentLanguage}/suppliers#contact`;
    // 'en' and all unsupported languages
    default:
      return `https://choco.com/de/en/suppliers#contact`;
  }
};
