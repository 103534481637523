import { Box, ChocoAsset, ThemeUIStyleObject } from '@chocoapp/chocolate-ui';
import { usePermissions } from '@chocoapp/toolbelt-auth';
import React, { FC } from 'react';

import { UserFragment } from '../../../../generated';
import { getAssetsPrefix } from '../../../../whiteLabel/utils';
import { useCollapsedContext } from '../useCollapsedContext';

import BuyerLocation from './BuyerLocation';
import SupplierProfile from './SupplierProfile';

const getLogoStyles = (isCollapsed: boolean): ThemeUIStyleObject => ({
  ml: isCollapsed ? '-8px' : '20px',
  py: '11px',
  transition: `all 150ms ease-out`,
});

type NavigationHeaderProps = {
  user: UserFragment;
};

const NavigationHeader: FC<NavigationHeaderProps> = ({ user }) => {
  const { isCollapsed } = useCollapsedContext();
  const { isSupplier } = usePermissions();

  return (
    <>
      <Box data-testid="ChocoLogo" sx={getLogoStyles(isCollapsed)}>
        <ChocoAsset
          width={80}
          height={42}
          prefix={getAssetsPrefix()}
          path="/brand/logo-light.svg"
        />
      </Box>
      {isSupplier ? (
        <SupplierProfile supplier={user.linkedSupplier} />
      ) : (
        // TODO store the buyer id in the URL as the selected location
        // for now we can just get the first element of the buyers list
        // because we have a check at MainPage level
        // to show the new navbar only if we have one location
        <BuyerLocation buyer={user.buyers?.edges[0].node} />
      )}
    </>
  );
};

export default NavigationHeader;
