import React from 'react';
import { useTranslation } from 'react-i18next';

import { AuthLayoutBody } from '../../auth/AuthLayoutBody';
import { AsyncI18n } from '../../components';
import { RequestReceived } from '../../components/commons/Paywall/RequestReceived';

const RequestReceivedScreen = (): JSX.Element => {
  const { t } = useTranslation('getInTouch');

  return (
    <AsyncI18n>
      <AuthLayoutBody
        backButton={{
          href: '/logout',
          label: t('goPremiumScreen.backToLogin'),
        }}
      >
        <RequestReceived />
      </AuthLayoutBody>
    </AsyncI18n>
  );
};

export default RequestReceivedScreen;
