import { useApolloClient } from '@apollo/client';
import {
  resetSegment,
  removeExtraFields as removeSegmentExtraFields,
  removeGroupId,
} from '@chocoapp/toolbelt-utils/lib/segment';
import { CognitoUserSession } from 'amazon-cognito-identity-js';
import { FC, useCallback, useEffect } from 'react';

import { useAuthContext } from '../auth';
import { useUnreadMessagesContext } from '../components';
import {
  CHOCO_SUPPORT_LOGIN_PHONE_KEY,
  LAST_VISITED_CHAT_KEY,
  LS_PERSISTED_FILTER_ACCEPTED,
  LS_PERSISTED_FILTER_PENDING,
  LS_PREMIUM_REQUESTED_KEY,
  UNAUTHORIZED_SUPPLIER_KEY,
} from '../constants/AppConstants';
import { useHistoryPush } from '../router';
import { removeSession, clearDetectorInstance } from '../util/activityDetector';

const clearChocoLocalStorage = () => {
  localStorage.removeItem(LAST_VISITED_CHAT_KEY);
  localStorage.removeItem(CHOCO_SUPPORT_LOGIN_PHONE_KEY);
  localStorage.removeItem(LS_PREMIUM_REQUESTED_KEY);
  localStorage.removeItem(UNAUTHORIZED_SUPPLIER_KEY);
  localStorage.removeItem(LS_PERSISTED_FILTER_PENDING);
  localStorage.removeItem(LS_PERSISTED_FILTER_ACCEPTED);
};

export const useLogout = () => {
  const { signOut } = useAuthContext();
  const { clearAll } = useUnreadMessagesContext();
  const { cache } = useApolloClient();

  const logout = useCallback(() => {
    signOut(async () => {
      await cache.reset();
      clearAll();
      removeSession();
      clearDetectorInstance();
      removeSegmentExtraFields();
      removeGroupId();
      resetSegment();
      clearChocoLocalStorage();
      sessionStorage.clear();
      window.Intercom('shutdown');
    });
  }, [signOut, clearAll, cache]);

  return { logout };
};

const LogoutPage: FC = () => {
  const { cognitoUser } = useAuthContext();
  const { logout } = useLogout();
  const { namedHistoryPush } = useHistoryPush();

  useEffect(() => {
    try {
      let hasSession = false;

      cognitoUser?.getSession(
        (_error: Error, session: CognitoUserSession | null) => {
          hasSession = true;
          if (!session?.isValid()) {
            // Redirect when no session is invalid
            throw Error('User session is invalid');
          }
          if (session?.isValid()) {
            logout();
          }
        }
      );

      if (!hasSession) {
        // Redirect when no session active
        throw Error('No logged in user');
      }
    } catch (error) {
      namedHistoryPush({ to: 'auth:login' });
    }
  }, [cognitoUser, logout, namedHistoryPush]);

  return null;
};

export default LogoutPage;
