import { removeSupportLoginPhone } from '@chocoapp/toolbelt-utils';
import queryString from 'query-string';
import { useCallback, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { useHistoryPush } from '../router';

import { useIsSupportLogin } from './SupportLoginProvider/SupportLoginProvider';

import { OneTimePasswordPageState, useAuthContext, useAuthFormError } from '.';

export const useAuthenticateUser = () => {
  const { initiateAuth, userPool } = useAuthContext();

  return (phoneNumber: string) => initiateAuth(phoneNumber, userPool);
};

const usePhoneNumberFormDataSuccess = () => {
  const { namedHistoryPush } = useHistoryPush();
  const location = useLocation();

  return useCallback(
    (redirectParams?: OneTimePasswordPageState) => {
      if (redirectParams?.method === 'signUp') {
        namedHistoryPush({
          to: 'auth:confirm-signup',
          search: queryString.parse(location.search),
          state: redirectParams ?? {},
        });
      } else {
        namedHistoryPush({
          to: 'auth:one-time-password',
          search: queryString.parse(location.search),
          state: redirectParams ?? {},
        });
      }
    },
    [location.search, namedHistoryPush]
  );
};

export const useSubmitPhoneNumber = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setIsError] = useState(false);
  const [errorCode, setErrorCode] = useState('');
  const { setIsSupportLogin } = useIsSupportLogin();
  const authenticateUser = useAuthenticateUser();
  const onSuccess = usePhoneNumberFormDataSuccess();
  const onError = useAuthFormError();

  const submitPhoneNumber = async (phoneNumber: string) => {
    try {
      setIsSubmitting(true);
      const authResponse = await authenticateUser(phoneNumber);

      onSuccess({
        twoFactorMethod: authResponse.twoFactorMethod,
        digits: authResponse.authDigits,
        codeSentTo: authResponse.codeSentTo,
        method: authResponse.method,
        userId: authResponse.userId,
      });
    } catch (err) {
      if ((err as Error).name === 'UserNotFoundException') {
        onError('loginV2.phoneNumber.newNumberError');
      } else {
        onError('loginV2.phoneNumber.error');
      }
      setErrorCode((err as any).code);
      removeSupportLoginPhone();
      setIsError(true);
      setIsSupportLogin(false);
      setIsSubmitting(false);
    }
  };

  return {
    error,
    errorCode,
    submitPhoneNumber,
    isSubmitting,
  };
};
