import { AppLogger } from '../application/logger';
import { REACT_APP_AWS_APP_SYNC_URL } from '../config';
import { OrderConfirmMutationHookResult } from '../generated';

export type HeaderTuple = [string, string];

export const configureMutationHeaders = (
  headersTuple: HeaderTuple[]
): { [key: string]: string } => {
  const headers: { [key: string]: string } = {};

  headersTuple.forEach(([headerKey, headerValue]) => {
    headers[headerKey] = headerValue;
  });

  return headers;
};

type ConfirmOrderMutationType = {
  token: string | null;
  orderId: string;
  source: string | null;
  transmissionChannel?: string | null;
};

/**
 * @throws error if token is null | undefined
 * @throws error if response is not 200/ok
 * @throws error if gql response body contains errors
 */
export const confirmOrderMutation = async ({
  token,
  orderId,
  source,
  transmissionChannel,
}: ConfirmOrderMutationType) => {
  if (!token) {
    const error = new Error(`Token is missing in URL ${window.location.href}`);
    AppLogger.error('confirmOrderMutationError', { error });
    throw error;
  }

  const headers: HeaderTuple[] = [
    ['Authorization', token],
    ['Content-Type', 'application/json'],
    ['source', source ?? ''],
  ];

  if (transmissionChannel) {
    headers.push(['transmission-channel', transmissionChannel]);
  }

  const mutationHeaders = configureMutationHeaders(headers);

  const graphql = JSON.stringify({
    query: /* GraphQL */ `
      mutation confirmOrder($id: ID!) {
        orderConfirm(id: $id) {
          id
        }
      }
    `,
    variables: { id: orderId },
  });

  const requestOptions: RequestInit = {
    method: 'POST',
    headers: mutationHeaders,
    body: graphql,
    redirect: 'follow',
    keepalive: true,
  };

  const fetchResponse = await fetch(REACT_APP_AWS_APP_SYNC_URL, requestOptions);

  if (!fetchResponse.ok) {
    throw new Error(
      `fetch threw an error with status: ${fetchResponse.status}`
    );
  }

  const response: {
    data?: OrderConfirmMutationHookResult;
    errors?: any[];
  } = await fetchResponse.json();

  const orderConfirmHasErrors = Boolean(response.errors?.length ?? 0);

  if (orderConfirmHasErrors) {
    throw new Error(`the order was not confirmed`);
  }
};
