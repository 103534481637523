import {
  ThemeUIStyleObject,
  Box,
  Flex,
  ChocoAsset,
} from '@chocoapp/chocolate-ui';
import React, { FC } from 'react';

import { EnvironmentSelector } from '../components/commons/EnvironmentSelector';
import { CreateOrJoinBusinessContextProvider } from '../data/providers/CreateOrJoinBusinessContextProvider';
import { getAssetsPrefix, getNormalizedAssetURL } from '../whiteLabel/utils';

const containerStyles = (): ThemeUIStyleObject => {
  const coverUrl = getNormalizedAssetURL('/illustrations/cover.svg');

  return {
    flex: '1',
    backgroundImage: `url('${coverUrl}')`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    color: ['brandPrimary1', 'brandPrimary1', 'brandPrimary4'],
  };
};

const logoStyles: ThemeUIStyleObject = {
  position: 'absolute',
  left: 'm',
  top: ['98px', '98px', '30px'],
  zIndex: '1',
};

const contentWrapperStyles: ThemeUIStyleObject = {
  flexDirection: 'column',
  flexBasis: ['100%', '100%', '560px'],
  pt: '200px',
  px: 'm',
  pb: 'l',
  bg: 'neutralBgMedium',
  position: 'relative',
  overflowY: 'auto',
};

const selectorStyles: ThemeUIStyleObject = {
  border: '2px solid',
  borderColor: 'baseBorder',
  borderRadius: '50%',
  position: 'absolute',
  bottom: '8px',
  left: '8px',
  zIndex: 1,
  p: 'xs',
};

const pageWrapperStyles: ThemeUIStyleObject = {
  height: '100vh',
  width: '100vw',
};

const iconStyles: ThemeUIStyleObject = {
  width: '135px',
  height: '78px',
};

export const AuthLayout: FC = ({ children }) => {
  return (
    <>
      <EnvironmentSelector wrapperSx={selectorStyles} />

      <Flex sx={pageWrapperStyles} data-testid="ChocoAppAuthPage">
        <Box sx={containerStyles()}>
          <Box sx={logoStyles}>
            <ChocoAsset
              sx={iconStyles}
              data-testid="StyledChocoLogo"
              prefix={getAssetsPrefix()}
              path="/brand/logo-light.svg"
            />
          </Box>
        </Box>
        <CreateOrJoinBusinessContextProvider>
          <Flex sx={contentWrapperStyles}>{children}</Flex>
        </CreateOrJoinBusinessContextProvider>
      </Flex>
    </>
  );
};
