import {
  Flex,
  ThemeUIStyleObject,
  ChocoIcon,
  Text,
} from '@chocoapp/chocolate-ui';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import HiddenWhenCollapsed from './HiddenWhenCollapsed';
import MenuTooltip from './MenuTooltip';
import { useCollapsedContext } from './useCollapsedContext';

const wrapperStyles: ThemeUIStyleObject = {
  alignItems: 'center',
  bg: 'baseBgMedium',
  color: 'baseTextWeak',
  cursor: 'pointer',
  p: '8px 20px',
  gap: '12px',

  ':hover': {
    color: 'baseTextWeakActive',
  },
};

const Collapse: FC = () => {
  const { isCollapsed, toggleCollapsed } = useCollapsedContext();

  const { t } = useTranslation('globalNavigation');

  return (
    <MenuTooltip tooltipText={t('navigation.expand')}>
      <Flex sx={wrapperStyles} data-testid="Collapse" onClick={toggleCollapsed}>
        <ChocoIcon name={isCollapsed ? 'uncollapsed' : 'collapsed'} />
        <HiddenWhenCollapsed>
          <Text variant="Footnote">{t('navigation.collapse')}</Text>
        </HiddenWhenCollapsed>
      </Flex>
    </MenuTooltip>
  );
};

export default Collapse;
