import { Box, Button, ChocoAsset, Text } from '@chocoapp/chocolate-ui';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import getQueryString from '../../../hooks/getQueryString';
import { getAssetsPrefix } from '../../../whiteLabel/utils';

import PremiumFeature from './PremiumFeature';
import { useTrackPaywall } from './useTrackPremiumScreen';
import { addPhoneNumberQuery } from './utils';

type GoPremiumProps = {
  onLetsTalkRedirect?: () => void;
};

export const GoPremium = ({ onLetsTalkRedirect }: GoPremiumProps) => {
  const { t } = useTranslation('getInTouch');

  const phoneNumber = getQueryString('phoneNumber');

  const trackEvent = useTrackPaywall({
    name: 'Paywall',
  });

  const handleTrackButton = () => {
    trackEvent({
      event: 'Paywall Lets Talk Clicked',
    });
    if (onLetsTalkRedirect) {
      onLetsTalkRedirect();
    }
  };

  return (
    <Box
      data-testid="GoPremium"
      sx={{ mt: 'xl', color: 'baseTextStrong', textAlign: 'center' }}
    >
      <Text variant="H4">{t('goPremiumScreen.title')}</Text>
      <Text sx={{ mt: 'xl', mb: 'm' }} variant="TitleSmall">
        {t('goPremiumScreen.subtitle')}
      </Text>
      <ChocoAsset
        prefix={getAssetsPrefix()}
        path="/illustrations/premium.svg"
      />

      <Text sx={{ mt: 's' }} variant="TitleLarge">
        {t('goPremiumScreen.featureHeader')}
      </Text>
      <PremiumFeature
        mt="xl"
        icon="clock"
        title={t('goPremiumScreen.feature1Title')}
        description={t('goPremiumScreen.feature1Description')}
      />
      <PremiumFeature
        icon="profile"
        title={t('goPremiumScreen.feature2Title')}
        description={t('goPremiumScreen.feature2Description')}
      />
      <PremiumFeature
        icon="reviewStars"
        title={t('goPremiumScreen.feature3Title')}
        description={t('goPremiumScreen.feature3Description')}
      />
      <Link
        to={
          onLetsTalkRedirect
            ? '#'
            : `/request-premium${addPhoneNumberQuery(phoneNumber)}`
        }
      >
        <Button
          type="submit"
          onClick={handleTrackButton}
          sx={{ width: '100%', mt: 'xxxl' }}
        >
          {t('goPremiumScreen.buttonCTA')}
        </Button>
      </Link>
    </Box>
  );
};
