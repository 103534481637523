import { useFlags } from '@chocoapp/launchdarkly-ui';
import React, { FC, useEffect, useMemo, ReactElement, ReactNode } from 'react';
import {
  useLocation,
  Route,
  Switch,
  Redirect,
  RouteComponentProps,
} from 'react-router-dom';

import PrivateRoute from './PrivateRoute';
import { SkipRouteWhenAuthenticated } from './SkipRouteWhenAuthenticated';

import { RoutesType, RouteType } from '.';

type InheritPropsType = {
  private?: RouteType['private'];
  layout?: RouteType['layout'];
  featureFlagKey?: RouteType['featureFlagKey'];
};

const updateRouteWithInheritance = (
  currentRoute: RouteType,
  inheritProps: InheritPropsType
) => ({
  ...currentRoute,
  private: currentRoute.private ?? inheritProps.private,
  layout: currentRoute.layout ?? inheritProps.layout,
  featureFlagKey: currentRoute.featureFlagKey ?? inheritProps.featureFlagKey,
});

const flattenRoutesWithInheritance = (
  initialRoutes: RoutesType,
  initialFlatRoutes: RouteType[] = [],
  inheritProps: InheritPropsType = {}
): RouteType[] => {
  if (!initialRoutes) return initialFlatRoutes;

  const flat: RouteType[] = initialFlatRoutes;

  Object.values(initialRoutes).forEach(
    ({ routes: childRoutes, ...currentRoute }) => {
      const updatedRoute = updateRouteWithInheritance(
        currentRoute,
        inheritProps
      );

      flat.push(updatedRoute);

      if (childRoutes) {
        // recursively apply flattenRoutes to possible childRoutes with updated inheritance
        flattenRoutesWithInheritance(childRoutes, flat, {
          private: updatedRoute.private,
          layout: updatedRoute.layout,
          featureFlagKey: updatedRoute.featureFlagKey,
        });
      }
    }
  );

  return flat;
};

type FeatureFlagProviderProps = {
  children: ReactNode;
} & RouteType;

const FeatureFlagWrapper = (props: FeatureFlagProviderProps) => {
  const flags = useFlags();

  const isDisabled =
    (props.featureFlagKey && flags[props.featureFlagKey] === false) ||
    props.disableForFlagKeys?.some((flagKey) => flags[flagKey] === true);

  if (isDisabled) return <Redirect to="/inbox" />;

  return <>{props.children}</>;
};

const getComponentWrapper = (props: RouteType) => {
  if (props.private) return PrivateRoute;
  if (props.skipIfAuthenticated) return SkipRouteWhenAuthenticated;

  return Route;
};

export const renderRoutes = (
  routes: RoutesType
): ReactElement<RouteComponentProps>[] => {
  return flattenRoutesWithInheritance(routes).map((props: RouteType) => {
    const RouteComponent = getComponentWrapper(props);
    const Component = props.component;
    const Layout = props.layout;

    return (
      <RouteComponent
        // remove react-key warning but prevent routes from remounting when react sees a key change
        // ref: https://stackoverflow.com/questions/59369709/how-to-pass-key-to-react-route
        key="0"
        exact={props.exact ?? true}
        path={props.path}
        render={(renderProps) => {
          if (!Layout) return <Component {...renderProps} />;

          return (
            <Layout>
              <FeatureFlagWrapper {...props}>
                <Component {...renderProps} />
              </FeatureFlagWrapper>
            </Layout>
          );
        }}
      />
    );
  });
};

const usePageView = () => {
  const location = useLocation();

  useEffect(() => {
    if (!window.Appcues) return;

    window.Appcues.page();
  }, [location]);
};

type RoutesProps = {
  routes: RoutesType;
};

const Routes: FC<RoutesProps> = ({ routes }) => {
  usePageView();

  // Switch only works with the first components level directly below it, so we need to render an array of routes without fragments
  // https://github.com/remix-run/react-router/issues/5785
  const AllRoutes = useMemo(() => renderRoutes(routes), [routes]);

  return (
    <Switch>
      {AllRoutes}
      {/* gracefully redirecting users that have favorited `/chats` to `/inbox`. This shall be removed in the future. */}
      <Route path="/chat" render={() => <Redirect to="/inbox" />} />
      {/* catch all unresolved paths and redirect to NotFoundPage */}
      <Redirect to="/not-found" />
    </Switch>
  );
};

export default Routes;
