import gql from 'graphql-tag';

import { BuyerEdgesFragment } from './BuyerFragments';

export default gql`
  fragment UserFragment on User {
    __typename
    id
    name
    phone
    businessName
    supplier
    email
    chocoUser
    supplierId
    botUser
    linkedSupplier {
      ...LinkedSupplierFragment
    }
    isTest
    isPuppy
    pending
    profileImage {
      smallSquare
    }
    buyers {
      ...BuyerEdgesFragment
    }
    hasAcceptedTermsAndConditions
    createdContext
  }

  fragment LinkedSupplierFragment on Supplier {
    id
    name
    referenceId
    accessPackage
    botUser {
      profileImage {
        smallSquare
      }
    }
    integration {
      ...SupplierIntegrationsFragment
    }
  }

  fragment SupplierIntegrationsFragment on IntegrationOption {
    ... on IntegrationEmailOption {
      emailAddress
      type
    }
  }

  ${BuyerEdgesFragment}
`;
