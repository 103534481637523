import {
  Box,
  ChocoAsset,
  Flex,
  ThemeUIStyleObject,
} from '@chocoapp/chocolate-ui';
import { trackSegmentPage } from '@chocoapp/toolbelt-utils';
import React, { FC, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import InviteBody from '../components/commons/Invite/InviteBody';
import InviteHeader from '../components/commons/Invite/InviteHeader';
import { INVITE_LINK_BASE_URL } from '../config';
import { getAssetsPrefix } from '../whiteLabel/utils';

const iconStyles: ThemeUIStyleObject = {
  position: 'absolute',
  left: '12px',
  top: '24px',
  width: '193px',
  height: '102px',
  filter:
    'invert(77%) sepia(10%) saturate(899%) hue-rotate(186deg) brightness(104%) contrast(106%)',
};

const InviteFallbackPage: FC = () => {
  const { search } = useLocation();
  const { replace } = useHistory();

  const params = new URLSearchParams(search);
  const referralCode = params.get('referralCode');

  const referralLink = `${INVITE_LINK_BASE_URL}/${referralCode}`;

  useEffect(() => {
    trackSegmentPage({
      category: 'supplierInviteLink',
      name: 'Web Fallback Page Viewed',
      props: {
        referral_code: referralCode,
      },
    });
  }, [referralCode]);

  useEffect(() => {
    if (!referralCode) {
      replace('/');
    }
  }, [referralCode, replace]);

  return (
    <Flex
      sx={{
        backgroundColor: 'baseBgMedium',
        alignItems: ['start', 'center'],
        justifyContent: 'center',
        width: '100%',
        overflowX: 'hidden',
        overflowY: ['scroll', 'hidden'],
      }}
    >
      <ChocoAsset
        sx={iconStyles}
        data-testid="StyledChocoLogo"
        prefix={getAssetsPrefix()}
        path="/brand/logo.svg"
      />
      <Box
        sx={{
          width: ['100%', 468],
          position: 'relative',
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            zIndex: 1,
            top: -85,
            right: -225,
            display: ['none', 'block'],
          }}
        >
          <ChocoAsset
            width={295}
            height={282}
            prefix={getAssetsPrefix()}
            path="/illustrations/envelope.svg"
          />
        </Box>
        <Box
          sx={{
            position: 'absolute',
            zIndex: 3,
            left: -353,
            bottom: -110,
            display: ['none', 'block'],
          }}
        >
          <ChocoAsset
            width={393}
            height={397}
            prefix={getAssetsPrefix()}
            path="/illustrations/panFlames.svg"
          />
        </Box>
        <Flex
          sx={{
            flexDirection: 'column',
            backgroundColor: 'cream',
            zIndex: 2,
            position: 'relative',
            borderRadius: 3,
            minHeight: ['100vh', 'auto'],
          }}
        >
          <InviteHeader />
          <InviteBody
            referralLink={referralLink}
            referralCode={referralCode!}
          />
        </Flex>
      </Box>
    </Flex>
  );
};

export default InviteFallbackPage;
