import {
  CircleSkeleton,
  useTheme,
  Flex,
  RectSkeleton,
} from '@chocoapp/chocolate-ui';
import React from 'react';

import HiddenWhenCollapsed from './HiddenWhenCollapsed';
import { wrapperStyles } from './styles';

export const SkeletonProfile = () => {
  const {
    theme: { colors },
  } = useTheme();

  const backgroundColor = colors.baseBgMedium;
  const foregroundColor = colors.baseBgStrong;

  return (
    <Flex sx={wrapperStyles}>
      <CircleSkeleton
        diameter={36}
        backgroundColor={backgroundColor}
        foregroundColor={foregroundColor}
        style={{ flexShrink: 0 }}
      />
      <HiddenWhenCollapsed>
        <Flex sx={{ alignItems: 'center', height: '20px' }}>
          <RectSkeleton
            width="85px"
            height="80%"
            backgroundColor={backgroundColor}
            foregroundColor={foregroundColor}
          />
        </Flex>
        <Flex sx={{ alignItems: 'center', height: '18px' }}>
          <RectSkeleton
            width="100%"
            height="80%"
            backgroundColor={backgroundColor}
            foregroundColor={foregroundColor}
          />
        </Flex>
      </HiddenWhenCollapsed>
    </Flex>
  );
};
