import {
  Box,
  ChocoIcon,
  Text,
  Link as ChocoLink,
} from '@chocoapp/chocolate-ui';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import getQueryString from '../../../hooks/getQueryString';
import { getSupportEmailByPhoneNumber } from '../../../util';
import { getPremiumUrlByPhoneNumber } from '../../../util/getPremiumUrlByPhoneNumber';

import { useTrackPaywall } from './useTrackPremiumScreen';
import { usePhoneNumber } from './utils';

export const RequestReceived = () => {
  const { t } = useTranslation('getInTouch');
  const { phoneNumber } = usePhoneNumber();
  const role = getQueryString('role');
  const fromContactInformation = getQueryString('fromContactInformation');

  const supportEmail = getSupportEmailByPhoneNumber(phoneNumber || '');
  const premiumUrl = getPremiumUrlByPhoneNumber(phoneNumber || '');

  const trackEvent = useTrackPaywall({
    name: 'Paywall Request Received',
    props: {
      from: fromContactInformation ? 'contactInformationViewed' : 'other',
      ...(role && { supplier_role: role }),
    },
  });

  const handleTracking = () => {
    trackEvent({
      event: 'Paywall Support Email Clicked',
      data: {
        supplier_role: role,
      },
    });
  };

  return (
    <Box data-testid="RequestReceived" sx={{ textAlign: 'center' }}>
      <Box sx={{ mb: 'm', mt: 'xl', display: 'inline-flex' }}>
        <ChocoIcon name="checkCircleFilled" color="positiveIcon" size="l" />
      </Box>
      <Text variant="H4" sx={{ mb: 'xl', color: 'baseTextStrong' }}>
        {t('requestReceived.title')}
      </Text>
      <Text sx={{ mb: 'xxxxl', color: 'baseTextStrong' }} variant="TitleSmall">
        {t('requestReceived.subtitle')}
      </Text>
      <Box sx={{ p: 'm', bg: 'neutralBgStrong', color: 'baseTextStrong' }}>
        <Trans ns="getInTouch" i18nKey="requestReceived.contactPremiumInfo">
          Visit our website to learn more about
          <Link
            target="_blank"
            style={{ color: 'unset' }}
            to={{ pathname: premiumUrl }}
          >
            Choco Premium
          </Link>
          . Or contact us at:
        </Trans>
        <br />
        <ChocoLink
          variant="BodyStrong"
          href={`mailto:${supportEmail}`}
          onClick={handleTracking}
        >
          {supportEmail}
        </ChocoLink>
      </Box>
    </Box>
  );
};
