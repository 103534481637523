import React, { FC } from 'react';
import { Redirect } from 'react-router-dom';

import { useAuthContext } from '../auth';
import { getDecodedProductListJwtToken } from '../hooks';
import { useIsUserInChat } from '../hooks/useIsUserInChat';

import WebLitePage from './WebLitePage';

export const MIN_SCREEN_SIZE_FOR_INBOX = 1024;

const ValidTokenPage: FC = () => {
  const { chatId } = getDecodedProductListJwtToken<{ chatId?: string }>();
  const { isAuthenticated } = useAuthContext();
  const { isUserInChat, loading } = useIsUserInChat();

  if (loading) return null;

  const screenWidth = window.screen.width;
  const screenHeight = window.screen.height;

  const webScreenSizeOk =
    screenWidth >= MIN_SCREEN_SIZE_FOR_INBOX ||
    screenHeight >= MIN_SCREEN_SIZE_FOR_INBOX;

  return isAuthenticated() && isUserInChat && webScreenSizeOk ? (
    // TODO(markus): handle chatId being undefined
    <Redirect to={`/inbox/${chatId}/products`} />
  ) : (
    <WebLitePage />
  );
};

export default ValidTokenPage;
