import { Flex, Text } from '@chocoapp/chocolate-ui';
import React, { FC } from 'react';

type UnreadMessagesProps = {
  count: number;
};

const UnreadMessages: FC<UnreadMessagesProps> = ({ count }) => (
  <Flex
    sx={{
      position: 'absolute',
      top: '0',
      left: '42px',
      height: '20px',
      borderRadius: '60px',
      backgroundColor: 'baseBg',
      color: 'baseTextWeak',
      px: '8px',
      alignContent: 'center',
      alignItems: 'center',
      justifyContent: 'center',
      flexShrink: 0,
      zIndex: 'tooltip',
    }}
    data-testid={`chocoWebUnreadMessagesCount_${count}`}
  >
    <Text variant="Text">{count}</Text>
  </Flex>
);

export default UnreadMessages;
