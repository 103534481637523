// if using
// const userId = window.choco.auth.getCurrentUserId();
// the flow won't work with Supplier Admin that can see a chat and confirm it
// because of how getCurrentUserId works - getBotUserId is initially null
// even tho at some point we get the real bot user id,
// isUserInChat is called twice, the first time with getUserIdFromCognitoSync
// because of the fallback when checking cognitoUser?.getSignInUserSession() that is initially null

import { useAuthContext } from '../auth';
import useIsUserInChatQuery from '../data/useIsUserInChatQuery';

import getDecodedProductListJwtToken from './getDecodedProductListJwtToken';

// so getBotUserId returns null and the user id returned will be the one from getUserIdFromCognitoSync
export const useIsUserInChat = () => {
  const { chatId } = getDecodedProductListJwtToken<{ chatId?: string }>();

  // this returns directly getUserIdFromCognitoSync
  const { userPool, getCurrentUserId } = useAuthContext();
  const userId = getCurrentUserId(userPool);

  // this checks also botUserId
  // const userId = window.choco.auth.getCurrentUserId();

  // TODO(markus): handle chatId being undefined
  return useIsUserInChatQuery({ userId, chatId: chatId! });
};
