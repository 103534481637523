import queryString from 'query-string';
import { useCallback } from 'react';
import { useLocation } from 'react-router-dom';

import { useHistoryPush } from '../router';

export const useRedirectAfterLogin = () => {
  const { namedHistoryPush, historyPush } = useHistoryPush();
  const location = useLocation();

  return useCallback(
    (hasAcceptedTermsAndConditions: boolean) => {
      const { next } = queryString.parse(location.search);

      if (!hasAcceptedTermsAndConditions) {
        namedHistoryPush({
          to: 'auth:terms-and-conditions',
        });
        return;
      }

      if (next && typeof next === 'string') {
        historyPush({ to: next });
      } else {
        namedHistoryPush({ to: 'main' });
      }
    },
    [location, namedHistoryPush, historyPush]
  );
};
