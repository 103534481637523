import {
  setLocalStorageItem,
  getLocalStorageItem,
} from '@chocoapp/toolbelt-utils';

import { REACT_APP_ENV_PREFIX } from '../../config';
import { OVERRIDE_STATE_KEY } from '../../constants/AppConstants';

import { ImportMapType } from './prefetchMFEs';

const getOverrideState = () => {
  const urlParams = new URLSearchParams(window.location.search);

  const overrideStateUrl = urlParams.get(OVERRIDE_STATE_KEY);

  /*
  this will enable more than one MFE overridden
  TODO (Sara) uncomment when we'll have a proper UI to handle this case
  
  const overrideStateUrlObj: Object = overrideStateUrl
    ? JSON.parse(overrideStateUrl)
    : {};

  const overrideStateObj = {
    ...getLocalStorageItem(OVERRIDE_STATE_KEY),
    ...overrideStateUrlObj,
  };
   */

  const overrideStateObj: object = overrideStateUrl
    ? JSON.parse(overrideStateUrl)
    : getLocalStorageItem(OVERRIDE_STATE_KEY);

  setLocalStorageItem(OVERRIDE_STATE_KEY, overrideStateObj);

  return overrideStateObj;
};

export const getOverrideDeps = (deps: ImportMapType): ImportMapType => {
  if (REACT_APP_ENV_PREFIX === 'choprd') return deps;

  const overrideStateObj = getOverrideState();

  const overrideDeps = {
    ...deps,
    imports: {
      ...deps.imports,
      ...overrideStateObj,
    },
  };

  return overrideDeps;
};

/**
 * insertNewImportMap
 * @description used to append an importmap directly into to the DOM
 * @param {ObjectOrArray<string>} deps
 */
export default function insertNewImportMap(
  deps: ImportMapType,
  id?: string,
  position?: InsertPosition
) {
  const overrideDeps = getOverrideDeps(deps);

  const newScript = document.createElement('script');
  newScript.type = 'systemjs-importmap';
  newScript.text = JSON.stringify(overrideDeps);
  newScript.id = id ?? '';

  const allMaps = document.querySelectorAll(
    'script[type="systemjs-importmap"]'
  );

  if (allMaps.length) {
    allMaps[allMaps.length - 1].insertAdjacentElement(
      position ?? 'afterend',
      newScript
    );
  } else {
    document.head.appendChild(newScript);
  }
}
