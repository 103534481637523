import { AuthLayout } from './auth/AuthLayout';
import { AlmostTherePage } from './pages/AlmostTherePage';
import ConfirmSignUpPage from './pages/ConfirmSignUpPage';
import { CreateBusinessPage } from './pages/CreateBusinessPage';
import { CreateOrJoinBusinessPage } from './pages/CreateOrJoinBusinessPage';
import { CreateProfilePage } from './pages/CreateProfilePage';
import GoPremiumScreen from './pages/GetInTouch/GoPremiumScreen';
import RequestPremiumScreen from './pages/GetInTouch/RequestPremiumScreen';
import RequestReceivedScreen from './pages/GetInTouch/RequestReceivedScreen';
import InviteFallbackPage from './pages/InviteFallbackPage';
import { JoinBusinessPage } from './pages/JoinBusinessPage';
import LoginPage from './pages/LoginPage';
import LogoutPage from './pages/LogoutPage';
import MainPage from './pages/MainPage';
import BroadcastPage from './pages/MFEs/BroadcastPage';
import CatalogPage from './pages/MFEs/CatalogPage';
import ContactsPage from './pages/MFEs/ContactsPage';
import InboxPage from './pages/MFEs/InboxPage';
import InventoryPage from './pages/MFEs/InventoryPage';
import OrdersPage from './pages/MFEs/OrdersPage';
import PaymentsPage from './pages/MFEs/PaymentsPage';
import ReportingPage from './pages/MFEs/ReportingPage';
import SettingsPage from './pages/MFEs/SettingsPage';
import TeamPage from './pages/MFEs/TeamPage';
import NotFoundPage from './pages/NotFoundPage';
import OneTimePasswordPage from './pages/OneTimePasswordPage';
import OrderConfirmationPage from './pages/OrderConfirmationPage';
import OrderPageToChocoLite from './pages/OrderPageToChocoLite';
import ProductsPage from './pages/ProductsPage';
import ReLoginPage from './pages/ReLoginPage';
import SupportLoginPage from './pages/SupportLoginPage';
import { TermsAndConditionsPage } from './pages/TermsAndConditionsPage';
import { RoutesType } from './router';

export const routes: RoutesType = {
  main: {
    path: '/',
    component: InboxPage,
    layout: MainPage,
    private: true,
    routes: {
      orders: {
        path: '/orders/:tab?/:orderId?/:subEntity?/:action?',
        component: OrdersPage,
      },
      inventoryV2: {
        path: '/inventory-v2',
        featureFlagKey: 'buyerInventoryManagementV2',
        component: InventoryPage,
        routes: {
          items: {
            path: '/inventory-v2/items',
            component: InventoryPage,
            routes: {
              locationItems: {
                path: '/inventory-v2/items/:locationId',
                component: InventoryPage,
              },
              createItem: {
                path: '/inventory-v2/items/:locationId/create',
                component: InventoryPage,
              },
              uploadItems: {
                path: '/inventory-v2/items/:locationId/upload',
                component: InventoryPage,
              },
            },
          },
          counts: {
            path: '/inventory-v2/counts',
            component: InventoryPage,
            routes: {
              locationCounts: {
                path: '/inventory-v2/counts/:locationId',
                component: InventoryPage,
              },
              locationCreateCount: {
                path: '/inventory-v2/counts/:locationId/create',
                component: InventoryPage,
              },
              locationCountSummary: {
                path: '/inventory-v2/counts/:locationId/summary',
                component: InventoryPage,
              },
            },
          },
        },
      },
      payments: {
        path: '/payments',
        component: PaymentsPage,
        featureFlagKey: 'supplierPaymentsTabWeb',
        routes: {
          invoices: {
            path: '/payments/invoices',
            component: PaymentsPage,
          },
          settlementReport: {
            path: '/payments/settlement-report',
            component: PaymentsPage,
          },
          creditCheck: {
            path: '/payments/credit-check',
            component: PaymentsPage,
            routes: {
              creditCheckModalDone: {
                path: '/payments/credit-check/done',
                component: PaymentsPage,
              },
            },
          },
        },
      },
      reporting: {
        path: '/reporting',
        component: ReportingPage,
      },
      team: {
        path: '/team',
        component: TeamPage,
        routes: {
          location: {
            path: '/team/:locationId',
            component: TeamPage,
          },
        },
      },
      inbox: {
        path: '/inbox',
        component: InboxPage,
        routes: {
          locations: {
            path: '/inbox/locations',
            component: InboxPage,
          },
          messages: {
            path: '/inbox/:chatId',
            component: InboxPage,
          },
          products: {
            path: '/inbox/:chatId/products',
            component: InboxPage,
          },
          editOrderGuide: {
            path: '/inbox/:chatId/edit',
            component: InboxPage,
          },
          order: {
            path: '/inbox/:chatId/order/:orderId',
            component: InboxPage,
          },
          deliveryCheck: {
            path: '/inbox/:chatId/deliveryCheck/:deliveryCheckOrderId',
            component: InboxPage,
          },
          createProduct: {
            path: '/inbox/:chatId/products/create',
            component: InboxPage,
          },
          placeOrder: {
            path: '/inbox/:chatId/place-order',
            component: InboxPage,
          },
          cart: {
            path: '/inbox/:chatId/cart',
            component: InboxPage,
          },
          noChats: {
            path: '/inbox-no-chats',
            component: InboxPage,
          },
        },
      },
      contacts: {
        path: '/contacts',
        component: ContactsPage,
        routes: {
          customers: {
            path: '/contacts/customers',
            component: ContactsPage,
          },
          customerDetailsV2: {
            path: '/contacts/customer-details/:id/:tab?',
            component: ContactsPage,
          },
          invite: {
            path: '/contacts/invite',
            component: ContactsPage,
          },
          step: {
            path: '/contacts/invite/:step',
            component: ContactsPage,
          },
          productList: {
            path: '/contacts/product-list',
            component: ContactsPage,
          },
          invitev2: {
            path: '/contacts/invite-v2',
            component: ContactsPage,
          },
        },
      },
      catalog: {
        path: '/catalog',
        component: CatalogPage,
        routes: {
          'create-product': {
            path: '/catalog/create',
            component: CatalogPage,
          },
          'create-catalog': {
            path: '/catalog/create-catalog',
            component: CatalogPage,
          },
          'create-product-v2': {
            path: '/catalog/create-v2',
            component: CatalogPage,
          },
          'edit-product': {
            path: '/catalog/edit/:productId',
            component: CatalogPage,
          },
          'catalog-edit': {
            path: '/catalog/:catalogId/edit',
            component: CatalogPage,
          },
        },
      },
      broadcast: {
        path: '/broadcast',
        component: BroadcastPage,
        // Hide broadcast feature for LOX vendors
        disableForFlagKeys: ['loxMvp'],
        routes: {
          'create-broadcast': {
            path: '/broadcast/create',
            component: BroadcastPage,
          },
          'create-broadcast-with-id': {
            path: '/broadcast/create/:id',
            component: BroadcastPage,
          },
          success: {
            path: '/broadcast/success',
            component: BroadcastPage,
          },
          'broadcast-detail': {
            path: '/broadcast/:id',
            component: BroadcastPage,
          },
        },
      },
      settings: {
        path: '/settings/:param1?/:param2?/:param3?/:param4?/:param5?',
        component: SettingsPage,
      },
    },
  },
  auth: {
    path: '/',
    component: LoginPage,
    layout: AuthLayout,
    routes: {
      login: {
        path: '/login',
        skipIfAuthenticated: true,
        component: LoginPage,
      },
      logout: {
        path: '/logout',
        component: LogoutPage,
      },
      'one-time-password': {
        path: '/sessions/two-factor',
        skipIfAuthenticated: true,
        component: OneTimePasswordPage,
      },
      'confirm-signup': {
        path: '/sessions/confirm-signup',
        skipIfAuthenticated: true,
        component: ConfirmSignUpPage,
      },
      'create-profile': {
        path: '/create-profile',
        private: true,
        component: CreateProfilePage,
      },
      'create-or-join': {
        path: '/create-or-join',
        private: true,
        component: CreateOrJoinBusinessPage,
      },
      'create-business': {
        path: '/create-business',
        private: true,
        component: CreateBusinessPage,
      },
      'join-business': {
        path: '/join-business',
        private: true,
        component: JoinBusinessPage,
      },
      'almost-there': {
        path: '/almost-there',
        private: true,
        component: AlmostTherePage,
      },
      'support-login': {
        path: '/support-login',
        component: SupportLoginPage,
      },
      getInTouch: {
        path: '/get-in-touch',
        component: GoPremiumScreen,
      },
      requestPremium: {
        path: '/request-premium',
        component: RequestPremiumScreen,
      },
      requestReceived: {
        path: '/request-received',
        component: RequestReceivedScreen,
      },
      'terms-and-conditions': {
        path: '/terms-and-conditions',
        component: TermsAndConditionsPage,
        private: true,
      },
    },
  },
  products: {
    path: '/products',
    component: ProductsPage,
  },
  orderConfirm: {
    path: '/order/confirm',
    component: OrderConfirmationPage,
  },
  order: {
    path: '/order',
    component: OrderPageToChocoLite,
  },
  're-login': {
    path: '/re-login',
    component: ReLoginPage,
  },
  invite: {
    path: '/invite',
    component: InviteFallbackPage,
  },
  'not-found': {
    path: '/not-found',
    component: NotFoundPage,
  },
};

export default routes;
