export const DE_AT_LUX_PREMIUM_URL = 'https://choco.com/de/premium';
export const FR_BE_CH_PREMIUM_URL = 'https://choco.com/fr/premium';
export const USA_PREMIUM_URL = 'https://choco.com/us/premium';
export const ES_PREMIUM_URL = 'https://choco.com/es/premium';
export const UK_PREMIUM_URL = 'https://choco.com/uk/premium';
export const DEFAULT_PREMIUM_URL = 'https://choco.com/de/en/premium';

export const getPremiumUrlByPhoneNumber = (phoneNumber: string): string => {
  switch (true) {
    case /(^\+49)|(^\+43)|(^\+352)/.test(phoneNumber): // DE, AT, LUX
      return DE_AT_LUX_PREMIUM_URL;
    case /(^\+32)|(^\+33)|(^\+41)/.test(phoneNumber): // FR, BE, CH
      return FR_BE_CH_PREMIUM_URL;
    case /(^\+1)/.test(phoneNumber): // US
      return USA_PREMIUM_URL;
    case /(^\+34)/.test(phoneNumber): // ES
      return ES_PREMIUM_URL;
    case /(^\+44)/.test(phoneNumber): // UK
      return UK_PREMIUM_URL;
    default:
      return DEFAULT_PREMIUM_URL; // REST OF WORLD ;-)
  }
};
