import {
  Text,
  ChocoIcon,
  Flex,
  ThemeUIStyleObject,
} from '@chocoapp/chocolate-ui';
import React, { MouseEvent, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

const backButtonStyles: ThemeUIStyleObject = {
  position: 'absolute',
  gap: 'xs',
  top: 'l',
  left: 'm',
  alignItems: 'center',
  textDecoration: 'none',
  cursor: 'pointer',
  color: 'baseText',
  '&:hover': {
    color: 'baseTextActive',
  },
};

export type AuthBackButtonProps = {
  label?: string;
  href?: string;
  onClick?: React.MouseEventHandler;
};

export const AuthBackButton = ({
  label,
  href,
  onClick,
}: AuthBackButtonProps) => {
  const { t } = useTranslation('auth');
  const { goBack, push } = useHistory();

  const onClickHandler = useCallback(
    (e: MouseEvent) => {
      if (!onClick && !href) goBack();

      if (onClick) onClick(e);
      if (href) push(href);
    },
    [goBack, href, onClick, push]
  );

  return (
    <Flex as="a" sx={backButtonStyles} onClick={onClickHandler}>
      <ChocoIcon name="arrowLeft" />
      <Text variant="Subhead">{label ?? t('back')}</Text>
    </Flex>
  );
};
