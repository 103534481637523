import { useState, useEffect, useRef } from 'react';

import { CountryCode } from '../components/commons/Login/CountryCode';
import { fetchUserGeoLocationData } from '../data/fetchUserGeoLocationData';

export const useUserCountry = (setCountryCode: React.Dispatch<CountryCode>) => {
  const isRequestResultIgnored = useRef(false);
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    fetchUserGeoLocationData().then(res => {
      setLoading(false);

      if (isRequestResultIgnored.current) return;

      setCountryCode(res.countryCode);
    });
  }, [isRequestResultIgnored, setCountryCode]);

  const ignoreGeoIpRequestData = () => {
    isRequestResultIgnored.current = true;
  };

  return {
    isLoading,
    ignoreGeoIpRequestData,
  };
};
